import React, {Component} from "react";
import {Button, Form, FormGroup, InputGroup} from "react-bootstrap";
import PropTypes from "prop-types";
import {insertRootCategory} from "../actions/categoriesActions";
import {insertSubCategory} from "../actions/categoriesActions";
import { hasErrors } from "./validations";

class CategoryEntryForm extends Component {

    constructor(props) {

        super(props);

        this.state = {
            name: '',
            error: '',
            nameIsTouched: false
        };

        this.validateForm = this.validateForm.bind(this);
        this.dismissError = this.dismissError.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setError = this.setError.bind(this);
    };

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value,
            [event.target.id + 'IsTouched']: true
        });
    };

    handleSubmit(event) {

        event.preventDefault();

          if (this.validateForm()) {
              let categoryObject = {
                  projectId: this.props.projectId,
                  parentId: this.props.parentId,
                  name: this.state.name,
                  type: this.props.categoryType.type
              };
              if (this.props.parentId !== null && this.props.parentId !== '' && this.props.parentId !== undefined) {
                insertSubCategory(this.props.parentId, categoryObject).then((result) => {
                  if (result.code) {
                    return this.setState({error: result.message});
                  } else {
                    this.props.onSuccessfulSubmit(result);
                  }
                });
              } else {
                insertRootCategory(categoryObject).then((result) => {
                  if (result.code) {
                    return this.setState({error: result.message});
                  } else {
                    this.props.onSuccessfulSubmit(result);
                  }
                });
              }
              return this.setState({name: '', error: '', nameIsTouched: false});
          }
    }

    validateForm() {
      const FormLabel = this.props.componentType === 'subcategory' ?
          this.props.categoryType.displayValue + " Subcategory" : this.props.categoryType.displayValue + " Category";
        return !hasErrors(this.state.name, "Add " + FormLabel, true, false, 'text', this.setError);
    }

    dismissError() {
        this.setState({error: ''});
    }

    setError(error) {
      this.setState({error: error});
    }

    render() {

        const inputClassName = " mb-0 ";
        const FormLabel = this.props.componentType === 'subcategory' ?
            this.props.categoryType.displayValue + " Subcategory" : this.props.categoryType.displayValue + " Category";
        const formClassName = this.props.componentType === 'subcategory' ?
            'add-category add-subcategory pl-5' : 'add-category';

        // noinspection CheckTagEmptyBody
        return (

            <form className={formClassName} onSubmit={this.handleSubmit}>
                <FormGroup className={inputClassName} controlId="name">
                  <InputGroup>
                    <Form.Control      
                      placeholder={"Add " + FormLabel}
                      type="text"
                      value={this.state.name}
                      onChange={this.handleChange}
                      autoComplete="off" />
                      <span className="input-group-btn">
                    <Button
                      className="btn-secondary rounded-0"
                      type="submit" variant="secondary"
                      //disabled={!this.validateForm()}
                    >
                        Add
                    </Button>
                    </span>
                  </InputGroup>
                </FormGroup>
                <div className="text-danger">
                  <small>
                    {this.state.nameIsTouched && hasErrors(this.state.name, "Add " + FormLabel, false, true, 'text', this.setError)}
                  </small>
                </div>
            </form>
        );
    }
}

CategoryEntryForm.propTypes = {
    projectId: PropTypes.string.isRequired,
    parentId: PropTypes.string,
    onSuccessfulSubmit: PropTypes.func.isRequired,
    componentType: PropTypes.string.isRequired,
    categoryType: PropTypes.shape({
        displayValue: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    }).isRequired,
};

// componentType = category or subcategory
// categoryType = COST, INCOME, FINANCING or INVESTMENT
// entityType = Project or Template

export default CategoryEntryForm;
