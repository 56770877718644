import React from 'react';
import {Link} from "react-router-dom";
import './Breadcrumbs.css';

const Breadcrumbs = (props) => {
  const { breadcrumbs } = props;
  return (
   <nav className="kb-nav_breadcrumb breadcrumb border-bottom bg-none py-0 px-3">
            <ol className="breadcrumb align-items-center">
      {breadcrumbs.map((breadcrumb, i) => {
        return breadcrumb && (
          <React.Fragment
          key={i + 'Fragmentbreadcrumbs[key]'}>

            {i !== breadcrumbs.length - 1 ? (
              <React.Fragment>
              <li className="breadcrumb-item">
              <Link
                className={i === breadcrumbs.length - 1 ? 'text-secondary active' : 'text-secondary'}
                to={breadcrumb.url}
                key={i + 'Crumbbreadcrumbs[key]'}
              >
                {breadcrumb.title}
              </Link>
             </li>
              </React.Fragment>
            ) : (
                 <li className="breadcrumb-item active " aria-current="page">
              <span className="breadcrumb-item active">
                {breadcrumb.title}
              </span>
              </li>
            )}
          </React.Fragment>
        );
      })}
     </ol>
        </nav>
  );
};

export default Breadcrumbs;



