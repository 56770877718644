import React, {Fragment} from 'react';
import PropTypes from "prop-types";

function PermissionsTableHeader(props) {
  return (
    <thead>
      <tr>
        <th scope="col">
          <span className="d-block py-3">List of permissions</span>
        </th>
        
        {props.referencePermissions.map((value, index) => (
          <Fragment key={index}>
              <th scope="col" className={"text-break custom-col-" + (index + 2)}>
                {props.roleName || props.roleName === '' ? props.uniqueRoleId === value.name ? props.roleName : value.name : value.name}
              </th>
          </Fragment>
        ))}
      </tr>
    </thead>
  );
}

PermissionsTableHeader.propTypes = {
  referencePermissions: PropTypes.arrayOf(
      PropTypes.shape({
          name: PropTypes.string.isRequired
      }).isRequired
  ).isRequired
};

export default PermissionsTableHeader;
