import React from "react";
import Breadcrumbs from "../components-shared/Breadcrumbs/Breadcrumbs";

// noinspection JSUnresolvedFunction
const underConstructionPng = require("../assets/images/under-construction.png")

export default function EmptyPage(props) {

    return (
        <div>
            <Breadcrumbs breadcrumbs={[{url: '/projects', title: 'Home'}, {url: `/emptyPage`, title: `Page title here`}]} />
            <h2 className="title">Page title here</h2>
            <div className="content-placeholder">
                <img src={underConstructionPng} alt="under construction"/>
            </div>

        </div>
    );
}
