import React, {Component, Fragment } from "react";
import {confirmAlert} from "react-confirm-alert";
import {getCategoryBudget, saveBudgetExecutionForecasts, validateBudgetExceeded, getCashPositonAction} from "../../category/actions/budgetExecutionActions";
import {
  freezeIRR,
  getProjectById,
  getProjectOpenedMonthsById
} from "../../project/actions/projectActions";
import {
  getPreviousAndNextCategory
} from "../../category/actions/categoriesActions";
import PageSection from "../../../components-shared/PageSection";
import {getAllContractors, getAllVATs} from "../../user/actions/userActions";
import NewCostContractForm from "../cost/forms/NewCostContractForm";
import QuickAddContractor from "./QuickAddContractor";
import MonthlyExecutionRemainingForecastView from "../../budget/support/forecast/MonthlyExecutionRemainingForecastView";
import MonthlyExecutionContractForecastView from "../../budget/support/forecast/MonthlyExecutionContractForecastView";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {deepClone} from "../../common/deepClone";
import moment from "moment";
import {monthAndYearMomentFromDateString} from "../../common/dateFormatting";
import DateRangeNavigator from "../../budget/support/forecast/DateRangeNavigator";
import {prettyPrint} from "../../common/numberOperations";
import {addDoubles } from "../../common/doubleOperations";
import {deleteAllForecastsByCriteria, getAllForecastsByCriteria, getAllForecastsByCriteriaNoLoading} from "../../forecast/actions/forecastActions";
import {committedForecast, remainingToForecast} from "../../forecast/support/ForecastConstants";
import ProjectNavigation from "../../project/ProjectNavigation";
import {getAllContractsWithForecastsByCategoryId, getAllContractsByCategoryIdNoForecasts} from "../actions/contractActions";
import RenderIf from "../../../main-components/RenderIf";
import {
  augmentContractForecasts,
  augmentForecasts,
  computeDateRangeLimit,
  computeDisplayedDateRange,
  computeDisplayedDateRangeDefault,
  computeForecastSum,
  findContractForecastArray,
  findForecastArray,
  forecastsForCurrentDateRange,
  generateSyntheticForecast,
  prepareForecastsForSave,
  updateForecastArrayWithSplit,
} from "./budgetExecutionOperations";
import {getCurrentDate} from "../../category/actions/currentDateActions";
import {toast} from 'react-toastify';
import {getCashPositionTableData} from "../../statistics/actions/statisticsActions";
import {categoryTypeInvestment} from "../../category/constant/CategoryConstants";
import Breadcrumbs from "../../../components-shared/Breadcrumbs/Breadcrumbs";
import RightSideModal from "../../../components-shared/RightSideModal/RightSideModal";
import {Button} from "react-bootstrap";
import {Tab, Tabs} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import DrawdownReimbursmentData from "../../budget/support/forecast/DrawdownReimbursmentData";

class ManageExecutionOnSubcategoryPage extends Component {

  constructor (props) {

    super(props);

    this.state = ManageExecutionOnSubcategoryPage.constructStateObject();

    this.getCategoryId = this.getCategoryId.bind(this);
    this.getCashPositon = this.getCashPositon.bind(this);

    this.getProjectDetailsAndForecasts = this.getProjectDetailsAndForecasts.bind(this);
    this.getAllContractors = this.getAllContractors.bind(this);

    this.contractUpdated = this.contractUpdated.bind(this);

    this.contractForecastUpdated = this.contractForecastUpdated.bind(this);
    this.remainingForecastUpdated = this.remainingForecastUpdated.bind(this);

    this.toggleAddContractor = this.toggleAddContractor.bind(this);
    this.updateDateRange = this.updateDateRange.bind(this);
    this.updateHeadingDateRange = this.updateHeadingDateRange.bind(this);
    this.updateCashflowDateRange = this.updateCashflowDateRange.bind(this);
    this.saveForecasts = this.saveForecasts.bind(this);
    this.saveSplits = this.saveSplits.bind(this);
    this.doSaveSplits = this.doSaveSplits.bind(this);
    this.saveHeadingSplits = this.saveHeadingSplits.bind(this);
    this.doHeadingSaveSplits = this.doHeadingSaveSplits.bind(this);
    this.moveRight = this.moveRight.bind(this);
    this.doMoveRight = this.doMoveRight.bind(this);
    this.moveHeadingRight = this.moveHeadingRight.bind(this);
    this.moveCashflow = this.moveCashflow.bind(this);
    this.dismissError = this.dismissError.bind(this);

    this.resetContractForecasts = this.resetContractForecasts.bind(this);
    this.resetRemainingForecasts = this.resetRemainingForecasts.bind(this);
    this.clearAllForecasts = this.clearAllForecasts.bind(this);
    this.getDefaultVat = this.getDefaultVat.bind(this);
    this.seeAllInvoices = this.seeAllInvoices.bind(this);
    this.addInvoice = this.addInvoice.bind(this);
    this.toggleAddInvoice = this.toggleAddInvoice.bind(this);
    this.cancelForm = this.cancelForm.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.changeTabHeader = this.changeTabHeader.bind(this);
    this.augmentContractForecastsAndReplaceDisplayValues = this.augmentContractForecastsAndReplaceDisplayValues.bind(this);
    this.getTotalToBeInvoiced = this.getTotalToBeInvoiced.bind(this);
    this.getTotalInvoiced = this.getTotalInvoiced.bind(this);
    this.getTotalCommitted = this.getTotalCommitted.bind(this);
    this.initComponent = this.initComponent.bind(this);
    this.displayIBTotal = this.displayIBTotal.bind(this);
    this.cashPositionUpdated = this.cashPositionUpdated.bind(this);
    this.freezeIRR = this.freezeIRR.bind(this);
  }

  static constructStateObject () {
    return {
      activeTab: 'Initial',
      activeTabHeader: 'Initial',
      projectDetails: {
        name: "",
        location: ""
      },
      categoryDetails: {
        name: "",
        budget: 0,
        committed: 0,
        invoiced: 0,
        type: "",
      },
      cashPosition: [],
      addingContractor: false,
      addingInvoice: false,

      dateRangeLimit: {
        start: moment(),
        end: moment()
      },
      displayedDateRange: {
        start: moment(),
        end: moment()
      },
      displayedHeadingDateRange: {
        start: moment(),
        end: moment()
      },
      dateHeadingRangeLimit: {
        start: moment(),
        end: moment()
      },
      cashflowCalendars: [],
      currentDateMoment: moment(),
      currentHeadingDateMoment: moment(),
      currentCashflowDateMoment: moment(),
      // arrays containing values that keep updating when modifying page information
      remainingForecasts: [],
      remainingHeadingForecasts: [],
      contractsWithForecasts: [],
      previousNextCategory: {},
      // copy of the initial values (used for the "reset" functionality)
      initialForecastState: {
        remainingForecasts: [],
        contractsWithForecasts: [],
        remainingHeadingForecasts: []
      },
      contractors: [],
      aboveBudgetWarning: false,
      error: '',
      // Statistics needed on this page to know what still needs to be invested / month
      cashPositionData: undefined,
      vatList: [],
      defaultVat: 19,
      defaultVatId: undefined,
      openedMonths: [],
      shouldLoad: true,
      displayedCashflowDateRange: '',
    }
  }

  /**
   * Get category details, budget for category, the list of all contractors.
   * Also get remaining and contract forecasts for the category.
   */
  componentDidMount () {
    this.initComponent();
  }

  initComponent(){
    this.getCategoryDetails();

    this.getProjectDetailsAndForecasts();

    this.getAllContractors();

    this.getAllVATs();

    this.getCashPositon(this.getCategoryId());

    getProjectOpenedMonthsById(this.props.match.params.projectId).then((result) => {
      if (!result.code) {
        this.setState({
          ...this.state,
          openedMonths: result
        })
      }
    })
  }


  getCashPositon(id) {
    getCashPositonAction(id).then((result) => {
      if (!result.code) {
        this.setState({
          ...this.state,
          cashPosition: result
        })
      }
    })
  }

  freezeIRR() {

    confirmAlert({
      title: 'Confirm to proceed',
      message: 'Are you sure you want to freeze the IRR? Once frozen it cannot be unfrozen',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            freezeIRR(this.props.match.params.projectId).then((result) => {
              if (result && result.code === 13008) {
                toast.error("IRR Already frozen!", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 5000,
                });
              } else if (result) {
                toast.error("Unable to freeze IRR!", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 5000,
                });
              } else {
                toast.info("The IRR was frozen!", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 5000,
                });
              }
            })
          }
        },
        {
          label: 'No'
        }
      ]
    });

  }

  getTotalToBeInvoiced() {
    return this.getTotalCommitted() - this.getTotalInvoiced();
  }

  getTotalInvoiced() {
    return this.state.contractsWithForecasts.reduce(function (accumulator, contractWithForecasts) {
      return accumulator + contractWithForecasts.invoiced;
    }, 0);
  }

  getTotalCommitted() {
    return this.state.contractsWithForecasts.reduce(function (accumulator, contractWithForecasts) {
      return accumulator + contractWithForecasts.amount;
    }, 0);
  }

  changeTab(key) {
    this.setState({
      activeTab: key
    });
  }

  changeTabHeader(key) {
    this.setState({
      activeTabHeader: key
    });
  }

  getAllVATs () {
    getAllVATs().then((vatList) => {
      let defaultVatId = this.getDefaultVat(vatList);
      this.setState({
        vatList: vatList,
        defaultVatId: defaultVatId
      });
    });
  }

  getDefaultVat (vatList) {
    const defaultVatIdArray = vatList &&
      vatList.filter(el => el.vatValue === this.state.defaultVat);
    const defaultVatId = defaultVatIdArray.length > 0 ? defaultVatIdArray[0].id : undefined;
    return defaultVatId;
  };

  getCategoryDetails () {

    getCategoryBudget(this.getCategoryId()).then((categoryDetails) => {
      const isCostOrIncome = categoryDetails.type === 'COST' || categoryDetails.type === 'INCOME';
      const hasExecutionBudget = categoryDetails.executionBudget;
      const activeTab = isCostOrIncome && hasExecutionBudget !== null ? 'Actual' : 'Initial';
      this.setState({
        activeTab: activeTab,
        categoryDetails: categoryDetails
      }, () => {
        this.getCashPositionData();
      })
    });
  }

  /**
   * Get category available in the URL.
   */
  getCategoryId () {
    return this.props.match.params.categoryId;
  }

  getCashPositionData () {
    // the details are required only for INVESTMENT categories.
    if (this.state.categoryDetails.type === categoryTypeInvestment.type) {

      getCashPositionTableData(this.getProjectId()).then((combinedFlowData) => {

        this.setState({
          cashPositionData: combinedFlowData,
        })
      });
    }
  }

  getProjectDetailsAndForecasts () {
    getProjectById(this.getProjectId()).then((projectDetails) => {
      if (projectDetails.code) {
        this.setState({error: projectDetails.message});
      } else {
        getCurrentDate().then(currentDate => {
          this.fetchDataFromOnesWithLoading(this.getProjectId(), this.getCategoryId(), remainingToForecast).then((res) => {
            const contractsWithForecasts = res[0];
            this.state.previousNextCategory = res[1];

            const displayedCashflowDateRange = computeDisplayedDateRangeDefault(monthAndYearMomentFromDateString(currentDate));
            this.setState({
              projectDetails: projectDetails,
              error: '',
              contractsWithForecasts: contractsWithForecasts,
              displayedDateRange: displayedCashflowDateRange,
              displayedHeadingDateRange: displayedCashflowDateRange,
              displayedCashflowDateRange: displayedCashflowDateRange,
              shouldLoad: true,
            });
            this.fetchDataFromAll(this.getProjectId(), this.getCategoryId(), remainingToForecast).then((res) => {
              const remainingForecasts = res[0];
              const contractsWithForecasts = res[1];
              const dateRangeLimit = computeDateRangeLimit(
                remainingForecasts,
                contractsWithForecasts,
                monthAndYearMomentFromDateString(projectDetails.startDate),
                monthAndYearMomentFromDateString(projectDetails.estimatedEndDate)
              );
              const currentDateMoment = monthAndYearMomentFromDateString(currentDate);
              let constructCashflowDates = [];

              const displayedCashflowDateRange = computeDisplayedDateRange(currentDateMoment, dateRangeLimit);
  
              const augmentedContractForecasts = augmentContractForecasts(contractsWithForecasts, dateRangeLimit);
              const contractsWithForecastsAugumented = this.augmentContractForecastsAndReplaceDisplayValues(deepClone(augmentedContractForecasts));
              contractsWithForecasts.forEach((contractWithForecasts, i) => {
                constructCashflowDates.push({
                  displayedCashflowDateRange: displayedCashflowDateRange,
                  dateCashflowRangeLimit: dateRangeLimit,
                  currentCashflowDateMoment: moment(),
                  updateCashflowDateRange: (dateRange) => {
                    let newCashflowCalendars = [...this.state.cashflowCalendars];
                    newCashflowCalendars[i]["displayedCashflowDateRange"] = dateRange;
                    this.setState({
                      ...this.state,
                      cashflowCalendars: newCashflowCalendars
                    });
                  },
                  contractsWithForecasts: contractsWithForecastsAugumented,
                });
              });
              const augumentedForecasts = augmentForecasts(remainingForecasts, dateRangeLimit);
              this.setState({
                projectDetails: projectDetails,
                error: '',
                remainingForecasts: augumentedForecasts,
                remainingHeadingForecasts: augumentedForecasts,
                contractsWithForecasts: contractsWithForecastsAugumented,
                initialForecastState: {
                  remainingForecasts: deepClone(augumentedForecasts),
                  contractsWithForecasts: augmentedContractForecasts,
                },
                currentDateMoment: currentDateMoment,
                dateRangeLimit: dateRangeLimit,
                dateHeadingRangeLimit: dateRangeLimit,
                dateCashflowRangeLimit: dateRangeLimit,
                displayedDateRange: displayedCashflowDateRange,
                displayedHeadingDateRange: displayedCashflowDateRange,
                displayedCashflowDateRange: displayedCashflowDateRange,
                cashflowCalendars: constructCashflowDates,
                shouldLoad: false,
              });
            });
          });
        });
      }
    });
  }

  async getAllForecastsByCriteriaWrapper(categoryId, remainingToForecast) {
    return await getAllForecastsByCriteriaNoLoading(categoryId, null, remainingToForecast.value);
  }

  async getAllContractsWithForecastsByCategoryIdWrapper(categoryId) {
    return await getAllContractsWithForecastsByCategoryId(categoryId);
  }

  async getAllContractsByCategoryIdWrapper(categoryId) {
    return await getAllContractsByCategoryIdNoForecasts(categoryId);
  }

  async getPreviousAndNextCategoryWrapper(projectId, categoryId) {
    return await getPreviousAndNextCategory(projectId, categoryId);
  }

  async fetchDataFromOnesWithLoading(projectId, categoryId, remainingToForecast) {
    const [contractsWithForecasts, previousNextCategory] = await Promise.all([
      this.getAllContractsByCategoryIdWrapper(categoryId),
      this.getPreviousAndNextCategoryWrapper(projectId, categoryId)
    ]);
    return [contractsWithForecasts, previousNextCategory];
  }

  async fetchDataFromAll(projectId, categoryId, remainingToForecast) {
    const [remainingForecasts, contractsWithForecasts] = await Promise.all([
      this.getAllForecastsByCriteriaWrapper(categoryId, remainingToForecast),
      this.getAllContractsWithForecastsByCategoryIdWrapper(categoryId),
    ]);
    return [remainingForecasts, contractsWithForecasts];
  }

  augmentContractForecastsAndReplaceDisplayValues(processedContractsWithForecasts) {
    for (let i = 0; i < processedContractsWithForecasts.length; i++) {
      processedContractsWithForecasts[i].amount = processedContractsWithForecasts[i].contractType === 'AGREEMENT' ? processedContractsWithForecasts[i].amount : processedContractsWithForecasts[i].invoiced;
    }
    return processedContractsWithForecasts;
  }

  /**
   * Get project id from the URL.
   */
  getProjectId () {
    return this.props.match.params.projectId;
  }

  /**
   * Get all available contractors
   */
  getAllContractors () {

    getAllContractors().then((contractors) => {
      this.setState({
        contractors: contractors
      })
    });
  }

  contractUpdated () {
    window.location.reload();
  }

  contractForecastUpdated (id, updatedAmount, type) {
    const hasId = forecast => forecast.id === id;
    const clone = deepClone(this.state.contractsWithForecasts);
    if (type === 'Actual') {
      const forecast = clone
          .find(contract => contract.forecasts.some(hasId))
          .forecasts.find(hasId);
      forecast
        .execution = updatedAmount;
      forecast.actualForecast = updatedAmount;
    } else {
      clone
        .find(contract => contract.forecasts.some(hasId))
        .forecasts.find(hasId)
        .initial = updatedAmount;
    }
    this.setState({contractsWithForecasts: clone});
  }

  remainingForecastUpdated (id, updatedAmount, type) {
    let clone = deepClone(this.state.remainingForecasts);
    let forecastToUpdate = clone.find(forecast => forecast.id === id);
    if (type === 'Initial') {
      forecastToUpdate.initial = updatedAmount;
    } else {
      forecastToUpdate.execution = updatedAmount;
      forecastToUpdate.actualForecast = updatedAmount;
    }
    this.setState({remainingForecasts: clone});
  }

  remainingHeadingForecastUpdated (id, updatedAmount, type) {
    let clone = deepClone(this.state.remainingHeadingForecasts);
    let forecastToUpdate = clone.find(forecast => forecast.id === id);
    if (type === 'Initial') {
      forecastToUpdate.initial = updatedAmount;
    } else {
      forecastToUpdate.execution = updatedAmount;
    }
    this.setState({remainingHeadingForecasts: clone});
  }

  cashPositionUpdated(year, month, amount) {
    let cashPosition = this.state.cashPosition;
    let monthToUpdate = cashPosition.find(position => position.year === year && position.month === month);
    monthToUpdate.manualValue = amount;
    this.setState({
      cashPosition: cashPosition
    })
  }

  toggleAddContractor () {
    this.setState({addingContractor: !this.state.addingContractor});
  };

  updateDateRange (dateRange) {
    this.setState({
      displayedDateRange: dateRange,
    });
  }

  updateHeadingDateRange (dateRange) {
    this.setState({
      displayedHeadingDateRange: dateRange,
    });
  }

  updateMonthlyActualForecast(cashflowForecasts, remainingForecasts) {
    let currentValue = 0;
    if (cashflowForecasts && remainingForecasts) {
      if (cashflowForecasts.length > 0) {
        for (let k = 0; k < cashflowForecasts.length; k++) {
          if (cashflowForecasts[k].forecasts) {
            for (let z = 0; z < cashflowForecasts[k].forecasts.length; z++) {
                  if(remainingForecasts[z].initial && remainingForecasts[z].monthAsInteger === cashflowForecasts[k].forecasts[z].monthAsInteger) {
                    if(remainingForecasts[z].initial < cashflowForecasts[k].forecasts[z].initial) {
                      currentValue = remainingForecasts[z].execution - cashflowForecasts[k].forecasts[z].initial;
                    } else {
                      currentValue = remainingForecasts[z].initial - cashflowForecasts[k].forecasts[z].initial;
                    }
                    remainingForecasts[z].execution = currentValue;
                    currentValue = 0;
                  }
            }
          }
        }
      }
    }
    return prepareForecastsForSave(remainingForecasts);
  }


  updateCashflowDateRange (dateRange) {
    this.setState({
      displayedCashflowDateRange: dateRange,
    });
  }

  saveForecasts () {
    saveBudgetExecutionForecasts({
      categoryId: this.getCategoryId(),
      manualCashPositions: this.state.cashPosition,
      remainingForecasts: this.state.remainingForecasts,
      contractForecasts: this.getContractForecastsToSave(),
    }).then(result => {
      if (result.code) {
        this.setState({
          error: result.message
        });
        if (result.code === 18002) {
          toast.error("Cannot perform updates on a closed financial month!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });
        }
        this.getProjectDetailsAndForecasts();
        // get new cash position data
        this.getCashPositionData();

        this.getCashPositon(this.getCategoryId());
      } else {
        toast.info("Your changes were saved successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
        // refresh forecasts
        this.getProjectDetailsAndForecasts();
        // get new cash position data
        this.getCashPositionData();

        this.getCashPositon(this.getCategoryId());

        this.validateBudget();
      }
    })
  }

  validateBudget() {
    validateBudgetExceeded(this.getCategoryId()).then(result => {
      if (result.code) {
        this.setState({
          error: result.message
        });
        if (result.code === 14005) {
          toast.error("Budget exceeded", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });
        }
      }
    })
  }

  getContractForecastsToSave () {
    return this.state.contractsWithForecasts.map(contractWithForecast => {
      return {
        contractId: contractWithForecast.id,
        forecasts: prepareForecastsForSave(contractWithForecast.forecasts)
      }
    });
  }

  /**
   * Save split amounts.
   * Because state is immutable, forecasts are cloned before updated.
   * Ask for confirmation if project end date will be exceeded (and it is not exceeded already).
   */
  saveSplits (forecastUpdates, forecastType, contractId, forecastPosition) {
    const latestUpdateMoment = forecastUpdates[forecastUpdates.length - 1].date;
    const numberOfMonthsDifference = latestUpdateMoment.diff(this.state.dateRangeLimit.end, 'months');
    const numberOfMonthsToExtend = numberOfMonthsDifference > 0 ? numberOfMonthsDifference : 0;

    this.promptForConfirmationIfNeededAndDo(this.doSaveSplits, numberOfMonthsToExtend, forecastType, contractId, forecastUpdates, forecastPosition);
  }
  saveHeadingSplits (forecastUpdates, forecastType, contractId, forecastPosition) {
    const latestUpdateMoment = forecastUpdates[forecastUpdates.length - 1].date;
    const numberOfMonthsDifference = latestUpdateMoment.diff(this.state.dateHeadingRangeLimit.end, 'months');
    const numberOfMonthsToExtend = numberOfMonthsDifference > 0 ? numberOfMonthsDifference : 0;

    this.promptForConfirmationIfNeededAndDo(this.doSaveSplits, numberOfMonthsToExtend, forecastType, contractId, forecastUpdates, forecastPosition);
  }
  /**
   * Do the actual saving of splits.
   */
  doSaveSplits (numberOfMonthsToExtend, forecastType, contractId, forecastUpdates, forecastPosition) {
    let extendedDateRangeState = this.extendedDateRangeLimitState(numberOfMonthsToExtend);
    if (forecastType === remainingToForecast.value) {
      updateForecastArrayWithSplit(extendedDateRangeState.remainingForecasts, forecastUpdates, forecastPosition);
    } else {
      let forecastArrayToUpdate = findContractForecastArray(extendedDateRangeState.contractsWithForecasts, contractId);
      updateForecastArrayWithSplit(forecastArrayToUpdate, forecastUpdates, forecastPosition);
    }

    if (this.state.categoryDetails &&
      (this.state.categoryDetails.type === "FINANCING" || this.state.categoryDetails.type === "INVESTMENT") ) {
        const copyChPos = [...this.state.cashPosition];

        const newChPos = copyChPos.map(chPos => {

          forecastUpdates.forEach(forec => {
            if ((moment(chPos.date).month() === moment(forec.date).month() ) && (moment(chPos.date).year() === moment(forec.date).year())) {
              chPos.manualValue = forec.amount;
            }
          });
          return chPos;
        });
        this.setState({cashPosition: newChPos});
      }
    this.setState(extendedDateRangeState);
  }

  doHeadingSaveSplits (numberOfMonthsToExtend, forecastType, contractId, forecastUpdates, forecastPosition) {
    let extendedDateRangeState = this.extendedHeadingDateRangeLimitState(numberOfMonthsToExtend);
    if (forecastType === remainingToForecast.value) {
      updateForecastArrayWithSplit(extendedDateRangeState.remainingForecasts, forecastUpdates, forecastPosition);
    } else {
      let forecastArrayToUpdate = findContractForecastArray(extendedDateRangeState.contractsWithForecasts, contractId);
      updateForecastArrayWithSplit(forecastArrayToUpdate, forecastUpdates, forecastPosition);
    }
    this.setState(extendedDateRangeState);
  }

  /**
   * Extend date range with numberOfMonths months and returns a new state with cloned forecasts,
   * to have setState() called only once, in the right moment. Otherwise it may result in inconsistent
   * state, since setState() is asynchronous.
   * */
  extendedDateRangeLimitState (numberOfMonths) {
    let remainingForecastsClone = deepClone(this.state.remainingForecasts);
    let contractForecastsClone = deepClone(this.state.contractsWithForecasts);
    let currentDateRangeEndMoment = this.state.dateRangeLimit.end.clone();

    for (let i = 0; i < numberOfMonths; i++) {
      currentDateRangeEndMoment.add(1, 'month');
      remainingForecastsClone.push(generateSyntheticForecast(currentDateRangeEndMoment.clone()));
      contractForecastsClone.forEach(contract => {
        contract.forecasts.push(generateSyntheticForecast(currentDateRangeEndMoment.clone()));
      });
    }

    return {
      remainingForecasts: remainingForecastsClone,
      contractsWithForecasts: contractForecastsClone,
      dateRangeLimit: {
        start: this.state.dateRangeLimit.start,
        end: currentDateRangeEndMoment
      },
      // Since the user just added a forecast beyond the date range limit, he is probably interested in it. Let's make it appear.
      displayedDateRange: {
        start: this.state.displayedDateRange.start.clone().add(numberOfMonths, 'month'),
        end: this.state.displayedDateRange.end.clone().add(numberOfMonths, 'month'),
      }
    };
  }



  extendedHeadingDateRangeLimitState (numberOfMonths) {
    let remainingForecastsClone = deepClone(this.state.remainingHeadingForecasts);
    let contractForecastsClone = deepClone(this.state.contractsHeadingWithForecasts);
    let currentDateRangeEndMoment = this.state.dateHeadingRangeLimit.end.clone();

    for (let i = 0; i < numberOfMonths; i++) {
      currentDateRangeEndMoment.add(1, 'month');
      remainingForecastsClone.push(generateSyntheticForecast(currentDateRangeEndMoment.clone()));
      contractForecastsClone.forEach(contract => {
        contract.forecasts.push(generateSyntheticForecast(currentDateRangeEndMoment.clone()));
      });
    }
    return {
      remainingHeadingForecasts: remainingForecastsClone,
      contractsHeadingWithForecasts: contractForecastsClone,
      dateHeadingRangeLimit: {
        start: this.state.dateHeadingRangeLimit.start,
        end: currentDateRangeEndMoment
      },
      // Since the user just added a forecast beyond the date range limit, he is probably interested in it. Let's make it appear.
      displayedHeadingDateRange: {
        start: this.state.displayedHeadingDateRange.start.clone().add(numberOfMonths, 'month'),
        end: this.state.displayedHeadingDateRange.end.clone().add(numberOfMonths, 'month'),
      }
    };
  }

  /**
   * Move (shift) all forecast amounts beyond the selected forecast to the right and set the selected one to 0.
   * Ask for confirmation if project end date will be exceeded (and it is not exceeded already).
   * */
  moveRight (forecastId, forecastType, contractId, tabType, activeTab) {
    // First we must make sure the last amount in the forecast array is 0, even if this means extending the date range.
    // const forecastArray = findForecastArray(this.state, forecastType, contractId);
    // const numberOfMonthsToExtend = forecastArray[forecastArray.length - 1].amount === 0 ? 0 : 1;
    // this.promptForConfirmationIfNeededAndDo(this.doMoveRight, numberOfMonthsToExtend, forecastType, contractId, forecastId, tabType, activeTab);


    if (this.state.categoryDetails &&
      (this.state.categoryDetails.type === "COST" || this.state.categoryDetails.type === "INCOME") ) {
        const remForecast = [...this.state.remainingForecasts];
        let forecastDate = null;
        let forecastMonth = null;
        let forecastYear = null;
        this.state.remainingForecasts.forEach(forc => {
          if (forc.id === forecastId) {
            forecastDate = forc.date;
            forecastMonth = forc.monthAsInteger;
            forecastYear = forc.year;
          }
        });
        let order = null;
        let valToUse = 0;
        for (let i = 0; i < remForecast.length - 1; i++){
          if ((remForecast[i].monthAsInteger === forecastMonth ) && ( remForecast[i].year === forecastYear)) {
            order = i;
            valToUse = remForecast[i].manualValue ? remForecast[i].manualValue : 0;
          }
        }
        let newRemFor = [];
        for (let i = remForecast.length - 1; i >= 0; i--){
          let newObj = remForecast[i];
          if (activeTab === 'Initial') {
            if (i === order) {
              newObj.initial = 0;
            } else if (i > order) {
              newObj.initial = remForecast[i-1].initial;
            }
          } else {
            if (i === order) {
              newObj.actualForecast = 0;
              newObj.execution = 0;
            } else if (i > order) {
              newObj.actualForecast = remForecast[i-1].actualForecast;
              newObj.execution = remForecast[i-1].execution;
            }
          }
          newRemFor.push(newObj);
        }
        let sortedRemFor = newRemFor.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf());
        this.setState({remainingForecasts: sortedRemFor});
      }
  }
  moveHeadingRight (forecastId, forecastType, contractId, activeTab) {
    // First we must make sure the last amount in the forecast array is 0, even if this means extending the date range.

    // const forecastArray = findForecastArray(this.state, forecastType, contractId);
    // const numberOfMonthsToExtend = forecastArray[forecastArray.length - 1].amount === 0 ? 0 : 1;
    // this.promptForConfirmationIfNeededAndDo(this.doHeadingMoveRight, numberOfMonthsToExtend, forecastType, contractId, forecastId, activeTab);

    if (this.state.categoryDetails &&
      (this.state.categoryDetails.type === "FINANCING" || this.state.categoryDetails.type === "INVESTMENT") ) {
        const copyChPos = [...this.state.cashPosition];
        let forecastDate = null;
        let forecastMonth = null;
        let forecastYear = null;
        this.state.remainingForecasts.forEach(forc => {
          if (forc.id === forecastId) {
            forecastDate = forc.date;
            forecastMonth = forc.monthAsInteger;
            forecastYear = forc.year;
          }
        })


        let order = null;
        let valToUse = 0;
        for (let i = 0; i < copyChPos.length - 1; i++){
          if ((copyChPos[i].month === forecastMonth ) && ( copyChPos[i].year === forecastYear)) {
            order = i;
            valToUse = copyChPos[i].manualValue ? copyChPos[i].manualValue : 0;
          }
        }

        let newChPos = [];
        // for (let i = copyChPos.length - 1; i >= 0; i--){
        //   let newObj = copyChPos[i];
        //   if (i === order) {
        //     newObj.manualValue = 0;
        //   } else if (i > order) {
        //     newObj.manualValue = copyChPos[i-1].manualValue;
        //   }
        //   newChPos.push(newObj);
        // }
      for (let i = copyChPos.length - 1; i >= 0; i--){
        let newObj = copyChPos[i];
        if (i === order) {
          newObj.manualValue = 0;
          newObj.withdrawnAmount = 0;
        } else if (i > order) {
          if (copyChPos[i-1].manualValue === null || copyChPos[i-1].manualValue === undefined) {
            newObj.manualValue = copyChPos[i-1].withdrawnAmount;
            newObj.withdrawnAmount = 0;
          } else {
            newObj.manualValue = copyChPos[i-1].manualValue;
            newObj.withdrawnAmount = 0;
          }
        }
        newChPos.push(newObj);
      }
      let sortedCashPos = newChPos.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf());
        this.setState({cashPosition: sortedCashPos});
      }
  }

  /**
   * Do the actual move to the right.
   * */
  doMoveRight (numberOfMonthsToExtend, forecastType, contractId, forecastId, tabType, activeTab) {
    let extendedDateRangeState = this.extendedDateRangeLimitState(numberOfMonthsToExtend);

    const extendedForecastArray = findForecastArray(extendedDateRangeState, forecastType, contractId);
    const forecastIndex = extendedForecastArray.findIndex(forecast => forecast.id === forecastId);
    // Do the actual shift.
    for (let i = extendedForecastArray.length - 1; i > forecastIndex; i--) {
      extendedForecastArray[i].amount = extendedForecastArray[i - 1].amount;
      extendedForecastArray[i-1].amount = 0;
      if (tabType === 'Initial') {
        extendedForecastArray[i].initial = extendedForecastArray[i - 1].initial;
        extendedForecastArray[i-1].initial = 0;
      }
      if (tabType === 'Actual') {
          extendedForecastArray[i].execution = extendedForecastArray[i - 1].actualForecast;
          extendedForecastArray[i-1].execution = 0;
          extendedForecastArray[i].actualForecast = extendedForecastArray[i - 1].actualForecast;
          extendedForecastArray[i-1].actualForecast = 0;
      }
    }
    extendedForecastArray[forecastIndex].amount = 0;
    this.setState(extendedDateRangeState);
  }
  doHeadingMoveRight (numberOfMonthsToExtend, forecastType, contractId, forecastId, activeTab) {
    let extendedDateRangeState = this.extendedHeadingDateRangeLimitState(numberOfMonthsToExtend);

    const extendedForecastArray = findForecastArray(extendedDateRangeState, forecastType, contractId);
    const forecastIndex = extendedForecastArray.findIndex(forecast => forecast.id === forecastId);
    // Do the actual shift.
    for (let i = extendedForecastArray.length - 1; i > forecastIndex; i--) {
      extendedForecastArray[i].amount = extendedForecastArray[i - 1].amount;
    }
    extendedForecastArray[forecastIndex].amount = 0;
    this.setState(extendedDateRangeState);
  }

  moveCashflow (forecastId, forecastType, contractId, tabType) {
    const forecasts = this.state.contractsWithForecasts.find(contract => contract.id === contractId).forecasts
    const forecastIndex = forecasts.findIndex(forecast => forecast.id === forecastId);
    // Do the actual shift.
    for (let i = forecasts.length - 1; i > forecastIndex; i--) {
      forecasts[i].amount = forecasts[i - 1].amount;
      forecasts[i-1].amount = 0;
      if (tabType === 'Initial') {
        forecasts[i].initial = forecasts[i - 1].initial;
        forecasts[i-1].initial = 0;
      }
      if (tabType === 'Actual') {
        forecasts[i].execution = forecasts[i - 1].actualForecast;
        forecasts[i-1].execution = 0;
        forecasts[i].actualForecast = forecasts[i - 1].actualForecast;
        forecasts[i-1].actualForecast = 0;
      }
    }
    forecasts[forecastIndex].amount = 0;
    let updatedForecasts = this.state.contractsWithForecasts;
    updatedForecasts.find(contract => contract.id === contractId).forecasts = forecasts
    this.setState({
      contractsWithForecasts: updatedForecasts
    });
  }

  projectEndDateIsNotExceededAlready () {
    return !(this.state.dateRangeLimit.end.isAfter(monthAndYearMomentFromDateString(this.state.projectDetails.estimatedEndDate)));
  }

  /**
   * Verify if we want to exceed project end date and if this is the first time it will be exceeded,
   * prompt for confirmation before executing the callback.
   */
  promptForConfirmationIfNeededAndDo (callback, numberOfMonthsToExtend, ...args) {
    if (numberOfMonthsToExtend > 0 && this.projectEndDateIsNotExceededAlready()) {
      confirmAlert({
        title: 'Confirm to save',
        message: 'Are you sure you want to add forecasts beyond the project end date?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              callback(numberOfMonthsToExtend, ...args);
            }
          },
          {
            label: 'No',
          }
        ]
      });
    } else {
      callback(numberOfMonthsToExtend, ...args);
    }
  }

  dismissError () {
    this.setState({error: ''});
  }

  // Show the warning only once.
  showAboveBudgetWarningIfNeeded (isAboveBudget) {
    if (isAboveBudget) {
      if (!this.state.aboveBudgetWarning) {
        toast.warn("Total forecast is above budget!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
        this.setState({
          aboveBudgetWarning: true
        });
      }
    } else if (this.state.aboveBudgetWarning) {
      this.setState({
        aboveBudgetWarning: false
      });
    }
  }

  resetContractForecasts (contractId) {

    // create a deep clone
    const clone = deepClone(this.state.contractsWithForecasts);

    // initial state
    this.state.initialForecastState.contractsWithForecasts.forEach((item, key) => {

      // we want to reset only for a specific contractId
      if (item.id === contractId) {
        // set initial state item to corresponding clone position
        clone[key] = item;
      }
    });

    this.setState({
      contractsWithForecasts: clone
    });
  }

  resetRemainingForecasts () {
    this.setState({
      remainingForecasts: this.state.initialForecastState.remainingForecasts
    });
  }
  resetHeadingRemainingForecasts () {
    this.setState({
      remainingHeadingForecasts: this.state.initialForecastState.remainingHeadingForecasts
    });
  }

  seeAllInvoices (contractId, invoiceType) {
    this.props.history.push('/project/' + this.getProjectId() + '/invoices/' + invoiceType + '/' + contractId);
  }

  addInvoice () {
    this.toggleAddInvoice();
  }

  toggleAddInvoice () {
    this.setState({addingInvoice: !this.state.addingInvoice});
  }
  clearHeadingAllForecasts (contractId = undefined) {

  }

  computeActualTotalForecast() {
    let actualTotalForecast = 0;

    if (!this.state.categoryDetails.initialBudget && !this.state.categoryDetails.executionBudget) {
      actualTotalForecast = actualTotalForecast - this.state.categoryDetails.committed;
    }
    if (this.state.categoryDetails.executionBudget > 0) {
      actualTotalForecast = this.state.categoryDetails.executionBudget - this.state.categoryDetails.committed;
    } else {
      actualTotalForecast = this.state.categoryDetails.initialBudget - this.state.categoryDetails.committed;
    }
    return actualTotalForecast;
  }

  computeRemainingForecast() {
    let remainingForecast = 0;
    let actualTotalForecast = computeForecastSum(this.state.remainingForecasts, 'Actual');

    if (!this.state.categoryDetails.initialBudget && !this.state.categoryDetails.executionBudget) {
      remainingForecast = remainingForecast - this.state.categoryDetails.committed
          - actualTotalForecast;
    }
    if (this.state.categoryDetails.executionBudget > 0) {
      remainingForecast = this.state.categoryDetails.executionBudget - this.state.categoryDetails.committed
          - actualTotalForecast;
    } else {
      remainingForecast = this.state.categoryDetails.initialBudget - this.state.categoryDetails.committed
          - actualTotalForecast;
    }
    return remainingForecast;
  }

  clearAllForecasts (contractId = undefined) {

    confirmAlert({
      title: contractId ? 'Confirm to delete all contract forecasts' : 'Confirm to delete all remaining forecasts for this category',
      message: 'The operation cannot be undone!',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            deleteAllForecastsByCriteria(this.getCategoryId(), contractId,
              contractId ? committedForecast.value : remainingToForecast.value)
              .then((result) => {
                if (result.status !== 200) {
                  toast.error(contractId ? "Contract forecasts cannot be deleted!" : "Remaining forecasts cannot be deleted!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                  });
                } else {
                  toast.info("The forecasts were removed successfully!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 5000,
                  });
                  // refresh forecasts
                  this.getProjectDetailsAndForecasts();
                  // force update on component ForecastTransferComponent
                  window.location.reload();
                }
              })
        },
        {
          label: 'No',
          onClick: () => {
          }
        }
      ]
    });
  }

  cancelForm () {
    this.toggleAddInvoice();
  }

  displayIBTotal() {
    const sumReducer = function (acumulator, value) {
      return acumulator + value;
    };

    if (this.state.remainingForecasts.length !== 0) {
      const totalForecast = this.state.remainingForecasts
          .map(forecast => forecast.actualForecast)
          .filter(value => value !== null)
          .reduce(sumReducer, 0)
      const totalCashflow = this.state.contractsWithForecasts
          .flatMap(contract => contract.forecasts)
          .map(forecast => forecast ? forecast.actualForecast : 0)
          .filter(value => value)
          .reduce(sumReducer, 0);
      return totalForecast + totalCashflow;
    }
    return 0;    
  }

  render () {

    const categoryId = this.getCategoryId();
    const isAddingContractorClassName = this.state.addingContractor ? " visible" : "";
    const initialForecastSum = computeForecastSum(this.state.remainingForecasts, 'Initial');
    const executionForecastSum = computeForecastSum(this.state.remainingForecasts, 'Actual');
    const totalForecast = addDoubles(this.state.categoryDetails.committed, initialForecastSum);
    const isAboveBudget = totalForecast > this.state.categoryDetails.budget;
    const actualTotalForecast = this.state.remainingForecasts.map(forecast => forecast.actualForecast).filter(value => value != null).reduce(function (acumulator, budget) {
        return acumulator + budget;
    }, 0)
    const catBudget = this.state.categoryDetails.executionBudget != null ? this.state.categoryDetails.executionBudget : this.state.categoryDetails.initialBudget != null ? this.state.categoryDetails.initialBudget : 0;
    const commitedOrElseZero = this.state.categoryDetails.committed != null ? this.state.categoryDetails.committed : 0;
    const remainingForecast = catBudget - commitedOrElseZero - actualTotalForecast;
    const remainingForecastOrElseZero = (remainingForecast != null && remainingForecast != undefined && remainingForecast) > 0 ? remainingForecast : 0


    this.showAboveBudgetWarningIfNeeded(isAboveBudget);

    return (
      <React.Fragment>
        {/*Just for navigation purposes.*/}
        {/*For example, the user could go directly to Invoices without going back first.*/}
        <ProjectNavigation/>
        <Breadcrumbs breadcrumbs={[{url: '/projects', title: 'Home'}, {
          url: `/project/${this.getProjectId()}`,
          title: this.state.projectDetails && this.state.projectDetails.name
        }, {url: `/project/${this.getProjectId()}/real-time-budget`, title: 'Real Time Budget'}, {
          url: `/manageExecution/${categoryId}/${this.getProjectId()}`,
          title: this.state.categoryDetails.name
        }]}/>

        <main className="kb-main kb-main_execution--subcat bg-white pb-5 ">
          <PageSection
            additionalTitleClass={isAboveBudget ? "" : ""}
            background="bg-light"
            noHeadingYPadding={true}
            title={
              <Fragment>
                <div className={"row"}>
                  <div className={"col-md-6 d-flex align-items-center text-break"}>
                    <h1 className="h4 font-weight-normal m-0">{this.state.categoryDetails.ibCode ? (
                      <span className="mr-3">IB {this.state.categoryDetails.ibCode}</span>
                    ) : ''} {this.state.categoryDetails.name}</h1>
                    <div className="arrow-icons">
                      { this.state.previousNextCategory.previousCategoryId !== null ?
                        <span className="arrow left" onClick={() => 
                          {window.location.href="/#/manageExecution/" + this.state.previousNextCategory.previousCategoryId + '/' 
                          + this.getProjectId() + '/' + this.props.match.params.categoryType; window.location.reload();}}></span>
                        :
                        <span className="left arrow-disabled"></span>
                      }
                      { this.state.previousNextCategory.nextCategoryId !== null ?
                        <span className="arrow right" onClick={() => 
                          {window.location.href="/#/manageExecution/" + this.state.previousNextCategory.nextCategoryId + '/' 
                          + this.getProjectId() + '/' + this.props.match.params.categoryType; window.location.reload();}}></span>
                        :
                        <span className="right arrow-disabled"></span>
                      }
                    </div>
                  </div>
                  <div
                    className={"col-md-6 ml-auto d-flex text-md-right align-items-center pb-2"}>
                    <div className="kb-save-banner ml-4 w-100  align-items-center ">
                      <button className="btn btn-light active mr-3 min-w-85" onClick={ () =>
                        {window.location.href="/#/project/" + this.getProjectId() + '/real-time-budget'; window.location.reload();}}><span>Cancel</span></button>
                      <button className="btn btn-primary min-w-85" onClick={this.saveForecasts} disabled={this.state.shouldLoad}><span>Save</span></button>
                      {
                        (this.state.categoryDetails.type === 'INVESTMENT') ? (
                            <React.Fragment>
                              <Button className="ml-3" onClick={()=>{
                                this.freezeIRR();
                              }
                              }>Freeze IRR</Button>
                            </React.Fragment>
                        ) : (
                            <React.Fragment/>
                        )
                      }
                    </div>
                  </div>
                </div>
              </Fragment>
            }
          >

            <RenderIf condition={this.state.error !== ''}>
              <h3 data-test="error" onClick={this.dismissError}>
                <button onClick={this.dismissError}>✖</button>
                {this.state.error}
              </h3>
            </RenderIf>

            <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 align-self-center pt-4">
              <div className="py-3 m-0">
                <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 font-weight-bold font-size-3">

                    {
                      this.state.categoryDetails &&
                      (this.state.categoryDetails.type === "COST" || this.state.categoryDetails.type === "INCOME") &&
                      (
                        <React.Fragment>
                        <div className="d-inline-block mr-5">
                        {this.state.categoryDetails.executionBudget !== null && this.state.categoryDetails.executionBudget !== undefined  ? "Execution Budget" : "Initial Budget"}: {this.state.categoryDetails.executionBudget !== null && this.state.categoryDetails.executionBudget !== undefined ?  prettyPrint(this.state.categoryDetails.executionBudget) : (this.state.categoryDetails.initialBudget !== null && this.state.categoryDetails.initialBudget !== undefined ? prettyPrint(this.state.categoryDetails.initialBudget) : 0) } EUR
                        </div>
                        {this.state.shouldLoad ? (
                            <div className="ml-5 d-inline-block">
                              <div className="container-inline-div">
                              Total IB {this.state.categoryDetails.ibCode}: {prettyPrint(this.displayIBTotal())} EUR
                              <div className="spinner spinner-total-ib"/> 
                              </div>
                              
                            </div>
                          ) : (
                            <div className="ml-5 d-inline-block">Total IB {this.state.categoryDetails.ibCode}: {prettyPrint(this.displayIBTotal())} EUR </div>
                          )}
                        
                        </React.Fragment>
                      )
                    }
                    {
                      this.state.categoryDetails &&
                      (this.state.categoryDetails.type === "FINANCING" || this.state.categoryDetails.type === "INVESTMENT") &&
                      (
                        <div className="d-inline-block mr-5">
                          <div className="ml-5 d-inline-block">Total Facility: {prettyPrint(this.state.categoryDetails.executionBudget != null ? this.state.categoryDetails.executionBudget : this.state.categoryDetails.initialBudget)} EUR</div>
                        </div>
                      )
                    }
                    </div>
                  </div>
                </div>
                </div>
              </div>
              </div>
              <div className="col-md-4 align-self-center pt-4">
              {
                // this.state.categoryDetails &&
                // (this.state.categoryDetails.type === "FINANCING" || this.state.categoryDetails.type === "INVESTMENT") &&
                // (
                //   <React.Fragment>
                //   <Button variant="light" className={this.state.activeTabHeader === 'Initial' ? "font-size-2 px-4 py-2 mr-2 active font-weight-bold" : "font-size-2 px-4 py-2 mr-2"} size='sm'
                //       onClick={() => this.changeTabHeader('Initial')}>
                //       Initial
                //   </Button>
                //   <Button variant="light" className={this.state.activeTabHeader === 'Actual' ? "font-size-2 px-4 py-2 active font-weight-bold" : "font-size-2 px-4 py-2"} size='sm'
                //       onClick={() => this.changeTabHeader('Actual')}>
                //       Actual
                //   </Button>
                //   </React.Fragment>
                // )
              }
              </div>
              <div className="col-md-4 align-self-center pt-4">
                <DateRangeNavigator
                  dateRange={this.state.displayedHeadingDateRange}
                  dateRangeLimit={this.state.dateHeadingRangeLimit}
                  updateDateRange={this.updateHeadingDateRange}
                />
              </div>
              <div className="col-md-4 align-self-center pt-4 text-right">
              </div>

              <div className="col-md-12 align-self-center pt-0 text-right  border border-top-none border-left-none border-right-none mb-5 mt-2">
              {this.state.categoryDetails && !this.state.shouldLoad &&
                (this.state.categoryDetails.type === "COST" || this.state.categoryDetails.type === "INCOME") &&
               (
                <MonthlyExecutionRemainingForecastView
                  shouldLoad={this.state.shouldLoad}
                  categoryName={'name'}
                  forecasts={forecastsForCurrentDateRange(this.state.remainingHeadingForecasts, this.state.displayedHeadingDateRange)}
                  contracts={this.state.contractsWithForecasts}
                  forecastSum={0}
                  forecastUpdated={this.remainingHeadingForecastUpdated}
                  onSplitSave={this.saveHeadingSplits}
                  onMoveRight={this.moveHeadingRight}
                  currentDate={this.state.currentHeadingDateMoment}
                  budget={''}
                  committed={1005000}
                  readOnly={true}
                  noYPadding={true}
                  cashPosition={true}
                  openedMonths={this.state.openedMonths}
                />
               )
              }
              {this.state.categoryDetails &&
                (this.state.categoryDetails.type === "FINANCING" || this.state.categoryDetails.type === "INVESTMENT") &&
               (
                <MonthlyExecutionRemainingForecastView
                  shouldLoad={this.state.shouldLoad}
                    comma2={true}
                  categoryName={'name'}
                  forecasts={forecastsForCurrentDateRange(this.state.remainingHeadingForecasts, this.state.displayedHeadingDateRange)}
                  contracts={this.state.cashPosition}
                  forecastSum={0}
                  forecastUpdated={this.remainingHeadingForecastUpdated}
                  onSplitSave={this.saveHeadingSplits}
                  onMoveRight={this.moveHeadingRight}
                  currentDate={this.state.currentHeadingDateMoment}
                  budget={''}
                  committed={1005000}
                  readOnly={false}
                  noYPadding={true}
                  cashPosition={true}
                  cashPositionValues={this.state.cashPosition}
                  cashPositionUpdated={this.cashPositionUpdated}
                  openedMonths={this.state.openedMonths}
                  type={this.state.activeTabHeader}
                  resetForecasts={() => false}
                  clearAllForecasts={() => false}
                  addDrowdownReimbursment={true}
                  categoryId={this.getCategoryId()}
                  refreshState={this.initComponent}
                  //toBeInvestedCashPosition={''}
              />
               )
              }
              </div>
            </div>
            </div>
            {
              this.state.categoryDetails &&
              (this.state.categoryDetails.type === "FINANCING" || this.state.categoryDetails.type === "INVESTMENT") &&
              (
                <div className="col-12 border-bottom kb-section-actions ">
                  <div className="row d-flex">
                    <div className="col-4 text-left d-flex align-items-center">
                    </div>
                    <div className="col text-right font-weight-bold kb-table-card-heading py-3"><span className="d-block font-weight-normal"> </span>
                    </div>
                    <div className="col text-right font-weight-bold kb-table-card-heading py-3"><span className="d-block font-weight-normal">Drawdown</span>
                      <span className="font-size-3">{prettyPrint(this.state.categoryDetails.drawdownViewDto.drawdown)}</span>
                    </div>
                    <div className="col text-right font-weight-bold kb-table-card-heading py-3"><span className="d-block font-weight-normal">Remaining</span>
                      <span className="font-size-3">{prettyPrint(this.state.categoryDetails.drawdownViewDto.remaining)}</span>
                    </div>
                    <div className="col text-right font-weight-bold kb-table-card-heading py-3"><span className="d-block font-weight-normal">Reimbursement</span>
                      <span className="font-size-3">{prettyPrint(this.state.categoryDetails.drawdownViewDto.reimbursement)}</span>
                    </div>
                    <div className="col text-right font-weight-bold kb-table-card-heading py-3"><span className="d-block font-weight-normal">Outstanding</span>
                      <span className="font-size-3">{prettyPrint(this.state.categoryDetails.drawdownViewDto.outstanding)}</span>
                    </div>
                    <div className="col two-btn-col text-left d-flex align-items-center">

                    </div>
                  </div>
                </div>
              )
            }
            {
              this.state.categoryDetails &&
              (this.state.categoryDetails.type === "COST" || this.state.categoryDetails.type === "INCOME") &&
              (
                <div className="col-12 border-bottom kb-section-actions ">
                  <div className="row d-flex">
                    <div className="col-5 text-left d-flex align-items-center">
                    {this.state.shouldLoad ?  (
                      <div className='container-inline-div'>
                        <div className='inline-div'> <h4>Contracts</h4> </div> <div className="spinner spinner-contracts"/> 
                      </div>
                  ) : (
                    <h4>Contracts</h4>
                  )}
                      
                    </div>
                    <div className="col text-right font-weight-bold kb-table-card-heading py-3"><span className="d-block font-weight-normal"> </span>
                    </div>
                    <div className="col text-right font-weight-bold kb-table-card-heading py-3"><span className="d-block font-weight-normal"> </span>
                    </div>
                    <div className="col text-right font-weight-bold kb-table-card-heading py-3"><span className="d-block font-weight-normal">Committed</span>
                      <span className="font-size-3">{this.state.shouldLoad ? 0 : prettyPrint(this.getTotalCommitted())}</span>
                    </div>
                    <div className="col text-right font-weight-bold kb-table-card-heading py-3"><span className="d-block font-weight-normal">Invoiced</span>
                      <span className="font-size-3">{this.state.shouldLoad ? 0 : prettyPrint(this.getTotalInvoiced())}</span>
                    </div>
                    <div className="col text-right font-weight-bold kb-table-card-heading py-3"><span className="d-block font-weight-normal">To be Invoiced</span>
                      <span className="font-size-3">{this.state.shouldLoad ? 0 : prettyPrint(this.getTotalToBeInvoiced())}</span>
                    </div>
                    <div className="col two-btn-col text-left d-flex align-items-center">

                    </div>
                  </div>
                </div>
              )
            }
            {
              this.state.categoryDetails &&
              (this.state.categoryDetails.type === "COST" || this.state.categoryDetails.type === "INCOME") &&
              (<div className="col-12">
              <div className="accordion" id="accordion-execution">
                {
                  this.state.contractsWithForecasts && this.state.contractsWithForecasts.map((contractWithForecast, i) => {
                    return (
                      <React.Fragment
                      key={i}>
                        <MonthlyExecutionContractForecastView
                          shouldLoad={this.state.shouldLoad}
                          contract={contractWithForecast}
                          forecasts={this.state.contractsWithForecasts && contractWithForecast.forecasts && this.state.cashflowCalendars && this.state.cashflowCalendars[i] ? forecastsForCurrentDateRange(contractWithForecast.forecasts, this.state && this.state.cashflowCalendars && this.state.cashflowCalendars.length > 0 ? this.state.cashflowCalendars[i]["displayedCashflowDateRange"] : this.state.displayedCashflowDateRange) : []}
                          forecastSum={computeForecastSum(contractWithForecast.forecasts, '')}
                          forecastUpdated={this.contractForecastUpdated}
                          refreshState={this.contractUpdated}
                          onSplitSave={this.saveSplits}
                          onMoveRight={this.moveCashflow}
                          currentDate={this.state.currentDateMoment}
                          resetForecasts={this.resetContractForecasts}
                          clearAllForecasts={this.clearAllForecasts}
                          categoryType={this.props.match.params.categoryType}
                          seeAllInvoices={this.seeAllInvoices}
                          addInvoice={this.addInvoice}
                          invoicesRequestParams={{
                            projectId: this.getProjectId(),
                            categoryName: this.state.categoryDetails.name,
                          }}
                          projectId={this.getProjectId()}
                          budgetLine={categoryId}
                          onCancel={this.getProjectDetailsAndForecasts}
                          saveForecasts={this.saveForecasts}
                          vatList={this.state.vatList}
                          dateRange={this.state && this.state.cashflowCalendars && this.state.cashflowCalendars[i] && this.state.cashflowCalendars.length > 0 && this.state.cashflowCalendars[i]["displayedCashflowDateRange"]}
                          dateRangeLimit={this.state && this.state.cashflowCalendars && this.state.cashflowCalendars[i] && this.state.cashflowCalendars.length > 0 && this.state.cashflowCalendars[i]["dateCashflowRangeLimit"] }
                          updateDateRange={
                            this.state && this.state.cashflowCalendars && this.state.cashflowCalendars[i] && this.state.cashflowCalendars.length > 0 && this.state.cashflowCalendars[i]["updateCashflowDateRange"]
                        }
                          openedMonths={this.state.openedMonths}
                          categoryDetailsType={this.state.categoryDetails.type}
                        />
                      </React.Fragment>
                    );
                  })
                }
                <div className="kb-accordion-actions pt-4 pb-5">
                  <button className="btn btn-primary mr-3" onClick={this.toggleAddContractor}>
                    Add new contract
                  </button>
                </div>
              </div>
            </div>)}
            {this.state.categoryDetails &&
              (this.state.categoryDetails.type === "FINANCING" || this.state.categoryDetails.type === "INVESTMENT") && <DrawdownReimbursmentData
                drawdowns={this.state.categoryDetails.drawdownViewDto && this.state.categoryDetails.drawdownViewDto.drawdowns ? this.state.categoryDetails.drawdownViewDto.drawdowns : []}
                refreshState={this.initComponent}
                categoryId={this.getCategoryId()}
              />}
              {this.state.categoryDetails && (this.state.categoryDetails.type === 'COST' || this.state.categoryDetails.type === 'INCOME') &&<div className="col-12 w-100 px-0">

              <div className="black-nav-tabs pl-200">
                <div className="forcast-title">
                  {this.state.shouldLoad ?  (
                      <div className='container-inline-div'>
                        <div className='inline-div'> Forecast </div> <div className="spinner spinner-smaller"/> 
                      </div>
                  ) : (
                      <span>Forecast</span>
                  )}
                </div>
                <Tabs
                transition={false}
                id="controlled-tab"
                activeKey={this.state.activeTab}
                onSelect={key => this.changeTab(key)}
                className="kb-tabs-nav bg-dark p-2 tabs-to-right"  variant='pills'
              >
                <Tab tabClassName="kb-tabs-nav px-4 mr-2" eventKey={'Initial'} title={'Initial'} key={'Initial'}>
                  <div className="container-fluid">

                    <div className="row">
                    <div className="col-md-4 align-self-center pt-4">
                    </div>
                    <div className="col-md-4 align-self-center pt-4">
                      <DateRangeNavigator
                        dateRange={this.state.displayedDateRange}
                        dateRangeLimit={this.state.dateRangeLimit}
                        updateDateRange={this.updateDateRange}
                      />
                    </div>
                    <div className="col-md-4 align-self-center pt-4 text-right">
                      <div className="py-3 m-0 kb-rtb-forecast-initial-actual">
                        <div className="kb-real-forecast-title">Initial Total Forecast </div>
                        <div className="kb-real-forecast-value">{this.state.shouldLoad ? 0 : prettyPrint(initialForecastSum)} EUR</div>
                      </div>
                    </div>
                  </div>
                  {this.state.contractsWithForecasts && <MonthlyExecutionRemainingForecastView
                    shouldLoad={this.state.shouldLoad}
                    categoryName={this.state.categoryDetails.name}
                    forecasts={forecastsForCurrentDateRange(this.state.remainingForecasts, this.state.displayedDateRange)}
                    forecastSum={initialForecastSum}
                    forecastUpdated={this.remainingForecastUpdated}
                    onSplitSave={this.saveSplits}
                    onMoveRight={this.moveRight}
                    currentDate={this.state.currentDateMoment}
                    budget={this.state.categoryDetails.budget}
                    committed={this.state.categoryDetails.committed}
                    resetForecasts={this.resetRemainingForecasts}
                    clearAllForecasts={this.clearAllForecasts}
                    toBeInvestedCashPosition={this.state.cashPositionData}
                    type={'Initial'}
                    openedMonths={this.state.openedMonths}
                  />}
                  </div>
                </Tab>

                {this.state.categoryDetails && (this.state.categoryDetails.type === 'COST' || this.state.categoryDetails.type === 'INCOME') &&
                <Tab tabClassName="kb-tabs-nav px-4 mr-2" eventKey={'Actual'} title={'Actual'} key={'Actual'}>

                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-4 align-self-center pt-4">
                    </div>
                    <div className="col-md-4 align-self-center pt-4">
                      <DateRangeNavigator
                        dateRange={this.state.displayedDateRange}
                        dateRangeLimit={this.state.dateRangeLimit}
                        updateDateRange={this.updateDateRange}
                      />
                    </div>
                    <div className="col-md-4 align-self-center pt-4 text-right">
                        <div className="row">
                            <div className="col text-right">
                                <div className="d-inline-block text-left">
                                    <div className="text-left kb-real-forecast-title">Actual Total Forecast </div>
                                    <div className="text-left kb-real-forecast-title">Remaining Forecast</div>
                                </div>
                                <div className="d-inline-block text-right ml-3">
                                    <div className="text-right kb-real-forecast-value">
                                      {this.state.shouldLoad ? 0 : prettyPrint(actualTotalForecast)} EUR
                                    </div>
                                    <div className="text-right kb-real-forecast-value">{this.state.shouldLoad ? 0 : prettyPrint(remainingForecastOrElseZero)} EUR</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 align-self-center pt-4 text-right">
                    </div>
                  </div>
                  {this.state.contractsWithForecasts && <MonthlyExecutionRemainingForecastView
                    shouldLoad={this.state.shouldLoad}
                    categoryName={this.state.categoryDetails.name}
                    forecasts={forecastsForCurrentDateRange(this.state.remainingForecasts, this.state.displayedDateRange)}
                    forecastSum={executionForecastSum}
                    forecastUpdated={this.remainingForecastUpdated}
                    onSplitSave={this.saveSplits}
                    onMoveRight={this.moveRight}
                    currentDate={this.state.currentDateMoment}
                    budget={this.state.categoryDetails.budget}
                    committed={this.state.categoryDetails.committed}
                    resetForecasts={this.resetRemainingForecasts}
                    clearAllForecasts={this.clearAllForecasts}
                    toBeInvestedCashPosition={this.state.cashPositionData}
                    type={'Actual'}
                    openedMonths={this.state.openedMonths}
                  />}
                  </div>
                </Tab>
                }

              </Tabs>
            </div>
            </div>
            //end condition no fin or inv
          }

          </PageSection>
        </main>

        {/*add contract form*/}
        <RenderIf condition={this.state.addingContractor}>
          <div className='popup'>
            <Button
              className="btn btn-link kb-btn_close-popup"
              onClick={this.togglePopup}
            >

            </Button>
            <div className="popup_inner">
              <div className={"kb-manage-contractors kb-add-contractor-form" + isAddingContractorClassName}>

                <h5 className={"kb-popup_title py-3 pt-5"}>
                  Add new contract to: {this.state.categoryDetails.name}
                </h5>

                <QuickAddContractor parentRefresh={this.getAllContractors}/>

                <NewCostContractForm
                  categoryId={categoryId}
                  contractors={this.state.contractors}
                  vatList={this.state.vatList}
                  defaultVat={this.state.defaultVatId}
                  refreshState={this.contractUpdated}
                  onCancel={this.toggleAddContractor}
                  categoryDetails={this.state.categoryDetails}
                />
              </div>
            </div>
          </div>
        </RenderIf>

        <RightSideModal
          items={['Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ', 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.', 'Dessert apple pie dragée topping carrot cake ice cream biscuit pie. Gummi bears soufflé jelly beans.', 'Fruitcake ice cream cookie ice cream marshmallow soufflé sesame snaps cupcake pie.']}
        />
      </React.Fragment>
    );
  }

}

export default withRouter(ManageExecutionOnSubcategoryPage);
