import moment from "moment";

export const dateDayMonthYearFormat = "DD/MM/YYYY";

/**
 * Will display the date in : "Oct 2018" format
 */
export const dateMonthYearFormat = "MMM YYYY";

/**
 * Receives a string which represent a date, and outputs a string in the wanted format.
 * The string date must follow ISO 8601 format https://en.wikipedia.org/wiki/ISO_8601
 * more details http://momentjs.com/docs/
 * @param value the date as string
 * @returns {string} the formatted date
 */
export function stringToFormattedDate(value) {
    return moment(value).format(dateDayMonthYearFormat);
}

export function stringToCustomFormattedDate(value, dateFormat) {
    return moment(value).format(dateFormat);
}

export function isMomentMonthAndYearEqual(moment1, moment2) {
    return moment1.format(dateMonthYearFormat) === moment2.format(dateMonthYearFormat);
}

// strips date, keeps only month and year
export function monthAndYearMomentFromDateString(date) {
    const dateMoment = moment(date);
    return momentFromMonthAndYear(dateMoment.month(), dateMoment.year());
}

// month is zero-indexed
export function momentFromMonthAndYear(month, year) {
    return moment()
        .month(month)
        .year(year)
        .date(1)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);
}