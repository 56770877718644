import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import CollapsiblePageSection from "../../components-shared/CollapsiblePageSection";
import AssignableUsersForm from "../user/support/project/forms/AssignableUsersForm";
import AssignedUsersListGrid from "../user/support/project/view/AssignedUsersListGrid";

const ProjectUsers = (props) => {

    const {formIsEnabled, assignedUsersMap, assignableUsersMap, userRoles, assignUser, unAssignUser} = props;
    const hasUserRoles = userRoles && userRoles instanceof Array && userRoles.length > 0;

    const displayRoleSection = useCallback((userRole) => {

        const hasUsersAssigned = assignedUsersMap && assignedUsersMap[userRole.name];
        const numberOfUsersAssigned = hasUsersAssigned ? assignedUsersMap[userRole.name].length : 0;

        const title = (
            <React.Fragment>
                <i className="fas fa-user-friends mr-3"></i>
                <span className="font-weight-bold">{userRole.name}</span>
                <span
                    className="kb-name_badge text-primary ml-2 font-weight-bold">{numberOfUsersAssigned}</span>
            </React.Fragment>
        )

        return (
            <CollapsiblePageSection key={userRole.name}
                                    borderBottom={true}
                                    title={title}
            >

                <AssignableUsersForm
                    userRole={userRole}
                    assignableUsers={assignableUsersMap[userRole.name]}
                    assignUser={assignUser}
                    formIsEnabled={formIsEnabled}
                />
                <AssignedUsersListGrid
                    userRole={userRole}
                    assignedUsers={assignedUsersMap[userRole.name]}
                    unAssignUser={unAssignUser}
                    formIsEnabled={formIsEnabled}
                />
            </CollapsiblePageSection>
        )
    }, [formIsEnabled, assignedUsersMap, assignableUsersMap, assignUser, unAssignUser])


    return (
        <React.Fragment>
            <div className="kb-assign-users">
                <div className="roles">
                    {
                        hasUserRoles &&
                        userRoles.map(role => displayRoleSection(role))
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

ProjectUsers.propTypes = {
    userRoles: PropTypes.array,
    assignableUsersMap: PropTypes.object,
    assignedUsersMap: PropTypes.object,
    assignUser: PropTypes.func,
    unAssignUser: PropTypes.func,
    formIsEnabled: PropTypes.bool
};

export default withRouter(ProjectUsers);
