import React from 'react';
import {connect} from "react-redux";
import logo from "../assets/images/keybud-logo-01.png";
import { addLoading, removeLoading } from '../redux/actions/actions';

const LoadingScreen = (props) => { 
  const loadingArr = props.loading.callsLoading;
  return (
    <div className={"preloader" + (props.isLoading
      || (loadingArr ? loadingArr.length > 0 : false) 
    ? " visible-loader" : "")}>
        <div className="loading-bar">
            <img src={logo} alt="kybud" className="loading-logo"/>
            <div className="loading-element">
            </div>
            <div className="text-load text-center">Please wait...</div>
        </div>
    </div>
  );
}

const mapStateToProps = state => ({
  loading: state.loading
});

const mapDispatchToProps = dispatch => ({
  addLoading: id => dispatch(addLoading(id)),
  removeLoading: id => dispatch(removeLoading(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadingScreen);
