import React, {useContext, useState, useEffect, useRef, useCallback} from "react";
import {categoryEntityType, categoryTypes, categoryTypeBusinessPlan} from "../category/constant/CategoryConstants";
import CategoryManagementComponentContainer from "../category/CategoryManagementComponentContainer";
import Breadcrumbs from "../../components-shared/Breadcrumbs/Breadcrumbs";
import RightSideModal from "../../components-shared/RightSideModal/RightSideModal";
import CategoriesContext, {CategoriesContextProvider} from "../../main-components/CategoriesContext";
import {Button} from "react-bootstrap";
import BudgetBusinessPlanComponentContainer from "../budget/support/project/v2/BudgetBusinessPlanComponentContainer";
import {getCategories} from "../category/actions/categoriesActions";

export default function ManageProjectCategoryPage(props) {
    const [categoriesLengths, setCategory, setCategoriesLength] = useContext(CategoriesContext);
    const [path, setPath] = useState('cost');
    const [loopItems, setLoopItems] = useState([]);
    const [loopItemsLength, setLoopItemsLength] = useState([]);
    const [cost, setCost] = useState(0);
    const [income, setIncome] = useState(0);
    const [financing, setFinancing] = useState(0);
    const [investment, setInvestment] = useState(0);
    const [costCategories, setCostCategories] = useState(null);
    const [costInitialBudget, setCostInitialBudget] = useState(null);
    const [costExecutionBudget, setCostExecutionBudget] = useState(null);

    const componentIsMounted = useRef(false);
    const setComponentIsMounted = useCallback(value => {
        componentIsMounted.current = value;
    }, []);

    useEffect(() => {
        return () => {
            if (componentIsMounted.current) {
                setComponentIsMounted(false);
            }   
        }
    }, [setComponentIsMounted])

    useEffect(() => {
        if (!componentIsMounted.current) {
            setComponentIsMounted(true);
        }   
        const getCostCategories = () => {
            getCategories(props.match.params.projectId, 'COST').then(result => {
                if (componentIsMounted.current) {
                    setCostCategories(result);
                }
            })
        }
        getCostCategories();
    }, [props.match.params.projectId, setComponentIsMounted]);

    useEffect(() => {
        setCostInitialBudget(getTotalCostInitialBudget(costCategories))
        setCostExecutionBudget(getTotalCostExecutionBudget(costCategories))
    }, [costCategories]);

    useEffect(() => {
        const updateLoopItems = (categories) => {
            let newLoopItems = loopItems;
            let loopItemsLength = newLoopItems.push(categories);
            setLoopItems(newLoopItems);
            setLoopItemsLength(loopItemsLength);
        }

        const getCatTotals = () => {
            const stateCalls = {
                cost: setCost,
                income: setIncome,
                financing: setFinancing,
                investment: setInvestment
            };
            categoryTypes.map((categoryType, i) => (
                getCategories(props.match.params.projectId, categoryType.type).then((categories) => {
                    if (componentIsMounted.current) {
                        if (!categories.code) {
                            stateCalls[categoryType.type.toLowerCase()](categories ? categories.length : 0)
                        }
                        updateLoopItems(categories);
                    }
                })
            ));
        }
        getCatTotals();
    }, [props.match.params.projectId, setLoopItems, setLoopItemsLength, loopItems, setComponentIsMounted]);
    useEffect(() => {
        if (loopItemsLength === categoryTypes.length) {
            const categoriesObj = {
                cost: cost,
                income: income,
                financing: financing,
                investment: investment
            };
            setCategoriesLength(categoriesObj);
        }
    }, [loopItemsLength, setCategoriesLength, cost, financing, income, investment]);

    const getProjectId = () => {
        return props.match.params.projectId;
    }


    const getTotalCostExecutionBudget = (costCategories) => {
        const total = costCategories && costCategories
            .flatMap(category => category.children)
            .filter(category => category != null)
            .map(category => category.executionBudget)
            .filter(value => value != null)
            .map(value => parseInt(value))
            .reduce(function (acumulator, value) {
                return acumulator + value;
            }, 0);
        return parseInt(total);
    }

    const getTotalCostInitialBudget = (costCategories) => {
        const total = costCategories && costCategories
            .flatMap(category => category.children)
            .filter(category => category != null)
            .map(category => category.initialBudget)
            .filter(value => value != null)
            .map(value => parseInt(value))
            .reduce(function (acumulator, value) {
                return acumulator + value;
            }, 0);
        return parseInt(total);
    }

    const onCostUpdate = (costCategories) => {
        setCostInitialBudget(getTotalCostInitialBudget(costCategories));
        setCostExecutionBudget(getTotalCostExecutionBudget(costCategories));
    }

    return (
        <React.Fragment>
            <Breadcrumbs breadcrumbs={[{url: '/projects', title: 'Home'}, {
                url: `/project/${getProjectId()}`,
                title: props.projectDetails && props.projectDetails.name
            }, {url: `/budget`, title: 'Manage Budget'}]}/>
            <header className="kb-header px-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 d-flex align-items-center ">
                            <div className="kb-title_page d-flex align-items-center py-4">
                                <h1 className="h4 font-weight-normal m-0">Budget</h1>
                            </div>
                            <nav className="kb-nav_ctas ml-auto">
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
            <main className="kb-main kb-main_categories pb-4 bg-white">
                <div className="bg-light">
                    <div className="col-12 pb-3">
                        {
                            categoryTypes.map(categoryType => (
                                <Button variant={path === categoryType.type.toLowerCase() ? "secondary" : "light"}
                                        className={path === categoryType.type.toLowerCase() ? "btn-secondary mr-3" : "btn-light mr-3"}
                                        onClick={() => setPath(categoryType.type.toLowerCase())}
                                        key={categoryType.type.toLowerCase() + 'button'}>{categoryType.displayValue + ' ' + categoriesLengths[categoryType.type.toLowerCase()]}</Button>
                            ))
                        }
                        <Button variant={path === categoryTypeBusinessPlan.type.toLowerCase() ? "secondary" : "light"}
                                className={path === categoryTypeBusinessPlan.type.toLowerCase() ? "btn-secondary mr-3" : "btn-light mr-3"}
                                onClick={() => setPath(categoryTypeBusinessPlan.type.toLowerCase())}>{categoryTypeBusinessPlan.displayValue}</Button>
                    </div>
                </div>
                {
                    categoryTypes.map(categoryType =>
                        path === categoryType.type.toLowerCase() && (
                            <div className="container-fluid" key={categoryType.type.toLowerCase()}>
                                <div className="row">
                                    <div className="col-12">
                                        <CategoriesContextProvider>
                                            <CategoryManagementComponentContainer
                                                key={categoryType.type}
                                                entityId={props.match.params.projectId}
                                                categoryType={categoryType}
                                                entityType={categoryEntityType.projectCategory}
                                                displayTotal={true}
                                                categoriesLengths={categoriesLengths}
                                                setCategory={setCategory}
                                                collapsed={false}
                                                totalCostInitialBudget={costInitialBudget}
                                                totalCostExecutionBudget={costExecutionBudget}
                                                onCostUpdate={onCostUpdate}
                                            />
                                        </CategoriesContextProvider>
                                    </div>
                                </div>
                            </div>)
                    )
                }
                {
                    path === categoryTypeBusinessPlan.type.toLowerCase() &&
                    <BudgetBusinessPlanComponentContainer projectId={getProjectId}/>
                }


            </main>
            <RightSideModal
                items={['ootsie roll wafer wafer cotton candy marshmallow. Croissant topping liquorice wafer brownie marshmallow oat cake. ', 'Gummies wafer candy canes apple pie jelly beans pastry wafer. Chocolate bar pudding jelly-o sugar plum. Tiramisu pie donut sweet bear claw.', 'Candy cupcake carrot cake candy brownie jelly. Marzipan biscuit marzipan. Candy dragée pie lollipop jelly-o. Cotton candy danish dessert liquorice pastry caramels sugar plum powder toffee.']}
            />
        </React.Fragment>
    );
}
