import React, { useEffect, useState } from 'react';
import { prettyPrint } from '../../../common/numberOperations';

const VatReport = (props) => {

  const vatReport = props.vatReport;

  return (
    <div className="col-md-12">
      <div className="bg-white p-4 my-3">
        <div className="row">
          <div className="col-md-12 font-weight-bold my-3">
            {vatReport.shouldLoad ? (
              <div className='container-inline-div'>
                <div className='inline-div'>
                  {props.title}
                </div>
                <div className="spinner inline-div" />
              </div>
            ) : (
              <div>
                {props.title}
              </div>
            )}
          </div>
          <div className="col-md-6 font-weight-bold my-3">
            <table className="table table-striped">
              <tbody>
                <tr>
                  <th scope="col">VAT Deductible</th>
                  <td className="col-3 text-right pr-3">{vatReport && prettyPrint(vatReport.vatDeductible)} EUR</td>
                </tr>
                <tr>
                  <th scope="row">VAT Collectable</th>
                  <td className="col-3 text-right pr-3">{vatReport && prettyPrint(vatReport.vatCollectable)} EUR</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6 font-weight-bold my-3">
            <table className="table table-striped">
              <tbody>
                <tr>
                  <th scope="row">VAT Payable</th>
                  <td className="col-3 text-right pr-3">{vatReport && prettyPrint(vatReport.vatPayable)} EUR</td>
                </tr>
                <tr>
                  <th scope="row">VAT Recovarable</th>
                  <td className="col-3 text-right pr-3">{vatReport && prettyPrint(vatReport.vatRecovarable)} EUR</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VatReport;
