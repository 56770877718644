import { ADD_NEW_LOADING, REMOVE_LOADING } from "./action-types";

export const addLoading = (payload) => dispatch => {
  dispatch(dispatchAddLoading(payload));
}
export const removeLoading = (payload) => dispatch => {
  dispatch(dispatchRemoveLoading(payload));
}




export const dispatchAddLoading = (payload) => {
  return {type: ADD_NEW_LOADING, payload};
}
export const dispatchRemoveLoading = (payload) => {
  return {type: REMOVE_LOADING, payload};
}