import React, {Component} from 'react';
import {getProjectById} from "./actions/projectActions";
import {withRouter} from "react-router-dom";
import Breadcrumbs from '../../components-shared/Breadcrumbs/Breadcrumbs';
import RightSideModal from '../../components-shared/RightSideModal/RightSideModal';
import './settings.css'
import ProjectSettings from "./ProjectSettings";

class ManageProjectDetailsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projectDetails: null,
        };

        this.getProjectId = this.getProjectId.bind(this);
        this.refreshState = this.refreshState.bind(this);
        this.componentIsMounted = false;
    }

    componentDidMount() {
        this.componentIsMounted = true;
        this.refreshState();
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
    }

    getProjectId() {
        return this.props.match.params.projectId;
    }

    refreshState() {
        const projectId = this.getProjectId();
        if (projectId) {
            getProjectById(projectId).then((projectDetails) => {
                if (this.componentIsMounted) {
                    this.setState({
                        projectDetails: projectDetails,
                    });
                }
            });
        }
    }

    render() {
        const projectId = this.getProjectId();
        const projectDetails = this.state.projectDetails;

        return (
            <React.Fragment>
                <Breadcrumbs breadcrumbs={[{url: '/projects', title: 'Home'}, {
                    url: `/project/${projectId}`,
                    title: projectDetails && projectDetails.name
                }, {url: `/view`, title: 'View Project'}]}/>
                <header className="kb-header px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 d-flex align-items-center ">
                                <div className="kb-title_page d-flex align-items-center py-4">
                                    <h1 className="h4 font-weight-normal m-0">Project settings</h1>
                                </div>
                                <nav className="kb-nav_ctas ml-auto">
                                </nav>
                            </div>
                        </div>
                    </div>
                </header>
                <main className="kb-main kb-main_project--details px-3 py-4 bg-white">
                    <ProjectSettings
                        projectId={this.getProjectId()}
                        editProject={true}
                        formIsEnabled={false}
                    />
                </main>

                <RightSideModal
                    items={['Candy canes carrot cake biscuit liquorice tart icing chocolate brownie.', 'Gingerbread topping candy sweet roll. Macaroon soufflé biscuit brownie tart sesame snaps. Cotton candy croissant topping tart gummi bears sweet roll. Brownie sweet fruitcake jelly-o cake.', 'Lollipop gummies tiramisu soufflé macaroon candy canes candy canes lollipop. Gummies marshmallow jelly-o muffin chocolate bar icing. Liquorice sesame snaps toffee.']}
                />
            </React.Fragment>
        );
    }
}

export default withRouter(ManageProjectDetailsPage);