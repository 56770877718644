import React, {Component} from "react";
import PropTypes from 'prop-types';
import {getConfiguringTotals} from "../../../../category/actions/budgetExecutionActions";
import ProjectUnauthorizedPanel from "../../../../../components-shared/ProjectUnauthorizedPanel";
import BudgetExecutionComponentView from "./BudgetExecutionComponentView";
import BudgetExecutionTotalsView from "./BudgetExecutionTotalsView";
import BudgetExecutionConfiguringTotalsView from "./BudgetExecutionConfiguringTotalsView";

class BudgetExecutionComponentContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            totals: null,
            configuringTotals: [],
            error: '',
        };
    };

    componentDidMount() {
        const projectId = this.props.projectId;
        if (projectId) {
            getConfiguringTotals(projectId, this.props.categoryType.type).then((data) => {
                if (data.code) {
                    return this.setState({error: data.message});
                } else {
                    this.setState({
                        configuringTotals: data
                    })
                }
            });
        }
    }


    render() {
        const projectId = this.props.projectId;

        if (this.state.error) {
            return <ProjectUnauthorizedPanel error={this.state.error} projectId={projectId}/>;
        }
        return (
            <React.Fragment>
                <h4 className="mb-0 py-3 pl-4 border-bottom kb-real-time-budget-color">{this.props.categoryType.displayValue + " Execution"}</h4>
                <div className="categories page-budget">
                    <BudgetExecutionComponentView
                        categories={this.props.categories}
                        // invoices={this.props.invoices}
                        // contracts={this.props.contracts}
                        // forecasts={this.props.forecasts}
                        projectId={projectId}
                        // allCategories={this.props.categories}
                        // totalCost = {this.props.totalCost}
                    />
                    {this.props.categories && this.props.categories.length > 0 &&
                    <BudgetExecutionTotalsView
                        categoryType={this.props.categoryType.type}
                        categories={this.props.categories}
                        hasExecution={this.props.hasExecution}
                        costTotal={this.props.costTotal}
                    />
                    }
                    <BudgetExecutionConfiguringTotalsView
                        configuringTotals={this.state.configuringTotals}
                        categoryType={this.props.categoryType.type}
                        categories={this.props.categories}
                        hasExecution={this.props.hasExecution}
                        costTotal={this.props.costTotal}
                    />
                </div>
            </React.Fragment>
        );
    }
}

BudgetExecutionComponentContainer.propTypes = {
    projectId: PropTypes.string.isRequired,
    categoryType: PropTypes.shape({
        displayValue: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    }).isRequired,
    categories: PropTypes.array,
    // invoices: PropTypes.array,
    // contracts: PropTypes.array,
    // forecasts: PropTypes.array
};

// componentType = category or subcategory
// categoryType = COST, INCOME, FINANCING or INVESTMENT

export default BudgetExecutionComponentContainer;