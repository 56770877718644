import React, {useState} from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import RenderIf from '../../../../main-components/RenderIf';
import { deleteDrawdownAction } from '../../../category/actions/budgetExecutionActions';
import { prettyPrint } from '../../../common/numberOperations';
import AddDrawdownReimbursment from './AddDrawdownReimbursment';

const DrawdownReimbursmentData = (props) => {
  const cardClassname = "kb-list-item";
  
  const [addingDrawdownReimbursment, setAddingDrawdownReimbursment] = useState(false);
  const [drawdownToEdit, setDrawdownToEdit] = useState(null);
  
  const toggleAddingDrawdownReimbursment = () => {
    setAddingDrawdownReimbursment(!addingDrawdownReimbursment);
  };

  const deleteDrawDownReimbursment = (id) => {
    deleteDrawdownAction(id).then(res => {
      if (!res || res.status !== 200) {
        toast.error("There was an error during saving!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
      } else {
        toast.success("Your changes were saved successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
        props.refreshState();
      }
    });
  }

  const toggleEditDrawDownReimbursment = (drawdown) => {
    toggleAddingDrawdownReimbursment();
    setDrawdownToEdit(drawdown);
  }

  return (
    <div className="col-12">
      <div className="accordion" id="accordion-drawdown-reimbursment-data">
        <div className={"kb-list-accordion"}>
          {
            props.drawdowns.map((drawdown, i) => {              
              return (<div className={cardClassname + " row d-flex align-items-center border-bottom"} key={i} id={i}>
                <div className="col-4 text-left py-3">
                    <span className="font-weight-bold">{drawdown.name}</span> 
                </div>
                <div className="col text-left py-3 font-weight-bold">
                {drawdown.drawdownType === 'DRAWDOWN' ? 'Drawdown' : drawdown.drawdownType === 'REIMBURSEMENT' ? 'Reimbursement' : ''}
                </div>
                <div className="col text-right py-3 font-weight-bold">
                    <div>{drawdown.drawdownType === 'DRAWDOWN' ? prettyPrint(drawdown.amount) : 0} EUR</div>
                </div>
                <div className="col text-right py-3 font-weight-bold">
                    <div>{prettyPrint(drawdown.remaining)} EUR</div>
                </div>
                <div className="col text-right py-3 font-weight-bold">
                    <div>{drawdown.drawdownType === 'REIMBURSEMENT' ? prettyPrint(drawdown.amount) : 0} EUR</div>
                </div>
                <div className="col text-right py-3 font-weight-bold">
                    <div>{prettyPrint(drawdown.outstanding)} EUR</div>
                </div>
                <div className="col two-btn-col text-right py-3">
                    <button className="kb-btn_edit btn btn-link px-2 ml-1"
                            title="Edit contract"
                            onClick={() => toggleEditDrawDownReimbursment(drawdown)}>
                        <i className="fas fa-pen"/>
                    </button>
                    <button className="kb-btn_delete btn btn-link px-2 ml-1"
                            title="Delete contract"
                            onClick={() => deleteDrawDownReimbursment(drawdown.id)}>
                        <i className="fas fa-trash"/>
                    </button>
                </div>
              </div>)
            })
          }
        </div>
      </div>
      <RenderIf condition={addingDrawdownReimbursment}>
        <div className='popup'>
          <Button
            className="btn btn-link kb-btn_close-popup"
            onClick={toggleAddingDrawdownReimbursment}
          >

          </Button>
          <div className="popup_inner variable-width-side-modal">
            <div className={"kb-manage-contractors kb-add-contractor-form"}>
                <AddDrawdownReimbursment 
                  toggleModal={toggleAddingDrawdownReimbursment} categoryId={props.categoryId}
                  refreshState={props.refreshState}
                  drawdownToEdit={drawdownToEdit}
                />
            </div>
          </div>
        </div>
      </RenderIf>
    </div>
  );
}

export default DrawdownReimbursmentData;
