export const hasErrors = (stateField, fieldName, formValidation, inFormError, fieldType, setError) => {
  const errorsArray = [
    {
        errMsg: `The ${fieldName} field should not be empty or have only whitespaces`,
        isValid: (value) => {
            return value && value.replace(/\s/g, '').length > 0;
        },
        usedFor: ['text']
    },
    {
        errMsg: `The ${fieldName} field should not be longer than 100 characters`,
        isValid: (value) => {
            return value.length <= 100;
        },
        usedFor: ['text']
    }];

    
    let errorsFound = false;
    let errorsMessageFound = '';
        
        errorsArray.forEach(error => {
            if (error.usedFor.includes(fieldType)){
                if (formValidation) {
                    if (!error.isValid(stateField)) {
                        errorsFound = true;
                    }
                } else {
                    if (!error.isValid(stateField)) {
                        errorsMessageFound = error.errMsg;
                    }
                }
            }
        });
  
    if (inFormError) {
        return errorsMessageFound;
    } else if (formValidation) {
        return errorsFound;
    } else {
        return setError(errorsMessageFound);
    }

}