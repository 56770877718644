import React from 'react';
import { prettyPrint } from '../../../common/numberOperations';
import { containerStyle } from "./TableDataReport";

const rowStyle = {
  marginLeft: "30px",
  marginRight: "30px"
}
const GeneralSummaryTop = (props) => {

  const header = props.header;


  return (
    <div className="bg-dark text-white mb-3 py-3" style={containerStyle}>
      <div className="row" style={rowStyle}>
        <div className="col-2">
          <div>Budget</div>
          <div className="font-size-3 font-weight-bold">{header && prettyPrint(header.budget)} EUR</div>
        </div>
        <div className="col-2">
          <div>Committed</div>
          <div className="font-size-3 font-weight-bold">{header && prettyPrint(header.committed)} EUR</div>
        </div>
        <div className="col-2">
          <div>Invoiced</div>
          <div className="font-size-3 font-weight-bold">{header && prettyPrint(header.invoiced)} EUR</div>
        </div>
        <div className="col-2">
          <div>Forecast</div>
          <div className="font-size-3 font-weight-bold">{header && prettyPrint(header.forecast)} EUR</div>
        </div>
        <div className="col-2">
          <div>Total</div>
          <div className="font-size-3 font-weight-bold">{header && prettyPrint(header.total)} EUR</div>
        </div>

        {header.shouldLoad ? (
          <div className="col-2 container-inline-div">
            <div className='inline-div'>
              <div>Status</div>
              <div className="font-size-3 font-weight-bold">{header && prettyPrint(header.status)} EUR</div>
            </div>
            <div className='spinner spinner-header' />
          </div>
        ) : (
          <div className="col-2">
            <div>
              <div>Status</div>
              <div className="font-size-3 font-weight-bold">{header && prettyPrint(header.status)} EUR</div>
            </div>
          </div>
        )}

      </div>
    </div>
  );
}

export default GeneralSummaryTop;
