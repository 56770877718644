import React, { useState, createContext } from 'react';

export const CategoriesContext = createContext();
const categories = {
  cost: 0,
  income: 0,
  financing: 0,
  investment: 0,
  reimbursement: 0
}
export const CategoriesContextProvider = (props) => {
  const [categoriesLengths, setCategoriesLength] = useState(categories);
  const setCategory = (category, value) => {
    let newCategoriesLengths = {...categoriesLengths};
    newCategoriesLengths[category] = value;
    setCategoriesLength(newCategoriesLengths);
  }
  return (
    <CategoriesContext.Provider value={[categoriesLengths, setCategory, setCategoriesLength]}>
      {props.children}
    </CategoriesContext.Provider>
  );
}

export const CategoriesContextConsumer = CategoriesContext.Consumer;

export default CategoriesContext;
