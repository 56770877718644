import React, {Component} from "react";
import Breadcrumbs from "../../components-shared/Breadcrumbs/Breadcrumbs";
import {getAllContractorsByProjectId} from "../user/actions/userActions";
import Pagination from "react-js-pagination";
import RightSideModal from "../../components-shared/RightSideModal/RightSideModal";

class ManageProjectContractorsPage extends Component {
  
  constructor (props) {
    super(props);
    
    this.state = {
      contractors: [],
      name: "",
      pageNumber: 0,
      pageSize: 12
    };
    
    this.getProjectId = this.getProjectId.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    
    this.getContractorsForProject = this.getContractorsForProject.bind(this);
    
    this.timeout = 0;
    this.componentIsMounted = false;
  }
  
  componentDidMount () {
    if (!this.componentIsMounted) {
      this.componentIsMounted = true;
      this.getContractorsForProject();
    }
  }

  componentWillUnmount () {
    this.componentIsMounted = false;
  }
  
  getProjectId () {
    return this.props.match.params.projectId;
  }
  
  /**
   * Get all available contractors
   * projectId, name, pageNumber, pageSize
   */
  getContractorsForProject () {
    getAllContractorsByProjectId(this.getProjectId(), this.state.name, this.state.pageNumber, this.state.pageSize).then((contractors) => {
      if (this.componentIsMounted) {
        this.setState({
          contractors: contractors
        });
      }
    })
  }
  
  onPageChange (pageNumber) {
    getAllContractorsByProjectId(this.getProjectId(), this.state.name, pageNumber - 1, this.state.pageSize).then((contractors) => {
      if (this.componentIsMounted) {
        this.setState({
          contractors: contractors,
          pageNumber: pageNumber - 1
        })
      }
    })
  }
  
  onFilterChange (name) {
    this.setState({
      name: name
    }, this.getContractorsByFilterWithDelay());
  }
  
  getContractorsByFilterWithDelay () {
    return () => {
      const isEmptyOrLongerThanThreeCharacters = this.state.name === "" || this.state.name.length >= 3;
      
      if (isEmptyOrLongerThanThreeCharacters) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(
          this.getContractorsByFilter(),
          250
        )
      }
    };
  }
  
  getContractorsByFilter () {
    return () => {
      getAllContractorsByProjectId(this.getProjectId(), this.state.name, 0, this.state.pageSize).then((contractors) => {
        if (this.componentIsMounted) {
          this.setState({
            contractors: contractors,
            pageNumber: 0,
          });
        }
      });
    };
  }
  
  render () {
    
    // noinspection CheckTagEmptyBody
    return (
         <React.Fragment>
            <Breadcrumbs breadcrumbs={[
          {url: '/projects', title: 'Home'},
          {url: `/project/${this.getProjectId()}`, title: this.props.projectDetails && this.props.projectDetails.name},
          {url: `/contractors`, title: 'View Contractors'}]}/>
           <header className="kb-header px-3">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 d-flex align-items-center ">
                        <div className="kb-title_page d-flex align-items-center py-4">
                            <h1 className="h4 font-weight-normal m-0">View Contractors</h1>
                        </div>
                        <nav className="kb-nav_ctas ml-auto">
                        
                        </nav>
                    </div>
                </div>
            </div>
        </header>
           <main className="kb-main kb-main_contractors px-3 py-4">
              <div className="container-fluid">
                  <div className="row">
                      <div className="col-12 pb-4">
                           <form className="filter-wrapper" onSubmit={e => { e.preventDefault(); }}>
                                <div className="form-group has-search m-0">
                                    <span className="fa fa-search form-control-search"></span>
                                    <input type="text" className="form-control bg-white" placeholder="Contractor name (at least 3 characters)" value={this.state.name} onChange={e=> this.onFilterChange(e.target.value)}/>
                                </div>
                            </form>
                      </div>
                  </div>
                  <div className="row">
                     
                          {this.state.contractors &&
                          this.state.contractors.contractors &&
                          this.state.contractors.contractors.length > 0 &&
                          this.state.contractors.contractors.map(user =>
                          <React.Fragment key={user.id}>
                          <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
                              <div className="card kb-card_contractors h-100">
                                  <div className="card-header bg-white border-bottom">
                                      <p className="label-icon h2 mb-3"><i className="fas fa-id-card"></i></p>
                                      <h5 className="label-company font-weight-bold mb-0">{user.name}</h5>
                                  </div>
                                  <div className="card-body py-3">
                                      <p className="user-email mb-2">{user.email}</p>
                                      <p className="user-phone mb-2">{user.phoneNumber}&nbsp;</p>
                                      <p className="user-description mb-2">no additional info</p>
                                  </div>
                              </div>
                              </div>
                          </React.Fragment>
                          )
                          }
                          {
                          this.state.contractors &&
                          this.state.contractors.contractors &&
                          this.state.contractors.contractors.length === 0 && (
                            <div className="col-12">
                              <h5 className="py-5">No contractors found</h5>
                              </div>
                          )
                          }
                      
                  </div> 
                  <div className="row">
                      <div className="col-12">
                          <div className="pagination-wrapper relative-pagination">
                              <Pagination 
                                activePage={this.state.pageNumber + 1} 
                                itemsCountPerPage={this.state.pageSize} 
                                totalItemsCount={this.state.contractors && this.state.contractors.totalItems ? this.state.contractors.totalItems : 0} 
                                pageRangeDisplayed={5} onChange={this.onPageChange} />
                          </div>
                      </div>
                  </div>
              </div>
          </main>
           
           <RightSideModal 
            items={['Cake lemon drops brownie cake gummi bears pie. Jelly-o pie cotton candy powder gingerbread.', 'Tootsie roll jelly-o muffin oat cake cake carrot cake. Cake donut chocolate bar jelly chocolate cake cookie chocolate bar candy canes pie. Gingerbread jelly-o donut marshmallow pastry fruitcake dragée.']}
        />
         </React.Fragment>

    )
  }
}

export default ManageProjectContractorsPage;
