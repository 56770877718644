import {genericDelete, genericGet, genericGetNoLoading, genericPost, genericPut} from "../../../components-shared/fetch-wrapper";
import {replaceUrlParams} from "../../../components-shared/url-utils";

const budgetExecutionEndpoint = "/real-time-budget";
const getCategoriesBudgetForProjectEndpoint = budgetExecutionEndpoint + '/category-type/:categoryType/project/:projectId';
const businessPlanEndpoint = "/business-plan";
//const categoryConfiguringTotalsEndpoint = "/real-time-budget/total-execution/";
//const getConfiguringTotalsForProjectAndCategoryEndpoint = categoryConfiguringTotalsEndpoint + ':projectId/category-type/:categoryType';
const configuredTotalEndpoint = "/configured-total";
const getConfiguredTotalsForProjectAndCategoryEndpoint = configuredTotalEndpoint + '/:projectId/category-type/:categoryType';

export function getCategoriesBudget(projectId, categoryType) {
    const urlParams = [{key: ":categoryType", value: categoryType}, {key: ":projectId", value: projectId}];
    const url = replaceUrlParams(getCategoriesBudgetForProjectEndpoint, urlParams);

    return genericGet(url);
}

export function getConfiguringTotals(projectId, categoryType) {
    const urlParams = [{key: ":projectId", value: projectId}, {key: ":categoryType", value: categoryType}];
    const url = replaceUrlParams(getConfiguredTotalsForProjectAndCategoryEndpoint, urlParams);

    return genericGetNoLoading(url);
}

export function getCategoryBudget(categoryId) {
    const url = budgetExecutionEndpoint + "/category/" + categoryId;

    return genericGet(url);
}

export function getBusinessPlan(projectId) {
    const url = businessPlanEndpoint + "/" + projectId;
    return genericGet(url);
}

export function saveBudgetExecutionForecasts(data) {
    const url = budgetExecutionEndpoint + "/saveForecasts";
    return genericPost(url, data);
}

export function validateBudgetExceeded(categoryId) {
    const url = budgetExecutionEndpoint + "/validateBudgetExceeded/" + categoryId;
    return genericGetNoLoading(url);
}

export function getCashPositonAction(categoryId) {
    const url = "/category/" + categoryId + "/cashPosition";
    return genericGet(url);
}

export function postDrawdownAction(data) {
    const url = "/drawdown-management";
    return genericPost(url, data);
}

export function deleteDrawdownAction(id) {
    const url = "/drawdown-management/" + id;
    return genericDelete(url);
}

export function putDrawdownAction(data) {
    const url = "/drawdown-management";
    return genericPut(url, data);
}

export function getRTBWithForecastHistory(projectId) {
    const url = "/rtb/" + projectId + "/forecast-history";
    return genericGet(url);
}

export function getRTBAndCalculateForecastHistory(projectId) {
    const url = "/rtb/" + projectId;
    return genericGetNoLoading(url);
}