import React from "react";
import PropTypes from "prop-types";

export default function AssignedUsersListGrid(props) {

    return (
        <div className="kb-assigned-list ml-5 mr-3">
            <div className="kb-assigned-list_label py-3 d-none">Assigned {props.userRole.displayName} Users</div>
            {props.assignedUsers ?
                props.assignedUsers.map((user) => (
                    <div
                        className={props.assignedUsers[props.assignedUsers.length - 1] !== user ? "kb-assigned-list_item border-bottom d-flex align-items-center" : "kb-assigned-list_item d-flex align-items-center"}
                        key={user.id}>
                        <div className="kb-assigned-list_name font-weight-bold">
                            {user.firstName} {user.lastName}
                        </div>

                        <button className="btn btn-link px-2 ml-auto" onClick={() => props.unAssignUser(user.id)} disabled={!props.formIsEnabled}><i
                            className="fas fa-trash"></i>
                        </button>

                    </div>
                )) : <span>None</span>
            }
        </div>
    );

}

AssignedUsersListGrid.propTypes = {
    userRole: PropTypes.object.isRequired,
    assignedUsers: PropTypes.array,
    unAssignUser: PropTypes.func,
    formIsEnabled: PropTypes.bool,
};
