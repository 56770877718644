import React from "react";
import PropTypes from 'prop-types';

export default function PageSection(props) {

    return (
        
                <div className={"container-fluid" + (props.additionalTitleClass || "")}>
                    <div className={props.background ? `${props.background} row` : "row"}>
                            <div className="col-12">
                                <h5 className={props.noHeadingYPadding ? "p-0 m-0 pt-2" : "py-3"}>
                                 {props.title}
                                 </h5>
                            </div>
                        </div>
                    <div className="row">
                            {props.children}
                    </div>
                </div>
            


    );
}

PageSection.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    additionalTitleClass: PropTypes.string,
    background: PropTypes.string,
    noHeadingYPadding: PropTypes.bool
};


 
            