import React, {useContext, Fragment, useState, useEffect} from 'react';
import ContentRouter from "./main-components/ContentRouter";
import {AppContextData} from "./main-components/AppContextData";
import {ToastContainer} from 'react-toastify';
import {AuthContext} from "./main-components/AuthContext";
import AuthContextProvider from "./main-components/AuthContext";
import Login from './templates/login';
import Menu from "./main-components/Menu";
import Header from "./main-components/Header";


const AppContainer = (props) => {
  const [userDetails, setUserDetails] = useState(AppContextData.getUserDetails().id);
  useEffect(() => {
    if (AppContextData.getUserDetails().id !== userDetails) {
      setUserDetails(AppContextData.getUserDetails().id);
    }
  }, [userDetails])
  const authContext = useContext(AuthContext);
  let renderContent = "";
  if(userDetails && userDetails !== '#') {
    renderContent = (
    <Fragment>
      <Header/>
      <Menu/>
      <div className="main-content" id="content">
          <ContentRouter/>
      </div>
      <ToastContainer/>
    </Fragment>);
   } else {
   renderContent = (<Login isAuth={userDetails && userDetails !== '#'} login={authContext.login} />);
 }
  return (
    <AuthContextProvider>
    {renderContent}
    </AuthContextProvider>
  );
};


AppContainer.propTypes = {

};


export default AppContainer;
