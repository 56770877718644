export const ADMIN = 'ADMIN';
export const PROJECT_MANAGER = 'PROJECT_MANAGER';
export const ACCOUNTING = 'ACCOUNTING';
export const SALES = 'SALES';
export const CONTRACTOR = 'CONTRACTOR';
export const EMPLOYEE = 'EMPLOYEE';

export const userRoles = [
    {displayName: 'Admin', value: ADMIN, isAssignableToProject: true},
    {displayName: 'Project Manager', value: PROJECT_MANAGER, isAssignableToProject: true},
    {displayName: 'Accounting', value: ACCOUNTING, isAssignableToProject: true},
    {displayName: 'Sales', value: SALES, isAssignableToProject: true},
    {displayName: 'Contractor', value: CONTRACTOR, isAssignableToProject: false},
    {displayName: 'Employee', value: EMPLOYEE, isAssignableToProject: false}
];

export const userStatesFilter = [
    {displayName: 'All', value: 'ALL'},
    {displayName: 'Active', value: 'ACTIVE'},
    {displayName: 'Inactive', value: 'INACTIVE'},
    {displayName: 'Un-assigned', value: 'UNASSIGNED'}
];

export const userRolesFilter = [
    {displayName: 'All', value: 'ALL'},
    ...userRoles
];
