import React from "react";

import {Button, FormLabel, FormControl, FormGroup} from "react-bootstrap";
import {getCompanySettings, updateSettings} from "../actions/settingsActions";
import {AppContextData} from "../../../main-components/AppContextData";
import {toast} from 'react-toastify';

class CompanySettingsForm extends React.Component {

    constructor(props) {
        super(props);

        // Needed because the value of the color in the color picker must not be null!
        // And until the actual form is loaded the default values from the user context will be used.
        const defSettings = AppContextData.getCompanySettings();

        this.state = CompanySettingsForm.constructStateObject(defSettings);

        this.validation = {
            companyName: 'Company name is mandatory!',
            colorScheme: 'Color Scheme is mandatory!',
        };

        this.handleFormEdit = this.handleFormEdit.bind(this);
        this.handleRadioEdit = this.handleRadioEdit.bind(this);

        this.dismissError = this.dismissError.bind(this);
        this.getSettingsFormError = this.getSettingsFormError.bind(this);
        this.isSettingsFormInvalid = this.isSettingsFormInvalid.bind(this);
        this.handleSettingsSubmit = this.handleSettingsSubmit.bind(this);
    }

    static constructStateObject(defSettings) {
        return {
            companyName: '',
            companyLocation: '',
            companyEmail: '',
            companyPhone: '',
            companyWebsite: '',
            colorScheme: defSettings.colorScheme || '',
            error: ''
        };
    }

    componentDidMount() {
        getCompanySettings().then(settings => {
            this.setState(settings)
        })
    }

    handleFormEdit(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleRadioEdit(event) {
        this.setState({
            colorScheme: event.target.value
        });
    }

    dismissError() {
        this.setState({error: ''});
    }

    getSettingsFormError() {
        const vObj = this.validation;
        for (let toValidate in vObj) {
            if (vObj.hasOwnProperty(toValidate) && !this.state[toValidate]) {
                return vObj[toValidate];
            }
        }
    }

    isSettingsFormInvalid() {
        return !!this.getSettingsFormError();
    }

    handleSettingsSubmit(event) {
        event.preventDefault();

        const errorMsg = this.getSettingsFormError();
        if (errorMsg) {
            this.setState({
                error: errorMsg
            })
        } else {
            updateSettings(this.state).then((json) => {
                if (json.code) {
                    toast.error("Error on saving the form", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    return this.setState({error: json.message});
                } else {
                    this.setState(json);
                    toast.info("Saved the form successfully. The page will reload after this message disappears", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => window.location.reload()
                    });
                }
            });
        }
    }

    render() {

        const inputClassName = "";

        // noinspection CheckTagEmptyBody
        return ( 
                <div  className="col-12 col-lg-6">
            <form onSubmit={this.handleSettingsSubmit}>

                {this.state.error &&
                <h3 data-test="error" onClick={this.dismissError}>
                    <button onClick={this.dismissError}>✖</button>
                    {this.state.error}
                </h3>
                }

                <FormGroup className={inputClassName} controlId="companyName">
                    <FormLabel>Your company name</FormLabel>
                    <FormControl
                        type="companyName"
                        value={this.state.companyName}
                        onChange={this.handleFormEdit}
                    />
                </FormGroup>

                <FormGroup className={inputClassName} controlId="companyLocation">
                    <FormLabel>Your company location</FormLabel>
                    <FormControl
                        type="companyLocation"
                        value={this.state.companyLocation}
                        onChange={this.handleFormEdit}
                    />
                </FormGroup>

                <FormGroup className={inputClassName} controlId="companyEmail">
                    <FormLabel>Your company email</FormLabel>
                    <FormControl
                        type="companyEmail"
                        value={this.state.companyEmail}
                        onChange={this.handleFormEdit}
                    />
                </FormGroup>

                <FormGroup className={inputClassName} controlId="companyPhone">
                    <FormLabel>Your company phone</FormLabel>
                    <FormControl
                        type="companyPhone"
                        value={this.state.companyPhone}
                        onChange={this.handleFormEdit}
                    />
                </FormGroup>

                <FormGroup className={inputClassName} controlId="companyWebsite">
                    <FormLabel>Your company website</FormLabel>
                    <FormControl
                        type="companyWebsite"
                        value={this.state.companyWebsite}
                        onChange={this.handleFormEdit}
                    />
                </FormGroup>
                <div className="form-group d-none">
                <div className="radio">
                    <div className="color-scheme-1-picker"></div>
                    <label>
                        <input type="radio"
                               value="color-scheme-1"
                               checked={this.state.colorScheme === 'color-scheme-1'}
                               onChange={this.handleRadioEdit}
                        />
                        Color-Scheme-1
                    </label>
                </div>

                <div className="radio">
                    <div className="color-scheme-2-picker"></div>
                    <label>
                        <input type="radio"
                               value="color-scheme-2"
                               checked={this.state.colorScheme === 'color-scheme-2'}
                               onChange={this.handleRadioEdit}
                        />
                        Color-Scheme-2
                    </label>
                </div>

                <div className="radio">
                    <div className="color-scheme-3-picker"></div>
                    <label>
                        <input type="radio"
                               value="color-scheme-3"
                               checked={this.state.colorScheme === 'color-scheme-3'}
                               onChange={this.handleRadioEdit}
                        />
                        Color-Scheme-3
                    </label>
                </div>
                </div>
                <Button
                    variant="primary"
                    type="submit"
                    disabled={this.isSettingsFormInvalid()}
                >
                    Save
                </Button>

            </form>
            </div>
        );
    }
}

export default CompanySettingsForm;
