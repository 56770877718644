import React from "react";
import PropTypes from 'prop-types';
import { ReactSortable } from "react-sortablejs";
import TemplateCategoryViewAndEdit from "./TemplateCategoryViewAndEdit";
import CategoryEntryForm from "../forms/CategoryEntryForm";
import {categoryEntityType} from "../constant/CategoryConstants";
import ProjectCategoryViewAndEdit from "./ProjectCategoryViewAndEdit";
import ExpandCollapseChildren from "../../../components-shared/ExpandCollapseChildren";

const  CategoryManagementComponentView = (props) => {

    const componentType = props.isChild ? "subcategory" : "category";

    const ViewAndEditComponent = props.entityType === categoryEntityType.templateCategory ?
        TemplateCategoryViewAndEdit : ProjectCategoryViewAndEdit;

    const setCats = (oldIndex, newIndex) => {
        if (newIndex > oldIndex) {
            props.onMoveDown(props.categories[oldIndex]);
        } else if (newIndex < oldIndex) {
            props.onMoveUp(props.categories[oldIndex]);
        }
    }

    // noinspection JSUnresolvedVariable
    return (

        <React.Fragment>

        {props.categories &&
            (<ReactSortable 
                list={props.categories} 
                setList={()=>{}}
                onEnd={(evt) => setCats(evt.oldIndex, evt.newIndex) }
                delayOnTouchStart={true}
                delay={2}
                handle=".drag-handle"
                >
                {props.categories.map((category) => (
                <div className={"kb-" + componentType + "-entry kb-list-item bg-white border-top"} key={category.id}>

                    <ViewAndEditComponent
                        category={category}
                        onDelete={() => props.onDelete(category)}
                        onEdit={(state) => props.onEdit(category, state)}
                        className={"kb-" + componentType + "-view"}
                        onMoveUp={() => props.onMoveUp(category)}
                        onMoveDown={() => props.onMoveDown(category)}
                        totalCostExecutionBudget={props.totalCostExecutionBudget}
                        totalCostInitialBudget={props.totalCostInitialBudget}
                        isChild={props.isChild}
                    />

                    {props.isChild ||
                    <ExpandCollapseChildren>
                        <CategoryManagementComponentView
                            categories={category.children}
                            projectId={props.projectId}
                            parentId={category.id}
                            onDelete={props.onDelete}
                            onSubmit={props.onSubmit}
                            onEdit={props.onEdit}
                            onMoveUp={props.onMoveUp}
                            onMoveDown={props.onMoveDown}
                            isChild={true}
                            categoryType={props.categoryType}
                            entityType={props.entityType}
                            totalCostExecutionBudget={props.totalCostExecutionBudget}
                            totalCostInitialBudget={props.totalCostInitialBudget}
                        />

                    </ExpandCollapseChildren>
                    }
                </div>)
            )}</ReactSortable>)
            }

            <CategoryEntryForm projectId={props.projectId}
                               parentId={props.parentId}
                               onSuccessfulSubmit={props.onSubmit}
                               componentType={componentType}
                               categoryType={props.categoryType}
            />
        </React.Fragment>
    );
}

CategoryManagementComponentView.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            children: PropTypes.array
        }).isRequired
    ),
    projectId: PropTypes.string.isRequired,
    parentId: PropTypes.string,
    onDelete: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onMoveUp: PropTypes.func.isRequired,
    onMoveDown: PropTypes.func.isRequired,
    isChild: PropTypes.bool,
    categoryType: PropTypes.shape({
        displayValue: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    }).isRequired,
    entityType: PropTypes.string.isRequired,
    totalCostInitialBudget: PropTypes.number,
    totalCostExecutionBudget: PropTypes.number,
};

// componentType = category or subcategory
// categoryType = COST, INCOME, FINANCING or INVESTMENT
// entityType = Project or Template

CategoryManagementComponentView.defaultProps = {
    categories: [],
    isChild: false
};
export default CategoryManagementComponentView;