import { ADD_NEW_LOADING, REMOVE_LOADING } from "../actions/action-types";

const initialState = {
  callsLoading: []
}

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_NEW_LOADING:
      const idToAdd = action.payload;
      return {
        ...state,
        callsLoading: [...state.callsLoading, idToAdd]
      }
    case REMOVE_LOADING:
      const idToRemove = action.payload;
      const newCallsLoading = state.callsLoading.filter(el => el !== idToRemove);
      return {
        ...state,
        callsLoading: [...newCallsLoading]
      }
    default:
      return state;
  }
}