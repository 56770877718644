import React from "react";

export default function GroupedActions(props) {
    let classes = "kb-action-group ml-3";
    if (props.classes) {
        classes = classes + " " + props.classes;
    }
    return (
        <div className={classes}>
            {props.children}
        </div>
    )
}