import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {loadPictureData} from "../../picture/actions/pictureActions";

class ProjectCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            picturePreview: ''
        }
    }

    componentDidMount() {
        if (this.props.project.fileMetadata && this.props.project.fileMetadata.binaryId) {
            loadPictureData(this.props.project.fileMetadata.binaryId)
                .then(result => result.blob())
                .then(result => {

                        let reader = new FileReader();

                        reader.onloadend = () => {
                            this.setState({
                                picturePreview: reader.result,
                            })
                        };

                        reader.readAsDataURL(result);
                    }
                );
        }
    }

    render() {

        const selectedImage = this.state.picturePreview ? 'url(' + this.state.picturePreview + ')' : 'url(/img/placeholder.png)';

        return (


            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                    <div className="card kb-card-project h-100">
                        <div className="card-body pb-2">
                           
                        <Link className="link-overlay" to={"/project/" + this.props.project.id + "/view"}/>
                            <h5 className="card-title">{this.props.project.name} </h5>
                            <p className="d-none">{this.props.project.description}</p>
                            <p className="text-muted m-0"> <i className="fas fa-map-marker-alt  mr-3"></i><small>{this.props.project.location}</small></p>
                        </div>
                        <div className="card-footer bg-white d-flex align-items-center ">
                            
                        <Link className="btn btn-secondary mr-3" to={"/project/" + this.props.project.id + "/budget"}> Edit</Link>
                            <Link className="btn btn-light mr-3" to={"/project/" + this.props.project.id + "/view"}>View</Link>

                            <div className="ml-auto">
                                <button type="button" className="btn btn-link d-none text-secondary ml-auto px-0" data-toggle="tooltip" data-placement="top" title="" data-original-title="Locked">
                                    <i className="fas fa-lock"></i>
                                </button>
                            </div>
                        </div>
                        <div className="card-header card-image p-0"  style={{backgroundImage: selectedImage}}>
                           
                        </div>
                    </div>
                </div>



        )
    }
}

ProjectCard.propTypes = {
    project: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string
    }).isRequired
};

export default ProjectCard;
