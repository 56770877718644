import React from "react";
import PropTypes from 'prop-types';

export default function PicturePreview(props) {
    // noinspection CheckTagEmptyBody
    return (
        <React.Fragment>
            {props.picturePreview ?
                <div className="kb-project-picture-placeholder">
                    <img src={props.picturePreview} className="img-fluid" alt="preview"/>
                </div>
                :
                <div className="kb-project-picture-placeholder"></div>}
        </React.Fragment>
    )
}

PicturePreview.propTypes = {
    picturePreview: PropTypes.any,
};