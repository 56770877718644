import React, {Component} from "react";
import {dateMonthYearFormat} from "../../../common/dateFormatting";
import PropTypes from "prop-types";
import Moment from "moment";

class DateRangeNavigator extends Component {

    constructor(props) {

        super(props);

        this.goBack = this.goBack.bind(this);
        this.goForward = this.goForward.bind(this);

    }

    numberOfMonthsCanGoBack() {
        return this.props.dateRange.start.diff(this.props.dateRangeLimit.start, 'months');
    }

    goBack(numberOfMonths) {
        const goBackLimit = this.numberOfMonthsCanGoBack();
        const times = numberOfMonths <= goBackLimit ? numberOfMonths : goBackLimit;
        if (times > 0) {
            this.props.updateDateRange({
                start: this.props.dateRange.start.clone().subtract(times, 'month'),
                end: this.props.dateRange.end.clone().subtract(times, 'month'),
            });
        }
    }

    numberOfMonthsCanGoForward() {
        return this.props.dateRangeLimit.end.diff(this.props.dateRange.end, 'months');
    }

    goForward(numberOfMonths) {
        const goForwardLimit = this.numberOfMonthsCanGoForward();
        const times = numberOfMonths <= goForwardLimit ? numberOfMonths : goForwardLimit;
        if (times > 0) {
            this.props.updateDateRange({
                start: this.props.dateRange.start.clone().add(times, 'month'),
                end: this.props.dateRange.end.clone().add(times, 'month'),
            });
        }
    }

    render() {
        return (
            <div className="kb-action-container d-flex align-items-center justify-content-center">
            <button className="btn btn-light px-3" variant="light" title="Go back 12 months" onClick={() => this.goBack(12)}>    <i className="fas fa-angle-double-left"></i></button>
            <button className="btn btn-light px-3" variant="light" title="Go back 1 month" onClick={() => this.goBack(1)}> <i className="fas fa-angle-left"></i></button>
           
           <div className="mr-3 ml-3 calendar-range font-weight-bold">
                {this.props.dateRange.start.format(dateMonthYearFormat)} - {this.props.dateRange.end.format(dateMonthYearFormat)}
            </div>
           <button className="btn btn-light px-3" variant="light" title="Go forward 1 month" onClick={() => this.goForward(1)}> <i className="fas fa-angle-right"></i></button>
           <button className="btn btn-light px-3" variant="light" title="Go forward 12 months"
                        onClick={() => this.goForward(12)}>    <i className="fas fa-angle-double-right"></i></button>


         
              
            </div>
        )
    }
}

DateRangeNavigator.propTypes = {
    dateRange: PropTypes.shape({
        start: PropTypes.instanceOf(Moment).isRequired,
        end: PropTypes.instanceOf(Moment).isRequired,
    }).isRequired,
    dateRangeLimit: PropTypes.shape({
        start: PropTypes.instanceOf(Moment).isRequired,
        end: PropTypes.instanceOf(Moment).isRequired,
    }).isRequired,
    updateDateRange: PropTypes.func.isRequired,
};

export default DateRangeNavigator;
