import React from "react";
import PropTypes from "prop-types";
import {unAssignCategoryToCategoryTotal} from "../actions/categoriesTotalsActions";
import {confirmAlert} from "react-confirm-alert";

export default function AssignedCategoriesListGrid(props) {

    return (
        <div className="kb-assigned-list border-bottom ml-5 mr-3">
            <div className="kb-assigned-list_label py-3 d-none">Assigned {props.categoryType.displayValue} Categories</div>
            {(props.categories && props.categories.length > 0) ?
                props.categories.map((category) => (
                    <div className="kb-assigned-list_item border-top d-flex align-items-center" key={category.id}>
                        <div className="kb-assigned-list_name">{category.name}</div>

                        <button className="btn btn-link px-2 ml-auto" style={{zIndex:999999}} onClick={() => {
                            confirmAlert({
                                title: 'Confirm to submit',
                                message: 'Are you sure you want to unassign "' + category.name + '" ?',
                                buttons: [
                                    {
                                        label: 'Yes',
                                        onClick: () =>
                                            unAssignCategoryToCategoryTotal(props.categoryTotal.id, category.id)
                                                .then(props.refreshCategoryTotalsList)
                                    },
                                    {
                                        label: 'No'
                                    }
                                ]
                            });
                        }}><i className="fas fa-times"></i>
                        </button>

                         

                    </div>
                )) : <span className="text-muted">None</span>
            }
        </div> 
    );
}

AssignedCategoriesListGrid.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired
    ),
    categoryTotal: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    refreshCategoryTotalsList: PropTypes.func.isRequired,
    categoryType: PropTypes.shape({
        displayValue: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    }).isRequired,
};
