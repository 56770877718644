import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import InvoiceDetailsForm from "./InvoiceDetailsForm";
import InvoiceBudgetLinesForm from "./InvoiceBudgetLinesForm";
import {getBudgetLinesForProject} from "../../category/actions/budgetLineActions";
import {confirmAlert} from "react-confirm-alert";
import {getInvoicesById, patchInvoice, saveInvoice, updateInvoice} from "../actions/invoicesActions";
import PropTypes from "prop-types";
import {getAllVATs} from "../../user/actions/userActions";
import {toast} from "react-toastify";

class InvoiceFormWrapper extends Component {
  
  constructor (props) {
    super(props);
    
    this.state = InvoiceFormWrapper.constructStateObject(props);
    
    this.handleChangeInvoiceType = this.handleChangeInvoiceType.bind(this);
    this.handleChangeBudgetDate = this.handleChangeBudgetDate.bind(this);
    this.handleChangeMaturityDate = this.handleChangeMaturityDate.bind(this);
    this.handleChangePaymentDate = this.handleChangePaymentDate.bind(this);
    this.getVatValue = this.getVatValue.bind(this);
    this.handleAmountWithVATChange = this.handleAmountWithVATChange.bind(this);
    this.clearEuroValues = this.clearEuroValues.bind(this);
    this.clearRonValues = this.clearRonValues.bind(this);
    this.updateRecords = this.updateRecords.bind(this);
    this.handleVATRateChange = this.handleVATRateChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkTotalAmount = this.checkTotalAmount.bind(this);
    this.getConfirmSaveOptions = this.getConfirmSaveOptions.bind(this);
    this.getConfirmCancelOptions = this.getConfirmCancelOptions.bind(this);
    this.addOrUpdate = this.addOrUpdate.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.getVatAmountRon = this.getVatAmountRon.bind(this);
    this.getAmountWithVatRon = this.getAmountWithVatRon.bind(this);
    this.getAmountWithoutVatRon = this.getAmountWithoutVatRon.bind(this);
    this.updateRecordRonAmounts = this.updateRecordRonAmounts.bind(this);
    this.getVatAmountEuro = this.getVatAmountEuro.bind(this);
    this.getAmountWithVatEuro = this.getAmountWithVatEuro.bind(this);
    this.updateRecordEuroAmounts = this.updateRecordEuroAmounts.bind(this);
    this.handleChangeAmountWithoutVatEuro = this.handleChangeAmountWithoutVatEuro.bind(this);
    this.addBudgetLine = this.addBudgetLine.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.errorCheck = this.errorCheck.bind(this);
    this.getSaveInvoiceBody = this.getSaveInvoiceBody.bind(this);
    this.isValid = this.isValid.bind(this);
    this.handleBNRRateChange = this.handleBNRRateChange.bind(this);
    this.handleChangeInvoiceDate = this.handleChangeInvoiceDate.bind(this);
    this.loadForm = this.loadForm.bind(this);

  }
  
  static constructStateObject (props) {
    
    return {
      invoiceType: 'COST',
      invoiceNo: '',
      invoiceDate: '',
      budgetDate: '',
      maturityDate: '',
      paymentDate: '',
      supplier: '',
      description: '',
      ammountWithVatRon: '',
      vatRate: '',
      bnrRate: '',
      ammountWithoutVatRon: '',
      ammountWithoutVatEuro: '',
      vatAmmountEuro: '',
      vatAmmountRon: '',
      
      contractId: props.contractId ? props.contractId : '',
      
      // Add the projectId in the state to send a single insert object in the BE
      projectId: props.projectId,
      
      // Add the category type in the state to send a single insert object in the BE
      categoryType: props.categoryType,
      
      contractsData: undefined,
      
      // update in contract forecast
      updateInContractForecast: false,
      
      // greater value
      greaterInvoiceValue: false,
      isFullUpdate: false,
      invoiceRecords: [
        {
          budgetLine: '',
          budgetLineContract: '',
          budgetLineAmmountWithoutVatEuro: '',
          budgetLineAmmountWithVatEuro: '',
          budgetLineVatAmmountEuro: '',
          budgetLineAmmountWithoutVatRon: '',
          budgetLineAmmountWithVatRon: '',
          budgetLineVatAmmountRon: ''
        }
      ],
      displayErrors: {
        invoiceType: false,
        invoiceNo: false,
        invoiceDate: false,
        budgetDate: false,
        supplier: false,
        ammountWithVatRon: false,
        vatRate: false,
        bnrRate: false,
        invoiceRecords: [
          {
            budgetLine: false,
            budgetLineContract: false,
            budgetLineAmmountWithoutVatEuro: false
          }
        ]
      },
      fieldsValidations: {
        invoiceType: [
          {
            errMsg: "The Invoice Type should be selected",
            isValid: (value) => {
              return value.replace(/\s/g, '').length > 0;
            }
          },
        ],
        invoiceNo: [
          {
            errMsg: "The Invoice Number should have only alphanumeric characters",
            isValid: (value) => {
              return /^[a-z0-9]+$/i.test(value);
            }
          },
          {
            errMsg: "The Invoice Number should not be larger than 30 characters",
            isValid: (value) => {
              return value.length <= 30;
            }
          },
          {
            errMsg: "The Invoice Number field should not be empty or have only whitespaces",
            isValid: (value) => {
              return value.replace(/\s/g, '').length > 0;
            }
          },
        ],
        invoiceDate: [
          {
            errMsg: "The Invoice Date is mandatory",
            isValid: (value) => {
              return value !== '' && value !== null && value !== undefined
            }
          }
        ],
        budgetDate: [
          {
            errMsg: "The Budget Date is mandatory",
            isValid: (value) => {
              return value !== '' && value !== null && value !== undefined
            }
          }
        ],
        supplier: [
          {
            errMsg: "The name is mandatory",
            isValid: (value) => {
              return value !== '' && value !== null && value !== undefined
            }
          },
          {
            errMsg: "The Supplier should not be larger than 30 characters",
            isValid: (value) => {
              return value.length <= 100;
            }
          }
        ],
        ammountWithVatRon: [
          {
            errMsg: "The Amount with VAT is mandatory",
            isValid: (value) => {
              return value !== '' && value !== null && value !== undefined
            }
          },
          {
            errMsg: "The Amount with VAT max length is 20",
            isValid: (value) => {
              return value.length <= 20;
            }
          },
          {
            errMsg: "The Amount with VAT can have only 2 decimals",
            isValid: (value) => {
              const fields = value.split(".");
              return !(fields[1] && fields[1].length > 2);
            }
          }
        ],
        vatRate: [
          {
            errMsg: "The VAT rate is mandatory",
            isValid: (value) => {
              return value !== '' && value !== null && value !== undefined
            }
          }
        ],
        bnrRate: [
          {
            errMsg: "The BNR rate is mandatory",
            isValid: (value) => {
              return value !== '' && value !== null && value !== undefined
            }
          },
          {
            errMsg: "The BNR rate must be lower than 1000",
            isValid: (value) => {
              return value < 1000
            }
          },
          {
            errMsg: "The BNR rate can have only 4 decimals",
            isValid: (value) => {
              const fields = value.split(".");
              return !(fields[1] && fields[1].length > 4);
            }
          },
          {
            errMsg: "The BNR rate must be bigger than 0",
            isValid: (value) => {
              if (value == 0) {
                return false;
              }
              return true;
            }
          }
        ],
        budgetLine: [
          {
            errMsg: "The Budget Line is mandatory",
            isValid: (value) => {
              return value !== '' && value !== null && value !== undefined
            }
          }
        ],
        budgetLineContract: [
          {
            errMsg: "The Contract is mandatory",
            isValid: (value) => {
              return value !== '' && value !== null && value !== undefined
            }
          }
        ],
        budgetLineAmmountWithoutVatEuro: [
          {
            errMsg: "The Amount without VAT is mandatory",
            isValid: (value) => {
              return value !== '' && value !== null && value !== undefined
            }
          }
        ]
      }
    };
  }
  
  componentDidMount () {
    this.loadForm();
    this.loadBudgetLines('COST')
  }

  loadForm() {
    const invoiceType = this.props.categoryType;
    if (this.props.fromBudgetLine) {
      getAllVATs().then(vats => {
        getBudgetLinesForProject(this.props.projectId, invoiceType).then((budgetLines) => {
          const invoiceRecords = [{
            budgetLine: this.props.budgetLine,
            budgetLineContract: this.props.contract,
            budgetLineAmmountWithoutVatEuro: '',
            budgetLineAmmountWithVatEuro: '',
            budgetLineVatAmmountEuro: '',
            budgetLineAmmountWithoutVatRon: '',
            budgetLineAmmountWithVatRon: '',
            budgetLineVatAmmountRon: ''
          }]
          let displayErrors = this.state.displayErrors;
          displayErrors.invoiceRecords = [{
            budgetLine: false,
            budgetLineContract: false,
            budgetLineAmmountWithoutVatEuro: false
          }]
          displayErrors.invoiceType = true;
          if (invoiceType === 'INCOME') {
            this.setState({
              budgetLines: budgetLines,
              invoiceType: invoiceType,
              budgetDate: this.state.invoiceDate,
              invoiceRecords: invoiceRecords,
              displayErrors: displayErrors,
              vats: vats,
              isFullUpdate: false
            })
          } else {
            this.setState({
              budgetLines: budgetLines,
              invoiceType: invoiceType,
              invoiceRecords: invoiceRecords,
              displayErrors: displayErrors,
              vats: vats,
              isFullUpdate: false
            })
          }
        });
      });
    } else if (this.props.editInvoice) {
      getAllVATs().then(vats => {
        getInvoicesById(this.props.invoiceId).then(invoice => {
          getBudgetLinesForProject(this.props.projectId, invoiceType).then((budgetLines) => {
            let invoiceRecords = []
            let displayErrors = this.state.displayErrors;
            displayErrors.invoiceRecords = []
            displayErrors.invoiceType = true;
            for (let i = 0; i < invoice.invoiceRecords.length; i++) {
              invoiceRecords.push({
                budgetLine: invoice.invoiceRecords[i].categoryId,
                budgetLineContract: invoice.invoiceRecords[i].contractId,
                budgetLineAmmountWithoutVatEuro: invoice.invoiceRecords[i].amount.euroWithoutVat + '',
                budgetLineAmmountWithVatEuro: invoice.invoiceRecords[i].amount.euroWithVat + '',
                budgetLineVatAmmountEuro: invoice.invoiceRecords[i].amount.euroVat + '',
                budgetLineAmmountWithoutVatRon: invoice.invoiceRecords[i].amount.ronWithoutVat + '',
                budgetLineAmmountWithVatRon: invoice.invoiceRecords[i].amount.ronWithVat + '',
                budgetLineVatAmmountRon: invoice.invoiceRecords[i].amount.ronVat + '',
              });
              displayErrors.invoiceRecords.push({
                budgetLine: false,
                budgetLineContract: false,
                budgetLineAmmountWithoutVatEuro: false
              });
            }
            this.setState({
              invoiceRecords: invoiceRecords,
              displayErrors: displayErrors,
              budgetLines: budgetLines,
              invoiceType: invoice.invoiceType,
              invoiceNo: invoice.invoiceNo,
              invoiceDate: invoice.emissionDate,
              budgetDate: invoice.budgetDate,
              maturityDate: invoice.maturityDate,
              paymentDate: invoice.paymentDate,
              supplier: invoice.supplierOrClientName,
              description: invoice.description,
              vatRate: invoice.vatId,
              bnrRate: invoice.exchangeRate + '',
              ammountWithVatRon: invoice.amount.ronWithVat + '',
              ammountWithoutVatRon: invoice.amount.ronWithoutVat + '',
              ammountWithoutVatEuro: invoice.amount.euroWithoutVat + '',
              vatAmmountEuro: invoice.amount.euroVat + '',
              vatAmmountRon: invoice.amount.ronVat + '',
              vats: vats,
              isFullUpdate: false
            });
          })
        })
      });
    } else {
      getAllVATs().then(vats => {
        this.setState({
          vats: vats,
          isFullUpdate: false
        });
      });
    }
  }

  handleChangeInvoiceType (event) {
    const invoiceType = event.target.value;
    getBudgetLinesForProject(this.props.projectId, invoiceType).then((budgetLines) => {
      const invoiceRecords = [{
        budgetLine: '',
        budgetLineContract: '',
        budgetLineAmmountWithoutVatEuro: '',
        budgetLineAmmountWithVatEuro: '',
        budgetLineVatAmmountEuro: '',
        budgetLineAmmountWithoutVatRon: '',
        budgetLineAmmountWithVatRon: '',
        budgetLineVatAmmountRon: ''
      }]
      let displayErrors = this.state.displayErrors;
      displayErrors.invoiceRecords = [{
        budgetLine: false,
        budgetLineContract: false,
        budgetLineAmmountWithoutVatEuro: false
      }]
      displayErrors.invoiceType = true;
      if (invoiceType === 'INCOME') {
        this.setState({
          budgetLines: budgetLines,
          invoiceType: invoiceType,
          budgetDate: this.state.invoiceDate,
          invoiceRecords: invoiceRecords,
          displayErrors: displayErrors,
          isFullUpdate: true
        })
      } else {
        this.setState({
          budgetLines: budgetLines,
          invoiceType: invoiceType,
          invoiceRecords: invoiceRecords,
          displayErrors: displayErrors,
          isFullUpdate: true
        })
      }
    });
  };

  loadBudgetLines (invoiceType) {
    getBudgetLinesForProject(this.props.projectId, invoiceType).then((budgetLines) => {
      const invoiceRecords = [{
        budgetLine: '',
        budgetLineContract: '',
        budgetLineAmmountWithoutVatEuro: '',
        budgetLineAmmountWithVatEuro: '',
        budgetLineVatAmmountEuro: '',
        budgetLineAmmountWithoutVatRon: '',
        budgetLineAmmountWithVatRon: '',
        budgetLineVatAmmountRon: ''
      }]
      let displayErrors = this.state.displayErrors;
      displayErrors.invoiceRecords = [{
        budgetLine: false,
        budgetLineContract: false,
        budgetLineAmmountWithoutVatEuro: false
      }]
      displayErrors.invoiceType = true;
      if (invoiceType === 'INCOME') {
        this.setState({
          budgetLines: budgetLines,
          invoiceType: invoiceType,
          budgetDate: this.state.invoiceDate,
          invoiceRecords: invoiceRecords,
          displayErrors: displayErrors,
          isFullUpdate: true
        })
      } else {
        this.setState({
          budgetLines: budgetLines,
          invoiceType: invoiceType,
          invoiceRecords: invoiceRecords,
          displayErrors: displayErrors,
          isFullUpdate: true
        })
      }
    });
  };
  
  handleChangeBudgetDate (budgetDate) {
    let displayErrors = this.state.displayErrors;
    displayErrors.budgetDate = true;
    this.setState({
      budgetDate: {
        year: budgetDate.getFullYear(),
        month: budgetDate.getMonth(),
        day: budgetDate.getDate()
      },
      displayErrors: displayErrors,
      isFullUpdate: true
    });
  }
  
  handleChangeMaturityDate (maturityDate) {
    let displayErrors = this.state.displayErrors;
    displayErrors.maturityDate = true;
    this.setState({
      maturityDate: {
        year: maturityDate.getFullYear(),
        month: maturityDate.getMonth(),
        day: maturityDate.getDate()
      },
      displayErrors: displayErrors,
      isFullUpdate: true
    });
  }
  
  handleChangePaymentDate (paymentDate) {
    let displayErrors = this.state.displayErrors;
    displayErrors.paymentDate = true;
    this.setState({
      paymentDate: {
        year: paymentDate.getFullYear(),
        month: paymentDate.getMonth(),
        day: paymentDate.getDate()
      }
    });
  }
  
  getVatValue (vatId) {
    return this.state.vats.filter(vat => vat.id === vatId)[0].vatValue;
  }
  
  handleAmountWithVATChange (event) {
    let displayErrors = this.state.displayErrors;
    displayErrors.ammountWithVatRon = true;
    if (this.state.vatRate && this.state.bnrRate) {
      
      const vatRate = this.getVatValue(this.state.vatRate);
      const amountWithVatRon = event.target.value;
      const bnrRate = this.state.bnrRate;
      
      const vatAmountRon = Math.round(amountWithVatRon * vatRate / (100 + vatRate));
      const amountWithoutVatRon = Math.round(amountWithVatRon - vatAmountRon);
      const amountWithoutVATEUR = bnrRate != 0 ? Math.round(amountWithoutVatRon / bnrRate) : 0;
      const vatAmountEuro = bnrRate != 0 ? Math.round(vatAmountRon / bnrRate) : 0;
      
      this.setState({
        ammountWithVatRon: event.target.value,
        
        ammountWithoutVatRon: amountWithoutVatRon,
        ammountWithoutVatEuro: amountWithoutVATEUR,
        vatAmmountEuro: vatAmountEuro,
        vatAmmountRon: vatAmountRon,
        displayErrors: displayErrors,
        isFullUpdate: true
      });
    } else if (this.state.vatRate) {
      const vatRate = this.getVatValue(this.state.vatRate);
      const amountWithVatRon = event.target.value;
      
      const vatAmountRon = Math.round(amountWithVatRon * vatRate / (100 + vatRate));
      const amountWithoutVatRon = Math.round(amountWithVatRon - vatAmountRon);
      
      this.setState({
        ammountWithVatRon: event.target.value,
        
        ammountWithoutVatRon: amountWithoutVatRon,
        vatAmmountRon: vatAmountRon,
        displayErrors: displayErrors,
        isFullUpdate: true
      });
    } else {
      this.setState({
        ammountWithVatRon: event.target.value,
        displayErrors: displayErrors,
        isFullUpdate: true
      });
    }
  }
  
  clearEuroValues (record) {
    record.budgetLineAmmountWithVatEuro = '';
    record.budgetLineVatAmmountEuro = '';
  }
  
  clearRonValues (record) {
    record.budgetLineAmmountWithoutVatRon = '';
    record.budgetLineAmmountWithVatRon = '';
    record.budgetLineVatAmmountRon = '';
  }
  
  updateRecords (vatRate, bnrRate) {
    let newInvoiceRecords = this.state.invoiceRecords
    
    newInvoiceRecords.forEach(record => {
      if (record.budgetLineAmmountWithoutVatEuro) {
        this.updateRecordEuroAmounts(record.budgetLineAmmountWithoutVatEuro, vatRate, record);
        if (bnrRate) {
          this.updateRecordRonAmounts(record, bnrRate);
        }
      }
    });
    return newInvoiceRecords;
  }
  
  handleVATRateChange (event) {
    let displayErrors = this.state.displayErrors;
    displayErrors.vatRate = true;
    if (event.target.value === "") {
      let newInvoiceRecords = this.state.invoiceRecords
      
      newInvoiceRecords.forEach(record => {
        this.clearEuroValues(record);
        this.clearRonValues(record)
      });
      this.setState({
        vatRate: event.target.value,
        
        ammountWithoutVatRon: "",
        ammountWithoutVatEuro: "",
        vatAmmountEuro: "",
        vatAmmountRon: "",
        invoiceRecords: newInvoiceRecords,
        displayErrors: displayErrors,
        isFullUpdate: true
      });
    } else {
      if (this.state.ammountWithVatRon && this.state.bnrRate) {
        const vatRate = this.getVatValue(event.target.value);
        const amountWithVatRon = this.state.ammountWithVatRon;
        const bnrRate = this.state.bnrRate;
        
        const vatAmountRon = Math.round(amountWithVatRon * vatRate / (100 + vatRate));
        const amountWithoutVatRon = Math.round(amountWithVatRon - vatAmountRon);
        const amountWithoutVATEUR = bnrRate != 0 ? Math.round(amountWithoutVatRon / bnrRate) : 0;
        const vatAmountEuro = bnrRate != 0 ? Math.round(vatAmountRon / bnrRate) : 0;
        
        let newInvoiceRecords = this.updateRecords(vatRate, this.state.bnrRate);
        
        this.setState({
          vatRate: event.target.value,
          
          ammountWithoutVatRon: amountWithoutVatRon,
          ammountWithoutVatEuro: amountWithoutVATEUR,
          vatAmmountEuro: vatAmountEuro,
          vatAmmountRon: vatAmountRon,
          invoiceRecords: newInvoiceRecords,
          displayErrors: displayErrors,
          isFullUpdate: true
        });
        
      } else if (this.state.ammountWithVatRon) {
        const vatRate = this.getVatValue(event.target.value);
        const amountWithVatRon = this.state.ammountWithVatRon;
        
        const vatAmountRon = Math.round(amountWithVatRon * vatRate / (100 + vatRate));
        const amountWithoutVatRon = Math.round(amountWithVatRon - vatAmountRon);
        
        const newInvoiceRecords = this.updateRecords(vatRate, this.state.bnrRate);
        
        this.setState({
          vatRate: event.target.value,
          
          ammountWithoutVatRon: amountWithoutVatRon,
          vatAmmountRon: vatAmountRon,
          invoiceRecords: newInvoiceRecords,
          displayErrors: displayErrors,
          isFullUpdate: true
        });
      } else {
        const vatRate = this.getVatValue(event.target.value);
        const newInvoiceRecords = this.updateRecords(vatRate, this.state.bnrRate);
        this.setState({
          vatRate: event.target.value,
          invoiceRecords: newInvoiceRecords,
          displayErrors: displayErrors,
          isFullUpdate: true
        });
      }
    }
  }
  
  handleChange (event, field, i) {
    let displayErrors = this.state.displayErrors;
    displayErrors[field] = true;
    if ((i || i === 0) && field.includes('budgetLine')) {
      let newInvoiceRecords = this.state.invoiceRecords.slice();
      newInvoiceRecords[i][field] = event.target.value;
      let displayErrors = this.state.displayErrors;
      displayErrors.invoiceRecords[i][field] = true;
      if (field === 'budgetLine' && event.target.value === '') {
        newInvoiceRecords[i].budgetLineContract = '';
      }
      this.setState({
        invoiceRecords: newInvoiceRecords,
        displayErrors: displayErrors,
        isFullUpdate: true
      });
    } else {
      this.setState({
        [event.target.id]: event.target.value,
        displayErrors: displayErrors,
        isFullUpdate: true
      });
    }
  };
  
  checkTotalAmount () {
    const amountWithoutVATEuro = parseInt(this.state.ammountWithoutVatEuro) || 0;
    const invoiceRecordsTotalAmountWithoutVATEuro = parseInt(this.state.invoiceRecords
      .map(record => record.budgetLineAmmountWithoutVatEuro)
      .reduce((a, b) => (parseInt(a) || 0) + (parseInt(b) || 0), 0)) || 0;
    if (amountWithoutVATEuro === invoiceRecordsTotalAmountWithoutVATEuro) {
      return '';
    }
    const difference = amountWithoutVATEuro - invoiceRecordsTotalAmountWithoutVATEuro;
    return <div className="text-danger input-error">Total Amounts without VAT (EUR) assigned to budget lines DO NOT match the Invoice Amount without VAT (EUR),
      difference: {difference}</div>;
  }
  
  getConfirmSaveOptions () {
    return {
      title: 'Confirm to submit',
      message: 'Are you sure you want to save the invoice ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            if (!this.props.editInvoice) {
              saveInvoice(this.getSaveInvoiceBody()).then((json) => {
                if (json) {
                  if (json.code && json.code === 18002) {
                    toast.error("Please select a Budget date related to an open month!", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      autoClose: 5000,
                    });
                    return;
                  }
                  this.props.cancelForm();
                } else {
                  this.props.saveForecasts();
                }
              });
            } else {
              if (this.state.isFullUpdate) {
                updateInvoice(this.getSaveInvoiceBody()).then((json) => {
                  if (json) {
                    if (json.code && json.code === 18002) {
                      toast.error("Invoice assigned to a closed month, only Payment Date can be updated", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 5000,
                      });
                      this.loadForm();
                      return;
                    }
                    this.props.cancelForm();
                  } else {
                    this.props.saveForecasts();
                  }
                });
              } else {
                patchInvoice(this.getSaveInvoiceBody()).then((json) => {
                  if (json) {
                    this.props.cancelForm();
                  }
                });
              }
            }
          }
        },
        {
          label: 'No'
        }
      ]
    };
  }
  
  getSaveInvoiceBody () {
    const invoiceRecords = this.state.invoiceRecords.map(record => {
      return {
        contractId: record.budgetLineContract,
        categoryId: record.budgetLine,
        amount: {
          euroWithoutVat: record.budgetLineAmmountWithoutVatEuro,
          euroWithVat: record.budgetLineAmmountWithVatEuro,
          euroVat: record.budgetLineVatAmmountEuro,
          ronWithoutVat: record.budgetLineAmmountWithoutVatRon,
          ronWithVat: record.budgetLineAmmountWithVatRon,
          ronVat: record.budgetLineVatAmmountRon
        }
      }
    });
    return {
      id: this.props.invoiceId,
      invoiceType: this.state.invoiceType,
      invoiceNo: this.state.invoiceNo,
      emissionDate: this.state.invoiceDate, //
      budgetDate: this.state.budgetDate,
      maturityDate: this.state.maturityDate,
      paymentDate: this.state.paymentDate,
      supplierOrClientName: this.state.supplier, //
      description: this.state.description,
      vatId: this.state.vatRate, //
      exchangeRate: this.state.bnrRate, //
      projectId: this.state.projectId, //
      amount: {
        euroWithoutVat: this.state.ammountWithoutVatEuro,
        euroWithVat: this.state.ammountWithoutVatEuro + this.state.vatAmmountEuro,
        euroVat: this.state.vatAmmountEuro,
        ronWithoutVat: this.state.ammountWithoutVatRon,
        ronWithVat: this.state.ammountWithVatRon,
        ronVat: this.state.vatAmmountRon
      },
      invoiceRecords: invoiceRecords
    }
  }
  
  getConfirmCancelOptions () {
    return {
      title: 'Confirm to submit',
      message: 'Are you sure you want to discard the changes ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.cancelForm();
          }
        },
        {
          label: 'No'
        }
      ]
    };
  }
  
  isValid () {
    
    if (this.errorCheck('invoiceType').props.children !== '') {
      return false;
    }
    if (this.errorCheck('invoiceNo').props.children !== '') {
      return false;
    }
    if (this.errorCheck('invoiceDate').props.children !== '') {
      return false;
    }
    if (this.errorCheck('budgetDate').props.children !== '') {
      return false;
    }
    if (this.errorCheck('supplier').props.children !== '') {
      return false;
    }
    if (this.errorCheck('ammountWithVatRon').props.children !== '') {
      return false;
    }
    if (this.errorCheck('vatRate').props.children !== '') {
      return false;
    }
    if (this.errorCheck('bnrRate').props.children !== '') {
      return false;
    }
    if (this.checkTotalAmount() !== '') {
      return false;
    }
    let isValid = true;
    let i = 0;
    this.state.invoiceRecords.forEach(record => {
      if (this.errorCheck('budgetLine', i).props.children !== '') {
        isValid = false;
      }
      if (this.errorCheck('budgetLineContract', i).props.children !== '') {
        isValid = false;
      }
      if (this.errorCheck('budgetLineAmmountWithoutVatEuro', i).props.children !== '') {
        isValid = false;
      }
      i++;
    })
    return isValid;
  }
  
  addOrUpdate () {
    if (this.isValid()) {
      const confirmSaveOptions = this.getConfirmSaveOptions();
      confirmAlert(confirmSaveOptions);
    } else {
      let displayErrors = this.state.displayErrors;
      displayErrors.invoiceType = true;
      displayErrors.invoiceNo = true;
      displayErrors.invoiceDate = true;
      displayErrors.budgetDate = true;
      displayErrors.supplier = true;
      displayErrors.ammountWithVatRon = true;
      displayErrors.vatRate = true;
      displayErrors.bnrRate = true;
      displayErrors.invoiceRecords.forEach(record => {
        record.budgetLine = true;
        record.budgetLineContract = true;
        record.budgetLineAmmountWithoutVatEuro = true;
      })
      this.setState({
        displayErrors: displayErrors
      })
    }
  }
  
  cancelEdit () {
    const confirmCancelOptions = this.getConfirmCancelOptions();
    confirmAlert(confirmCancelOptions);
  }
  
  getVatAmountRon (vatAmountEuro, bnrRate) {
    return Math.round(vatAmountEuro * bnrRate);
  }
  
  getAmountWithVatRon (amountWithVatEuro, bnrRate) {
    return Math.round(amountWithVatEuro * bnrRate);
  }
  
  getAmountWithoutVatRon (amountWithoutVatEuro, bnrRate) {
    return Math.round(amountWithoutVatEuro * bnrRate);
  }
  
  updateRecordRonAmounts (newInvoiceRecord, bnrRate) {
    const amountWithoutVatRon = this.getAmountWithoutVatRon(newInvoiceRecord['budgetLineAmmountWithoutVatEuro'], bnrRate);
    const amountWithVatRon = this.getAmountWithVatRon(newInvoiceRecord['budgetLineAmmountWithVatEuro'], bnrRate);
    const vatAmountRon = this.getVatAmountRon(newInvoiceRecord['budgetLineVatAmmountEuro'], bnrRate);
    newInvoiceRecord['budgetLineAmmountWithoutVatRon'] = amountWithoutVatRon;
    newInvoiceRecord['budgetLineAmmountWithVatRon'] = amountWithVatRon;
    newInvoiceRecord['budgetLineVatAmmountRon'] = vatAmountRon;
  }
  
  getVatAmountEuro (amountWithVatEuro, amountWithoutVatEuro) {
    return Math.round(amountWithVatEuro - amountWithoutVatEuro);
  }
  
  getAmountWithVatEuro (amountWithoutVatEuro, vatRate) {
    return Math.round(amountWithoutVatEuro + amountWithoutVatEuro * (vatRate / 100));
  }
  
  updateRecordEuroAmounts (amountWithoutVatEuro, vatRate, newInvoiceRecord) {
    amountWithoutVatEuro = amountWithoutVatEuro === "" ? "" : Math.round(amountWithoutVatEuro);
    const amountWithVatEuro = this.getAmountWithVatEuro(amountWithoutVatEuro, vatRate);
    const vatAmountEuro = this.getVatAmountEuro(amountWithVatEuro, amountWithoutVatEuro);
    newInvoiceRecord['budgetLineAmmountWithoutVatEuro'] = amountWithoutVatEuro;
    newInvoiceRecord['budgetLineAmmountWithVatEuro'] = amountWithVatEuro;
    newInvoiceRecord['budgetLineVatAmmountEuro'] = vatAmountEuro;
  }
  
  handleChangeAmountWithoutVatEuro (event, i) {
    let displayErrors = this.state.displayErrors;
    displayErrors.invoiceRecords[i].budgetLineAmmountWithoutVatEuro = true;
    
    let newInvoiceRecords = this.state.invoiceRecords.slice();
    let newInvoiceRecord = newInvoiceRecords[i];
    if (this.state.vatRate && this.state.bnrRate) {
      const vatRate = this.getVatValue(this.state.vatRate);
      this.updateRecordEuroAmounts(event.target.value, vatRate, newInvoiceRecord);
      this.updateRecordRonAmounts(newInvoiceRecord, this.state.bnrRate);
    } else if (this.state.vatRate) {
      const vatRate = this.getVatValue(this.state.vatRate);
      this.updateRecordEuroAmounts(event.target.value, vatRate, newInvoiceRecord);
    } else {
      newInvoiceRecord['budgetLineAmmountWithoutVatEuro'] = event.target.value === "" ? "" : Math.round(event.target.value);
    }
    
    this.setState({
      invoiceRecords: newInvoiceRecords,
      displayErrors: displayErrors,
      isFullUpdate: true
    });
  }
  
  addBudgetLine () {
    let newInvoiceRecords = this.state.invoiceRecords.slice();
    let newInvoiceRecord = {
      budgetLine: '',
      budgetLineContract: '',
      budgetLineAmmountWithoutVatEuro: '',
      budgetLineAmmountWithVatEuro: '',
      budgetLineVatAmmountEuro: '',
      budgetLineAmmountWithoutVatRon: '',
      budgetLineAmmountWithVatRon: '',
      budgetLineVatAmmountRon: ''
    };
    newInvoiceRecords.push(newInvoiceRecord);
    
    let displayErrors = this.state.displayErrors;
    const newError = {
      budgetLine: false,
      budgetLineContract: false,
      budgetLineAmmountWithoutVatEuro: false
    };
    displayErrors.invoiceRecords.push(newError);
    
    this.setState({
      invoiceRecords: newInvoiceRecords,
      displayErrors: displayErrors,
      isFullUpdate: true
    });
  }
  
  handleDelete (i) {
    let invoiceRecords = this.state.invoiceRecords.slice();
    invoiceRecords.splice(i, 1);
    
    let displayErrors = this.state.displayErrors;
    displayErrors.invoiceRecords.splice(i, 1);
    this.setState({
      invoiceRecords: invoiceRecords.slice(),
      displayErrors: displayErrors,
      isFullUpdate: true
    })
  }
  
  errorCheck (field, order) {
    if (this.state.fieldsValidations && this.state.fieldsValidations[field]) {
      if (field.includes('budgetLine')) {
        let error = '';
        this.state.fieldsValidations[field].forEach(validation => {
          if (!validation.isValid(this.state.invoiceRecords[order][field])) {
            error = validation.errMsg;
          }
        });
        return <div className="text-danger input-error">{error}</div>;
      } else {
        let error = '';
        this.state.fieldsValidations[field].forEach(validation => {
          if (!validation.isValid(this.state[field])) {
            error = validation.errMsg;
          }
        });
        return <div className="text-danger input-error">{error}</div>;
      }
    }
    return <React.Fragment></React.Fragment>;
  }
  
  handleBNRRateChange (event) {
    let displayErrors = this.state.displayErrors;
    displayErrors.bnrRate = true;
    if (event.target.value) {
      if (this.state.ammountWithVatRon && this.state.vatRate) {
        const vatRate = this.getVatValue(this.state.vatRate);
        const amountWithVatRon = this.state.ammountWithVatRon;
        const bnrRate = event.target.value;
        
        const vatAmountRon = Math.round(amountWithVatRon * vatRate / (100 + vatRate));
        const amountWithoutVatRon = Math.round(amountWithVatRon - vatAmountRon);
        const amountWithoutVATEUR = bnrRate != 0 ? Math.round(amountWithoutVatRon / bnrRate) : 0;
        const vatAmountEuro = bnrRate != 0 ? Math.round(vatAmountRon / bnrRate) : 0;
        
        const newInvoiceRecords = this.updateRecords(vatRate, bnrRate);
        
        this.setState({
          bnrRate: event.target.value,
          
          ammountWithoutVatRon: amountWithoutVatRon,
          ammountWithoutVatEuro: amountWithoutVATEUR,
          vatAmmountEuro: vatAmountEuro,
          vatAmmountRon: vatAmountRon,
          invoiceRecords: newInvoiceRecords,
          displayErrors: displayErrors,
          isFullUpdate: true
        });
      } else if (this.state.vatRate) {
        const vatRate = this.getVatValue(this.state.vatRate);
        const newInvoiceRecords = this.updateRecords(vatRate, event.target.value);
        this.setState({
          bnrRate: event.target.value,
          invoiceRecords: newInvoiceRecords,
          displayErrors: displayErrors,
          isFullUpdate: true
        });
      } else {
        this.setState({
          bnrRate: event.target.value,
          displayErrors: displayErrors,
          isFullUpdate: true
        });
      }
    } else {
      let newInvoiceRecords = this.state.invoiceRecords
      
      newInvoiceRecords.forEach(record => {
        this.clearRonValues(record);
      });
      this.setState({
        bnrRate: event.target.value,
        ammountWithoutVatEuro: '',
        vatAmmountEuro: '',
        invoiceRecords: newInvoiceRecords,
        displayErrors: displayErrors,
        isFullUpdate: true
      });
    }
  }
  
  handleChangeInvoiceDate (invoiceDate) {
    let displayErrors = this.state.displayErrors;
    displayErrors.invoiceDate = true;
    if (this.state.invoiceType === 'INCOME') {
      this.setState({
        invoiceDate: {
          year: invoiceDate.getFullYear(),
          month: invoiceDate.getMonth(),
          day: invoiceDate.getDate()
        },
        budgetDate: {
          year: invoiceDate.getFullYear(),
          month: invoiceDate.getMonth(),
          day: invoiceDate.getDate()
        },
        displayErrors: displayErrors,
        isFullUpdate: true
      });
    } else {
      this.setState({
        invoiceDate: {
          year: invoiceDate.getFullYear(),
          month: invoiceDate.getMonth(),
          day: invoiceDate.getDate()
        },
        displayErrors: displayErrors,
        isFullUpdate: true
      });
    }
  }
  
  render () {
    //const isEditable = !!this.props.onCancel;
    
    return (
      <React.Fragment>
        <div className="kb-header">
          <div className="container-fluid">
            <div className="row px-3">
              <div className="col-6 d-flex align-items-center ">
                <div className="kb-title_page d-flex align-items-center py-4">
                  {!this.props.editInvoice ?
                    <h1 className="h4 font-weight-normal m-0">Add new Invoice</h1> :
                    <h1 className="h4 font-weight-normal m-0">Edit Invoice {this.state.invoiceNo}</h1>
                  }
                </div>
                <nav className="kb-nav_ctas ml-auto">
                </nav>
              </div>
              {(!this.props.fromBudgetLine && !this.props.editInvoice) &&
              <div className="col-6 text-right pr-4">
                <button type="button" variant='light' className="btn btn-link kb-btn-cancel_permissions ml-3" onClick={this.cancelEdit}>Cancel</button>
                <button type="button" className="btn btn-primary kb-btn-save_permissions ml-3" onClick={this.addOrUpdate}>Save</button>
              </div>
              }
            </div>
          </div>
        </div>
        <div className="kb-main kb-main_invoice px-3 py-4 bg-white">
          {this.props.isMonthClosed &&
          <span className="text-danger">Invoice assigned to a closed month, only Payment Date can be updated</span>
          }
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 pb-1">
                <div className="row">
                  <InvoiceDetailsForm
                    invoiceType={this.state.invoiceType}
                    invoiceNo={this.state.invoiceNo}
                    invoiceDate={this.state.invoiceDate}
                    budgetDate={this.state.budgetDate}
                    maturityDate={this.state.maturityDate}
                    paymentDate={this.state.paymentDate}
                    supplier={this.state.supplier}
                    description={this.state.description}
                    ammountWithVatRon={this.state.ammountWithVatRon}
                    vatRate={this.state.vatRate}
                    bnrRate={this.state.bnrRate}
                    ammountWithoutVatRon={this.state.ammountWithoutVatRon}
                    vatAmmountRon={this.state.vatAmmountRon}
                    ammountWithoutVatEuro={this.state.ammountWithoutVatEuro}
                    vatAmmountEuro={this.state.vatAmmountEuro}
                    vats={this.state.vats}
                    isEditable={this.props.fromBudgetLine}
                    errorCheck={this.errorCheck}
                    displayErrors={this.state.displayErrors}
                    handleChangeInvoiceType={this.handleChangeInvoiceType}
                    handleChangeBudgetDate={this.handleChangeBudgetDate}
                    handleChangeInvoiceDate={this.handleChangeInvoiceDate}
                    handleChangeMaturityDate={this.handleChangeMaturityDate}
                    handleChangePaymentDate={this.handleChangePaymentDate}
                    handleAmountWithVATChange={this.handleAmountWithVATChange}
                    handleVATRateChange={this.handleVATRateChange}
                    handleBNRRateChange={this.handleBNRRateChange}
                    handleChange={this.handleChange}
                    checkTotalAmount={this.checkTotalAmount}
                    inModal={this.props.inModal}
                    isMonthClosed={this.props.isMonthClosed}
                  />
                  <InvoiceBudgetLinesForm
                    invoiceType={this.state.invoiceType}
                    invoiceRecords={this.state.invoiceRecords}
                    budgetLines={this.state.budgetLines}
                    displayErrors={this.state.displayErrors}
                    handleChange={this.handleChange}
                    handleChangeAmountWithoutVatEuro={this.handleChangeAmountWithoutVatEuro}
                    addBudgetLine={this.addBudgetLine}
                    handleDelete={this.handleDelete}
                    errorCheck={this.errorCheck}
                    inModal={this.props.inModal}
                    cancelEdit={this.cancelEdit}
                    addOrUpdate={this.addOrUpdate}
                    editInvoice={this.props.editInvoice}
                    fromBudgetLine={this.props.fromBudgetLine}
                    isMonthClosed={this.props.isMonthClosed}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

InvoiceFormWrapper.propTypes = {
  fromBudgetLine: PropTypes.bool,
  editInvoice: PropTypes.bool,
  invoiceId: PropTypes.string,
  cancelForm: PropTypes.func,
  projectId: PropTypes.string.isRequired,
  categoryType: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  budgetLine: PropTypes.string,
  contract: PropTypes.string,
  isMonthClosed: PropTypes.bool
}

export default withRouter(InvoiceFormWrapper);
