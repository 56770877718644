import React, {Component} from 'react';
import PropTypes from "prop-types";
import {
    getBudgetStatsData,
    getCashStatsData,
    getProfitStatsData,
    getTotalBudgetForProjectAndCategoryType
} from "./actions/statisticsActions";
import {prettyPrint, prettyPrintWithPrecision} from "../common/numberOperations";
import DonutChart from "../../components-shared/DonutChart";
import CollapsiblePageSection from "../../components-shared/CollapsiblePageSection";
import {categoryTypeCost, categoryTypeIncome, categoryTypeInvestment} from "../category/constant/CategoryConstants";
import {addDoubles, divideDoubles, subtractDoubles} from "../common/doubleOperations";

export class CustomLineDotDemo extends Component {
    render() {
      const { cx, cy, stroke } = this.props;
        return stroke && (<svg x={cx - 5} y={cy - 5} width={20} height={20} fill={stroke} viewBox="0 0 1024 1024">
        <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"/>
      </svg>);
    }
  }

class StatisticsProfitAndCashDisplay extends Component {

    constructor(props) {

        super(props);

        this.state = {
            profitData: {
                netProfit: 0.0,
                // Predicted KPI
                predictedRoi: 0.0,
                predictedRoe: 0.0,
                predictedIrr: 0.0,
                // Prognosis KPI
                prognosisRoi: 0.0,
                prognosisRoe: 0.0,
                prognosisIrr: 0.0,
                // Execution KPI
                executionRoi: 0.0,
                executionRoe: 0.0,
                executionIrr: 0.0,
            },
            cashData: {
                neededForNextMonths: 0.0,
                insertedToDate: 0.0,
            },
            // Prognosis
            prognosisTotalCost: 0.0,
            prognosisTotalIncome: 0.0,
            prognosisTotalInvestment: 0.0,
            // Execution
            executionActualCost: 0.0,
            executionActualIncome: 0.0,
            executionActualInvestment: 0.0,
            startIndex: 0,
            endIndex: 11 
        }; 
    }

    componentDidMount() {

        getProfitStatsData(this.props.projectId).then((profitData) => {
            this.setState({
                profitData: profitData
            })
        });

        getCashStatsData(this.props.projectId).then((cashData) => {
            this.setState({
                cashData: cashData
            })
        });

        getTotalBudgetForProjectAndCategoryType(this.props.projectId, categoryTypeCost.type).then((budgetTotal) => {
            this.setState({
                prognosisTotalCost: budgetTotal
            })
        });

        getTotalBudgetForProjectAndCategoryType(this.props.projectId, categoryTypeIncome.type).then((budgetTotal) => {
            this.setState({
                prognosisTotalIncome: budgetTotal
            })
        });

        getTotalBudgetForProjectAndCategoryType(this.props.projectId, categoryTypeInvestment.type).then((budgetTotal) => {
            this.setState({
                prognosisTotalInvestment: budgetTotal
            })
        });

        getBudgetStatsData(this.props.projectId, categoryTypeCost.type).then((budgetStats) => {
            this.setState({
                executionActualCost: budgetStats
            })
        });

        getBudgetStatsData(this.props.projectId, categoryTypeIncome.type).then((budgetStats) => {
            this.setState({
                executionActualIncome: budgetStats
            })
        });

        getBudgetStatsData(this.props.projectId, categoryTypeInvestment.type).then((budgetStats) => {
            this.setState({
                executionActualInvestment: budgetStats
            })
        });
    }

    computeActualCost() {
        return addDoubles(this.state.executionActualCost.remainingForecast, this.state.executionActualCost.committed);
    }

    computeNetProfit() {
        return subtractDoubles(
            addDoubles(this.state.executionActualIncome.remainingForecast, this.state.executionActualIncome.committed),
            addDoubles(this.state.executionActualCost.remainingForecast, this.state.executionActualCost.committed))
            ;
    }

    computeActualInvestment() {
        return addDoubles(this.state.executionActualInvestment.remainingForecast, this.state.executionActualInvestment.committed);
    }

    render() { 
        return (
            <React.Fragment>
                <CollapsiblePageSection title={"Prognosis KPI"}>

                    <div className="stats-card card-size-1of3">
                        <div className="card-title">Total Cost</div>
                        <div className="card-value">
                            {this.state.prognosisTotalCost ? prettyPrint(this.state.prognosisTotalCost) : 0}
                        </div>
                    </div>

                    <div className="stats-card card-size-1of3">
                        <div className="card-title">Total Income</div>
                        <div className="card-value">
                            {this.state.prognosisTotalIncome ? prettyPrint(this.state.prognosisTotalIncome) : 0}
                        </div>
                    </div>

                    <div className="stats-card card-size-1of3">
                        <div className="card-title">Net Profit</div>
                        <div className="card-value">
                            {this.state.profitData ? prettyPrint(this.state.profitData.netProfit) : 0}
                        </div>
                    </div>

                    <div className="stats-card card-size-1of3 card-center-content stats-roi">
                        <div className="card-title">ROI prognosis</div>
                        <div className="card-value">
                            <DonutChart
                                value={this.state.profitData ? prettyPrintWithPrecision(this.state.profitData.prognosisRoi * 100) : 0}/>
                        </div>
                    </div>

                    <div className="stats-card card-size-1of3 card-center-content stats-roe">
                        <div className="card-title">ROE prognosis</div>
                        <div className="card-value">
                            <DonutChart
                                value={this.state.profitData ?
                                    prettyPrintWithPrecision(divideDoubles(this.state.profitData.netProfit, this.state.prognosisTotalInvestment) * 100)
                                    :
                                    0
                                }
                            />
                        </div>
                    </div>

                    <div className="stats-card card-size-1of3 card-center-content stats-irr">
                        <div className="card-title">IRR prognosis</div>
                        <div className="card-value">
                            <DonutChart
                                value={this.state.profitData ? prettyPrintWithPrecision(this.state.profitData.prognosisIrr * 100) : 0}/>
                        </div>
                    </div>
                </CollapsiblePageSection>

                <CollapsiblePageSection title={"Execution KPI"}>

                    <div className="stats-card card-size-1of3">
                        <div className="card-title">Actual Cost</div>
                        <div className="card-value">
                            {this.state.executionActualCost ?
                                prettyPrint(this.computeActualCost())
                                :
                                0
                            }
                        </div>
                    </div>

                    <div className="stats-card card-size-1of3">
                        <div className="card-title">Actual Income</div>
                        <div className="card-value">
                            {this.state.executionActualIncome ?
                                prettyPrint(addDoubles(this.state.executionActualIncome.remainingForecast, this.state.executionActualIncome.committed))
                                :
                                0
                            }
                        </div>
                    </div>

                    <div className="stats-card card-size-1of3">
                        <div className="card-title">Net Profit</div>
                        <div className="card-value">
                            {this.state.executionActualCost && this.state.executionActualIncome ?
                                prettyPrint(this.computeNetProfit())
                                :
                                0
                            }
                        </div>
                    </div>

                    <div className="stats-card card-size-1of3">
                        <div className="card-title">Cash needed for next 3 months</div>
                        <div className="card-value">
                            {this.state.cashData ? prettyPrint(this.state.cashData.neededForNextMonths) : 0}
                        </div>
                    </div>

                    <div className="stats-card card-size-1of3">
                        <div className="card-title">Invoiced to date</div>
                        <div className="card-value">
                            {this.state.cashData ? prettyPrint(this.state.cashData.insertedToDate) : 0}
                        </div>
                    </div>

                    <div className="stats-card card-size-1of3">
                        <div className="card-title">Actual Investment</div>
                        <div className="card-value">
                            {this.state.executionActualInvestment ?
                                prettyPrint(this.computeActualInvestment())
                                :
                                0
                            }
                        </div>
                    </div>

                    <div className="stats-card card-size-1of3 card-center-content stats-roi">
                        <div className="card-title">ROI execution</div>
                        <div className="card-value">
                            <DonutChart
                                value={this.state.profitData ?
                                    prettyPrintWithPrecision(divideDoubles(this.computeNetProfit(), this.computeActualCost()) * 100)
                                    :
                                    0
                                }
                            />
                        </div>
                    </div>

                    <div className="stats-card card-size-1of3 card-center-content stats-roe">
                        <div className="card-title">ROE execution</div>
                        <div className="card-value">
                            <DonutChart
                                value={this.state.profitData ?
                                    prettyPrintWithPrecision(divideDoubles(this.computeNetProfit(), this.computeActualInvestment()) * 100)
                                    :
                                    0
                                }
                            />
                        </div>
                    </div>

                    <div className="stats-card card-size-1of3 card-center-content stats-irr">
                        <div className="card-title">IRR execution</div>
                        <div className="card-value">
                            <DonutChart
                                value={this.state.profitData ? prettyPrintWithPrecision(this.state.profitData.executionIrr * 100)
                                    :
                                    0
                                }
                            />
                        </div>
                    </div>
                </CollapsiblePageSection>
            </React.Fragment>
        )
    }
}

StatisticsProfitAndCashDisplay.propTypes = {
    projectId: PropTypes.string.isRequired
};

export default StatisticsProfitAndCashDisplay;
