import React, {Component} from "react";
import {Button, FormLabel, FormControl, FormGroup} from "react-bootstrap";
import {quickAddContractorUser} from "../actions/userActions";
import PropTypes from "prop-types";
import 'react-phone-number-input/style.css';
import {toast} from "react-toastify";

class QuickAddContractorForm extends Component {

    constructor(props) {

        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            companyName: '',
            createUser: false,
            error: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.dismissError = this.dismissError.bind(this);
    }

    handleChange(event) {

        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleCheckboxChange(element) {
        this.setState({
            [element]: !this.state[element]
        });
    }

    handleSubmit(event) {

        event.preventDefault();

        if (!this.state.companyName) {
            return this.setState({error: 'Company name should not be empty.'});
        }

        if (this.state.error === '') {

            quickAddContractorUser(this.state).then((json) => {
                if (json.code) {
                    toast.error("Contractor cannot be created", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    return this.setState({error: json.message});
                } else {
                    toast.info("Contractor created successfully", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    this.props.afterSubmit();
                }
            });
        }

        return this.setState({error: ''});
    }

    dismissError() {
        this.setState({error: ''});
    }

    render() {

        const inputClassName = " ";

        return (
<form onSubmit={this.handleSubmit}>
    <div className="container-fluid px-0">
            <div className="row">
                <div className="col-12">
                    {
                    this.state.error &&
                    <div className="alert alert-danger mb-4" role="alert" data-test="error" onClick={this.dismissError}>
                        {this.state.error}
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.dismissError}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    }
                     </div>
                    <div className="col-md-6">
                    <FormGroup className={inputClassName} controlId="firstName">
                        <FormLabel>First Name</FormLabel>
                        <FormControl
                            type="firstName"
                            value={this.state.firstName}
                            onChange={this.handleChange}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup className={inputClassName} controlId="lastName">
                            <FormLabel>Last Name</FormLabel>
                            <FormControl
                                type="lastName"
                                value={this.state.lastName}
                                onChange={this.handleChange}
                                />
                            </FormGroup>
                        </div>
                        <div className="col-6">
                            <FormGroup className={inputClassName} controlId="companyName">
                                <FormLabel>Company Name</FormLabel>
                                <FormControl
                                    type="companyName"
                                    value={this.state.companyName}
                                    onChange={this.handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-6 mb-3 align-self-end">
                                <div className={ 'font-size-5'}>
                                    <div className="d-table">
                                        <div className="d-table-cell">
                                            <label className="fancy mb-0 pb-0 mr-2" htmlFor="createUser">
                                                <input id="createUser" name={'createUser'} type="checkbox" value={this.state.createUser} checked={this.state.createUser} onChange={() => this.handleCheckboxChange("createUser")} />
                                            </label>
                                        </div>
                                        <div className="d-table-cell font-size-2">
                                            <FormLabel>Create User</FormLabel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-3 mb-5">
                                <Button variant="primary" type="submit">Save</Button>
                                <Button className="btn btn-light ml-3" variant='light' onClick={this.props.onCancel}>Cancel</Button>
                            </div>
                        </div>
                    </div>
                </form>
        );
    }
}

QuickAddContractorForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    afterSubmit: PropTypes.func.isRequired,
};

export default QuickAddContractorForm;
