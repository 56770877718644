import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {getAllTemplates} from "../actions/templateActions";
import CloneTemplateCard from "./fragments/CloneTemplateCard";
import {getProjectById, loadProjectFromTemplate} from "../../project/actions/projectActions";
import ProjectTitleDisplay from "../../project/fragment/ProjectTitleDisplay";
import BackButtonNavigationBar from "../../../components-shared/BackButtonNavigationBar";
import Breadcrumbs from "../../../components-shared/Breadcrumbs/Breadcrumbs";

class CloneProjectFromTemplatePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            templates: [],
        };

        this.getProjectId = this.getProjectId.bind(this);
        this.onTemplateUse = this.onTemplateUse.bind(this);
    }

    componentDidMount() {
        getAllTemplates().then(templates => {
            this.setState({
                templates: templates,
            })
        });
        const projectId = this.getProjectId();
        if (projectId) {
            getProjectById(projectId).then((projectDetails) => {
                if (projectDetails.code) {
                    return this.setState({error: projectDetails.message});
                } else {
                    this.setState({
                        projectDetails: projectDetails,
                        error: ''
                    })
                }
            });
        }
    }

    getProjectId() {
        return this.props.match.params.projectId;
    }

    onTemplateUse(templateId) {
        loadProjectFromTemplate(this.getProjectId(), templateId).then((result) => {
            if (result.status !== 200) {
                return this.setState({error: result.message});
            } else {
                this.props.history.push('/project/' + this.getProjectId() + '/budget');
            }
        });
    }

    render() {
        const templates = this.state.templates.slice();
        return (
            <React.Fragment>
                <ProjectTitleDisplay projectDetails={this.state.projectDetails}/>
                <BackButtonNavigationBar classNamePrefix={"project"}/>
                 <Breadcrumbs breadcrumbs={[{url: '/projects', title: 'Home'}, {url: '/template/clone/' + this.getProjectId(), title: this.state.projectDetails && this.state.projectDetails.name}]} />
                 <header className="kb-header px-3">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center ">
                                    <div className="kb-title_page d-flex align-items-center py-4">
                                        <h1 className="h4 font-weight-normal m-0">Load from template</h1>
                                    </div>
                                    <nav className="kb-nav_ctas ml-auto">
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </header>
                    <main className="kb-main kb-main_template--view px-3 pb-4">
                             <div className="container-fluid px-0">
                                <div className="row">
                                {templates.map(template => (
                                    <CloneTemplateCard key={template.id} template={template} onClick={this.onTemplateUse}/>))
                                }
                                </div>
                        </div>
                    </main>
            </React.Fragment>
        );
    }
}

export default withRouter(CloneProjectFromTemplatePage);