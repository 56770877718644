import {
    genericDelete,
    genericGet,
    genericGetNoLoading,
    genericPost,
    genericPostVoidReturn,
    genericPut
} from "../../../components-shared/fetch-wrapper";
import {replaceUrlParams} from "../../../components-shared/url-utils";

const categoryManagementEndpoint = '/category-management';
const categoriesEndpoint = '/categories'
const removeProjectCategoryEndpoint = categoryManagementEndpoint + '/';
const getCategoriesForProjectEndpoint = categoryManagementEndpoint +
    '/category-type/:categoryType/project/:projectId';

export function getCategoriesForProject(projectId) {
    return genericGet(categoriesEndpoint + "/" + projectId);
}

export function getPreviousAndNextCategory(projectId, categoryId) {
    return genericGetNoLoading(categoriesEndpoint + "/previous-next-category/" + projectId + "/" + categoryId);
}

export function insertRootCategory(data) {
    return genericPost(categoryManagementEndpoint, data);
}

export function insertSubCategory(rootCategoryId, data) {
    return genericPost(categoryManagementEndpoint + "/" + rootCategoryId, data);
}

export function removeCategory(categoryId) {
    return genericDelete(removeProjectCategoryEndpoint + categoryId);
}

export function getCategories(projectId, categoryType) {
    const urlParams = [{key: ":categoryType", value: categoryType}, {key: ":projectId", value: projectId}];
    const url = replaceUrlParams(getCategoriesForProjectEndpoint, urlParams);

    return genericGet(url);
}

export function updateCategory(categoryObject) {
    return genericPut(categoryManagementEndpoint, categoryObject);
}

export function getCategoryDetails(categoryId) {
    const url = categoryManagementEndpoint + "/" + categoryId;

    return genericGet(url);
}

export function moveCategoryUp(categoryId) {
    return genericPostVoidReturn(categoryManagementEndpoint + "/move", {categoryId: categoryId, direction: "UP"});
}

export function moveCategoryDown(categoryId) {
    return genericPostVoidReturn(categoryManagementEndpoint + "/move", {categoryId: categoryId, direction: "DOWN"});
}
