import React, {Component} from "react";
import PropTypes from "prop-types";
import {getUserActivityCounters} from "../actions/userActions";
import RegisterForm from "../forms/RegisterForm";
import {Button} from "react-bootstrap";
import UserSearchCard from "./UserSearchCard";

class UserManagementHeader extends Component {

    constructor(props) {

        super(props);

        this.state = UserManagementHeader.constructStateObject();

        this.togglePopup = this.togglePopup.bind(this);
    }

    static constructStateObject() {
        return {
            activeUsers: '0',
            inactiveUsers: '0',
            unassignedUsers: '0',
            showPopup: false,
        };
    }

    componentDidMount() {

        getUserActivityCounters().then((userActivityCounters) => {
            this.setState({
                activeUsers: userActivityCounters.activeUsers,
                inactiveUsers: userActivityCounters.inactiveUsers,
                unassignedUsers: userActivityCounters.unassignedUsers,
            })
        });
    }

    togglePopup() {

        this.setState({
            showPopup: !this.state.showPopup
        }, () => {
            if (!this.state.showPopup) {
                this.props.refreshList();

                getUserActivityCounters().then((userActivityCounters) => {
                    this.setState({
                        activeUsers: userActivityCounters.activeUsers,
                        inactiveUsers: userActivityCounters.inactiveUsers,
                        unassignedUsers: userActivityCounters.unassignedUsers,
                    })
                });
            }
        });
    }

    render() {

        const buttonName = "View All";

        return (
                 <React.Fragment>
                                        <div className="kb-title_page d-flex align-items-center py-4">
                                            <h1 className="h4 font-weight-normal m-0">User Management</h1>
                                          </div>
                               <nav className="kb-nav_ctas ml-auto">
                                     <Button
                                        variant="primary"
                                        className="kb-btn_add--user"
                                        onClick={this.togglePopup}
                                    >
                                        Add new user
                                    </Button>

                                    {
                                        this.state.showPopup ?
                                            <div className='popup'>
                                                <div className='popup_inner'>
                                                 <h3 className="kb-popup_title pb-4 pt-5 mt-5">Add new user</h3>
                                                    <RegisterForm
                                                        text='Close'
                                                        closePopup={this.togglePopup}
                                                    />
                                                </div>
                                            </div>
                                            : null
                                    }
                                </nav>

                                  <div className="d-none">
              
                    <UserSearchCard cardTitle={"Active Users"}
                                    cardSubtitle={this.state.activeUsers}
                                    cardText={"Find All Active Users"}
                                    onClickFunction={() => this.props.handleClick("active", !this.props.linkFilters.active)}
                                    buttonName={buttonName}
                    />

                    <UserSearchCard cardTitle={"Inactive Users"}
                                    cardSubtitle={this.state.inactiveUsers}
                                    cardText={"Find All Inactive Users"}
                                    onClickFunction={() => this.props.handleClick("inactive", !this.props.linkFilters.inactive)}
                                    buttonName={buttonName}
                    />

                    <UserSearchCard cardTitle={"Unassigned Users"}
                                    cardSubtitle={this.state.unassignedUsers}
                                    cardText={"Find All Unassigned Users"}
                                    onClickFunction={() => this.props.handleClick("unassigned", !this.props.linkFilters.unassigned)}
                                    buttonName={buttonName}
                    />
               
            </div>
              
             </React.Fragment>
        );
    }
}

UserManagementHeader.propTypes = {
    handleClick: PropTypes.func.isRequired,
    linkFilters: PropTypes.object.isRequired,
    refreshList: PropTypes.func.isRequired
};

export default UserManagementHeader;
