export const categoryTypeCost = {
    type: 'COST',
    displayValue: 'Cost',
    categoryTotalManagementAvailable: true,
    categoryBudgetAvailable: true,
    categoryBudgetTotalAvailable: true,
    collapsed: false,
};

export const categoryTypeIncome = {
    type: 'INCOME',
    displayValue: 'Income',
    categoryTotalManagementAvailable: true,
    categoryBudgetAvailable: true,
    categoryBudgetTotalAvailable: true,
    collapsed: true,
};

const categoryTypeFinancing = {
    type: 'FINANCING',
    displayValue: 'Financing',
    categoryTotalManagementAvailable: true,
    categoryBudgetAvailable: true,
    categoryBudgetTotalAvailable: true,
    collapsed: true,
};

export const categoryTypeInvestment = {
    type: 'INVESTMENT',
    displayValue: 'Investment',
    categoryTotalManagementAvailable: true,
    categoryBudgetAvailable: true,
    categoryBudgetTotalAvailable: false,
    collapsed: true,
};

// const categoryTypeReimbursement = {
//     type: 'REIMBURSEMENT',
//     displayValue: 'Reimbursement',
//     categoryTotalManagementAvailable: false,
//     categoryBudgetAvailable: true,
//     categoryBudgetTotalAvailable: false,
//     collapsed: true,
// };

export const categoryTypeBusinessPlan = {
    type: 'BUSINESSPLAN',
    displayValue: 'Business Plan',
    collapsed: true
}

export const initialBudget = {
    type: "INITIALBUDGET",
    displayValue: 'Initial Budget',
    collapsed: true
}

export const executionBudget = {
    type: "EXECUTIONBUDGET",
    displayValue: 'Execution Budget',
    collapsed: true
}

export const categoryTypes = [
    categoryTypeCost,
    categoryTypeIncome,
    categoryTypeFinancing,
    categoryTypeInvestment,
];

export const categoryEntityType = {
    projectCategory: 'Project',
    templateCategory: 'Template'
};
