import React, {Component} from "react";
import PropTypes from "prop-types";
import {prettyPrint, round} from "../../common/numberOperations";
import {Link} from "react-router-dom";
import CustomDatePicker from "../../../components-shared/CustomDatePicker";
import {stringToFormattedDate} from "../../common/dateFormatting";
import {Form} from "react-bootstrap";
import moment from "moment";
import {hasErrors} from "../forms/validations";
import CommaSeparatedInput from "../../../components-shared/CommaSeparatedInput/CommaSeparatedInput";

class ProjectCategoryViewAndEdit extends Component {

    constructor(props) {

        super(props);

        this.state = {
            editing: false,
            nameIsTouched: false,
            name: this.props.category.name,
            name1: this.props.category.name,
            distributionPeriod: this.props.category.distributionPeriod || undefined,
            initialBudget: this.props.category.initialBudget || undefined,
            initialBudgetPercentage: this.props.category.initialBudgetPercentage || undefined,
            executionBudget: this.props.category.executionBudget || undefined,
            executionBudgetPercentage: this.props.category.executionBudgetPercentage || undefined,
            maturityDate: this.props.category.maturityDate ? moment(this.props.category.maturityDate) : '',
            availability: this.props.category.availability ? moment(this.props.category.availability) : '',
            investmentDate: this.props.category.investmentDate ? moment(this.props.category.investmentDate) : '',
            interestType: this.props.category.interestType,
            interestRate: this.props.category.interestRate || undefined,
            interestTypes: [
                {
                    value: "",
                    formattedValue: "Select..."
                },
                {
                    value: "MONTHLY",
                    formattedValue: "Monthly"
                },
                {
                    value: "QUARTERLY",
                    formattedValue: "Quarterly"
                },
                {
                    value: "SEMI_ANNUALLY",
                    formattedValue: "Semi-annually"
                },
                {
                    value: "ANNUALLY",
                    formattedValue: "Annually"
                },
                {
                    value: "ON_TERM",
                    formattedValue: "On term"
                },
            ],
            fieldValidations: {
                interestRate: {
                    isValid: (value) => {
                        if (value) {
                            value = value + "";
                            const isNumeric = /^[0-9..]+$/i.test(value);
                            let hasLessThatTwoDecimals = true;
                            if (value.split(".").length === 2 && value.split(".")[1].length > 2) {
                                hasLessThatTwoDecimals = false;
                            }
                            return isNumeric && hasLessThatTwoDecimals && value <= 100;
                        }
                        return true;
                    }
                },
                distributionPeriod: {
                    isValid: (value) => {
                        if (value) {
                            value = value + "";
                            const isNumeric = /^[0-9]+$/i.test(value);
                            return isNumeric && value < 10000;
                        }
                        return true;
                    }
                },
                initialBudget: {
                    isValid: (value) => {
                        if (value) {
                            value = value + "";
                            const isNumeric = /^[0-9]+$/i.test(value);
                            return isNumeric && value < 10000000000;
                        }
                        return true;
                    }
                },
                executionBudget: {
                    isValid: (value) => {
                        if (value) {
                            value = value + "";
                            const isNumeric = /^[0-9]+$/i.test(value);
                            return isNumeric && value < 10000000000;
                        }
                        return true;
                    }
                },
                initialBudgetPercentage: {
                    isValid: (value) => {
                        if (value) {
                            const elements = (value + '').split('.');
                            if (elements && elements.length === 2 && elements[1].length > 2) {
                                return false
                            }
                        }
                        return true;
                    }
                },
                executionBudgetPercentage: {
                    isValid: (value) => {
                        if (value) {
                            const elements = (value + '').split('.');
                            if (elements && elements.length === 2 && elements[1].length > 2) {
                                return false;
                            }
                        }
                        return true;
                    }
                }
            },
            displayErrors: {
                interestRate: false,
                distributionPeriod: false,
                initialBudget: false,
                initialBudgetPercentage: false,
                executionBudget: false,
                executionBudgetPercentage: false,
            }
        };

        this.handleChange = this.handleChange.bind(this);

        this.handleEdit = this.handleEdit.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleChangeMaturityDate = this.handleChangeMaturityDate.bind(this);
        this.handleChangeAvailability = this.handleChangeAvailability.bind(this);
        this.handleChangeInvestmentDate = this.handleChangeInvestmentDate.bind(this);
        this.dateFrom = this.dateFrom.bind(this);
        this.parseDate = this.parseDate.bind(this);
        this.handleChangeInterestType = this.handleChangeInterestType.bind(this);
        this.getInterestType = this.getInterestType.bind(this);
        this.getMaturityDateInput = this.getMaturityDateInput.bind(this);
        this.getAvailabilityInput = this.getAvailabilityInput.bind(this);
        this.getInterestTypeInput = this.getInterestTypeInput.bind(this);
        this.getInterestRateInput = this.getInterestRateInput.bind(this);
        this.getInitialBudgetInput = this.getInitialBudgetInput.bind(this);
        this.getExecutionBudgetInput = this.getExecutionBudgetInput.bind(this);
        this.getInvestmentDateInput = this.getInvestmentDateInput.bind(this);
        this.getDistributionPeriodInput = this.getDistributionPeriodInput.bind(this);
        this.isValid = this.isValid.bind(this);
        this.formIsValid = this.formIsValid.bind(this);
        this.setError = this.setError.bind(this);
        this.isInitialBudgetPercentageValid = this.isInitialBudgetPercentageValid.bind(this);
        this.isExecutionBudgetPercentageValid = this.isExecutionBudgetPercentageValid.bind(this);

        this.name = React.createRef();
    }

    isValid(field, value) {
        return this.state.fieldValidations[field].isValid(value);
    }

    formIsValid() {
        if(this.props.isChild) {
            const name = this.name.current ? this.name.current.innerText : '';
            const formLabel = this.props.category.parentId ? this.props.category.type.charAt(0).toUpperCase() + this.props.category.type.toLowerCase().slice(1) + ' Subcategory' : this.props.category.type.charAt(0).toUpperCase() + this.props.category.type.toLowerCase().slice(1) + ' Category';
            return this.isValid("interestRate", this.state.interestRate) &&
                this.isValid("distributionPeriod", this.state.distributionPeriod) &&
                this.isValid("initialBudget", this.state.initialBudget) &&
                this.isValid("initialBudgetPercentage", this.state.initialBudgetPercentage) &&
                this.isValid("executionBudget", this.state.executionBudget) &&
                this.isValid("executionBudgetPercentage", this.state.executionBudgetPercentage) &&
                (this.props.category.type === 'COST' || this.props.category.type === 'INCOME' || this.isInitialBudgetPercentageValid()) &&
                (this.props.category.type === 'COST' || this.props.category.type === 'INCOME' || this.isExecutionBudgetPercentageValid()) &&
                !hasErrors(name, "Edit " + formLabel, true, false, 'text', this.setError);
        } else {
            const name = this.name.current ? this.name.current.innerText : '';
            const formLabel = this.props.category.parentId ? this.props.category.type.charAt(0).toUpperCase() + this.props.category.type.toLowerCase().slice(1) + ' Subcategory' : this.props.category.type.charAt(0).toUpperCase() + this.props.category.type.toLowerCase().slice(1) + ' Category';
            return !hasErrors(name, "Edit " + formLabel, true, false, 'text', this.setError);
        }
    }

    handleChange(event) {
        let displayErrors = this.state.displayErrors;
        displayErrors[event.target.id] = !this.isValid(event.target.id, event.target.value);
        if (this.props.category.type === 'FINANCING' || this.props.category.type === 'INVESTMENT') {
            if (event.target.id === "executionBudget") {
                const executionBudgetPercentage = this.props.totalCostExecutionBudget ? event.target.value * 100 / this.props.totalCostExecutionBudget : 0;
                this.setState({
                    executionBudget: event.target.value,
                    executionBudgetPercentage: round(executionBudgetPercentage, 2),
                    displayErrors: displayErrors
                })
            }
            if (event.target.id === "initialBudget") {
                const initialBudgetPercentage = this.props.totalCostInitialBudget ? event.target.value * 100 / this.props.totalCostInitialBudget : 0;
                this.setState({
                    initialBudget: event.target.value,
                    initialBudgetPercentage: round(initialBudgetPercentage, 2),
                    displayErrors: displayErrors
                })
            }
            if (event.target.id === "executionBudgetPercentage") {
                const executionBudget = event.target.value * this.props.totalCostExecutionBudget / 100;
                this.setState({
                    executionBudget: parseInt(executionBudget),
                    executionBudgetPercentage: event.target.value,
                    displayErrors: displayErrors
                })
            }
            if (event.target.id === "initialBudgetPercentage") {
                const initialBudget = event.target.value * this.props.totalCostInitialBudget / 100;
                this.setState({
                    initialBudget: parseInt(initialBudget),
                    initialBudgetPercentage: event.target.value,
                    displayErrors: displayErrors
                })
            }
        }
        this.setState({
            [event.target.id]: event.target.value,
            displayErrors: displayErrors
        });
    };

    handleEdit() {
        this.setState({editing: !this.state.editing});
    };

    handleSave() {
        if (this.formIsValid()) {
            const name = this.name.current ? this.name.current.innerText : '';
            this.setState({
                name: name,
            });
            const response = this.props.onEdit({
                name: name,
                initialBudget: this.state.initialBudget,
                initialBudgetPercentage: this.state.initialBudgetPercentage,
                executionBudget: this.state.executionBudget,
                executionBudgetPercentage: this.state.executionBudgetPercentage,
                distributionPeriod: this.state.distributionPeriod,
                maturityDate: this.state.maturityDate,
                availability: this.state.availability,
                investmentDate: this.state.investmentDate,
                interestType: this.state.interestType,
                interestRate: this.state.interestRate,
            });
            if (response === "") {
                this.setState({editing: !this.state.editing, nameIsTouched: false});
            }
        }
    }

    handleKeyPress(event) {
        if (event.target.id === 'name') {
            this.setState({nameIsTouched: true});
        }
        if (event.key === 'Enter') {
            this.handleSave();
        }
    }

    handleChangeMaturityDate(maturityDate) {
        this.setState({
            maturityDate: maturityDate
        })
    }

    handleChangeAvailability(availability) {
        this.setState({
            availability: availability
        })
    }

    handleChangeInvestmentDate(investmentDate) {
        this.setState({
            investmentDate: investmentDate
        })
    }

    dateFrom(date) {
        return date ? this.parseDate(date) : '';
    }

    parseDate(date) {
        return date.split("+")[0];
    }

    handleChangeInterestType(event) {
        this.setState({
            interestType: event.target.value !== "" ? event.target.value : null
        });
    }

    getInterestType() {
        const interestTypes = this.state.interestTypes.filter(interestType => interestType.value === this.state.interestType);
        if (interestTypes.length) {
            return interestTypes[0].formattedValue !== "" ? interestTypes[0].formattedValue : "";
        }
        return "";
    }

    setError(error) {
        this.setState({error: error});
    }


    render() {

        const formClassName = () => {
            if (this.props.category.type === 'FINANCING' || this.props.category.type === 'INVESTMENT') {
                if (this.props.category.parentId) {
                    return "col-2 pr-0 pl-5";
                }
                return "col-2 pr-0";
            }
            if (this.props.category.parentId) {
                return "col-5 pr-0 pl-5";
            }
            return "col-5 pr-0";
        }

        const formLabel = this.props.category.parentId ? this.props.category.type.charAt(0).toUpperCase() + this.props.category.type.toLowerCase().slice(1) + ' Subcategory' : this.props.category.type.charAt(0).toUpperCase() + this.props.category.type.toLowerCase().slice(1) + ' Category';
        return (
            <div className={this.props.className + ""}>
                <div className="row expandable-row ml-0 w-100">
                    <div className={formClassName()}>
                        {this.state.editing ?
                            <div className="d-table w-100">
                                <div className="d-table-cell h-100 align-top position-relative">
                                    <div className="empty-spacebar"></div>
                                    <button
                                        className={this.props.category.parentId ? "kb-btn-drag btn btn-link px-3 drag-handle pl-4 d-table-cell absolute-drag-btn" : "kb-btn-drag btn btn-link px-3 drag-handle d-table-cell absolute-drag-btn"}>
                                        <i className="fas fa-ellipsis-v"></i></button>
                                </div>
                                <div className={"name-input-wrapper mr-auto d-table-cell w-100"}>
                                    <div className="dynamic-input-field-container">
                                    <span
                                        id={"name"}
                                        ref={this.name}
                                        className={this.props.className + "-name-form kb-list-item-title_edit form-control textarea h-auto mb-1 no-max-width dynamic-input-field"}
                                        role="textbox"
                                        contentEditable
                                        suppressContentEditableWarning={true}
                                        onKeyDown={this.handleKeyPress}
                                        onInput={(e) => this.setState({name1: e.currentTarget.textContent})}
                                    >
                                        {this.state.name}
                                    </span>
                                    </div>
                                    <div className="text-danger">
                                        <small>
                                            {this.state.nameIsTouched && hasErrors(this.state.name1, "Edit " + formLabel, false, true, 'text', this.setError)}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="d-table">
                                <div className="d-table-cell h-100 align-top position-relative">
                                    <div className="empty-spacebar"></div>
                                    <button
                                        className={this.props.category.parentId ? "kb-btn-drag btn btn-link px-3 drag-handle pl-4 d-table-cell absolute-drag-btn" : "kb-btn-drag btn btn-link px-3 drag-handle d-table-cell absolute-drag-btn"}>
                                        <i className="fas fa-ellipsis-v"></i></button>
                                </div>
                                <div className="d-table-cell w-100">
                                    <div className="dynamic-input-field-container">
                                        {this.props.category.parentId ?
                                            (<span className="text-break dynamic-input-field pt-2">
                                      <Link
                                          to={"/manageExecution/" + this.props.category.id + '/' + this.props.category.projectId + '/' + this.props.category.type}
                                          className="sub-category-link">
                                      {this.props.category.name}
                                      </Link>
                                    </span>)
                                            :
                                            (<span
                                                className="text-break dynamic-input-field pt-2">{this.props.category.name}
                                                <span
                                                    className="kb-name_badge text-primary ml-2">{this.props.category.children ? this.props.category.children.length : 0}</span>
                                      </span>)
                                        }

                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {this.props.category.parentId ?
                        <React.Fragment>
                            {this.state.editing ?
                                <React.Fragment>
                                    {this.props.category.type === 'COST' &&
                                    <React.Fragment>
                                        <div className="col-1 pr-0"></div>
                                        <div className="col-1 pr-0"></div>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">{prettyPrint(this.props.category.ibCode)}</div>
                                        <div className="col-1 pr-0">
                                            {this.getDistributionPeriodInput()}
                                        </div>
                                        <div className="col-1 pr-0">
                                            {this.getInitialBudgetInput()}
                                        </div>
                                        <div className="col-1 pr-0">
                                            {this.getExecutionBudgetInput()}
                                        </div>
                                    </React.Fragment>
                                    }
                                    {this.props.category.type === 'INCOME' &&
                                    <React.Fragment>
                                        <div className="col-1 pr-0"></div>
                                        <div className="col-1 pr-0"></div>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">{prettyPrint(this.props.category.ibCode)}</div>
                                        <div className="col-1 pr-0">
                                            {this.getDistributionPeriodInput()}
                                        </div>
                                        <div className="col-1 pr-0">
                                            {this.getInitialBudgetInput()}
                                        </div>

                                        <div className="col-1 pr-0">
                                            {this.getExecutionBudgetInput()}
                                        </div>
                                    </React.Fragment>
                                    }
                                    {this.props.category.type === 'FINANCING' &&
                                    <React.Fragment>
                                        <div className="col-1 pr-0">
                                            {this.getMaturityDateInput()}
                                        </div>
                                        <div className="col-1 pr-0">
                                            {this.getAvailabilityInput()}
                                        </div>
                                        <div className="col-1 pr-0">
                                            {this.getInterestTypeInput()}
                                        </div>

                                        <div className="col-1 pr-0">
                                            {this.getInterestRateInput()}
                                        </div>
                                        <div className="col-1 pr-0">
                                            {this.getDistributionPeriodInput()}
                                        </div>
                                        <div className="col-1 pr-0">
                                            {this.getInitialBudgetInput()}
                                        </div>
                                        <div className="col-1 pr-0">
                                            {this.getInitialBudgetPercentageInput()}
                                        </div>
                                        <div className="col-1 pr-0">
                                            {this.getExecutionBudgetInput()}
                                        </div>
                                        <div className="col-1 pr-0">
                                            {this.getExecutionBudgetPercentageInput()}
                                        </div>
                                    </React.Fragment>
                                    }
                                    {this.props.category.type === 'INVESTMENT' &&
                                    <React.Fragment>
                                        <div className="col-1 pr-0">
                                            {this.getMaturityDateInput()}
                                        </div>
                                        <div className="col-1 pr-0">
                                            {this.getInvestmentDateInput()}
                                        </div>
                                        <div className="col-1 pr-0">
                                            {this.getInterestTypeInput()}
                                        </div>

                                        <div className="col-1 pr-0">
                                            {this.getInterestRateInput()}
                                        </div>
                                        <div className="col-1 pr-0">
                                            {this.getDistributionPeriodInput()}
                                        </div>
                                        <div className="col-1 pr-0">
                                            {this.getInitialBudgetInput()}
                                        </div>
                                        <div className="col-1 pr-0">
                                            {this.getInitialBudgetPercentageInput()}
                                        </div>
                                        <div className="col-1 pr-0">
                                            {this.getExecutionBudgetInput()}
                                        </div>
                                        <div className="col-1 pr-0">
                                            {this.getExecutionBudgetPercentageInput()}
                                        </div>
                                    </React.Fragment>
                                    }
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {this.props.category.type === 'COST' &&
                                    <React.Fragment>
                                        <div className="col-1 pr-0"></div>
                                        <div className="col-1 pr-0"></div>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">{prettyPrint(this.props.category.ibCode)}</div>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">{this.props.category.distributionPeriod}</div>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">{this.props.category.initialBudget ? prettyPrint(this.props.category.initialBudget) : 0}</div>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">{this.props.category.executionBudget ? prettyPrint(this.props.category.executionBudget) : 0}</div>
                                    </React.Fragment>
                                    }
                                    {this.props.category.type === 'INCOME' &&
                                    <React.Fragment>
                                        <div className="col-1 pr-0"></div>
                                        <div className="col-1 pr-0"></div>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">{prettyPrint(this.props.category.ibCode)}</div>
                                        <div className="col-1 pr-0 text-break pt-2">
                                            {this.props.category.distributionPeriod}
                                        </div>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">{this.props.category.initialBudget ? prettyPrint(this.props.category.initialBudget) : 0}</div>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">{this.props.category.executionBudget ? prettyPrint(this.props.category.executionBudget) : 0}</div>
                                    </React.Fragment>
                                    }
                                    {this.props.category.type === 'FINANCING' &&
                                    <React.Fragment>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">
                                            {this.props.category.maturityDate && stringToFormattedDate(this.props.category.maturityDate)}
                                        </div>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">{this.props.category.availability && stringToFormattedDate(this.props.category.availability)}</div>
                                        <div className="col-1 pr-0 text-break pt-2">{this.getInterestType()}</div>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">{this.props.category.interestRate && this.props.category.interestRate + '%'}
                                        </div>
                                        <div className="col-1 pr-0 text-break pt-2">
                                            {this.props.category.distributionPeriod}
                                        </div>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">{this.state.initialBudget ? prettyPrint(this.state.initialBudget) : 0}</div>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">
                                            {this.state.initialBudgetPercentage && round(this.state.initialBudgetPercentage, 2) + '%'}
                                            {!this.isInitialBudgetPercentageValid() &&
                                            <div className="text-danger"><small>Update according to the new Total
                                                Budget</small></div>
                                            }
                                        </div>

                                        <div
                                            className="col-1 pr-0 text-break pt-2">{this.state.executionBudget ? prettyPrint(this.state.executionBudget) : 0}</div>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">
                                            {this.state.executionBudgetPercentage && round(this.state.executionBudgetPercentage, 2) + '%'}
                                            {!this.isExecutionBudgetPercentageValid() &&
                                            <div className="text-danger"><small>Update according to the new Total
                                                Budget</small></div>
                                            }
                                        </div>
                                    </React.Fragment>
                                    }
                                    {this.props.category.type === 'INVESTMENT' &&
                                    <React.Fragment>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">
                                            {this.props.category.maturityDate && stringToFormattedDate(this.props.category.maturityDate)}
                                        </div>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">{this.props.category.investmentDate && stringToFormattedDate(this.props.category.investmentDate)}</div>
                                        <div className="col-1 pr-0 text-break pt-2">{this.getInterestType()}</div>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">{this.props.category.interestRate && this.props.category.interestRate + '%'}
                                        </div>
                                        <div className="col-1 pr-0 text-break pt-2">
                                            {this.props.category.distributionPeriod}
                                        </div>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">{this.state.initialBudget ? prettyPrint(this.state.initialBudget) : 0}</div>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">
                                            {this.state.initialBudgetPercentage && round(this.state.initialBudgetPercentage, 2) + '%'}
                                            {!this.isInitialBudgetPercentageValid() &&
                                            <div className="text-danger"><small>Update according to the new Total
                                                Budget</small></div>
                                            }
                                        </div>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">{this.state.executionBudget ? prettyPrint(this.state.executionBudget) : 0}</div>
                                        <div
                                            className="col-1 pr-0 text-break pt-2">
                                            {this.state.executionBudgetPercentage && round(this.state.executionBudgetPercentage, 2) + '%'}
                                            {!this.isExecutionBudgetPercentageValid() &&
                                            <div className="text-danger"><small>Update according to the new Total
                                                Budget</small></div>
                                            }
                                        </div>
                                    </React.Fragment>
                                    }
                                </React.Fragment>
                            }
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {(this.props.category.type === 'COST' || this.props.category.type === 'INCOME') &&
                            <React.Fragment>
                                <div className="col-1 pr-0"></div>
                                <div className="col-1 pr-0"></div>
                                <div className="col-1 pr-0"></div>
                                <div className="col-1 pr-0"></div>
                                <div
                                    className="col-1 pr-0 text-break pt-2">{this.props.category.initialBudget ? prettyPrint(this.props.category.initialBudget) : 0}</div>
                                <div
                                    className="col-1 pr-0 text-break pt-2">{this.props.category.executionBudget ? prettyPrint(this.props.category.executionBudget) : 0}</div>
                            </React.Fragment>
                            }
                            {(this.props.category.type === 'FINANCING' || this.props.category.type === 'INVESTMENT') &&
                            <React.Fragment>
                                <div className="col-1 pr-0"></div>
                                <div className="col-1 pr-0"></div>
                                <div className="col-1 pr-0"></div>
                                <div className="col-1 pr-0"></div>
                                <div className="col-1 pr-0"></div>
                                <div
                                    className="col-1 pr-0 text-break pt-2">{this.props.category.initialBudget ? prettyPrint(this.props.category.initialBudget) : 0}</div>
                                <div className="col-1 pr-0"></div>
                                <div
                                    className="col-1 pr-0 text-break pt-2">{this.props.category.executionBudget ? prettyPrint(this.props.category.executionBudget) : 0}</div>
                                <div className="col-1 pr-0"></div>
                            </React.Fragment>
                            }
                        </React.Fragment>
                    }
                    <div className="col-1 pr-0 pl-0 expandable-col text-right">
                        <div className="pr-3">
                            {this.state.editing ?
                                <button className={"kb-btn_save btn btn-link text-primary px-2 ml-1 d-inline-block"}
                                        onClick={this.handleSave}
                                >
                                    <i className="fas fa-check"></i>
                                </button>
                                :
                                <button className={"kb-btn_edit btn btn-link px-2 ml-1 d-inline-block"}
                                        onClick={this.handleEdit}>
                                    <i className="fas fa-pen"></i>
                                </button>
                            }
                            <button className={"kb-btn_delete btn btn-link px-2 ml-1 d-inline-block"}
                                    onClick={this.props.onDelete}>
                                <i className="fas fa-trash"></i>
                            </button>
                        </div>
                    </div>
                </div>


            </div>
        );
    }

    getDistributionPeriodInput() {
        return <div className={"kb-value-input-wrapper w-100"}>
            <CommaSeparatedInput
                elementType="FormControl"
                type="number"
                decimalsAfterDot={0}
                min={0}
                max={9999}
                id={"distributionPeriod"}
                value={this.state.distributionPeriod}
                handleChange={this.handleChange}
            />
            {this.state.displayErrors.distributionPeriod &&
            <div className="text-danger"><small>Max 4 numeric characters.</small></div>}
        </div>;
    }

    getInvestmentDateInput() {
        return <div className={"kb-value-input-wrapper w-100"}>
            <CustomDatePicker
                selected={this.state.investmentDate}
                onChange={this.handleChangeInvestmentDate}
                onKeyDown={this.handleKeyPress}
            />
        </div>;
    }

    getExecutionBudgetInput() {
        return <div className={"kb-value-input-wrapper w-100"}>
            <CommaSeparatedInput
                elementType="FormControl"
                type="number"
                decimalsAfterDot={0}
                min={0}
                max={10000000000}
                id={"executionBudget"}
                value={this.state.executionBudget}
                handleChange={this.handleChange}
            />
            {this.state.displayErrors.executionBudget &&
            <div className="text-danger"><small>Max 10 numeric characters.</small></div>}
        </div>;
    }

    getInitialBudgetInput() {
        return <div className={"kb-value-input-wrapper w-100"}>
            {(this.state.initialBudget || true ) && <CommaSeparatedInput
                elementType="FormControl"
                type="number"
                decimalsAfterDot={0}
                min={0}
                max={10000000000}
                id={"initialBudget"}
                value={this.state.initialBudget}
                handleChange={this.handleChange}
            />}
            {this.state.displayErrors.initialBudget &&
            <div className="text-danger"><small>Max 10 numeric characters.</small></div>}
        </div>;
    }

    getInitialBudgetPercentageInput() {
        return <div className={"kb-value-input-wrapper w-100"}>
            {(this.state.initialBudgetPercentage || true ) && <CommaSeparatedInput
                elementType="FormControl"
                type="number"
                decimalsAfterDot={2}
                min={0}
                id={"initialBudgetPercentage"}
                value={this.state.initialBudgetPercentage}
                handleChange={this.handleChange}
            />}
            {this.state.displayErrors.initialBudgetPercentage &&
            <div className="text-danger"><small>Max 2 decimal allowed</small></div>}
            {!this.state.displayErrors.initialBudgetPercentage && !this.isInitialBudgetPercentageValid() &&
            <div className="text-danger"><small>Update according to the new Total Budget</small></div>
            }
        </div>;
    }

    getExecutionBudgetPercentageInput() {
        return <div className={"kb-value-input-wrapper w-100"}>
            <CommaSeparatedInput
                elementType="FormControl"
                type="number"
                decimalsAfterDot={2}
                min={0}
                id={"executionBudgetPercentage"}
                value={this.state.executionBudgetPercentage}
                handleChange={this.handleChange}
            />
            {this.state.displayErrors.executionBudgetPercentage &&
            <div className="text-danger"><small>Max 2 decimal allowed</small></div>}
            {!this.state.displayErrors.executionBudgetPercentage && !this.isExecutionBudgetPercentageValid() &&
            <div className="text-danger"><small>Update according to the new Total Budget</small></div>
            }
        </div>;
    }

    getInterestRateInput() {
        return <div className={"kb-value-input-wrapper w-100"}>
            <CommaSeparatedInput
                elementType="FormControl"
                type="number"
                decimalsAfterDot={2}
                min={0}
                id={"interestRate"}
                value={this.state.interestRate}
                handleChange={this.handleChange}
            />
            {this.state.displayErrors.interestRate &&
            <div className="text-danger"><small>Max 100.00</small></div>}
        </div>;
    }

    getInterestTypeInput() {
        return <div className={"kb-value-input-wrapper w-100"}>
            <Form.Control
                as="select"
                type="contract"
                value={this.state.interestType ? this.state.interestType : ""}
                onChange={this.handleChangeInterestType}
            >
                {this.state.interestTypes.map(interestType => (
                    <option key={interestType.value}
                            value={interestType.value}>{interestType.formattedValue}</option>
                ))}

            </Form.Control>
        </div>;
    }

    getMaturityDateInput() {
        return <div className={"kb-value-input-wrapper w-100"}>
            <CustomDatePicker
                selected={this.state.maturityDate}
                onChange={this.handleChangeMaturityDate}
                onKeyDown={this.handleKeyPress}
            />
        </div>;
    }

    getAvailabilityInput() {
        return <div className={"kb-value-input-wrapper w-100"}>
            <CustomDatePicker
                selected={this.state.availability}
                onChange={this.handleChangeAvailability}
                onKeyDown={this.handleKeyPress}
            />
        </div>;
    }

    isInitialBudgetPercentageValid() {
        return (this.props.totalCostInitialBudget && this.props.totalCostInitialBudget !== 0) ? (round(this.state.initialBudget * 100 / this.props.totalCostInitialBudget, 2) === parseFloat(this.state.initialBudgetPercentage)) : true;
    }

    isExecutionBudgetPercentageValid() {
        return (this.props.totalCostExecutionBudget && this.props.totalCostExecutionBudget !== 0) ? (round(this.state.executionBudget * 100 / this.props.totalCostExecutionBudget, 2) === parseFloat(this.state.executionBudgetPercentage)) : true;
    }

}

ProjectCategoryViewAndEdit.propTypes = {
    category: PropTypes.shape({
        id: PropTypes.string,
        parentId: PropTypes.string,
        name: PropTypes.string.isRequired,
        initialBudget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        executionBudget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        distributionPeriod: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf([null])]),
        children: PropTypes.array,
        ibCode: PropTypes.number
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onMoveUp: PropTypes.func.isRequired,
    onMoveDown: PropTypes.func.isRequired,
    className: PropTypes.string,
    totalCostInitialBudget: PropTypes.number,
    totalCostExecutionBudget: PropTypes.number,
    isChild: PropTypes.bool
};

ProjectCategoryViewAndEdit.defaultProps = {
    className: 'category-view'
};

export default ProjectCategoryViewAndEdit;
