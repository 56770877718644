import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import { NavDescriptor, NavPanel } from '../../../components-shared/NavPanel';
import { viewAndManageAppSettings } from '../../../main-components/UserPermissionsFunctions';
import { getAlluserRoles, getAllPermissions} from "../actions/userPermissionsActions";
import AddUserRole from './AddUserRole';
import RolePermissionPage from './RolePermissionPage';
import UserPermissionsPage from './UserPermissionsPage';
import { titleToUrl } from "./functions";

function PermissionsPage(props) {
  const [roles, setRoles] = useState([]);
  const [initialRoles, setInitialRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [initialPermissions, setInitialPermissions] = useState([]);
  const [permissionGroups, setPermissionGroups] = useState([]);
  useEffect(() => {
    getRoles();
    getPermissions();
  }, []);

  const getRoles = () => {
    getAlluserRoles().then(roles => {
      setRoles(roles);
      setInitialRoles(roles);
    });
  }
  const getPermissions = () => {
    getAllPermissions().then(permissions => {
      setPermissions(permissions.permissions);
      setInitialPermissions(permissions.permissions);
      setPermissionGroups(permissions.permissionGroups);
    });
  }


  const pageProps = {
    getRoles: getRoles,
    getPermissions: getPermissions,
    roles: roles,
    initialRoles: initialRoles,
    setRoles: setRoles,
    setInitialRoles: setInitialRoles,
    permissions: permissions,
    initialPermissions: initialPermissions,
    setPermissions: setPermissions,
    setInitialPermissions: setInitialPermissions,
    permissionGroups: permissionGroups,
    setPermissionGroups: setPermissionGroups
  }

  const navDescriptors = [
      new NavDescriptor('/settings/roles', viewAndManageAppSettings(), '/show-all', 'Show all', (props) => <UserPermissionsPage {...props} {...pageProps} />, "fas fa-gavel"),
      new NavDescriptor('/settings/roles', viewAndManageAppSettings(), '/add-role', 'Add new role', (props) => <AddUserRole {...props}  {...pageProps} />, "fas fa-gavel")
  ];
  roles && roles.length > 0 && roles.forEach(role => {
    if (role && role.name) {
      const urlPath = titleToUrl(role.name);
      navDescriptors.push(new NavDescriptor(`/settings/roles`, viewAndManageAppSettings(), `/${urlPath}`, role.name, (props) => <RolePermissionPage {...props} {...pageProps} pageName={role.name} />, "fas fa-gavel"));
    }
  })



  // noinspection CheckTagEmptyBody
  return (
      <React.Fragment>
          <Breadcrumb breadcrumbs={[{url: '/projects', title: 'Home'}, {url: '/settings', title: 'Settings'}, {url: '/settings/roles', title: 'Permissions'}]} />
           <main className="kb-main kb-main_user--permissions px-3 pb-4">
                  <div className="container-fluid">
                      <div className="row">
                          <div className="col-12 col-md-3 col-lg-3 col-xl-2 border-right">
                              <div className="kb-permissions-aside">
                                  <div className="d-flex align-items-center py-4">
                                      <h1 className="kb-title_page h4 font-weight-normal m-0">Roles</h1>
                                  </div>
                                  <ul className="kb-nav-link nav flex-column py-2">
                                      <li className="nav-item py-lg-2 ">
                                          <NavLink to="/settings/roles/show-all" replace>Show All</NavLink>
                                      </li>
                                      {
                                        roles && roles.length > 0 && roles.map(role => {
                                          const urlPath = titleToUrl(role.name);
                                          const userReadableName = role.name;
                                          return (
                                            <li className="nav-itemm py-lg-2 text-break" key={role.id}>
                                                <NavLink to={`/settings/roles/${urlPath}`} replace >{userReadableName}</NavLink>
                                            </li>)
                                        })
                                      }
                                      <li className="nav-item py-lg-2">
                                          <NavLink to={`/settings/roles/add-role`} replace ><i className="text-primary fas fa-plus-circle mr-3"></i> Add New role</NavLink>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                          <div className="col-12 col-md-9 col-lg-9 col-xl-10">
                              <div className="row">
                                <div className="col-12 bg-white py-4">
                                      <NavPanel navDescriptors={navDescriptors}
                                        navBarClassName={"settings-permissions-navigation"}
                                        contentClassName={"settings-permissions-content-wrapper"}
                                        {...props}
                                        hideNav={true}
                                      />
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </main>
              </React.Fragment>
  );
}

export default PermissionsPage;
