import React, {Component} from "react";
import PropTypes from "prop-types";
//import Select from 'react-select';

import {Button, FormLabel, Form, FormControl, FormGroup} from "react-bootstrap";
import {getUserLoggedIn, 
  //myAccountPage, userManagementPage
   updateUser
} from "../actions/userActions";
//import {userRoles} from "../support/UserConstants";
import {AppContextData} from "../../../main-components/AppContextData";
import update from "immutability-helper";
import {confirmAlert} from 'react-confirm-alert';
import {withRouter} from "react-router-dom";
import PhoneInput from 'react-phone-number-input';
import {toast} from 'react-toastify';
import {getRoles} from "../actions/rolesActions";
import '../styles/dropdown.css';

class EditUserDetailsForm extends Component {
  
  constructor (props) {
    super(props);
    
    this.state = EditUserDetailsForm.constructStateObject(props.userDetails);
    
    this.handleChange = this.handleChange.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handleCheckboxEdit = this.handleCheckboxEdit.bind(this);
    
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.dismissError = this.dismissError.bind(this);
    this.cancelOperation = this.cancelOperation.bind(this);
    this.getConfirmCancelOperationOptions = this.getConfirmCancelOperationOptions.bind(this);
    
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.handleRoleDelete = this.handleRoleDelete.bind(this);
    this.handleAddRole = this.handleAddRole.bind(this);
    this.isEmpty = this.isEmpty.bind(this);
    this.rolesAreValid = this.rolesAreValid.bind(this);
    this.hasErrors = this.hasErrors.bind(this);
    this.getAssignableRoles = this.getAssignableRoles.bind(this);
  };
  
  static constructStateObject (userDetails) {
    return {
      basicInfo: {
        id: userDetails.id || '',
        email: userDetails.email || '',
        firstName: userDetails.firstName || '',
        lastName: userDetails.lastName || '',
        companyName: userDetails.companyName || '',
        phoneNumber: userDetails.phoneNumber || '',
        additionalInfo: userDetails.additionalInfo || '',
        userRoles: userDetails.userRoles || [''],
        userRoleIds: userDetails.userRoleIds || [''],
        active: userDetails.active || true,
        submitted: false,
        error: '',
      },
      roles: [],
      isSubmited: false
    };
  }

  getAssignableRoles(index) {
    if (this.state.roles.length) {
      let allRoles = this.state.roles;
      let assignedRoleIds = this.state.basicInfo.userRoleIds;

      for (let i = 0; i < allRoles.length; i++) {
        allRoles[i].selectedIndex = this.getIndex(assignedRoleIds, allRoles[i].id);
      }

      return allRoles.filter(role => {
        if (role.selectedIndex === index) {
          return true;
        }
        return !assignedRoleIds.includes(role.id);
      });
    }
    return [];
  }

  getIndex(assignedRoleIds, id) {
    return assignedRoleIds.findIndex(roleId => roleId === id);
  }

  componentDidMount () {
    getRoles().then(response => {
      this.setState({
        roles: response
      })
    })
  }
  
  handleChange (event) {
    this.setState({
      basicInfo: update(this.state.basicInfo, {
        [event.target.id]: {$set: event.target.value}
      })
    });
  };
  
  handlePhoneNumberChange (value) {
    this.setState({
      basicInfo: update(this.state.basicInfo, {
        phoneNumber: {$set: value}
      })
    });
  }
  
  handleCheckboxEdit () {
    this.setState({
      basicInfo: update(this.state.basicInfo, {
        active: {$set: !this.state.basicInfo.active}
      })
    });
  }

  
  hasErrors(stateField, fieldName, formValidation, inFormError, fieldType) {
    const errorsArray = [{
          errMsg: `The ${fieldName} should have only alphanumeric characters and white spaces`,
          isValid: (value) => {
              return value && /^[a-z 0-9]+$/i.test(value);
          },
          usedFor: ['text']
      },
      {
          errMsg: `The ${fieldName} field should not start or end with a whitespace`,
          isValid: (value) => {
              return value && !value.startsWith(' ') && !value.endsWith(' ');
          },
          usedFor: ['text', 'email', 'password', 'confirmPassword']
      },
      {
          errMsg: `The ${fieldName} field is not valid`,
          isValid: (value) => {
              const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return re.test(String(value).toLowerCase());
          },
          usedFor: ['email']
      },
      {
          errMsg: `Passwords must match!`,
          isValid: (value) => {
              return value === this.state.password;
          },
          usedFor: ['confirmPassword']
      },
      {
          errMsg: `The ${fieldName} field should not be empty or have only whitespaces`,
          isValid: (value) => {
              return value && value.replace(/\s/g, '').length > 0;
          },
          usedFor: ['text', 'email', 'password', 'confirmPassword']
      }];

      
      let errorsFound = false;
      let errorsMessageFound = '';
      if (stateField === 'userRoleIds') {
          if (formValidation) {
              if (!this.rolesAreValid()) {
                  errorsFound = true;
              }
          } else {
              if (!this.rolesAreValid()) {
                  errorsMessageFound = 'User Role is required!';
              }
          }
      } else {
          
          errorsArray.forEach(error => {
              if (error.usedFor.includes(fieldType)){
                  if (formValidation) {
                      if (!error.isValid(this.state['basicInfo'][stateField])) {
                          errorsFound = true;
                      }
                  } else {
                      if (!error.isValid(this.state['basicInfo'][stateField])) {
                          errorsMessageFound = error.errMsg;
                      }
                  }
              }
          });
      }
    
      if (inFormError) {
          return errorsMessageFound;
      } else if (formValidation) {
          return errorsFound;
      } else {
          return this.setState({error: errorsMessageFound});
      }

}
  
  handleSubmit (event) {
    event.preventDefault();
    
    this.setState({
        isSubmited: true
    }, () => {
      
      if (this.validateForm() && !this.state.basicInfo.submitted) {
        confirmAlert({
          title: 'Confirm',
          message: 'Are you sure you want to update user details?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                // submitted becomes true only on YES
                this.setState({
                  basicInfo: update(this.state.basicInfo, {
                    submitted: {$set: true}
                  })
                });
                updateUser(this.state.basicInfo).then((json) => {
                  if (json.code) {
                    return update(this.state.basicInfo, {
                      error: {$set: json.message}
                    });
                  } else {
                    // If edit is done from my account which is the currently logged in user we must update the App Context Data
                    // with the new data. To be sure, make the call again and retrieve the updated information and add the values
                    // to be displayed properly.
                    if (this.props.myAccountEdit) {
                      //TODO [CS] handle this
                      getUserLoggedIn().then((userDetails) => {
                        AppContextData.setUserValues(userDetails);
                      });
                      toast.info("Saved the myAccount form successfully", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 5000,
                      });
                      toast.info("Refresh header with new information of the logged in user", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 8000,
                        onClose: () => window.location.reload()
                      });
                      this.props.history.goBack();
                    } else {
                      toast.info("Saved the user details successfully.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      });
                      this.props.history.goBack();
                    }
                  }
                });
              }
            },
            {
              label: 'No',
            }
          ]
        });
      }
      
      return this.setState({error: ''});
    });
  }
  
  validateForm () {
    const email = !this.hasErrors('email', 'Email', true, false, 'email');
    const firstName = !this.hasErrors('firstName', 'First Name', true, false);
    const lastName = !this.hasErrors('lastName', 'Last Name', true, false);
    const companyName = !this.hasErrors('companyName', 'Company name', true, false); 
    const valid = email && firstName && lastName && companyName;
    let st = this.state.basicInfo;
    return valid && !st.submitted && this.rolesAreValid();
  }
  
  rolesAreValid () {
    return !this.state.basicInfo.userRoleIds.some(this.isEmpty);
  }
  
  isEmpty (value) {
    return value === '';
  }
  
  handleRoleChange (e, i) {
    let basicInfo = this.state.basicInfo;
    basicInfo.userRoleIds[i] = e.target.value;
    this.setState({
      basicInfo: basicInfo
    });
  }
  
  handleRoleDelete (i) {
    let basicInfo = this.state.basicInfo;
    basicInfo.userRoleIds.splice(i, 1);
    this.setState({
      basicInfo: basicInfo
    });
  }
  
  handleAddRole () {
    let basicInfo = this.state.basicInfo;
    basicInfo.userRoleIds.push('');
    this.setState({
      basicInfo: basicInfo
    });
  }
  
  dismissError () {
    this.setState({
      basicInfo: update(this.state.basicInfo, {
        error: {$set: ''}
      })
    });
  }
  
  getConfirmCancelOperationOptions () {
    return {
      title: 'Confirm',
      message: 'Are you sure you want to discard the changes?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.history.goBack();
          }
        },
        {
          label: 'No'
        }
      ]
    };
  }
  
  cancelOperation () {
    const confirmCancelOperationOptions = this.getConfirmCancelOperationOptions();
    confirmAlert(confirmCancelOperationOptions);
  }
  
  render () {
    const inputClassName = " ";
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-12">
              <FormGroup className={inputClassName} controlId="email">
                <FormLabel>Email</FormLabel>
                <FormControl
                  type="text"
                  value={this.state.basicInfo.email}
                  onChange={this.handleChange}
                />
                <div className="text-danger"><small>{(this.state.isSubmited) && this.hasErrors('email', 'Email', false, true, 'email')}</small></div>
              </FormGroup>
            </div>
            <div className="col-12">
              <FormGroup className={inputClassName} controlId="firstName">
                <FormLabel>First Name</FormLabel>
                <FormControl
                  type="firstName"
                  value={this.state.basicInfo.firstName}
                  onChange={this.handleChange}
                />
                <div className="text-danger"><small>{(this.state.isSubmited) && this.hasErrors('firstName', 'First Name', false, true, 'text')}</small></div>
              </FormGroup>
            </div>
            <div className="col-12">
              <FormGroup className={inputClassName} controlId="lastName">
                <FormLabel>Last Name</FormLabel>
                <FormControl
                  type="lastName"
                  value={this.state.basicInfo.lastName}
                  onChange={this.handleChange}
                />
                <div className="text-danger"><small>{(this.state.isSubmited) && this.hasErrors('lastName', 'Last Name', false, true, 'text')}</small></div>
              </FormGroup>
            </div>
            <div className="col-12">
              <FormGroup className={inputClassName} controlId="companyName">
                <FormLabel>Company Name</FormLabel>
                <FormControl
                  type="companyName"
                  value={this.state.basicInfo.companyName}
                  onChange={this.handleChange}
                />
                <div className="text-danger"><small>{(this.state.isSubmited) && this.hasErrors('companyName', 'Company Name', false, true, 'text')}</small></div>
              </FormGroup>
            </div>
            <div className="col-12">
              <FormGroup className={inputClassName} controlId="phoneNumber">
                <FormLabel>Phone Number</FormLabel>
                <PhoneInput
                  placeholder="Enter phone number"
                  value={this.state.basicInfo.phoneNumber}
                  onChange={this.handlePhoneNumberChange}
                />
              </FormGroup>
            </div>
            <div className="col-12">
              <FormGroup className={inputClassName} controlId="additionalInfo">
                <FormLabel>Additional info</FormLabel>
                <FormControl
                  type="additionalInfo"
                  componentclass="textarea"
                  value={this.state.basicInfo.additionalInfo}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </div>
            <div className="col-12">
              <FormLabel>User Role</FormLabel>
              {this.state.basicInfo && this.state.basicInfo.userRoleIds && this.state.basicInfo.userRoleIds.length > 0 &&
              this.state.basicInfo.userRoleIds.map((userRoleId, i) => (
                <React.Fragment key={userRoleId + i}>
                <div className="col-12 pl-0 pr-0 mb-3">
                  <div className="dropdown-role__container d-flex w-100">
                    <div className="dropdown-role__input">
                        <FormGroup className={inputClassName} controlId={"userRoleId" + i}>
                        
                        <Form.Control
                          as="select"
                          value={this.state.basicInfo && this.state.basicInfo.userRoleIds && this.state.basicInfo.userRoleIds[i] ? this.state.basicInfo.userRoleIds[i] : ""}
                          onChange={(e) => this.handleRoleChange(e, i)}
                          disabled={this.props.myAccountEdit}
                        >
                          <option key={""} value={""}>{"Select role.."}</option>
                          {this.getAssignableRoles(i).map((item) => (
                            <option key={item.id} value={item.id}>{item.name}</option>))}
                        </Form.Control>
                        <div className="text-danger"><small>{(this.state.isSubmited) && this.hasErrors('userRoleIds', "User Role", false, true, 'text')}</small></div>
                      </FormGroup>
                      </div>
                  {!this.props.myAccountEdit &&
                  <div className="dropdown-role__icon">
                    <button
                      type="button"
                      className="border-0 bg-transparent mb-0"
                      disabled={this.state.basicInfo.userRoleIds.length === 1}
                      onClick={() => this.handleRoleDelete(i)}>
                      <i className="fas fa-trash"></i>
                    </button>
                  </div>
                  }
                  </div>
                  </div>
                </React.Fragment>
              ))
              }
            </div>
            {!this.props.myAccountEdit &&
              <div className="col-12 mb-5">
                <button type="button" className="btn btn-primary kb-btn-save_permissions" onClick={() => this.handleAddRole()}>Add</button>
              </div>
            }
            <div className="col-12 mt-3 mb-5">
              <Button
                variant="primary"
                type="submit"
              >
                Save
              </Button>
              <Button
                type="button"
                className="ml-3 btn-light"
                variant='light'
                onClick={() => this.cancelOperation()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  }
  
}

EditUserDetailsForm.propTypes = {
  userDetails: PropTypes.object.isRequired,
  myAccountEdit: PropTypes.bool.isRequired,
};

export default withRouter(EditUserDetailsForm);
