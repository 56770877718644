import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import QuickAddContractorForm from "../../user/forms/QuickAddContractorForm";

class QuickAddContractor extends Component {

    constructor(props) {

        super(props);

        this.state = {
            addQuickContractor: false
        };

        this.toggleQuickUser = this.toggleQuickUser.bind(this);

    }

    toggleQuickUser() {
        this.setState({addQuickContractor: !this.state.addQuickContractor});
    };

    render() {
        return (
            <Fragment>
                <div className={"quick-add-contractor"}>
                    <div className="row">
                        <div className="col-12">
                            <span className={"quick-add-label"}> 
                                <span className="font-weight-bold text-primary mr-3 user-select-none">Quick add new contractor</span>
                                <button 
                                    className={"btn btn-primary quick-add-button"} 
                                    onClick={this.toggleQuickUser}
                                    >Add
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
                {this.state.addQuickContractor &&
                <QuickAddContractorForm
                    afterSubmit={() => {
                        this.props.parentRefresh();
                        this.toggleQuickUser();
                    }}
                    onCancel={this.toggleQuickUser}/>
                }
            </Fragment>
        )
    }
}

QuickAddContractor.propTypes = {
    parentRefresh: PropTypes.func.isRequired,
};

export default QuickAddContractor;
