import {genericGet} from "../../../components-shared/fetch-wrapper";

const statisticsManagement = '/statistics/';

export function getBudgetStatsData(projectId, categoryType) {
    const url = statisticsManagement + "budget/" + projectId + "/category-type/" + categoryType;

    return genericGet(url);
}

export function getCategoryTotalsForProjectAndCategoryType(projectId, categoryType) {
    const url = statisticsManagement + "project/" + projectId + "/category-type/" + categoryType;

    return genericGet(url);
}

export function getTotalBudgetForProjectAndCategoryType(projectId, categoryType) {
    const url = statisticsManagement + "total/project/" + projectId + "/category-type/" + categoryType;

    return genericGet(url);
}

export function getProfitStatsData(projectId) {
    const url = statisticsManagement + "profit/" + projectId;

    return genericGet(url);
}

export function getCashStatsData(projectId) {
    const url = statisticsManagement + "cash/" + projectId;

    return genericGet(url);
}

export function getForecastCalendarForProject(projectId, categoryType) {
    const url = statisticsManagement + "execution/calendar/forecast/project/" + projectId + "/category-type/" + categoryType;

    return genericGet(url);
}

export function getForecastsDetailedViewByProjectCategoryTypeMonthAndYear(projectId, categoryType, month, year) {
    const url = statisticsManagement + "forecast/project/" + projectId + "/category-type/" + categoryType + "/detailed/" + month + "/" + year;

    return genericGet(url);
}

export function getInvoiceCalendarForProject(projectId, categoryType) {
    const url = statisticsManagement + "execution/calendar/invoice/project/" + projectId + "/category-type/" + categoryType;

    return genericGet(url);
}

// TODO [CS] unused
export function getInvoiceCalendarForCategory(categoryId) {
    const url = statisticsManagement + "execution/calendar/invoice/category/" + categoryId;

    return genericGet(url);
}

export function getInvoicesDetailedViewByCategoryMonthAndYear(categoryId, month, year) {
    const url = statisticsManagement + "invoice/category/" + categoryId + "/detailed/" + month + "/" + year;

    return genericGet(url);
}

export function getInvoicesDetailedViewByProjectCategoryTypeMonthAndYear(projectId, categoryType, month, year) {
    const url = statisticsManagement + "invoice/project/" + projectId + "/category-type/" + categoryType + "/detailed/" + month + "/" + year;

    return genericGet(url);
}

export function getCashPositionTableData(projectId) {
    const url = statisticsManagement + "cash/position/" + projectId;

    return genericGet(url);
}
