import React, {Component} from "react";
import PropTypes from "prop-types";
import {prettyPrint} from "../../../../common/numberOperations";

class BudgetExecutionTotalsView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            initialBudget: null,
            executionBudget: null,
            committed: null,
            invoiced: null,
            toBeInvoiced: null,
            unCommitted: null,
            forecast: null,
            total: null,
            status: null,
        };

    }

    componentDidMount() {
        const hasExecution = this.hasExecution();
        const initialBudget = this.getInitialBudget();
        const executionBudget = this.getExecutionBudget();
        const reimbursement = this.getReimbursement();
        const drawdown = this.getDrawdown() - reimbursement;
        this.setState({
            initialBudget: initialBudget,
            executionBudget: executionBudget,
            committed: this.getCommitted(),
            invoiced: this.getInvoiced(),
            toBeInvoiced: this.getToBeInvoiced(),
            unCommitted: this.getUncommitted(),
            forecast: this.getForecast(),
            total: this.getTotal(),
            status: this.getStatus(),
            drawdown: drawdown,
            remainingBalance: this.getRemainingBalance(hasExecution, initialBudget, executionBudget, drawdown),
            outstandingBalance: this.getOutstandingBalance(hasExecution, initialBudget, executionBudget, reimbursement),
            loanToCost: this.getLoanToCost(drawdown),
        })
    }

    hasExecution() {
        return this.props.categories
            .flatMap(category => category.subCategoriesEndOfMonthBalance)
            .map(category => category.executionBudget)
            .find(execution => execution > 0) !== undefined
    }

    getLoanToCost(drawdown) {
        return drawdown / this.props.costTotal * 100
    }

    getOutstandingBalance(hasExecution, initialBudget, executionBudget, reimbursement) {
        const budget = hasExecution ? executionBudget : initialBudget;
        return budget - reimbursement;
    }

    getRemainingBalance(hasExecution, initialBudget, executionBudget, drawdown) {
        const budget = hasExecution ? executionBudget : initialBudget;
        return budget - drawdown;
    }

    getDrawdown() {
        return this.props.categories.map(category=>category.drawdown).reduce(function (acumulator, budget) {
            return acumulator + budget;
        }, 0);
    }

    getReimbursement() {
        return this.props.categories.map(category=>category.reimbursement).reduce(function (acumulator, budget) {
            return acumulator + budget;
        }, 0);
    }

    sumReducer() {
        return function (acumulator, budget) {
            return acumulator + budget;
        };
    }

    getExecutionBudget() {
        return this.props.categories
            .map(category => category.executionBudget ? category.executionBudget : 0)
            .reduce(this.sumReducer(), 0)
    }

    getInitialBudget() {
        return this.props.categories
            .map(category => category.initialBudget ? category.initialBudget : 0)
            .reduce(this.sumReducer(), 0)
    }

    getCommitted() {
        return this.props.categories
            .map(category => category.committed ? category.committed : 0)
            .reduce(this.sumReducer(), 0)
    }

    getInvoiced() {
        return this.props.categories
            .map(category => category.invoiced ? category.invoiced : 0)
            .reduce(this.sumReducer(), 0)
    }

    getToBeInvoiced() {
        return this.props.categories
            .map(category => category.toBeInvoiced ? category.invoiced : 0)
            .reduce(this.sumReducer(), 0)
    }

    getUncommitted() {
        return this.props.categories
            .map(category => category.uncommitted ? category.uncommitted : 0)
            .reduce(this.sumReducer(), 0)
    }

    getForecast() {
        return this.props.categories
            .map(category => category.forecast ? category.forecast : 0)
            .reduce(this.sumReducer(), 0)
    }

    getTotal() {
        return this.props.categories
            .map(category => category.total ? category.total : 0)
            .reduce(this.sumReducer(), 0)
    }

    getStatus(hasExecution) {
        return this.props.categories
            .map(category => category.status ? category.status : 0)
            .reduce(this.sumReducer(), 0)
    }

    render() {
        return (
            <div className="kb-category-entry kb-real-time-budget-border-line">
                <div className="kb-table kb-totals-view kb-category-view">
                    <div className="row kb-category-view_values">
                        <div className={"col-4 kb-col-name"}>
                            <span className={"table-row-name-total-fragment"}>
                                Total
                            </span>
                        </div>
                        <div className={"col table-col"} data-label="Total Initial budget">
                            <span className="d-none">Total Initial budget</span>
                            {this.state.initialBudget ? prettyPrint(this.state.initialBudget) : 0}
                        </div>
                        <div className={"col table-col"} data-label="Total Execution budget">
                            <span className="d-none">Total Execution budget</span>
                            {this.state.executionBudget ? prettyPrint(this.state.executionBudget) : 0}
                        </div>
                        {(this.props.categoryType === 'COST' || this.props.categoryType === 'INCOME') &&
                        <React.Fragment>
                            <div className={"col table-col"} data-label="Total Committed">
                                <span className="d-none">Total Committed</span>
                                {prettyPrint(this.state.committed)}
                            </div>
                            <div className={"col table-col"} data-label="Total Invoiced">
                                <span className="d-none">Total Invoiced</span>
                                {prettyPrint(this.state.invoiced)}
                            </div>
                            <div className={"col table-col"} data-label="Total To be Invoiced">
                                <span className="d-none">Total To be Invoiced</span>
                                {prettyPrint(this.state.toBeInvoiced)}
                            </div>
                            <div className={"col table-col"} data-label="Total Uncommitted">
                                <span className="d-none">Total Uncommitted</span>
                                {prettyPrint(this.state.unCommitted)}
                            </div>
                            <div className={"col table-col"} data-label="Total Forecast">
                                <span className="d-none">Total Forecast</span>
                                {prettyPrint(this.state.forecast)}
                            </div>
                            <div className={"col table-col"} data-label="Total">
                                <span className="d-none">Total</span>
                                {prettyPrint(this.state.total)}
                            </div>
                            {this.state.status && this.state.status < 0 ?
                                <div className={"col table-col kb-real-time-budget-overflow"} data-label="Total Status">
                                    <span className="d-none">Total Status</span>
                                    {this.state.status ? prettyPrint(this.state.status) : 0}
                                </div>
                                :
                                <div className={"col table-col"} data-label="Total Status">
                                    <span className="d-none">Total Status</span>
                                    {this.state.status ? prettyPrint(this.state.status) : 0}
                                </div>
                            }
                        </React.Fragment>
                        }
                        {(this.props.categoryType === 'FINANCING' || this.props.categoryType === 'INVESTMENT') &&
                        <React.Fragment>
                            <div className={"col table-col"} data-label="Total Contribution Per Entity">
                                <span className="d-none">Total Contribution Per Entity</span>
                                {this.state.budgetComponentBudget !== 0 ? '100%' : '0%'}
                            </div>
                            <div className={"col table-col"} data-label="Total Drawdown">
                                <span className="d-none">Total Drawdown</span>
                                {prettyPrint(this.state.drawdown)}
                            </div>
                            <div className={"col table-col"} data-label="Total Remaining Balance">
                                <span className="d-none">Total Remaining Balance</span>
                                {prettyPrint(this.state.remainingBalance)}
                            </div>
                            <div className={"col table-col"} data-label="Total Outstanding Balance">
                                <span className="d-none">Total Outstanding Balance</span>
                                {prettyPrint(this.state.outstandingBalance)}
                            </div>
                            <div className={"col table-col"} data-label="Total Loan To Cost">
                                <span className="d-none">Total Loan To Cost</span>
                                {prettyPrint(this.state.loanToCost)}
                            </div>
                        </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

BudgetExecutionTotalsView.propTypes = {
    categoryType: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        parentId: PropTypes.string,
        projectId: PropTypes.string,
        initialBudget: PropTypes.number,
        executionBudget: PropTypes.number,
        committed: PropTypes.number,
        invoiced: PropTypes.number,
        remainingForecast: PropTypes.number,
        cashFlowPrognosis: PropTypes.number,
    })).isRequired,
    invoices: PropTypes.array,
    contracts: PropTypes.array,
    forecasts: PropTypes.array
};

export default BudgetExecutionTotalsView;