import {Button} from "react-bootstrap";
import React from "react";
import PropTypes from "prop-types";
import {exportInvoices} from "../actions/invoicesActions"
import {confirmAlert} from "react-confirm-alert";

export default function InvoicesExcelExport(props) {


    const exportInvoicesToExcelAction = (projectId, categoryType, filters ) => {

        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to export invoices list in .xlsx format ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                       retrieveExportedInvoices(projectId, categoryType, filters);
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const retrieveExportedInvoices = (projectId, categoryType, filters) => {
        let excelFile = {};
        let invoiceExportData = {};

        invoiceExportData.invoiceType = categoryType;
        invoiceExportData.invoiceFilter = {};

        if(filters.filter === "invoiceNo" && filters.keyword) {
            invoiceExportData.invoiceFilter.invoiceNo = filters.keyword;
        }

        if(filters.filter === "clientOrSupplierName" && filters.keyword) {
            invoiceExportData.invoiceFilter.clientOrSupplierName = filters.keyword;
        }

        if(filters.filter === "ibCode" && filters.keyword) {
            invoiceExportData.invoiceFilter.ibCode = filters.keyword;
        }

        if(filters.filter === "contractNo" && filters.keyword) {
            invoiceExportData.invoiceFilter.contractNo = filters.keyword;
        }

        if(filters.date === "emissionDateInterval") {
            invoiceExportData.invoiceFilter.emissionDateInterval = {};
            if(filters.fromDate) {
                invoiceExportData.invoiceFilter.emissionDateInterval.from = new Date(filters.fromDate);
            }
            if(filters.toDate) {
                invoiceExportData.invoiceFilter.emissionDateInterval.to = new Date(filters.toDate);
            }
        }


        if(filters.date === "maturityDateInterval") {
            invoiceExportData.invoiceFilter.maturityDateInterval = {};
            if(filters.fromDate) {
                invoiceExportData.invoiceFilter.maturityDateInterval.from = new Date(filters.fromDate);
            }
            if(filters.toDate) {
                invoiceExportData.invoiceFilter.maturityDateInterval.to = new Date(filters.toDate);
            }
        }


        if(filters.date === "paymentDateInterval") {
            invoiceExportData.invoiceFilter.paymentDateInterval = {};
            if(filters.fromDate) {
                invoiceExportData.invoiceFilter.paymentDateInterval.from = new Date(filters.fromDate);
            }
            if(filters.toDate) {
                invoiceExportData.invoiceFilter.paymentDateInterval.to = new Date(filters.toDate);
            }
        }


        if(filters.date === "budgetDateInterval") {
            invoiceExportData.invoiceFilter.budgetDateInterval = {};
            if(filters.fromDate) {
                invoiceExportData.invoiceFilter.budgetDateInterval.from = new Date(filters.fromDate);
            }
            if(filters.toDate) {
                invoiceExportData.invoiceFilter.budgetDateInterval.to = new Date(filters.toDate);
            }
        }
        invoiceExportData.offset = new Date().getTimezoneOffset();

        exportInvoices(projectId, invoiceExportData).then((response) =>  {
            const filename =  "Kybud_Invoices.xlsx"

            if(response.ok === false || response.code === 18003) {
                confirmAlert({
                    title: 'Notification message',
                    message: 'Invoices not present, file can not be exported !',
                    buttons: [
                        {
                            label: 'Ok'
                        }
                    ]
                });
            } else {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let link = document.createElement('a');
                    link.href = url;
                    link.download = filename;
                    link.click();
                });
            }
        })

        return excelFile;
    }

    return (
        <Button variant="secondary" onClick={() => exportInvoicesToExcelAction(props.projectId, props.categoryType, props.filters)}>
            <i className="fas fa-file-download text-white mr-2"></i>Export</Button>
    )
}

InvoicesExcelExport.propTypes = {
    projectId: PropTypes.string.isRequired,
    categoryType: PropTypes.string.isRequired,
    filters: PropTypes.shape({
        filter: PropTypes.string,
        keyword: PropTypes.string,
        date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        fromDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        toDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
};
