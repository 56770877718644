import React, {Component} from "react";
import {
    getCategories,
    moveCategoryDown,
    moveCategoryUp,
    removeCategory,
    updateCategory
} from "./actions/categoriesActions";
import PropTypes from 'prop-types';
import CategoryManagementComponentView from "./view/CategoryManagementComponentView";
import CategoryTotalManagementComponentView from "./view/CategoryTotalManagementComponentView";
import {getCategoryTotals, removeCategoryTotal, updateCategoryTotal} from "./actions/categoriesTotalsActions";
import CollapsiblePageSection from "../../components-shared/CollapsiblePageSection";
import {confirmAlert} from 'react-confirm-alert';
import {toast} from "react-toastify";
import ProjectUnauthorizedPanel from "../../components-shared/ProjectUnauthorizedPanel";
import {categoryEntityType} from "./constant/CategoryConstants";
import {prettyPrint} from "../common/numberOperations";
import './../../assets/styles/category.css'

class CategoryManagementComponentContainer extends Component {

    constructor(props) {

        super(props);

        this.state = {
            categories: [],
            categoryTotals: [],
            error: '',
        };

        this.refreshCategoryTotalsList = this.refreshCategoryTotalsList.bind(this);
        this.refreshCategoriesList = this.refreshCategoriesList.bind(this);

        this.onCategorySubmit = this.onCategorySubmit.bind(this);
        this.onCategoryDeleteAction = this.onCategoryDeleteAction.bind(this);
        this.onCategoryDelete = this.onCategoryDelete.bind(this);
        this.onCategoryEdit = this.onCategoryEdit.bind(this);

        this.updateCategoryTotalsList = this.updateCategoryTotalsList.bind(this);

        this.onCategoryTotalSubmit = this.onCategoryTotalSubmit.bind(this);
        this.onCategoryTotalDeleteAction = this.onCategoryTotalDeleteAction.bind(this);
        this.onCategoryTotalDelete = this.onCategoryTotalDelete.bind(this);
        this.onCategoryTotalEdit = this.onCategoryTotalEdit.bind(this);

        this.onMoveUp = this.onMoveUp.bind(this);
        this.onMoveDown = this.onMoveDown.bind(this);
        this.setCategories = this.setCategories.bind(this);
        this.componentIsMounted = false;
    };

    componentDidMount() {
        this.componentIsMounted = true;
        const entityId = this.props.entityId;
        if (this.props.setCategory) {
            this.props.setCategory(this.props.categoryType.type.toLowerCase(), 0)
        }

        if (entityId) {
            this.refreshCategoriesList();
            this.refreshCategoryTotalsList();
        }
    }

    componentWillUnmount () {
        this.componentIsMounted = false;
    }
  
  refreshCategoriesList () {
    getCategories(this.props.entityId, this.props.categoryType.type).then((categories) => {
      if (categories.code) {
        return this.setState({error: categories.message});
      } else {
        if (this.componentIsMounted) {
            this.setState({
            categories: categories,
            error: ''
            }, () => {
            if (this.props.setCategory) {
                this.props.setCategory(this.props.categoryType.type.toLowerCase(), categories ? categories.length : 0)
            }
            })
        }
      }
    });
  }
  
  componentDidUpdate(prevProps, prevState) {
        if (prevState.categories !== this.state.categories) {
            if (this.props.setCategory) {
                this.props.setCategory(this.props.categoryType.type.toLowerCase(), this.state.categories ? this.state.categories.length : 0)
            }
        }
    }

    refreshCategoryTotalsList() {

        if (this.props.categoryType.categoryTotalManagementAvailable) {
            getCategoryTotals(this.props.entityId, this.props.categoryType.type).then((categoryTotals) => {
                if (this.componentIsMounted) {
                    if (categoryTotals.code) {
                        return this.setState({error: categoryTotals.message});
                    } else {
                        this.setState({
                            categoryTotals: categoryTotals,
                            error: ''
                        })
                    }
                }
            });
        }
    }

    onCategorySubmit(categoryObject) {

        const categories = this.state.categories.slice();

        let {toUpdateList, parentCategory} = this.findTheCategoryInCategoriesList(categories, categoryObject);

        toUpdateList.push(categoryObject);
        if (parentCategory) {
            parentCategory.children = toUpdateList;
        }
        this.setState({categories: categories, error: ''});

        this.updateCategoryTotalsList(categoryObject);
  
        this.refreshCategoriesList();
    }

    onCategoryDeleteAction(categoryObject) {

        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to delete "' + categoryObject.name + '"',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.onCategoryDelete(categoryObject)
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
        });
    }

    onCategoryDelete(categoryObject) {

        const categories = this.state.categories.slice();

        let {toUpdateList, parentCategory, index} = this.findTheCategoryInCategoriesList(categories, categoryObject);

        removeCategory(categoryObject.id).then((result) => {
            if (this.componentIsMounted) {
                if (result.status !== 200) {
                    toast.error("Cannot delete category!", {position: toast.POSITION.BOTTOM_RIGHT});
                    return this.setState({error: result.message});
                } else {
                    toUpdateList.splice(index, 1);
                    if (parentCategory) {
                        parentCategory.children = toUpdateList;
                        this.updateCategoryValue(parentCategory);
                    }
                    this.setState({categories: categories, error: ''});
                    this.updateCategoryTotalsList(categoryObject);
                    this.refreshCategoriesList();
                    if (categoryObject.type === 'COST') {
                        this.props.onCostUpdate(categories);
                    }
                }
            }
        });
    }

    onCategoryEdit(categoryObject, newState) {

        const categories = this.state.categories.slice();
        let {toUpdateList, parentCategory, index} = this.findTheCategoryInCategoriesList(categories, categoryObject);

        const request = Object.assign(categoryObject, newState);
        updateCategory(request).then(result => {
            if (this.componentIsMounted) {
                if (result.code) {
                    return result.message;
                } else {
                    // add or edit category does not populate the children field
                    result.children = toUpdateList[index].children;
                    toUpdateList.splice(index, 1, result);
                    if (parentCategory) {
                        parentCategory.children = toUpdateList;
                        this.updateCategoryValue(parentCategory);
                    }
                    this.setState({categories: categories, error: ''});
                    this.updateCategoryTotalsList(result);
                }
            }
        });
        if (this.props.entityType !== 'Template' && this.props.categoryType.type === "COST") {
            this.props.onCostUpdate(categories);
        }
        return "";
    }

    updateCategoryTotalsList(categoryObject) {
        if (!categoryObject.parentId) {
            this.refreshCategoryTotalsList();
        }
    }

    findTheCategoryInCategoriesList(categories, categoryObject) {

        //toUpdateList = list to which an action will be performed with the categoryObject
        let toUpdateList = categories;
        //category = the element which contains the list. if null, then root contains the list
        let parentCategory = null;

        //if the category Object is not in the first list (a category)
        //identify the list and category
        if (categoryObject.parentId) {
            const index = toUpdateList.findIndex(category => {
                return category.id === categoryObject.parentId;
            });
            parentCategory = toUpdateList[index];
            toUpdateList = parentCategory.children || [];
        }

        //identify the index of the element which needs to be deleted
        const index = toUpdateList.findIndex((category) => {
            return category.id === categoryObject.id;
        });
        return {toUpdateList: toUpdateList, parentCategory: parentCategory, index: index};
    }

    updateCategoryValue(categoryObject) {

        let subcategoriesInitialValue = 0;
        let subcategoriesExecutionValue = 0;
        const children = categoryObject.children || [];
        children.forEach(category => {
            subcategoriesInitialValue += category.initialBudget;
            subcategoriesExecutionValue += category.executionBudget;
        });
        categoryObject.initialBudget = subcategoriesInitialValue;
        categoryObject.executionBudget = subcategoriesExecutionValue;
    }

    onCategoryTotalSubmit(categoryTotalObject) {

        categoryTotalObject.assignableCategories = this.state.categories.slice();
        const categoryTotals = this.state.categoryTotals.slice();
        categoryTotals.push(categoryTotalObject);
        this.setState({categoryTotals: categoryTotals, error: ''});
    }

    onCategoryTotalDeleteAction(categoryTotalObject) {

        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to delete "' + categoryTotalObject.name + '" ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.onCategoryTotalDelete(categoryTotalObject)
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
        });
    }

    onCategoryTotalDelete(categoryTotalObject) {

        const categoryTotals = this.state.categoryTotals.slice();
        const index = categoryTotals.findIndex(categoryTotal => {
            return categoryTotal.id === categoryTotalObject.id;
        });
        removeCategoryTotal(categoryTotalObject.id).then((result) => {
            if (this.componentIsMounted) {
                if (result.status !== 200) {
                    toast.error("Cannot delete category total!", {position: toast.POSITION.BOTTOM_RIGHT});
                    return this.setState({error: result.message});
                } else {
                    categoryTotals.splice(index, 1);
                    this.setState({categoryTotals: categoryTotals, error: ''})
                }
            }
        });
    }

    onCategoryTotalEdit(categoryTotalObject, newState) {

        const categoryTotals = this.state.categoryTotals.slice();
        const index = categoryTotals.findIndex(categoryTotal => {
            return categoryTotal.id === categoryTotalObject.id;
        });
        const request = Object.assign({}, Object.assign(categoryTotalObject, newState));
        delete request["assignedCategories"];
        delete request["assignableCategories"];
        updateCategoryTotal(request).then(result => {
            if (this.componentIsMounted) {
                if (result.code) {
                    return result.message;
                } else {
                    result.assignedCategories = categoryTotalObject.assignedCategories;
                    result.assignableCategories = categoryTotalObject.assignableCategories;
                    categoryTotals.splice(index, 1, result);
                    this.setState({categoryTotals: categoryTotals, error: ''})
                }
            }
        });
        return "";
    }

    onMoveUp(categoryObject) {

        const categories = this.state.categories.slice();
        let {toUpdateList, parentCategory, index} = this.findTheCategoryInCategoriesList(categories, categoryObject);

        moveCategoryUp(categoryObject.id).then(result => {
            if (this.componentIsMounted) {
                if (result.code) {
                    toast.error("Error on request. \"" + result.message + "\"", {position: toast.POSITION.BOTTOM_RIGHT});
                    return result.message;
                } else {

                    if (index > 0) {
                        toast.info("Category moved!", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000});

                        toUpdateList.splice(index, 1);
                        toUpdateList.splice(index - 1, 0, categoryObject);
                        if (parentCategory) {
                            parentCategory.children = toUpdateList;
                        }
                        this.setState({categories: categories, error: ''});
                        this.refreshCategoriesList();
                    }
                }
            }
        });
    }

    onMoveDown(categoryObject) {

        const categories = this.state.categories.slice();
        let {toUpdateList, parentCategory, index} = this.findTheCategoryInCategoriesList(categories, categoryObject);

        moveCategoryDown(categoryObject.id).then(result => {
            if (this.componentIsMounted) {
                if (result.code) {
                    toast.error("Error on request. \"" + result.message + "\"", {position: toast.POSITION.BOTTOM_RIGHT});
                    return result.message;
                } else {

                    if (index < toUpdateList.length - 1) {
                        toast.info("Category moved!", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000});

                        toUpdateList.splice(index, 1);
                        toUpdateList.splice(index + 1, 0, categoryObject);
                        if (parentCategory) {
                            parentCategory.children = toUpdateList;
                        }
                        this.setState({categories: categories, error: ''});
                        this.refreshCategoriesList();
                    }
                }
            }
        });
    }
    
    setCategories(categories) {
        this.setState({ categories: categories });
    }

    render() {
        const projectId = this.props.entityId;

        if (this.state.error) {
            return <ProjectUnauthorizedPanel error={this.state.error} projectId={projectId}/>;
        }
        let totalSectionInitialBudget = 0;
        let totalSectionExecutionBudget = 0;
        if(this.props.displayTotal) {
            this.state.categories && this.state.categories.forEach(cat => {
                if (cat.initialBudget && typeof(cat.initialBudget) == 'number') {
                    totalSectionInitialBudget = totalSectionInitialBudget + cat.initialBudget;
                    totalSectionExecutionBudget = totalSectionExecutionBudget + cat.executionBudget;
                }
            });
        }
    

        return (

            <div className="row">
                <div className={(this.props.entityType === categoryEntityType.templateCategory) ? "col-lg-6 col-xl-7" : "col-lg-12 col-xl-12"}>
                    <CollapsiblePageSection title={
                        <React.Fragment>
                            <div className="row">                        
                                <div className={this.props.displayTotal ? "col" : "col-12"}>
                                    Categories
                                    <span className="kb-name_badge text-primary ml-2">{this.state.categories ? this.state.categories.length : 0}</span>
                                </div>
                                { (this.props.categoryType.type === 'FINANCING' || this.props.categoryType.type === 'INVESTMENT') &&
                                this.props.entityType === 'Project' &&
                                <div className="col total-budget">
                                    <span>Total Budget:</span>
                                    <span> {this.props.totalCostExecutionBudget ?
                                        prettyPrint(this.props.totalCostExecutionBudget) : prettyPrint(this.props.totalCostInitialBudget)}</span>
                                </div>
                                }
                            </div>
                        </React.Fragment>}
                        collapsed={this.props.collapsed}
                    >

                        <div className="kb-view_categories">
                          {this.props.displayTotal ?
                            <div className="row ml-0 w-100">

                                {this.props.categoryType.type === 'COST' &&
                                    <React.Fragment>
                                        <div className="col-5 pr-0 pr-0"></div>
                                        <div className="col-1 pr-0"></div>
                                        <div className="col-1 pr-0"></div>
                                        <div className="col-1 pr-0 pr-0">IB Code</div>
                                        <div className="col-1 pr-0 pr-0">Distribution period</div>
                                        <div className="col-1 pr-0 pr-0">Initial</div>
                                        <div className="col-1 pr-0 pr-0">Execution</div>

                                        <div className="col-1 pr-0 pr-0"></div>
                                    </React.Fragment>
                                }
                                {this.props.categoryType.type === 'INCOME' &&
                                    <React.Fragment>
                                        <div className="col-5 pr-0 pr-0"></div>
                                        <div className="col-1 pr-0"></div>
                                        <div className="col-1 pr-0"></div>
                                        <div className="col-1 pr-0 pr-0">IB Code</div>
                                        <div className="col-1 pr-0 pr-0">Distribution period</div>
                                        <div className="col-1 pr-0 pr-0">Initial</div>
                                        <div className="col-1 pr-0 pr-0">Execution</div>

                                        <div className="col-1 pr-0 pr-0"></div>
                                    </React.Fragment>
                                }
                                { this.props.categoryType.type === 'FINANCING' &&
                                    <React.Fragment>
                                        <div className="col-2 pr-0 pr-0"></div>

                                        <div className="col-1 pr-0">Maturity Date</div>
                                        <div className="col-1 pr-0">Availability</div>
                                        <div className="col-1 pr-0">Interest Type</div>
                                        <div className="col-1 pr-0">Interest Rate</div>
                                        <div className="col-1 pr-0 pr-0">Distribution period</div>
                                        <div className="col-1 pr-0 pr-0">Initial Amount</div>
                                        <div className="col-1 pr-0 pr-0">Initial Percentage</div>
                                        <div className="col-1 pr-0 pr-0">Execution Amount</div>
                                        <div className="col-1 pr-0 pr-0">Execution Percentage</div>

                                        <div className="col-1 pr-0 pr-0"></div>
                                    </React.Fragment>
                                }
                                { this.props.categoryType.type === 'INVESTMENT' &&
                                <React.Fragment>
                                    <div className="col-2 pr-0 pr-0"></div>

                                    <div className="col-1 pr-0">Maturity Date</div>
                                    <div className="col-1 pr-0">Investment Date</div>
                                    <div className="col-1 pr-0">Interest Type</div>
                                    <div className="col-1 pr-0">Interest Rate</div>
                                    <div className="col-1 pr-0 pr-0">Distribution period</div>
                                    <div className="col-1 pr-0 pr-0">Initial Amount</div>
                                    <div className="col-1 pr-0 pr-0">Initial Percentage</div>
                                    <div className="col-1 pr-0 pr-0">Execution Amount</div>
                                    <div className="col-1 pr-0 pr-0">Execution Percentage</div>

                                    <div className="col-1 pr-0 pr-0"></div>
                                </React.Fragment>
                                }

                            </div>
                           :
                           <div className="row">
                               <div className="col-6 pr-0"></div>
                               { (this.props.categoryType.type === 'COST' || this.props.categoryType.type === 'INCOME') ?
                                 <div className="col-2 pr-0">IB Code</div> : <div className="col-2 pr-0"></div>
                               }
                               <div className="col-2 pr-0">Distribution Period</div> 
                               
                               <div className="col-2 pr-0 pl-0"></div>
                           </div>
                          }
                            <CategoryManagementComponentView
                                categories={this.state.categories}
                                projectId={projectId}
                                onSubmit={this.onCategorySubmit}
                                onDelete={this.onCategoryDeleteAction}
                                onEdit={this.onCategoryEdit}
                                onMoveUp={this.onMoveUp}
                                onMoveDown={this.onMoveDown}
                                categoryType={this.props.categoryType}
                                entityType={this.props.entityType}
                                totalCostExecutionBudget={this.props.totalCostExecutionBudget}
                                totalCostInitialBudget={this.props.totalCostInitialBudget}
                            />
                        </div>

                    </CollapsiblePageSection>
                </div>
                    {this.props.categoryType.categoryTotalManagementAvailable &&
                        <div className={(this.props.entityType === categoryEntityType.templateCategory) ? "col-lg-6 col-xl-5" : "col-lg-12 col-xl-12"}>
                            <CollapsiblePageSection title={
                                <React.Fragment>
                                    {"Totals Configuration"}
                                    <span className="kb-name_badge text-primary ml-2">{this.state.categories ? this.state.categoryTotals.length : 0}</span>
                                </React.Fragment>}
                                collapsed={this.props.collapsed}
                            >

                                <CategoryTotalManagementComponentView categoryTotals={this.state.categoryTotals}
                                                                    projectId={projectId}
                                                                    onSubmit={this.onCategoryTotalSubmit}
                                                                    onDelete={this.onCategoryTotalDeleteAction}
                                                                    onEdit={this.onCategoryTotalEdit}
                                                                    categoryType={this.props.categoryType}
                                                                    refreshCategoryTotalsList={this.refreshCategoryTotalsList}/>
                            </CollapsiblePageSection>
                        </div>
                    }
            </div>

        );
    }
}

CategoryManagementComponentContainer.propTypes = {
    entityId: PropTypes.string.isRequired,
    categoryType: PropTypes.shape({
        displayValue: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        categoryTotalManagementAvailable: PropTypes.bool.isRequired
    }).isRequired,
    entityType: PropTypes.string.isRequired,
    totalCostInitialBudget: PropTypes.number,
    totalCostExecutionBudget: PropTypes.number,
    onCostUpdate: PropTypes.func
};

// componentType = category or subcategory
// categoryType = COST, INCOME, FINANCING or INVESTMENT
// entityType = Project or Template

export default CategoryManagementComponentContainer;
