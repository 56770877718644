import React from "react";

import {Button, FormLabel, FormControl, FormGroup} from "react-bootstrap";
import PropTypes from "prop-types";

export default function ViewTemplateForm(props) {

    const inputClassName = "";
    // if the key is not put here on the top level div, then react will not re-render this component after the template
    // info gets reloaded.
    // the successions of events that happen:
    //  - the template is edited
    //  - the template is saved
    //  - refresh is triggered in Container
    //  - the view re-renders with editing=false
    //  - the Container sends down the props
    // somehow, i think that the last 2 steps are happening too fast, or react just does not know how to
    // compare the dom of the 2 forms, and it won't trigger the fifth step render, the Container render, only the local
    // state render, the fourth step.
    const defaultNameValue = props.template ? props.template.name : "";
    const defaultDescriptionValue = props.template ? props.template.description : "";
    return (
        <div key={defaultNameValue + defaultDescriptionValue} className="kb-template-form">
            <FormGroup className={inputClassName} controlId="name">
                <FormLabel>Project Name</FormLabel>
                <FormControl
                    type="projectName"
                    defaultValue={defaultNameValue}
                    disabled={true}
                />
            </FormGroup>

            <FormGroup className={inputClassName} controlId="description">
                <FormLabel>Description</FormLabel>
                <FormControl
                    componentclass="textarea"
                    type="description"
                    defaultValue={defaultDescriptionValue}
                    disabled={true}
                />
            </FormGroup>

            <Button variant="primary" type="submit" onClick={props.editClickedFunc}> Edit </Button>
        </div>
    );
}

ViewTemplateForm.propTypes = {
    template: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string
    }),
    editClickedFunc: PropTypes.func.isRequired
};
