import React, {Component} from "react";
import PropTypes from "prop-types";
import ForecastSplitCell from "./ForecastSplitCell";
import Moment from "moment";
import {isNullOrUndefined} from "../../../common/checks";

class ForecastSplitTable extends Component {
    constructor(props) {
        super(props);
        this.getInvoicedValue = this.getInvoicedValue.bind(this);
        this.getToBeInvested = this.getToBeInvested.bind(this);
        this.isCurrentMonth = this.isCurrentMonth.bind(this);
        this.computeMonthExtraDetail = this.computeMonthExtraDetail.bind(this);
    }
    /**
     * Get the invoiced sum for the forecast month and year by searching for it in invoicedPerMonthArray.
     * Not found means there are no invoices for the respective month so display 0.
     * invoicedPerMonthArray is not present for remaining forecasts so display empty string.
     *
     * @param forecast the forecast with month and year
     * @returns {string} invoiced per month value displayed
     */
    getInvoicedValue(forecast) {

        if (this.props.invoicedPerMonthArray) { //this is set only for contract forecasts
            let invoicedPerMonth = this.props.invoicedPerMonthArray.find(
                invoiced => invoiced.month === forecast.monthAsInteger && invoiced.year === forecast.year
            );
            return invoicedPerMonth ? invoicedPerMonth.amount + '' : '0';
        }
        return '';
    }

    /**
     * Get to be invested value. It basically represents the cash position for each month.
     * If the value is positive (everything is all right)
     * If the value is negative (that much amount still needs to be invested)
     *
     * @param forecast
     * @returns {string}
     */
    getToBeInvested(forecast) {

        if (!isNullOrUndefined(this.props.toBeInvestedCashPosition)) { // this is set only for remaining forecasts (investment)

            const {toBeInvestedCashPosition} = this.props;

            let toBeInvestedPerMonth = Object.keys(toBeInvestedCashPosition).map(function (key) {

                let cashPotionValue = toBeInvestedCashPosition[key];

                if (cashPotionValue.monthAsInteger === forecast.monthAsInteger && cashPotionValue.year === forecast.year) {
                    return cashPotionValue;
                }
                return null;
            }).filter((element) => {
                return element != null;
            });
            toBeInvestedPerMonth = toBeInvestedPerMonth ? toBeInvestedPerMonth.filter((element) => {
                return element != null;
            }) : undefined;

            return toBeInvestedPerMonth && toBeInvestedPerMonth[0] ? toBeInvestedPerMonth[0].cumulatedNet + '' : '0';
        }
        return '';
    }

    isCurrentMonth(forecast) {
        // moment months are zero indexed
        return this.props.currentDate.month() + 1 === forecast.monthAsInteger && this.props.currentDate.year() === forecast.year;
    }

    /**
     * MonthExtraDetail explained:
     *
     * for Contract Forecasts => invoiced value
     * for Remaining Forecasts => toBeInvested value
     * for prognosis => nothing
     *
     * @param forecast
     * @returns {string|undefined}
     */
    computeMonthExtraDetail(forecast) {

        if (!isNullOrUndefined(this.props.invoicedPerMonthArray)) {
            return this.getInvoicedValue(forecast)
        }

        if (!isNullOrUndefined(this.props.toBeInvestedCashPosition)) {
            return this.getToBeInvested(forecast)
        }

        return undefined;
    }

    render() {
        return (
            <div className="collapse show w-100">
            <div className={"container-fluid kb-months w-100" + (this.props.noYPadding ? "" : "py-4")}>
                <div className="row justify-content-center w-100 ml-0">
                    {this.props.forecasts &&
                    this.props.forecasts.map((forecast) => {
                        return forecast && (
                            <ForecastSplitCell
                                comma2={this.props.comma2}
                                key={'ForecastSplitCell' + forecast.id}
                                forecast={forecast}
                                monthExtraDetail={this.computeMonthExtraDetail(forecast)}
                                toBeForecasted={this.props.toBeForecasted}
                                forecastType={this.props.forecastType}
                                contractId={this.props.contractId}
                                forecastUpdated={this.props.forecastUpdated}
                                cashPositionUpdated={this.props.cashPositionUpdated}
                                onSplitSave={this.props.onSplitSave}
                                onMoveRight={this.props.onMoveRight}
                                isCurrentMonth={this.isCurrentMonth(forecast)}
                                invoicesRequestParams={this.props.invoicesRequestParams}
                                type={this.props.type}
                                category={this.props.category}
                                readOnly={this.props.readOnly}
                                cashPosition={this.props.cashPosition}
                                cashPositionValues={this.props.cashPositionValues}
                                contracts={this.props.contracts}
                                categoryType={this.props.categoryType}
                                openedMonths={this.props.openedMonths}
                                activeTab={this.props.activeTab ? this.props.activeTab: this.props.type}
                            />);
                    })
                    }
                </div>
            </div>
            </div>
        )
    }
}

ForecastSplitTable.propTypes = {
    forecasts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            amount: PropTypes.number,
            month: PropTypes.string.isRequired,
            monthAsInteger: PropTypes.number.isRequired,
            year: PropTypes.number.isRequired
        }).isRequired
    ),
    type: PropTypes.string,
    category: PropTypes.string,
    forecastType: PropTypes.string.isRequired,
    toBeForecasted: PropTypes.number.isRequired,
    contractId: PropTypes.string,
    invoicedPerMonthArray: PropTypes.array,
    toBeInvestedCashPosition: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    forecastUpdated: PropTypes.func.isRequired,
    cashPositionUpdated: PropTypes.func,
    onSplitSave: PropTypes.func.isRequired,
    onMoveRight: PropTypes.func.isRequired,
    currentDate: PropTypes.instanceOf(Moment).isRequired,
    invoicesRequestParams: PropTypes.shape({
        projectId: PropTypes.string,
        categoryName: PropTypes.string,
        contractId: PropTypes.string,
        contractorName: PropTypes.string,
    }),
    contracts: PropTypes.array,
    cashPosition: PropTypes.bool,
    categoryType: PropTypes.string,
    openedMonths: PropTypes.array
};

export default ForecastSplitTable;
