import React from "react";
import PropTypes from 'prop-types';

export default function ExpandCollapseArrow(props) {
    // noinspection CheckTagEmptyBody
    return (
        <React.Fragment>
            {props.toggleState ?
                <div className={"collapse-arrow"} onClick={props.toggleEvent}>
                    <i className="fas fa-plus"></i>
                </div>
                :
                <div className={"collapse-arrow"} onClick={props.toggleEvent}>
                    <i className="fas fa-minus"></i>
                </div>
            }
        </React.Fragment>
    );
}

ExpandCollapseArrow.propTypes = {
    toggleEvent: PropTypes.func.isRequired,
    toggleState: PropTypes.bool.isRequired
};
