import React from 'react';
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
//import PageSection from "../../components-shared/PageSection";
import EditUserDetailsForm from "./forms/EditUserDetailsForm";
import EditUserPasswordForm from "./forms/EditUserPasswordForm";
import {AppContextData} from "../../main-components/AppContextData";
import Breadcrumbs from '../../components-shared/Breadcrumbs/Breadcrumbs';
import BackButtonNavigationBar from "../../components-shared/BackButtonNavigationBar";

function EditMyAccountPage(props) {

    const userDetails = AppContextData.getUserDetails();

    // noinspection CheckTagEmptyBody
    return (
        <React.Fragment> 
                <BackButtonNavigationBar classNamePrefix={"account"}/>
                  <Breadcrumbs breadcrumbs={[{url: '/projects', title: 'Home'}, {url: '/edit-my-account', title: 'Edit my account'}]} />
                    <header className="kb-header px-3">
                    <div className="container-fluid">
                        <div className="row">

                             <div className="col-12 d-flex align-items-center ">
                                        <div className="kb-title_page d-flex align-items-center py-4">
                                            <h1 className="h4 font-weight-normal m-0">Edit my account</h1>
                                          </div>
                               <nav className="kb-nav_ctas ml-auto">
                                  
                                </nav> 
                            </div> 
                        </div>
                    </div>
                </header>
                 <main className="kb-main kb-main_account--edit bg-white px-3 py-4">
                <div className="container-fluid">
                    <div className="row">
                    <div className="col-12 col-md-6 col-lg-4 pb-5">
                     <h5 className="py-3">Edit User Information</h5>
                       <EditUserDetailsForm
                    userDetails={userDetails}
                    myAccountEdit={true}
                />
                       </div>
                        <div className="col-12 col-md-4 col-lg-3 pb-5">
                         <h5 className="py-3">Edit your account password</h5>
                           <EditUserPasswordForm
                    userId={userDetails.id}
                    myAccountEdit={true}
                />
                        </div>
                    </div> 
                    </div>
                    </main>
        </React.Fragment>


    );
}

EditMyAccountPage.propTypes = {
    location: PropTypes.object.isRequired
};

export default withRouter(EditMyAccountPage);
