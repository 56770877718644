import React, {Component} from "react";
import {withRouter} from 'react-router-dom';
import ProjectSettings from "../ProjectSettings";

class NewProjectForm extends Component {

    render() {
        return (
            <React.Fragment>
                <ProjectSettings
                    createProject={true}
                    formIsEnabled={true}
                />
            </React.Fragment>
        );
    }
}

export default withRouter(NewProjectForm);