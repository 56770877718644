import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import './settings.css'
import moment from "moment";
import ProjectDetails from "./ProjectDetails";
import PropTypes from "prop-types";
import {
    assignUserToProject,
    createPictureMetadataForProject,
    createProject, deleteProject,
    getProjectById,
    unAssignUserFromProject,
    updateProject
} from "./actions/projectActions";
import ProjectUsers from "./ProjectUsers";
import {getRoles} from "../user/actions/rolesActions";
import {getAllUsers} from "../user/actions/userActions";
import ProjectPicture from "./ProjectPicture";
import {createPictureData, loadPictureData} from "../picture/actions/pictureActions";
import ProjectButtons from "./ProjectButtons";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";

class ProjectSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projectDetails: {
                id: null,
                name: null,
                location: null,
                estimatedBudget: null,
                startDate: moment(),
                estimatedEndDate: moment(),
                additionalInfo: null,
                created: moment()
            },
            fileMetadata: {
                name: '',
                size: 0,
                type: '',
                lastModified: moment(),
            },
            picturePreview: null,
            file: '',
            allUsers: [],
            allUserRoles: [],
            assignedUsers: [],
            assignedUsersMap: null,
            assignableUsersMap: null,
            formIsEnabled: this.props.formIsEnabled,
            displayErrors: {
                name: false
            },
            fieldValidations: {
                name: {
                    isValid: (value) => {
                        return value !== null && value.length > 0;
                    }
                }
            }
        };
        this.handleProjectDetailsChange = this.handleProjectDetailsChange.bind(this);
        this.assignUser = this.assignUser.bind(this);
        this.unAssignUser = this.unAssignUser.bind(this);
        this.getAssignedUsersMap = this.getAssignedUsersMap.bind(this);
        this.getAssignableUsersMap = this.getAssignableUsersMap.bind(this);
        this.getUserByRoleWhereProjectIs = this.getUserByRoleWhereProjectIs.bind(this);
        this.getUserByRoleWhereProjectIsNot = this.getUserByRoleWhereProjectIsNot.bind(this);
        this.getAssignedUsers = this.getAssignedUsers.bind(this);
        this.readPicturePreviewFromBlob = this.readPicturePreviewFromBlob.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.toggleFormIsEnabled = this.toggleFormIsEnabled.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onSave = this.onSave.bind(this);
        this.createPictureMetadata = this.createPictureMetadata.bind(this);
        this.createPicture = this.createPicture.bind(this);
        this.assignUsers = this.assignUsers.bind(this);
        this.redirectToProjectView = this.redirectToProjectView.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.getUsersToUnAssign = this.getUsersToUnAssign.bind(this);
        this.getUsersToAssign = this.getUsersToAssign.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.componentIsMounted = false;
    }

    componentDidMount() {
        this.componentIsMounted = true;
        this.loadPage();
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
    }

    loadPage() {
        getRoles().then(roles => {
            getAllUsers().then(users => {
                if (this.componentIsMounted) {
                    this.setState({
                        allUserRoles: roles,
                        allUsers: users,
                        assignedUsers: this.getAssignedUsers(users),
                        assignedUsersMap: this.getAssignedUsersMap(roles, users),
                        assignableUsersMap: this.getAssignableUsersMap(roles, users),
                        formIsEnabled: this.props.formIsEnabled
                    });
                }
            })
        })
        if (this.props.projectId) {
            getProjectById(this.props.projectId).then(response => {
                if (this.componentIsMounted) {
                    if (!response.code) {
                        const fileMetadata = response.fileMetadata;
                        if (fileMetadata && fileMetadata.binaryId) {
                            loadPictureData(fileMetadata.binaryId).then(result => result.blob())
                                .then(result => this.readPicturePreviewFromBlob(result));
                            this.setState({
                                projectDetails: response,
                                fileMetadata: fileMetadata
                            })
                        } else {
                            this.setState({
                                projectDetails: response,
                            })
                        }
                    }
                }
            })
        }
    }

    readPicturePreviewFromBlob(result) {
        const reader = new FileReader();
        reader.onloadend = () => {
            if (this.componentIsMounted) {
                this.setState({
                    picturePreview: reader.result
                });
            }
        };

        reader.readAsDataURL(result);
    }

    getAssignedUsers(users) {
        return users && users instanceof Array ? users.filter(user => user.projectIds.includes(this.props.projectId)) : [];
    }

    getAssignedUsersMap(roles, users) {
        let assignedUsersMap = {};
        for (let i = 0; i < roles.length; i++) {
            const roleName = roles[i].name;
            assignedUsersMap[roleName] = this.getUserByRoleWhereProjectIs(users, roles[i].id, this.props.projectId);
        }
        return assignedUsersMap;
    }

    getAssignableUsersMap(roles, users) {
        let assignableUsersMap = {};
        for (let i = 0; i < roles.length; i++) {
            const roleName = roles[i].name;
            assignableUsersMap[roleName] = this.getUserByRoleWhereProjectIsNot(users, roles[i].id, this.props.projectId);
        }
        return assignableUsersMap;
    }

    getUserByRoleWhereProjectIs(users, roleId, projectId) {
        return users && users instanceof Array ? users.filter(user => user.userRoleIds.includes(roleId) && user.projectIds.includes(projectId)) : [];
    }

    getUserByRoleWhereProjectIsNot(users, roleId, projectId) {
        return users && users instanceof Array ? users.filter(user => user.userRoleIds.includes(roleId) && !user.projectIds.includes(projectId)) : [];
    }

    handleProjectDetailsChange(field, value) {
        let projectDetails = this.state.projectDetails;
        projectDetails[field] = value;
        let displayErrors = this.state.displayErrors;
        displayErrors[field] = this.state.fieldValidations[field] ? !this.state.fieldValidations[field].isValid(value) : false;
        this.setState({
            projectDetails: projectDetails,
            displayErrors: displayErrors
        })
    }

    assignUser(userId) {
        if (userId) {
            let assignedUsers = this.state.assignedUsers;
            const userToAdd = this.state.allUsers.filter(user => user.id === userId)[0];
            assignedUsers.push(userToAdd);

            let assignedUsersMap = this.state.assignedUsersMap;
            this.state.allUserRoles.forEach((role) => {
                if (userToAdd.userRoleIds.includes(role.id)) {
                    assignedUsersMap[role.name].push(userToAdd);
                }
            })

            let assignableUsersMap = this.state.assignableUsersMap;
            this.state.allUserRoles.forEach((role) => {
                if (userToAdd.userRoleIds.includes(role.id)) {
                    assignableUsersMap[role.name] = assignableUsersMap[role.name].filter(user => user.id !== userToAdd.id);
                }
            })

            this.setState({
                assignedUsers: assignedUsers,
                assignedUsersMap: assignedUsersMap,
                assignableUsersMap: assignableUsersMap,
            })
        }
    }

    unAssignUser(userId) {
        let assignedUsers = this.state.assignedUsers;
        const userToRemove = this.state.allUsers.filter(user => user.id === userId)[0];
        assignedUsers = assignedUsers.filter(user => user.id !== userId);

        let assignedUsersMap = this.state.assignedUsersMap;
        this.state.allUserRoles.forEach((role) => {
            if (userToRemove.userRoleIds.includes(role.id)) {
                assignedUsersMap[role.name] = assignedUsersMap[role.name].filter(user => user.id !== userToRemove.id);
            }
        })

        let assignableUsersMap = this.state.assignableUsersMap;
        this.state.allUserRoles.forEach((role) => {
            if (userToRemove.userRoleIds.includes(role.id)) {
                assignableUsersMap[role.name].push(userToRemove);
            }
        })

        this.setState({
            assignedUsers: assignedUsers,
            assignedUsersMap: assignedUsersMap,
            assignableUsersMap: assignableUsersMap,
        })
    }

    handleImageChange(e) {
        e.preventDefault();

        const file = e.target.files[0];

        this.setState({
            file: file,
            fileMetadata: {
                name: file.name,
                size: file.size,
                type: file.type,
                lastModified: moment(file.lastModified),
            },
            error: ''
        });

        const reader = new FileReader();
        reader.onloadend = () => {
            this.setState({
                picturePreview: reader.result
            });
        };

        reader.readAsDataURL(file);
    }

    toggleFormIsEnabled() {
        this.setState({
            formIsEnabled: !this.state.formIsEnabled
        })
    }

    onEdit() {
        let displayErrors = this.state.displayErrors;
        let hasErrors = false;
        if (!this.state.fieldValidations.name.isValid(this.state.projectDetails.name)) {
            displayErrors.name = true;
            hasErrors = true;
        }
        if (hasErrors) {
            this.setState({
                displayErrors: displayErrors
            })
            return;
        }
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to save the project?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.updateProject();
                    }
                },
                {
                    label: 'No'
                }
            ]
        })
    }

    updateProject() {
        updateProject(this.state.projectDetails).then(() => {
            let requests = [];
            const assignUserRequests = new Promise((resolve, reject) => {
                resolve(this.assignUsers(this.getUsersToAssign(), this.state.projectDetails.id));
            })
            requests.push(assignUserRequests);
            const unAssignUserRequests = new Promise((resolve, reject) => {
                resolve(this.unAssignUsers(this.getUsersToUnAssign(), this.state.projectDetails.id));
            })
            requests.push(unAssignUserRequests);
            if (this.state.fileMetadata && this.state.fileMetadata.name && this.state.file) {
                const imageRequests = new Promise((resolve, reject) => {
                    resolve(this.uploadImage(this.state.projectDetails.id));
                })
                requests.push(imageRequests);
            }

            if (requests.length > 0) {
                Promise.all(requests).then(() => {
                    this.toggleFormIsEnabled();
                })
            } else {
                this.toggleFormIsEnabled();
            }
        })
    }

    getUsersToAssign() {
        const initialUsers = this.getAssignedUsers(this.state.allUsers);
        return this.state.assignedUsers.filter(user => !initialUsers.includes(user));
    }

    getUsersToUnAssign() {
        const initialUsers = this.getAssignedUsers(this.state.allUsers);
        return initialUsers.filter(user => !this.state.assignedUsers.includes(user));
    }

    onDelete() {
        deleteProject(this.state.projectDetails.id).then((result) => {
            if (result.status !== 200) {
                toast.error("Project cannot be deleted, it is not empty!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            } else {
                toast.info("Project was deleted", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                this.props.history.push('/projects');
            }
        })
    }

    onCancel() {
        this.loadPage();
    }

    onSave() {
        let displayErrors = this.state.displayErrors;
        let hasErrors = false;
        if (!this.state.fieldValidations.name.isValid(this.state.projectDetails.name)) {
            displayErrors.name = true;
            hasErrors = true;
        }
        if (hasErrors) {
            this.setState({
                displayErrors: displayErrors
            })
            return;
        }
        createProject(this.state.projectDetails).then(projectResponse => {
            if (!projectResponse.code) {
                let requests = [];
                if (this.state.fileMetadata && this.state.fileMetadata.name && this.state.file) {
                    const imageRequests = new Promise((resolve, reject) => {
                        resolve(this.uploadImage(projectResponse.id));
                    })
                    requests.push(imageRequests);
                }
                if (this.state.assignedUsers.length > 0) {
                    const assignUsersRequests = new Promise((resolve, reject) => {
                        resolve(this.assignUsers(this.state.assignedUsers, projectResponse.id));
                    })
                    requests.push(assignUsersRequests);
                }
                if (requests.length > 0) {
                    Promise.all(requests).then(() => {
                        this.redirectToProjectView(projectResponse.id)
                    })
                } else {
                    this.redirectToProjectView(projectResponse.id);
                }
            }
        })
    }

    uploadImage(projectId) {
        return this.createPictureMetadata(projectId).then(fileMetadataResponse => {
            this.createPicture(fileMetadataResponse.id).then(() => {
            })
        });
    }

    redirectToProjectView(projectId) {
        return this.props.history.push('/project/' + projectId + '/view');
    }

    assignUsers(users, projectId) {
        let requests = users.map(user => {
            return new Promise((resolve, reject) => {
                resolve(assignUserToProject(projectId, user.id))
            })
        });
        return Promise.all(requests);
    }

    unAssignUsers(users, projectId) {
        let requests = users.map(user => {
            return new Promise((resolve, reject) => {
                resolve(unAssignUserFromProject(projectId, user.id))
            })
        });
        return Promise.all(requests);
    }

    createPictureMetadata(projectId) {
        const requestFileMetadata = Object.assign({}, this.state.fileMetadata);
        delete requestFileMetadata["file"];
        delete requestFileMetadata["picturePreview"];
        return createPictureMetadataForProject(projectId, requestFileMetadata);
    }

    createPicture(fileMetadataId) {
        let fd = new FormData();
        fd.append('picture', this.state.file, this.state.fileMetadata.name);
        return createPictureData(fd, fileMetadataId);
    }

    render() {

        return (
            <React.Fragment>
                <div className="container-fluid d-flex">
                    <div className="col-4">
                        <h5 className="py-3">Generic info about the project</h5>
                        <ProjectPicture picturePreview={this.state.picturePreview}
                                        formIsEnabled={this.state.formIsEnabled}
                                        handleImageChange={this.handleImageChange}
                        />
                        <ProjectDetails
                            projectDetails={this.state.projectDetails}
                            handleChange={this.handleProjectDetailsChange}
                            formIsEnabled={this.state.formIsEnabled}
                            displayErrors={this.state.displayErrors}
                        />
                        <ProjectButtons
                            projectDetails={this.state.projectDetails}
                            formIsEnabled={this.state.formIsEnabled}
                            editProject={this.props.editProject}
                            createProject={this.props.createProject}
                            toggleFormIsEnabled={this.toggleFormIsEnabled}
                            onEdit={this.onEdit}
                            onSave={this.onSave}
                            onDelete={this.onDelete}
                            onCancel={this.onCancel}
                        />
                    </div>
                    <div className="col-2 align-center">
                        <div className="vertical-line"></div>
                    </div>
                    <div className="col-6">
                        <h5 className="py-3 font-weight-bold">Assign users</h5>
                        {this.state.assignedUsersMap && this.state.assignableUsersMap &&
                        <ProjectUsers
                            assignUser={this.assignUser}
                            unAssignUser={this.unAssignUser}
                            assignedUsersMap={this.state.assignedUsersMap}
                            assignableUsersMap={this.state.assignableUsersMap}
                            userRoles={this.state.allUserRoles}
                            projectId={this.props.projectId}
                            formIsEnabled={this.state.formIsEnabled}
                        />}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

ProjectSettings.propTypes = {
    projectId: PropTypes.string,
    formIsEnabled: PropTypes.bool,
    editProject: PropTypes.bool,
    createProject: PropTypes.bool
};

export default withRouter(ProjectSettings);
