// This must be the first line in App.js
import 'react-app-polyfill/ie11';
import 'babel-polyfill';

import React, {Component} from 'react';
import {HashRouter} from "react-router-dom";

// import "./assets/styles/custom.css";

import {AppContextData} from "./main-components/AppContextData";
import AppContainer from "./AppContainer";

import {getUserLoggedIn} from "./components/user/actions/userActions";
import {getApplicationSettings, getCompanySettings} from "./components/settings/actions/settingsActions";

import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-datepicker/dist/react-datepicker.css';
import LoadingScreen from './staticpages/LoadingScreen';


class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userContextLoaded: false,
            settingsContextLoaded: false,
            appSettingsContextLoaded: false
        }
    }

    componentDidMount() {

        getUserLoggedIn().then((userDetails) => {
            AppContextData.setUserValues(userDetails);
            this.setState({userContextLoaded: true});
        });

        // TODO(rflueras) should bring only the color scheme...not the entire object
        // should return settings based on role, so probably multiple request will be made here
        getCompanySettings().then((settings) => {
            AppContextData.setCompanySettingsValues(settings);
            this.setState({settingsContextLoaded: true});
        });

        getApplicationSettings().then((settings) => {
            AppContextData.setApplicationSettingsValues(settings);
            this.setState({appSettingsContextLoaded: true});
        });

    }

    render() {

        if (!this.state.userContextLoaded || !this.state.settingsContextLoaded || !this.state.appSettingsContextLoaded) {
            // todo(all) this could be made mode beautiful so it will look nice and profi but i need some help for that :)
            return <LoadingScreen isLoading={!this.state.userContextLoaded || !this.state.settingsContextLoaded || !this.state.appSettingsContextLoaded}/>
        }

        const colorScheme = AppContextData.getCompanySettings().colorScheme;
        
        return (
            <HashRouter>
                <div className={colorScheme}>
                <LoadingScreen/>
                <AppContainer />
                </div>
            </HashRouter>
        );
    }
}

export default App;
