import {BigNumber} from "bignumber.js";

export function addDoubles(value1, value2) {
    let a = new BigNumber(value1);
    let b = new BigNumber(value2);
    return a.plus(b).toNumber();
}

export function subtractDoubles(value1, value2) {
    let a = new BigNumber(value1);
    let b = new BigNumber(value2);
    return a.minus(b).toNumber();
}

export function divideDoubles(value1, value2) {
    let a = new BigNumber(value1);
    let b = new BigNumber(value2);
    return a.dividedBy(b).decimalPlaces(2).toNumber();
}

export function multiplyDoubles(value1, value2) {
    let a = new BigNumber(value1);
    let b = new BigNumber(value2);
    return a.multipliedBy(b).decimalPlaces(2).toNumber();
}