import React, {useState} from 'react';
import {AppContextData} from "./AppContextData";

import {login} from "../components/user/actions/userActions";


export const AuthContext = React.createContext({
  isAuth: AppContextData.getUserDetails().id && AppContextData.getUserDetails().id !== '#' ? true : false,
  login: (email, password) => {
    login({email: email, password: password, submit: 'Login'})
    .then((res) => { 
      if (res && res.error) {
        console.log(res.error);
      } else {
        window.location.href='/#/projects';
        window.location.reload();
      }
    }).catch((e)=>{
      console.log(e);
      console.log(e.request);
    });
  }
})

const AuthContextProvider = (props) => {
  const userDetails = AppContextData.getUserDetails().id;
  const [isAuthenticated, setIsAuthenticated] = useState(userDetails && userDetails !== '#' ? true : false);
  const loginHandler = () => {
    setIsAuthenticated(true);
  }
  return (
    <AuthContext.Provider value={{login: loginHandler, isAuth: isAuthenticated}}>
      {props.children}
    </AuthContext.Provider>
  );
};


export default AuthContextProvider;
