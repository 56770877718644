import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import CentralModal from '../../../../components-shared/CentralModal/CentralModal';
import {postClosedMonth, openMonth} from '../../../project/actions/projectActions';
import {confirmAlert} from "react-confirm-alert";
import moment from 'moment';

export const monthsArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const CloseMonth = (props) => {

    const [selectedMonthClose, setSelectedMonthClose] = useState({});
    const [selectedMonthOpen, setSelectedMonthOpen] = useState('');
    const [selectionType, setSelectionType] = useState('Close');
    const [selectedMonthOpenError, setSelectedMonthOpenError] = useState('');
    const [selectedMonthCloseError, setSelectedMonthCloseError] = useState('');
    const [submited, setSubmited] = useState(false);

    const monthClosing = (projectId, closingMonth) => {
        let closingMonthObject = {};
        closingMonthObject.month = new Date(closingMonth).toISOString();
        closingMonthObject.closed = true;
        closingMonthObject.projectId = projectId;

        postClosedMonth(projectId, closingMonthObject).then((result) => {
            if (result.code === 13006) {
                confirmAlert({
                    title: 'Notification message',
                    message: 'Month is already closed !',
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {
                                window.location.reload();
                                setSubmited(false);
                            }
                        }
                    ]
                });
            } else if (result.code === 13005) {
                confirmAlert({
                    title: 'Notification message',
                    message: 'Month could not be found !',
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {
                                window.location.reload();
                                setSubmited(false);
                            }
                        }
                    ]
                });
            } else if (result.code === 13007) {
                confirmAlert({
                    title: 'Notification message',
                    message: 'The selected month cannot be closed until previous months are financially closed !',
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {
                                window.location.reload();
                                setSubmited(false);
                            }
                        }
                    ]
                });
            } else {
                window.location.reload();
            }
        })
        updateToggleSelectedMonth();
    }

    
    const monthOpening = (projectId, openingMonth) => {
        openMonth(projectId, openingMonth).then((result) => {
            if (result.code === 13006) {
                confirmAlert({
                    title: 'Notification message',
                    message: 'Month is already opened!',
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {
                                window.location.reload();
                                setSubmited(false);
                            }
                        }
                    ]
                });
            } else if (result.code === 13005) {
                confirmAlert({
                    title: 'Notification message',
                    message: 'Month could not be found!',
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {
                                window.location.reload();
                                setSubmited(false);
                            }
                        }
                    ]
                });
            } else {
                window.location.reload();
            }
        })
        updateToggleSelectedMonth();
    }

    const closeMonthAction = (projectId, closingMonth) => {

        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to close month: ' + closingMonth + '?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        monthClosing(projectId, closingMonth);
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const openMonthAction = (projectId, openingMonth) => {
        let displayDate = '';
        props.closedMonths && props.closedMonths.forEach(closedMonth => 
            {
                if (closedMonth.id === openingMonth) {
                    displayDate = parseDate(closedMonth.month);
                }
            }
        );

        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to open month: ' + displayDate + '?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        monthOpening(projectId, openingMonth);
                    }
                },
                {
                    label: 'No',

                }
            ]
        });
    }

    const updateToggleSelectedMonth = () => {
        props.toggleCloseMonthModal();
    }

    const doToggleCloseMonthModal = () => {
        props.toggleCloseMonthModal();
        setSelectedMonthOpen('');
        setSelectedMonthClose({});
        setSelectionType('Close');
        setSubmited(false);
    }

    const parseDate = (value) => {
        let values = [];
        let result = '';
        if (value) {
            values = value.split(/[-,:,T,+]+/);
            if (values.length > 3) {
                result = values[0] + "-" + values[1] + "-" + values[2];
            }
        }
        return result;
    }

    const handleSelectedMonthClose = (e) => {
        setSelectedMonthClose(e.target.value);
        setSelectedMonthOpen('');
        setSelectionType('Close');
        setSelectedMonthOpenError("");
        setSelectedMonthCloseError("");
    }
    const handleSelectedMonthOpen = (e) => {
        setSelectedMonthOpen(e.target.value);
        setSelectedMonthClose({});
        setSelectionType('Open');
        setSelectedMonthOpenError("");
        setSelectedMonthCloseError("");
    }

    const submitForm = () => {
        setSubmited(true);
        if (selectionType === 'Close' && JSON.stringify(selectedMonthClose) !== "{}") {
            let conditionCloseToTest = false;
            let firstOpenedMonth = null;
            let firstOpenedMonthsArr = [];
            props.openedMonthsList.forEach(month => {
                if(!month.closed) {
                    firstOpenedMonthsArr = [...firstOpenedMonthsArr, month]
                }
            });
            if(firstOpenedMonthsArr.length > 0) {
                firstOpenedMonth = firstOpenedMonthsArr[0];
            }
            if (firstOpenedMonth) {
                const dateToUse  = new Date(firstOpenedMonth.month);
                let firstMonthDate = moment(dateToUse).format('YYYY') + '-' + moment(dateToUse).format('MM') + '-01';
                if (selectedMonthClose === firstMonthDate) {
                    conditionCloseToTest = true;
                }
            }
            
            if (conditionCloseToTest) {
                closeMonthAction(props.projectId, selectedMonthClose)
            } else {
                setSelectedMonthCloseError("The selected month cannot be closed until previous months are financially closed");
            }
        } else if (selectionType === 'Open' && selectedMonthOpen) {

            let lastClosedMonth = null;
            let lastClosedMonthsArr = [];
            props.closedMonths.forEach(month => {
                if(month.closed) {
                    lastClosedMonthsArr = [...lastClosedMonthsArr, month]
                }
            });
            if(lastClosedMonthsArr.length > 0) {
                lastClosedMonth = lastClosedMonthsArr[lastClosedMonthsArr.length - 1];
            }
            let conditionToTest = false;
            if (lastClosedMonth) {
                conditionToTest = selectedMonthOpen === lastClosedMonth.id;
            }

            

            if (conditionToTest) {
                openMonthAction(props.projectId, selectedMonthOpen)
            } else {
                //set error
                setSelectedMonthOpenError("The selected month cannot be opened until next months are financially opened");
            }
        }
    }
    

    return (
        <CentralModal
            condition={props.condition}
            toggleCloseMonthModal={props.toggleCloseMonthModal}
        >
            <form>
                <div className="form-group">
                    <label htmlFor="monthToClose" className="font-weight-bold mb-4 text-dark">Select what month you want to
                        close</label>
                    <select 
                        onChange={(e) => handleSelectedMonthClose(e)} 
                        type="monthToClose" id="monthToClose"
                        className={"form-control " + (selectionType === 'Close' && JSON.stringify(selectedMonthClose) !== "{}"  ? 'font-weight-bold' : '')}
                        value={selectedMonthClose}
                    >
                        <option value="">Please select month</option>
                        {
                            props.openedMonthsList ? (
                                props.openedMonthsList.map(openedMonth => {
                                    let displayDate = monthsArray[openedMonth.monthAsInteger - 1] + ' ' + openedMonth.year;
                                    return openedMonth && <option
                                    value={parseDate(openedMonth.month)}
                                    key={parseDate(openedMonth.month)}>{displayDate}</option>
                                })
                            ) : (
                                <option value="Undefined">Undefined</option>
                            )
                        }
                    </select>
                    <div className="text-danger"><small>
                        {submited && selectedMonthCloseError ? selectedMonthCloseError : ''}
                    </small></div>
                </div>
                <div className="form-group">
                    <label htmlFor="monthToOpen" className="font-weight-bold mb-4 text-dark">Select what month you want to
                        open</label>
                    <select 
                        onChange={(e) => handleSelectedMonthOpen(e)} 
                        type="monthToOpen" id="monthToOpen" 
                        value={selectedMonthOpen}
                        className={"form-control " + (selectionType === 'Open' ? 'font-weight-bold' : '')}
                    >
                        <option value="">Please select month</option>
                        {
                            props.closedMonths ? (
                                props.closedMonths.map(closedMonth => {
                                    const dateToUse  = new Date(closedMonth.month);
                                    let displayDate = moment(dateToUse).format('MMMM') + ' ' + moment(dateToUse).format('YYYY');
                                    return closedMonth && <option
                                    value={closedMonth.id}
                                    key={closedMonth.id}>{displayDate}</option>
                                })
                            ) : (
                                <option value="Undefined">Undefined</option>
                            )
                        }
                    </select>
                    <div className="text-danger"><small>
                    {submited && !selectedMonthOpen && JSON.stringify(selectedMonthClose) === "{}" ? 'You must select one of the options' : '' }
                    {submited && selectedMonthOpenError ? selectedMonthOpenError : ''}
                    </small></div>
                </div>
                <div className="mt-4">
                    <Button
                        className="mr-3"
                        variant="primary"
                        onClick={submitForm}
                    >
                        {selectionType} month
                    </Button>
                    <Button
                        variant="light"
                        onClick={doToggleCloseMonthModal}
                    >
                        Cancel
                    </Button>
                </div>
            </form>
        </CentralModal>
    );
}

export default CloseMonth;
