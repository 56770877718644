import React, {Component} from 'react';
import PropTypes from "prop-types";
import {categoryTypeCost} from "../../category/constant/CategoryConstants";
import InvoiceFormWrapper from "./InvoiceFormWrapper";
import {getProjectOpenedMonthsById} from "../../project/actions/projectActions";
import {withRouter} from "react-router-dom";

class EditInvoiceForm extends Component {

    constructor(props) {

        super(props);

        this.state = EditInvoiceForm.constructStateObject(props);
    }

    static constructStateObject(props) {

        return {
            categoryType: props.invoice.categoryType || categoryTypeCost.type,
            isMonthClosed: false
        };
    }

    componentDidMount() {
        getProjectOpenedMonthsById(this.props.match.params.projectId).then(openMonths => {
            const isMonthClosed = !openMonths.some(month => this.props.invoice.budgetDateYear === month.year &&
                this.props.invoice.budgetDateMonth === month.monthAsInteger);
            this.setState({
                isMonthClosed: isMonthClosed
            })
        })
    }

    render() {

        return (
            <InvoiceFormWrapper
                editInvoice={true}
                invoiceId={this.props.invoice.id}
                cancelForm={this.props.onCancel}
                projectId={this.props.invoice.projectId}
                categoryType={this.state.categoryType}
                inModal={this.props.inModal}
                isMonthClosed={this.state.isMonthClosed}
            />
        );
    }
}

EditInvoiceForm.propTypes = {
    invoice: PropTypes.object.isRequired,
    refreshState: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default withRouter(EditInvoiceForm);
