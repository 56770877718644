import React from "react";
import PropTypes from 'prop-types';

import {withRouter} from 'react-router-dom';
import PicturePreview from "../picture/PicturePreview";

const ProjectPicture = (props) => {
    const {formIsEnabled, picturePreview, error, handleImageChange} = props

    return (
        <div className="kb-project-picture pb-5">

            <PicturePreview picturePreview={picturePreview}/>
            {
                formIsEnabled &&
                <input className="fileInput"
                       type="file"
                       onChange={(e) => handleImageChange(e)}/>
            }
            {
                error &&
                <div className="text-danger">{error}</div>
            }

        </div>
    );
}

ProjectPicture.propTypes = {
    picturePreview: PropTypes.any,
    handleImageChange: PropTypes.func,
    formIsEnabled: PropTypes.bool,
    error: PropTypes.string
};

export default withRouter(ProjectPicture);