import {genericGetMultipart, genericPostMultipart} from "../../../components-shared/fetch-wrapper";

const pictureEndpoint = '/picture/';

export function createPictureData(data, metadataId) {
    return genericPostMultipart(pictureEndpoint + metadataId, data);
}

export function loadPictureData(pictureId) {
    return genericGetMultipart(pictureEndpoint + pictureId);
}
