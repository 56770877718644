import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import Breadcrumbs from "../../../../components-shared/Breadcrumbs/Breadcrumbs";
import RightSideModal from "../../../../components-shared/RightSideModal/RightSideModal";
import CostIncomeProfitReport from './CostIncomeProfitReport';
import FacilityReport from './FacilityReport';
import VatReport from './VatReport';
import TableDataReport from './TableDataReport';
import ChartGraph from './ChartGraph';
import GeneralSummaryTop from './GeneralSummaryTop';
import {getReporting, saveAndGetCashPosition, saveAndGetCumulativeCashPostion, saveAndGetExecution, saveAndGetFinancingInvestmentVat, saveAndGetHeader, saveAndGetInitial} from "../reportingActions";

const ProjectReporting = (props) => {
    const {projectId} = props;
    const [reporting, setReporting] = useState();
    const [initialProfitReport, setInitialProfitReport] = useState();
    const [executionProfitReport, setExecutionProfitReport] = useState();
    const [financingInvestmentVat, setFinancingInvestmentVat] = useState();
    const [cumulativeCashPosition, setCumulativeCashPosition] = useState();
    const [cashPosition, setCashPosition] = useState();
    const [header, setHeader] = useState();


    useEffect(() => {
        getReporting(projectId).then((res) => {
            setReporting(res);
        })
    }, []);

    useEffect(() => {
        if (reporting) {
            saveAndGetInitial(projectId).then((res) => {
                setInitialProfitReport(res);
            });
            saveAndGetExecution(projectId).then((res) => {
                setExecutionProfitReport(res);
            });
            saveAndGetFinancingInvestmentVat(projectId).then((res) => {
                setFinancingInvestmentVat(res);
            });
            saveAndGetCashPosition(projectId).then((res) => {
                setCashPosition(res);
            });
            saveAndGetCumulativeCashPostion(projectId).then((res) => {
                setCumulativeCashPosition(res);
            });
            saveAndGetHeader(projectId).then((res) => {
                setHeader(res);
            });
        }
    }, [reporting]);

    

    return (
        <React.Fragment>
            <Breadcrumbs breadcrumbs={[{url: '/projects', title: 'Home'}, {
                url: `/project/${projectId}`,
                title: props.projectDetails && props.projectDetails.name
            }, {url: `/reporting/reporting`, title: 'Reporting'}]}/>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                    <ul className="kb-nav-link flex-column py-2 pl-0">
                                        <li className="py-lg-2 d-inline mr-2">
                                            <NavLink to={`/project/${projectId}/reporting/reporting`}
                                                     className="btn btn-light"
                                                     activeClassName="bg-dark text-white font-size-3" replace>
                                                Reporting
                                            </NavLink>
                                        </li>
                                        <li className="py-lg-2 d-inline mr-2">
                                            <NavLink to={`/project/${projectId}/reporting/traceability`}
                                                     className="btn btn-light font-size-3"
                                                     activeClassName="bg-dark text-white font-size-3" replace>
                                                Traceability
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <React.Fragment>
                {reporting  ?

                    (<React.Fragment>
                        <GeneralSummaryTop
                            header={header ? header : {...reporting.reportingHeader, shouldLoad: true}}
                        />

                        <main className="kb-main kb-main_statistics px-3 pb-4 ">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 pb-0">
                                        <div className="row">
                                            <CostIncomeProfitReport title="Initial report"
                                                                    report={initialProfitReport ? initialProfitReport : {...reporting.initialProfitReport, shouldLoad: true}}
                                                                    type={"Initial"}/>
                                            <CostIncomeProfitReport title="Execution report"
                                                                    report={executionProfitReport ? executionProfitReport : {...reporting.executionProfitReport, shouldLoad: true}}
                                                                    type={"Execution"}/>
                                            <FacilityReport title="Financing"
                                                            facilityReport={financingInvestmentVat ? financingInvestmentVat.financingFacilityReport : {...reporting.financingFacilityReport, shouldLoad: true}}/>
                                            <FacilityReport title="Investment"
                                                            facilityReport={financingInvestmentVat ? financingInvestmentVat.investmentFacilityReport : {...reporting.investmentFacilityReport, shouldLoad: true}}/>
                                            <VatReport title="VAT report" vatReport={financingInvestmentVat ? financingInvestmentVat.vatReport : {...reporting.vatReport, shouldLoad: true}}/>
                                        </div>
                                        <ChartGraph cumulativeCashPosition={cumulativeCashPosition ? cumulativeCashPosition : {...reporting.cumulativeCashPosition, shouldLoad: true}}/>
                                    </div>
                                </div>
                            </div>

                        </main>

                        <TableDataReport cashPosition={cashPosition ? {values: cashPosition} : {values: reporting.cashPosition, shouldLoad: true}}/>
                    </React.Fragment>)
                    :
                    <React.Fragment/>
                }
            </React.Fragment>

            <RightSideModal
                items={['Dragée brownie chocolate bar oat cake. Icing tiramisu chocolate bar. Biscuit halvah pastry cotton candy croissant chocolate cake gummi bears wafer cake. ', 'Cotton candy lollipop topping marshmallow gummi bears.', 'Candy croissant gingerbread cookie sweet macaroon. Candy canes macaroon candy canes marshmallow sweet candy muffin donut. Pudding chocolate sweet cake toffee. ']}
            />
        </React.Fragment>
    );
}

export default ProjectReporting;
