import React from 'react';
import {Button, FormLabel, FormControl, InputGroup} from "react-bootstrap";
import "./UrlInputGroup.css";

const UrlInputGroup = (props) => {
  return (
    <InputGroup  className={props.inputClassName}>
        <FormLabel>{props.label}</FormLabel>
        <div className="container">
          <div className="row">
            <div className="col-10 px-0">
            <FormControl
                  type="text"
                  value={props.pdfLink ? props.pdfLink : ''}
                  onChange={(e) => props.handleChange(e)}
                  id={props.id ? props.id : "pdfLink"}
                  onBlur={props.onBlur ? () => props.onBlur() : () => null}
              />
            </div>        
            <div className="col-2 px-0">
            {props.pdfLink ?
                      (<a href={props.pdfLink} target="_blank" rel="noopener noreferrer">
                        <Button className="btn-light url-button w-100 url-button">
                            <i className="fas fa-external-link-alt"></i>
                        </Button>
                    </a>)
                    : (<Button  className="btn-light w-100 url-button" disabled>
                      <i className="fas fa-minus-circle"></i>
                    </Button>)
              }
            </div>
          </div>
        </div>
    </InputGroup>
  );
}

export default UrlInputGroup;
