import React, {Component} from "react";
import PropTypes from 'prop-types';
import ExpandCollapseArrow from "./ExpandCollapseArrow";

class ExpandCollapseChildren extends Component {

    constructor(props) {
        super(props);
        this.state = {
            collapsed: this.props.collapsed === undefined ? true : this.props.collapsed,
        };

        this.toggleCollapse = this.toggleCollapse.bind(this);
    };
    
    toggleCollapse() {
        this.setState({collapsed: !this.state.collapsed});
    }

    render() {
        // noinspection CheckTagEmptyBody
        return (
            <React.Fragment>
                <ExpandCollapseArrow 
                    toggleEvent={this.toggleCollapse}
                    toggleState={this.state.collapsed}
                />
                {
                    this.state.collapsed || this.props.children
                }
            </React.Fragment>
        );
    }
}

ExpandCollapseChildren.propTypes = {
    collapsed: PropTypes.bool,
};

export default ExpandCollapseChildren;