import React, {useContext} from 'react';
import { Button } from 'react-bootstrap';
import ModalContext from '../../main-components/ModalContext';
import "./RightSideModal.css"

const RightSideModal = (props) => {
  const [isModalOpen, setModal] = useContext(ModalContext);
  return isModalOpen && setModal ? (
      <div className='popup'>
       <Button className="btn btn-link kb-btn_close-popup"  onClick={() => setModal(false)}> </Button>
          <div className='popup_inner'>
            <h3 className="kb-popup_title pb-4 pt-5 mt-5"><i className="fas fa-question-circle font-weight-bold "></i> Help</h3>
            <ul className="list-group list-group-flush pb-4">
              {
                props.items.map((item) => <li className="list-group-item" key={item}>{item}</li>)
              }
            </ul> 
            <Button variant="primary" className="mt-4 mb-5"
                onClick={() => setModal(false)}
              >
              Close
            </Button>
          </div>
      </div>
  ) : null;
}

export default RightSideModal;
