import moment from 'moment';
import React from 'react'
import { Button } from 'react-bootstrap';

const TraceabilityForm = (props) => {
  const {setPrimaryMonth, setBlurPrimaryMonth, closedMonths, errorCheck, setSecondaryMonth, secondaryMonth, selectComponent, exportTraceability, saved, blurPrimaryMonth, blurSelectComponent, setSelectComponent, setSelection,
  setBlurSelectComponent, primaryMonth, onCompare} = props;
  return (
    
    <div className="row">
    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 col-xl-12">
            <form>
            <div className="row">
              <div className="col-2 pl-0">
                <div className="form-group mb-0">
                  <select
                    onChange={(e) => {setPrimaryMonth(e.target.value); setBlurPrimaryMonth(true);}}
                    className="form-control bg-white font-weight-bold no-focus"
                    value={primaryMonth}
                  >
                      <option value="">Select primary month</option>
                      {
                        closedMonths.map(month => month && (
                          <option key={month.id} value={month.id}>{moment(new Date(month.month)).format('MMMM YYYY')}</option>
                        ))
                      }
                  </select>
                </div>
                {(saved || blurPrimaryMonth) && errorCheck(primaryMonth, 'primaryMonth')}
              </div>
              <div className="col-2 pl-0">
                <div className="form-group mb-0">
                  <select
                    onChange={(e) => {setSecondaryMonth(e.target.value);}}
                    className="form-control bg-white font-weight-bold text-medium-grey no-focus"
                    value={secondaryMonth}
                  >
                      <option value="">Select secondary month</option>
                      {
                        closedMonths.map(month => month && (
                          <option key={month.id} value={month.id}>{moment(new Date(month.month)).format('MMMM YYYY')}</option>
                        ))
                      }
                  </select>
                </div>
              </div>
              <div className="col-2 pl-0">
                <div className="form-group mb-0">
                  <select
                    onChange={(e) => {setSelectComponent(e.target.value); setSelection(e.target.value); setBlurSelectComponent(true);}}
                    className="form-control bg-white font-weight-bold text-medium-grey no-focus"
                    value={selectComponent}
                  >
                      <option value="">Select component</option>
                      <option value="Cost">Cost</option>
                      <option value="Income">Income</option>
                      <option value="Financing">Financing</option>
                      <option value="Investment">Investment</option>
                  </select>
                </div>
                {(saved || blurSelectComponent) && errorCheck(selectComponent, 'selectComponent')}
              </div>
              <div className="col-4 pl-0">
                <div className="form-group mb-0">
                  <Button variant="primary" className="mr-2" onClick={onCompare}>Compare</Button>
                  <Button variant="secondary" onClick={exportTraceability}>
                    <i className="fas fa-file-download text-white mr-2"></i>Export
                  </Button>
                </div>
              </div>
              <div className="col-2">
              </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default TraceabilityForm
