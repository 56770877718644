class AppContextDataClass {

    constructor() {

        this.defaults = {
            userDetails: {
                id: '#',
                firstName: '#',
                lastName: '#',
                userRoles: ['GUEST'],
                userRoleIds: ['GUEST'],
                email: '#',
                companyName: '',
                phoneNumber: '',
                additionalInfo: ''
            },

            companySettings: {
                colorScheme: ''
            },

            applicationSettings: {
                name: "",
                version: "",
                buildTime: "",
                javaVersion: ""
            }
        };

        this.companySettings = Object.assign({}, this.defaults.companySettings);
        this.userDetails = Object.assign({}, this.defaults.userDetails);
        this.applicationSettings = Object.assign({}, this.defaults.applicationSettings);
    }

    resetToDefaults() {
        this.setUserValues(this.defaults.userDetails);
        this.setCompanySettingsValues(this.defaults.companySettings);
        this.setApplicationSettingsValues(this.defaults.applicationSettings);
    }

    static overrideProp(src, dest, prop) {
        dest[prop] = src[prop] || dest[prop];
    }

    setUserValues(data) {
        AppContextDataClass.overrideProp(data, this.userDetails, 'id');
        AppContextDataClass.overrideProp(data, this.userDetails, 'firstName');
        AppContextDataClass.overrideProp(data, this.userDetails, 'lastName');
        AppContextDataClass.overrideProp(data, this.userDetails, 'userRoles');
        AppContextDataClass.overrideProp(data, this.userDetails, 'userRoleIds');
        AppContextDataClass.overrideProp(data, this.userDetails, 'email');
        AppContextDataClass.overrideProp(data, this.userDetails, 'companyName');
        AppContextDataClass.overrideProp(data, this.userDetails, 'phoneNumber');
        AppContextDataClass.overrideProp(data, this.userDetails, 'additionalInfo');
    }

    setCompanySettingsValues(data) {
        AppContextDataClass.overrideProp(data, this.companySettings, 'colorScheme');
    }

    setApplicationSettingsValues(data) {
        AppContextDataClass.overrideProp(data, this.applicationSettings, 'name');
        AppContextDataClass.overrideProp(data, this.applicationSettings, 'version');
        AppContextDataClass.overrideProp(data, this.applicationSettings, 'buildTime');
        AppContextDataClass.overrideProp(data, this.applicationSettings, 'javaVersion');
    }

    getUserDetails() {
        return Object.assign({}, this.userDetails);
    }

    getCompanySettings() {
        return Object.assign({}, this.companySettings);
    }

    getApplicationSettings() {
        return Object.assign({}, this.applicationSettings);
    }

    getCurrentRoles() {
        return this.userDetails.userRoles;
    }
  
    containsRole(role) {
      return this.userDetails.userRoles.includes(role);
    }

}

export const AppContextData = new AppContextDataClass();
