import React, {Component} from "react";
import PropTypes from "prop-types";
import ForecastSplitTable from "./ForecastSplitTable";
import {prettyPrint} from "../../../common/numberOperations";
import {subtractDoubles} from "../../../common/doubleOperations";
import RenderIf from "../../../../main-components/RenderIf";
import {confirmAlert} from "react-confirm-alert";
import {deleteContract} from "../../../contract/actions/contractActions";
import EditContractForm from "../../../contract/common/forms/EditContractForm";
import {committedForecast} from "../../../forecast/support/ForecastConstants";
import Moment from "moment";
import ButtonContainer from "./ButtonContainer";
import {Button} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import InvoiceFormWrapper from "../../../invoice/forms/InvoiceFormWrapper";
import DateRangeNavigator from "./DateRangeNavigator";
import { computeForecastSum } from "../../../contract/v2/budgetExecutionOperations";
import CloseMonth from "./CloseMonth";

class MonthlyExecutionContractForecastView extends Component {

    constructor(props) {

        super(props);

        this.state = {
            collapsed: true,
            editingContract: false,
            addingInvoice: false,
            activeTab: this.props.contract &&  this.props.contract.invoicedPerMonthArray &&  this.props.contract.invoicedPerMonthArray.length > 0 ? "Actual" : "Initial"
        };

        this.toggleTable = this.toggleTable.bind(this);
        this.deleteContract = this.deleteContract.bind(this);
        this.toggleEditContract = this.toggleEditContract.bind(this);
        this.cancelForm = this.cancelForm.bind(this);
        this.toggleAddInvoice = this.toggleAddInvoice.bind(this);
        this.addInvoice = this.addInvoice.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.getContractType = this.getContractType.bind(this);
        this.computeTotalActualCashflow = this.computeTotalActualCashflow.bind(this);
        this.computeTotalInitialCashflow = this.computeTotalInitialCashflow.bind(this);
    }

    changeTab(key) {
      this.setState({
        activeTab: key
      });
    }

    getContractType() {
        return this.props.contract.contractType === 'AGREEMENT' ? 'Agreement' : 'Fictive';
    }

    toggleTable() {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

    toggleEditContract() {
        this.setState({
            editingContract: !this.state.editingContract
        })
    }

    toggleAddInvoice () {
      this.setState({addingInvoice: !this.state.addingInvoice});
    }

    cancelForm () {
        this.props.onCancel();
      this.toggleAddInvoice();
    }

    deleteContract(contractId) {

        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to delete the contract?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteContract(contractId, this.props.match.params.categoryId).then(this.props.refreshState);
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }


    addInvoice () {
        this.toggleAddInvoice();
    }

    computeTotalActualCashflow() {
        return this.props.contract.forecasts
            .map(forecast => forecast.actualForecast)
            .reduce(function (acumulator, value) {
                return value ? acumulator + value : acumulator;
            }, 0);
    }

    computeTotalInitialCashflow() {
        return this.props.contract.forecasts
            .map(forecast => forecast.initial)
            .reduce(function (acumulator, value) {
                return value ? acumulator + value : acumulator;
            }, 0);
    }

    render() {
        const cardClassname = "kb-list-item";
        const isAddingInvoiceClassName = this.props.addingInvoice ? " visible" : "";

        let totalInitialCashflow = 0;
        let totalActualCashflow = 0;
        if (this.props.forecasts && this.props.forecasts.length > 0) {
            totalInitialCashflow = this.computeTotalInitialCashflow();
            totalActualCashflow = this.computeTotalActualCashflow();
        }

        const getErrorMessageInitial = () => {
            if (this.props.contract.invoiced > this.props.contract.amount ) {
                return "Total invoiced amount exceeds the commited amount, please update contract amount and cashflow!";
            }
            if(totalActualCashflow !== this.props.contract.amount) {
                return "Contract amount is different than Total cash-flow amount, please update contract amount and cash flow!";
            }
            return false;
        }
        const getErrorMessageActual = () => {
            if (this.props.contract.invoiced > this.props.contract.amount ) {
                return "Total invoiced amount exceeds the commited amount, please update contract amount and cashflow!";
            }
            if(totalActualCashflow !== this.props.contract.amount) {
                return "Contract amount is different than Total cash-flow amount, please update contract amount and cash flow!";
            }
            return false;
        }

        const initialCashflowSum = computeForecastSum(this.props.contract.forecasts, 'Initial');
        const executionCashflowSum = computeForecastSum(this.props.contract.forecasts, 'Actual');

        return (
            <div className={"kb-list-accordion"}>
                <div className={cardClassname + " row d-flex align-items-center border-bottom"} id="headingOne">

                  {
                    this.props.categoryDetailsType && 
                    (this.props.categoryDetailsType === "COST" || this.props.categoryDetailsType === "INCOME") &&
                    (
                        <React.Fragment>
                        
                    <div className="col-5 text-left py-3">

                            <button className="btn btn-link p-2" type="button" title={this.props.contract.contractorName}
                                    onClick={this.toggleTable}>
                                <i className={this.state.collapsed ? "fas fa-angle-down mr-3" : "fas fa-angle-up mr-2"}/>
                                <span className="font-weight-bold">{this.props.contract.contractorName}</span>
                                <div className="text-grey font-weight-bold text-left pl-4">{this.props.contract.agreementReference}</div>
                            </button>

                    </div>
                    <div className="col text-left py-3 font-weight-bold">
                        {!this.props.shouldLoad && 
                            (getErrorMessageActual() || getErrorMessageInitial()) ? (
                            <i className="fas fa-exclamation-circle text-danger font-size-5"></i>
                        ) : ""}
                    </div>
                    <div className="col text-left py-3 font-weight-bold" title={this.props.contract.agreementReference}>
                        {this.getContractType()}
                    </div>
                    <div className="col text-right py-3 font-weight-bold">
                        <div>{this.props.shouldLoad ? 0 : prettyPrint(this.props.contract.amount)} EUR</div>
                    </div>
                    <div className="col text-right py-3 font-weight-bold">
                        <div>{this.props.shouldLoad ? 0 : prettyPrint(this.props.contract.invoiced)} EUR</div>
                    </div>
                    <div className="col text-right py-3 font-weight-bold">
                        <div>{this.props.shouldLoad ? 0 : prettyPrint(subtractDoubles(this.props.contract.amount, this.props.contract.invoiced))} EUR</div>
                    </div>
                    <div className="col two-btn-col text-right py-3">
                        <button className="kb-btn_edit btn btn-link px-2 ml-1"
                                title="Edit contract"
                                onClick={this.toggleEditContract}
                                disabled={this.props.shouldLoad}>
                            <i className="fas fa-pen"/>
                        </button>
                        <button className="kb-btn_delete btn btn-link px-2 ml-1"
                                title="Delete contract"
                                onClick={() => this.deleteContract(this.props.contract.id)}
                                disabled={this.props.shouldLoad}>
                            <i className="fas fa-trash"/>
                        </button>
                    </div>
                    <RenderIf condition={!this.state.collapsed}>
                    <div className="col-12 bg-white">
                        <div className="accordion mt-3">

                        <div className="row">
                        <div  className="col-4">
                            {(this.props.categoryType === 'COST' || this.props.categoryType === 'INCOME') && (
                                <React.Fragment>
                                <Button variant="light" className={this.state.activeTab === 'Initial' ? "font-size-2 px-4 py-2 mr-2 active font-weight-bold" : "font-size-2 px-4 py-2 mr-2"} size='sm'
                                    onClick={() => this.changeTab('Initial')}>
                                    Initial
                                </Button>
                                <Button variant="light" className={this.state.activeTab === 'Actual' ? "font-size-2 px-4 py-2 active font-weight-bold" : "font-size-2 px-4 py-2"} size='sm'
                                    onClick={() => this.changeTab('Actual')}>
                                    Actual
                                </Button>
                                </React.Fragment>
                            )}
                        </div>
                        <div  className="col-4 text-grey2">
                            {this.props.dateRange && this.props.dateRangeLimit && <DateRangeNavigator
                                dateRange={this.props.dateRange}
                                dateRangeLimit={this.props.dateRangeLimit}
                                updateDateRange={this.props.updateDateRange}
                            /> }
                        </div>
                        <div  className="col-4 text-left">
                            {(this.props.categoryType === 'COST' || this.props.categoryType === 'INCOME') &&(
                            <div className="row">
                                <div className="col text-right">
                                    <div className="d-inline-block text-left">
                                        <div className="text-left kb-real-forecast-title">Total {this.state.activeTab} cash flow </div>
                                        {(this.state.activeTab === 'Actual' && this.props.contract.contractType === 'AGREEMENT') && <div className="text-left kb-real-forecast-title">Remaining cash flow</div>}
                                    </div>
                                    <div className="d-inline-block text-right ml-3">
                                        <div className="text-right kb-real-forecast-value">{this.state.activeTab === 'Actual' ? prettyPrint(totalActualCashflow) : prettyPrint(totalInitialCashflow)} EUR</div>
                                        {(this.state.activeTab === 'Actual' && this.props.contract.contractType === 'AGREEMENT') && <div className="text-right kb-real-forecast-value">{prettyPrint(totalInitialCashflow - totalActualCashflow)} EUR</div>}
                                    </div>
                                </div>
                            </div>)}
                        </div>
                        <div  className="col-12">
                        {
                            (this.props.categoryType === 'COST' || this.props.categoryType === 'INCOME') && this.state.activeTab === 'Initial' && (
                                <React.Fragment>
                                <ForecastSplitTable
                                    forecasts={this.props.forecasts}
                                    forecastUpdated={this.props.forecastUpdated}
                                    forecastType={committedForecast.value}
                                    toBeForecasted={subtractDoubles(this.props.contract.amount, initialCashflowSum)}
                                    onSplitSave={this.props.onSplitSave}
                                    onMoveRight={this.props.onMoveRight}
                                    currentDate={this.props.currentDate}
                                    contractId={this.props.contract.id}
                                    invoicedPerMonthArray={this.props.contract.invoicedPerMonthArray}
                                    invoicesRequestParams={{
                                        projectId: this.props.invoicesRequestParams.projectId,
                                        categoryName: this.props.invoicesRequestParams.categoryName,
                                        contractId: this.props.contract.id,
                                        contractorName: this.props.contract.contractorName,
                                    }}
                                    type={'Initial'}
                                    category='Cashflow'
                                    categoryType={this.props.categoryType}
                                    openedMonths={this.props.openedMonths}
                                    activeTab={this.state.activeTab}
                                />
                                <div className="mt-3">
                                    <ButtonContainer
                                        resetForecasts={this.props.resetForecasts}
                                        clearAllForecasts={this.props.clearAllForecasts}
                                        contractId={this.props.contract.id}
                                        categoryType={this.props.categoryType}
                                        addInvoice={this.addInvoice}
                                        seeAllInvoices={this.props.seeAllInvoices}
                                        errorMessage={getErrorMessageInitial()}
                                    />
                                </div>
                                </React.Fragment>
                            )
                        }
                        {
                            (this.props.categoryType === 'COST' || this.props.categoryType === 'INCOME') && this.state.activeTab === 'Actual' && (
                                <React.Fragment>
                                <ForecastSplitTable
                                    forecasts={this.props.forecasts}
                                    forecastUpdated={this.props.forecastUpdated}
                                    forecastType={committedForecast.value}
                                    toBeForecasted={subtractDoubles(this.props.contract.amount, executionCashflowSum)}
                                    onSplitSave={this.props.onSplitSave}
                                    onMoveRight={this.props.onMoveRight}
                                    currentDate={this.props.currentDate}
                                    contractId={this.props.contract.id}
                                    invoicedPerMonthArray={this.props.contract.invoicedPerMonthArray}
                                    invoicesRequestParams={{
                                        projectId: this.props.invoicesRequestParams.projectId,
                                        categoryName: this.props.invoicesRequestParams.categoryName,
                                        contractId: this.props.contract.id,
                                        contractorName: this.props.contract.contractorName,
                                    }}
                                    type={'Actual'}
                                    category='Cashflow'
                                    categoryType={this.props.categoryType}
                                    openedMonths={this.props.openedMonths}
                                    activeTab={this.state.activeTab}
                                />
                                <div className="mt-3">
                                    <ButtonContainer
                                        resetForecasts={this.props.resetForecasts}
                                        clearAllForecasts={this.props.clearAllForecasts}
                                        contractId={this.props.contract.id}
                                        categoryType={this.props.categoryType}
                                        addInvoice={this.addInvoice}
                                        seeAllInvoices={this.props.seeAllInvoices}
                                        errorMessage={getErrorMessageActual()}
                                    />
                                </div>
                                </React.Fragment>
                            )
                        }
                        {
                            (this.props.categoryType === 'FINANCING' || this.props.categoryType === 'INVESTMENT') && (
                                <React.Fragment>
                                <ForecastSplitTable
                                    forecasts={this.props.forecasts}
                                    forecastUpdated={this.props.forecastUpdated}
                                    forecastType={committedForecast.value}
                                    toBeForecasted={subtractDoubles(this.props.contract.amount, this.props.forecastSum)}
                                    onSplitSave={this.props.onSplitSave}
                                    onMoveRight={this.props.onMoveRight}
                                    currentDate={this.props.currentDate}
                                    contractId={this.props.contract.id}
                                    invoicedPerMonthArray={this.props.contract.invoicedPerMonthArray}
                                    invoicesRequestParams={{
                                        projectId: this.props.invoicesRequestParams.projectId,
                                        categoryName: this.props.invoicesRequestParams.categoryName,
                                        contractId: this.props.contract.id,
                                        contractorName: this.props.contract.contractorName,
                                    }}
                                    openedMonths={this.props.openedMonths}
                                    activeTab={this.state.activeTab}
                                />
                                <div className="mt-3">
                                    <ButtonContainer
                                        resetForecasts={this.props.resetForecasts}
                                        clearAllForecasts={this.props.clearAllForecasts}
                                        contractId={this.props.contract.id}
                                        categoryType={this.props.categoryType}
                                        addInvoice={this.addInvoice}
                                        seeAllInvoices={this.props.seeAllInvoices}
                                    />
                                </div>
                                </React.Fragment>
                            )
                        }
                        </div>
                        </div>
                        </div>
                        </div>
                    </RenderIf>
                    </React.Fragment>
                )
              }
                    <RenderIf condition={this.state.editingContract}>
                        <div className='popup'>
                         <Button
                                                className="btn btn-link kb-btn_close-popup"
                                                onClick={this.togglePopup}
                                            />
                                            <div className="popup_inner">
                            <div className={"manage-contract edit-contract-form visible"}>
                                <div className={"manage-contract-layout"}>
                                    <div>
                                        <div className="category-details">
                                            <h5 className="kb-popup_title py-3 pt-5">Edit Contract: {this.props.contract.reference}</h5>
                                        </div>
                                        <EditContractForm
                                            contract={this.props.contract}
                                            vatList={this.props.vatList}
                                            refreshState={this.props.refreshState}
                                            onCancel={this.toggleEditContract}
                                        />
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </RenderIf>
                  <RenderIf condition={this.state.addingInvoice}>
                    <div className='popup'>
                      <Button
                        className="btn btn-link kb-btn_close-popup"
                        onClick={this.togglePopup}
                      >

                      </Button>
                      <div className="popup_inner">
                        <div className={"kb-manage-contractors kb-add-contractor-form" + isAddingInvoiceClassName}>

                          <InvoiceFormWrapper
                            fromBudgetLine={true}
                            projectId={this.props.projectId}
                            categoryType={this.props.categoryType}
                            budgetLine={this.props.budgetLine}
                            refreshState={this.props.refreshState}
                            cancelForm={this.cancelForm}
                            contract={this.props.contract.id}
                            inModal={true}
                            saveForecasts={this.props.saveForecasts}
                          />
                        </div>
                      </div>
                    </div>
                  </RenderIf>
                    <CloseMonth
                        condition={this.state.closeMonthModal}
                        toggleCloseMonthModal={this.toggleCloseMonthModal}
                        openedMonthsList={this.state.openedMonths}
                        closedMonths={this.state.closedMonths}
                        projectId={this.props.projectId}
                    />
                </div>
            </div>
        )
    }
}

MonthlyExecutionContractForecastView.propTypes = {
    contract: PropTypes.shape({
        id: PropTypes.string.isRequired,
        contractorName: PropTypes.string.isRequired,
        agreementReference: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        vat: PropTypes.number.isRequired,
        amount: PropTypes.number.isRequired,
        invoiced: PropTypes.number.isRequired,
        invoicedPerMonthArray: PropTypes.array.isRequired,
    }).isRequired,
    forecasts: PropTypes.array.isRequired,
    forecastSum: PropTypes.number.isRequired,
    forecastUpdated: PropTypes.func.isRequired,
    refreshState: PropTypes.func.isRequired,
    onSplitSave: PropTypes.func.isRequired,
    onMoveRight: PropTypes.func.isRequired,
    currentDate: PropTypes.instanceOf(Moment).isRequired,
    resetForecasts: PropTypes.func.isRequired,
    clearAllForecasts: PropTypes.func.isRequired,
    seeAllInvoices: PropTypes.func.isRequired,
    addInvoice: PropTypes.func.isRequired,
    categoryType: PropTypes.string,
    onCancel: PropTypes.func,
    invoicesRequestParams: PropTypes.shape({
        projectId: PropTypes.string,
        categoryName: PropTypes.string,
    }),
    openedMonths: PropTypes.array
};

export default withRouter(MonthlyExecutionContractForecastView);
