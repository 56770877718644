export const titleToUrl = (title) =>{
  if (title) {
    return encodeURI(title.toLowerCase().replace(/\s/g, '_'));
  }
  return "";
}

export const makeId = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return "uniqueRoleId" + result + new Date().getTime();
}