import React from "react";
import PropTypes from "prop-types";
import GenericTemplateCard from "./GenericTemplateCard";

function CloneTemplateCard(props) {
    return (
        <GenericTemplateCard template={props.template}>
            <button className="btn btn-primary" onClick={() => props.onClick(props.template.id)}>Use This</button>
        </GenericTemplateCard>
    )
}

CloneTemplateCard.propTypes = {
    template: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string
    }).isRequired,
    onClick: PropTypes.func.isRequired
};

export default CloneTemplateCard;