import React, { useEffect, useState } from "react";
//import ContentPage from "../../../main-components/ContentPage";
import EditTemplateForm from "../forms/EditTemplateForm";
import {createTemplate, getAllTemplates, cloneTemplate} from "../actions/templateActions";
import Breadcrumbs from "../../../components-shared/Breadcrumbs/Breadcrumbs";

function NewTemplatePage(props) {
    // noinspection CheckTagEmptyBody
    const [templates, setTemplates] = useState([]);
    useEffect(() => {
        getAllTemplates().then(tmpl => {
            setTemplates(tmpl)
        });
    }, []);
    return (
         <React.Fragment>
              <nav className="kb-nav_primary navbar bg-dark d-block">
                    <button className="btn btn-link kb-close-link text-white mr-3" onClick={props.history.goBack}><i className="fas fa-arrow-left  mr-3"></i> Go back</button>
                </nav>
                 <Breadcrumbs breadcrumbs={[{url: '/projects', title: 'Home'}, {url: '/template/new', title: 'New Template'}]} />
                <header className="kb-header px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 d-flex align-items-center ">
                                <div className="kb-title_page d-flex align-items-center py-4">
                                    <h1 className="h4 font-weight-normal m-0">New template</h1>
                                </div>
                                <nav className="kb-nav_ctas ml-auto">
                                </nav>
                            </div>
                        </div>
                    </div>
                </header>
                <main className="kb-main kb-main_template--new bg-white">
                    <div className="container-fluid">
                        <div className="row">
                         <div className="col-12 col-md-6 col-xl-4 pb-5">
                            <h5 className="py-3">Details</h5>
                           <EditTemplateForm submitFunction={createTemplate} cloneFunction={cloneTemplate} templates={templates}/>
                        </div>
                        <div className="col-12 col-md-5 offset-lg-1 ">
                            <h5 className="py-3">How to use a template?</h5>
                            <ul className="pl-3">
                                <li className="pb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </li>
                                <li className="pb-3">Nunc vestibulum orci ac sodales dapibus. </li>
                                <li className="pb-3">Phasellus quis justo sagittis, faucibus ex sed, elementum ex. </li>
                                <li className="pb-3">Etiam ac risus ac quam viverra vulputate ac quis dolor. </li>
                                <li className="pb-3">Nam non sapien tristique, suscipit metus eget, tempus elit. </li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </main>
            </React.Fragment>
    );
}

export default NewTemplatePage;



