import React, {Component} from "react";
import PropTypes from "prop-types";
import GroupedActions from "../../../components-shared/GroupedActions";

function NameView(props) {
    return (
        <React.Fragment>
            <div className={props.className + "-name kb-category-view-name kb-list-item-title mr-auto"}>{props.name}</div>
            <GroupedActions>
                <button className={props.className + "-edit"} onClick={props.onEdit}>Edit</button>
                {props.children}
            </GroupedActions>
        </React.Fragment>
    )
}

NameView.propTypes = {
    className: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired
};

function NameEdit(props) {
    return (
        <React.Fragment>
            <input
                id={"name"}
                autoFocus
                className={props.className + "-form kb-list-item-title_edit form-control"}
                type="projectName"
                value={props.name}
                onChange={props.handleChange}
                onKeyPress={props.handleKeyPress}
            />
            <GroupedActions>
                <button className={props.className + "-save"} onClick={props.onSave}>Save</button>
                {props.children}
            </GroupedActions>
        </React.Fragment>
    )
}

NameEdit.propTypes = {
    className: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleKeyPress: PropTypes.func.isRequired
};

class CategoryTotalViewAndEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            name: this.props.categoryTotal.name
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleEdit() {
        this.setState({editing: !this.state.editing});
    };

    handleSave() {
        const response = this.props.onEdit(this.state);
        if (response === "") {
            this.setState({editing: !this.state.editing});
        }
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.handleSave();
        }
    }

    render() {
        const className = 'kb-view_total';
        return (
            <div className={className +  " kb-list-item_header d-flex align-items-center px-3 py-2"}>
                <div className="kb-view_icon mr-3"><i className="fas fa-layer-group"></i></div>
                {this.state.editing ?
                    <input
                        id={"name"}
                        autoFocus
                        className={className + "-form mr-auto kb-list-item-title_edit form-control"}
                        type="projectName"
                        value={this.state.name}
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                        autoComplete="off"
                    />
                    :
                    <div className={className + "-name kb-category-view-name kb-list-item-title mr-auto"}>
                        <span className="text-break">{this.props.categoryTotal.name}</span>
                        <span className="kb-name_badge text-primary ml-2">{this.props.categoryTotal.assignedCategories ? this.props.categoryTotal.assignedCategories.length : 0}</span>
                    </div>
                }

                 <GroupedActions>
                    {this.state.editing ?
                        <button className={"kb-btn_save btn btn-link text-primary px-2 ml-1"}
                                onClick={this.handleSave}>
                            <i className="fas fa-check"></i>
                        </button>
                        :
                        <button className={"kb-btn_edit  btn btn-link px-2 ml-1"}
                                onClick={this.handleEdit}>
                            <i className="fas fa-pen"></i>
                        </button>
                    }
                    <button className={"kb-btn_delete  btn btn-link px-2 ml-1"}
                            onClick={this.props.onDelete}>
                         <i className="fas fa-trash"></i>
                    </button>
                </GroupedActions> 

            </div>
        );
    }
}

CategoryTotalViewAndEdit.propTypes = {
    categoryTotal: PropTypes.shape({
        name: PropTypes.string.isRequired,
        assignedCategories: PropTypes.array,
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
};

export default CategoryTotalViewAndEdit;
