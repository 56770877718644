import React, {Component, useContext, useState} from "react";
import {getTemplateById} from "../actions/templateActions";
import {NavLink} from "react-router-dom";
import DisplayTemplateInfoSection from "./sections/DisplayTemplateInfoSection";
import {categoryEntityType, categoryTypes} from "../../category/constant/CategoryConstants";
import CategoryManagementComponentContainer from "../../category/CategoryManagementComponentContainer";
//import SettingsPageTitle from "../../settings/SettingsPageTitle";
import BackButtonNavigationBar from "../../../components-shared/BackButtonNavigationBar";
import Breadcrumbs from "../../../components-shared/Breadcrumbs/Breadcrumbs";
import { Tabs, Tab } from "react-bootstrap";
import CategoriesContext, { CategoriesContextProvider } from "../../../main-components/CategoriesContext";

class ManageTemplatePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            templateDetails: null,
            error: '',
            initialTemplate: null
        };

        this.getTemplateId = this.getTemplateId.bind(this);
        this.refreshState = this.refreshState.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
    }

    getTemplateId() {
        return this.props.match.params.templateId;
    }

    componentDidMount() {

        if (this.getTemplateId()) {
            this.refreshState();
        } else {
            this.setState({error: "Template identifier not defined!"});
        }
    }

    refreshState() {
        getTemplateById(this.getTemplateId()).then((result) => {
            if (result.code) {
                this.setState({error: result.message});
            } else {
                this.setState({
                    templateDetails: result,
                    error: '',
                    initialTemplate: result
                })
            }
        });
    }

    cancelEdit() {
        this.setState({
            templateDetails: this.state.initialTemplate
        })
    }

    render() {

        const templateId = this.getTemplateId();
        if (this.state.error) {
            return (
                <div className={"loading-placeholder"}>
                    <h2>Error "{this.state.error}" while getting the template</h2>
                    <h2>Go <NavLink to={"/settings/templates"}>back</NavLink> and retry</h2>
                </div>
            );
        }

        if (!templateId || !this.state.templateDetails) {
            return (
                <div className={"loading-placeholder"}>
                    <h2>No template selected</h2>
                    <h2>Go <NavLink to={"/settings/templates"}>back</NavLink> and retry</h2>
                </div>
            );
        }

        // noinspection CheckTagEmptyBody
        return (
            <React.Fragment>
            <BackButtonNavigationBar classNamePrefix={"settings"}/>

                <Breadcrumbs breadcrumbs={[{url: '/projects', title: 'Home'}, {url: '/settings' , title: 'Settings' }, {url: '/settings/templates' , title: 'Templates' }, {url: '/template/view/' + templateId, title: 'View Template'}]} />
                <header className="kb-header px-3">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center ">
                                    <div className="kb-title_page d-flex align-items-center py-4">
                                        <h1 className="h4 font-weight-normal m-0">View Template</h1>
                                    </div>
                                    <nav className="kb-nav_ctas ml-auto">
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </header>
                    <main className="kb-main kb-main_template--view px-3 pb-4 bg-white">
                        <DisplayTabs 
                            templateDetails={this.state.templateDetails} 
                            refreshState={this.refreshState} 
                            categoryTypes={categoryTypes} 
                            templateId={templateId} 
                            categoryEntityType={categoryEntityType} 
                            cancelEdit={this.cancelEdit}/>
                    </main>
                    
                </React.Fragment>
        );
    }
}

const DisplayTabs = (props) => {
    const [categoriesLengths, setCategory] = useContext(CategoriesContext);
    const [key, setKey] = useState('pills-details')
    const { templateDetails, refreshState, categoryTypes, templateId, categoryEntityType, cancelEdit } = props;
    return (
        <Tabs
            transition={false} 
            id="controlled-tab-example"
            activeKey={key}
            onSelect={key => setKey(key)}
            className="kb-tabs-nav px-3 bg-light pb-3"  variant='pills'
        >
            <Tab tabClassName="kb-tabs-nav px-4 mr-2" eventKey="pills-details" title="Details">
                <DisplayTemplateInfoSection template={templateDetails} refreshState={refreshState} cancelEdit={cancelEdit} />
            </Tab>
            
            {categoryTypes.map(categoryType =>
                (<Tab tabClassName="kb-tabs-nav px-4 mr-2" eventKey={categoryType.type.toLowerCase()} title={categoryType.displayValue + ' ' + categoriesLengths[categoryType.type.toLowerCase()]
            } key={categoryType.type.toLowerCase()}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <CategoriesContextProvider>
                                <CategoryManagementComponentContainer 
                                    entityId={templateId} 
                                    categoryType={categoryType} 
                                    entityType={categoryEntityType.templateCategory} 
                                    categoriesLengths={categoriesLengths} 
                                    setCategory={setCategory} 
                                    collapsed={false}
                                />
                            </CategoriesContextProvider>
                        </div>
                    </div>
                </div>
            </Tab>))}
        </Tabs>
    )
}

export default ManageTemplatePage;
