import React, {useEffect, useState} from 'react';
import { prettyPrint } from '../../../common/numberOperations';
import LineGraph from './LineGraph';

const FacilityReport = (props) => {
  const [dropdown, setDropdown] = useState(false);
  const  facilityReport  = props.facilityReport;

  return (
          <div className="col-md-6 font-weight-bold">
            <div className="p-4 bg-white mb-1">
              <div className="row">
                <div className="col-md-6 col-lg-7 align-self-center mb-3 mt-3">

                {facilityReport.shouldLoad ?  (
                  <div className='container-inline-div'> 
                    <div className='inline-div'>
                      {props.title} 
                    </div>
                    <div className="spinner inline-div" />
                  </div>
                  
                ) : (
                  <div>
                    {props.title}
                  </div>
                )}
                  <div className="font-size-4 font-weight-bold">{facilityReport && prettyPrint(facilityReport.total)} EUR</div>
                </div>
                <div className="col-md-4 align-self-center mb-3 mt-3">
                  <LineGraph percentage={facilityReport && facilityReport.percentageOfCost} color="#03B274" background="#efefef" height="5px"/>
                  <div className="font-size-3 font-weight-bold text-right text-primary">{facilityReport && prettyPrint(facilityReport.percentageOfCost)}% of cost</div>
                </div>
                <div className="col-md-2 col-lg-1 align-self-center text-right mb-3 mt-3">
                  <i className={"fas font-size-8 cursor-pointer" + (dropdown ? " fa-angle-up" : " fa-angle-down")} onClick={() => setDropdown(!dropdown)}></i>
                </div>
                {dropdown && 
                  <div className="col-12">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col" className="text-right"></th>
                          <th className="text-right">Total facility</th>
                          <th className="text-right">Total drawdown</th>
                          <th className="text-right">Remaining</th>
                        </tr>
                      </thead>
                      <tbody>
                        {facilityReport && facilityReport.facilityReports && facilityReport.facilityReports.map((facility, i) => (<tr key={i}>
                          <th scope="col">{facility.name}</th>
                          <td className="col-3 text-right pr-3">{prettyPrint(facility.totalFacility)} EUR</td>
                          <td className="col-3 text-right pr-3">{prettyPrint(facility.totalDrawdown)} EUR</td>
                          <td className="col-3 text-right pr-3">{prettyPrint(facility.remaining)} EUR</td>
                        </tr>))}
                      </tbody>
                    </table>
                    {(!facilityReport || !facilityReport.facilityReports || (facilityReport && facilityReport.facilityReports && facilityReport.facilityReports.length <= 0)) && (<div className='text-center'>No facilities </div>)}
                  </div>
                }
              </div>
            </div>
          </div>
  );
}

export default FacilityReport;
