import React, {Component} from 'react';

import {logout} from "../components/user/actions/userActions";
import {AppContextData} from "../main-components/AppContextData";

class Logout extends Component {

    handleLogout(e) {
        logout(e).then(() => {
            AppContextData.resetToDefaults();
            // window.location.assign("/");
            // window.location.reload();
            window.location.href = '/';
        });
    }

    render() {
        return <span className="dropdown-item logout" onClick={(e) => this.handleLogout(e)}>
            <span>Log Out</span>
        </span>;
    }
}

export default Logout;
