import React from "react";
import {FormLabel, FormControl, FormGroup} from "react-bootstrap";
import {withRouter} from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import CustomDatePicker from "../../components-shared/CustomDatePicker";
import PropTypes from "prop-types";
import moment from "moment";
import CommaSeparatedInput from "../../components-shared/CommaSeparatedInput/CommaSeparatedInput";

const ProjectDetails = (props) => {
    const {handleChange, projectDetails, formIsEnabled, displayErrors} = props;

    const inputClassName = " ";
    return (
        <div className="container-fluid px-0">
            <div className="row">
                <div className="col-12">
                    <FormGroup className={inputClassName} controlId="name">
                        <FormLabel>Project Name:</FormLabel>
                        <FormControl
                            type="text"
                            value={projectDetails.name ? projectDetails.name : ''}
                            onChange={e => handleChange("name", e.target.value)}
                            disabled={!formIsEnabled}
                        />
                        {displayErrors.name &&
                        <div className="text-danger input-error">Project name is mandatory</div>
                        }
                    </FormGroup>
                </div>
                <div className="col-12">
                    <FormGroup className={inputClassName} controlId="description">
                        <FormLabel>Description:</FormLabel>
                        <FormControl
                            type="text"
                            value={projectDetails.description ? projectDetails.description : ""}
                            onChange={e => handleChange("description", e.target.value)}
                            disabled={!formIsEnabled}
                        />
                    </FormGroup>
                </div>
                <div className="col-12">
                    <FormGroup className={inputClassName} controlId="location">
                        <FormLabel>Location:</FormLabel>
                        <FormControl
                            type="text"
                            value={projectDetails.location ? projectDetails.location : ''}
                            onChange={e => handleChange("location", e.target.value)}
                            disabled={!formIsEnabled}
                        />
                    </FormGroup>
                </div>
                <div className="col-12">
                    <FormGroup className={inputClassName}>
                        <FormLabel>Estimated Budget:</FormLabel>
                            <CommaSeparatedInput
                                elementType="FormControl"
                                type="number"
                                decimalsAfterDot={0}
                                min={0}
                                id="estimatedBudget"
                                value={projectDetails.estimatedBudget ? projectDetails.estimatedBudget : ''}
                                handleChange={e => {handleChange("estimatedBudget", e.target.value);}}
                                disabled={!formIsEnabled}
                            />
                    </FormGroup>
                </div>
                <div className="col-12">
                    <FormGroup className={inputClassName} controlId="startDate">
                        <FormLabel className="d-block">Start Date:</FormLabel>
                        <CustomDatePicker
                            selected={projectDetails.startDate ? moment(new Date(projectDetails.startDate)) : ''}
                            onChange={date => handleChange("startDate", date)}
                            disabled={!formIsEnabled}
                        />
                    </FormGroup>
                </div>
                <div className="col-12">
                    <FormGroup className={inputClassName} controlId="estimatedEndDate">
                        <FormLabel className="d-block">Estimated End Date:</FormLabel>
                        <CustomDatePicker
                            selected={projectDetails.estimatedEndDate ? moment(new Date(projectDetails.estimatedEndDate)) : ''}
                            onChange={date => handleChange("estimatedEndDate", date)}
                            disabled={!formIsEnabled}
                        />
                    </FormGroup>
                </div>

                <div className="col-12">
                    <FormGroup className={inputClassName} controlId="additionalInfo">
                        <FormLabel>Additional Info:</FormLabel>
                        <FormControl
                            type="text"
                            value={projectDetails.additionalInfo ? projectDetails.additionalInfo : ''}
                            onChange={e => handleChange("additionalInfo", e.target.value)}
                            disabled={!formIsEnabled}
                        />
                    </FormGroup>
                </div>
                <div className="col-12">
                    <FormGroup className={inputClassName} controlId="created">
                        <FormLabel>Created date:</FormLabel>
                        <CustomDatePicker
                            selected={projectDetails.created ? moment(new Date(projectDetails.created)) : ''}
                            disabled={true}
                        />
                    </FormGroup>
                </div>
            </div>
        </div>
    )
}

ProjectDetails.propTypes = {
    projectDetails: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        location: PropTypes.string,
        estimatedBudget: PropTypes.number | PropTypes.string,
        startDate: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.object
        ]),
        estimatedEndDate: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.object
        ]),
        additionalInfo: PropTypes.string,
        createdDate: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.object
        ])
    }),
    handleChange: PropTypes.func,
    formIsEnabled: PropTypes.bool,
    displayErrors: PropTypes.object,
};

export default withRouter(ProjectDetails);