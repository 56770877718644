import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, FormLabel, FormGroup} from "react-bootstrap";
import {divideDoubles, multiplyDoubles, subtractDoubles} from "../../common/doubleOperations";
import moment from "moment";
import {dateMonthYearFormat, stringToCustomFormattedDate} from "../../common/dateFormatting";
import CommaSeparatedInput from "../../../components-shared/CommaSeparatedInput/CommaSeparatedInput";

// this component is pretty useless, since, for each change in the parent, this component re-renders. extract all the contents here in the
// AdvancedFormulaSplitForm, that's were this belongs
class AdvancedFormulaValuesForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            splitValues: [],
        };

        this.createSplitValues = this.createSplitValues.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    componentDidMount() {

        this.createSplitValues();
    }

    /**
     * Create the split values. Each object created for the array will contain the index based month + the computed value (amount / number of months
     * to be split by). The last value of the split will be the subtraction of the total and all of the values so far (since there can be a case where for
     * example 0.01 can remain unassigned => 100/3).
     * The result is an array of (date, amount) pairs.
     */
    createSplitValues() {

        let splitValues = [];

        let changeableDate = this.props.startDate;

        // Outer loop to create the children
        for (let i = 0; i < this.props.numberOfMonths; i++) {

            const forecastUpdate = {};
            forecastUpdate.date = changeableDate;

            let divisionValue = divideDoubles(this.props.amount, this.props.numberOfMonths);

            if (i === this.props.numberOfMonths - 1) {
                forecastUpdate.amount = subtractDoubles(this.props.amount, multiplyDoubles(this.props.numberOfMonths - 1, divisionValue));
            } else {
                forecastUpdate.amount = divisionValue;
            }

            changeableDate = moment(changeableDate).add(1, 'M');

            // Create the initial Split values...
            splitValues.push(forecastUpdate)
        }

        this.setState({
            splitValues: splitValues,
        });
    }

    /**
     * The index is what defines the element we want to change.
     * If a number is already set in the array, then the value will be overridden and the new array will be set.
     * If the number is not set in the array, then a new value will be inserted.
     * The value from the calculated split will also be updated.
     *
     * @param index
     * @param event
     */
    handleChange(index, event) {

        let newArray = this.state.splitValues.slice();
        newArray[index].amount = event.target.value;

        this.setState({splitValues: newArray})
    }

    handleSave() {
        this.props.onSave(this.state.splitValues, this.props.forecastType, this.props.contractId, this.props.activeTab);
        this.props.closeSplitForm();
    }

    render() {

        return (
            <React.Fragment>
                {this.state.splitValues &&
                this.state.splitValues.map((splitValue, index) =>
                    <div key={index}>
                        <FormGroup className="form-group form-group-lg form-inline">
                            <FormLabel>Amount for {stringToCustomFormattedDate(splitValue.date, dateMonthYearFormat)}</FormLabel>
                            <CommaSeparatedInput
                                elementType="FormControl"
                                type="number"
                                decimalsAfterDot={2}
                                min={0}
                                id={"amount"}
                                value={this.state.splitValues[index].amount}
                                handleChange={(event) => this.handleChange(index, event)}
                                parentClassName="w-100"
                                className="w-100"
                            />
                        </FormGroup>
                    </div>
                )}
                    
                {this.props.numberOfMonths > 0 &&
                <Button onClick={this.handleSave}>
                    Save
                </Button>
                }
                <Button variant="light" className="ml-3" onClick={this.props.closeSplitForm}>Cancel</Button>
            </React.Fragment>
        );
    }
}

AdvancedFormulaValuesForm.propTypes = {
    numberOfMonths: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    startDate: PropTypes.object.isRequired,
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    forecastType: PropTypes.string.isRequired,
    contractId: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    closeSplitForm: PropTypes.func.isRequired,
};

export default AdvancedFormulaValuesForm;
