import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, NavLink } from "react-router-dom";
import Breadcrumbs from "../../../../components-shared/Breadcrumbs/Breadcrumbs";
import RightSideModal from "../../../../components-shared/RightSideModal/RightSideModal";
import { getClosedMonths, exportTraceabilityAction } from '../../actions/projectActions';
import { confirmAlert } from 'react-confirm-alert';
import {getEndOfMonthBalance} from "../traceabilityActions";
import TraceabilityForm from './TraceabilityForm';
import moment from "moment";
import {prettyPrint} from "../../../common/numberOperations";

const ProjectTraceability = (props) => {
  const { projectId } = props;
  const [closedMonths, setClosedMonths] = useState([]);
  const [primaryMonth, setPrimaryMonth] = useState({});

  const [secondaryMonth, setSecondaryMonth] = useState({});
  const [selectComponent, setSelectComponent] = useState('Cost');
  const [selection, setSelection] = useState('Cost');
  const [noClosedMonthError, setNoClosedMonthError] = useState('');

  const [blurPrimaryMonth, setBlurPrimaryMonth] = useState(false);
  const [blurSelectComponent, setBlurSelectComponent] = useState(false);

  const [saved] = useState(false);

  const [data, setData] = useState(null);
  
  const componentIsMounted = useRef(false);
  const setComponentIsMounted = useCallback((val) => {
      componentIsMounted.current = val;
  }, []);

  const [errorsToCheck] = useState([
    {
      errMsg: "The field is mandatory",
      isValid: (value) => {
        return value !== '' && value !== null && value !== undefined;
      },
      usedFor: ['primaryMonth', 'selectComponent']
    }
  ]);


  useEffect(() => {
    return () => {
        if (componentIsMounted.current) {
            setComponentIsMounted(false);
        }
    }
}, [setComponentIsMounted]);

  useEffect(() => {
      setComponentIsMounted(true);
      getClosedMonths(projectId).then((res) => {
        if (componentIsMounted.current) {
          setClosedMonths(res);
          if(res.length > 0) {
            setPrimaryMonth(res[0].id);
            setNoClosedMonthError('');
          } else {
            setNoClosedMonthError('NO closed month available to display');
          }
        }
      });
  }, [componentIsMounted, setComponentIsMounted, projectId])


  const errorCheck = (fieldValue, fieldName) => {
    let errorToShow = '';
    errorsToCheck.forEach(err => {
      if (err.usedFor.includes(fieldName)) {
        if (!err.isValid(fieldValue)) {
          errorToShow = (<div className="text-danger"><small>{err.errMsg}</small></div>);
        }
      }
    })
    return errorToShow;
  }

  const exportTraceability = () => {
    if (closedMonths.length === 0) {
      confirmAlert({
          title: 'Notification message',
          message: 'Closed months not available, unable to export',
          buttons: [
              {
                  label: 'Ok'
              }
          ]
      });
    } else {
        const primaryMonthData = closedMonths.filter(closedMonth => closedMonth.id === primaryMonth)[0];
        const secondaryMonthData = closedMonths.filter(closedMonth => closedMonth.id === secondaryMonth)[0];
      const traceabilityExportData = {
          projectId: projectId,
          primaryYear:primaryMonthData.year,
          primaryMonth:primaryMonthData.monthAsInteger,
          secondaryYear:secondaryMonthData.year,
          secondaryMonth:secondaryMonthData.monthAsInteger,
          categoryType:selection.toUpperCase()
      };
      exportTraceabilityAction(projectId, traceabilityExportData).then((response) =>  {
          const filename =  "End_Of_Month_Balance.xlsx"

          if(response.ok === false || response.code === 18003) {
              confirmAlert({
                  title: 'Notification message',
                  message: 'Traceability file can not be exported !',
                  buttons: [
                      {
                          label: 'Ok'
                      }
                  ]
              });
          } else {
              response.blob().then(blob => {
                  let url = window.URL.createObjectURL(blob);
                  let link = document.createElement('a');
                  link.href = url;
                  link.download = filename;
                  link.click();
              });
          }
      })
    }
  }


  const displayCostTableHeading = () => {
    return selection === 'Cost' && (
      <React.Fragment>
        <th scope="col">Initial budget</th>
        <th scope="col">Execution budget</th>
        <th scope="col">Committed</th>
        <th scope="col">Invoiced</th>
        <th scope="col">To be Invoiced</th>
        <th scope="col">Uncommitted</th>
        <th scope="col">Forecast</th>
        <th scope="col">Total</th>
        <th scope="col">Status</th>
      </React.Fragment>
    )
  }
  const displayIncomeTableHeading = () => {
    return selection === 'Income' && (
      <React.Fragment>
        <th scope="col">Initial budget</th>
        <th scope="col">Execution budget</th>
        <th scope="col">Committed</th>
        <th scope="col">Invoiced</th>
        <th scope="col">To be Invoiced</th>
        <th scope="col">Uncommitted</th>
        <th scope="col">Forecast</th>
        <th scope="col">Total</th>
        <th scope="col">Status</th>
      </React.Fragment>
    )
  }
  const displayFinancingTableHeading = () => {
    return selection === 'Financing' && (
      <React.Fragment>
        <th scope="col">Initial budget</th>
        <th scope="col">Execution budget</th>
        <th scope="col">Contribution Per Entity</th>
        <th scope="col">Drawdown</th>
        <th scope="col">Remaining Balance</th>
        <th scope="col">Outstanding Balance</th>
        <th scope="col">Loan To Cost</th>
      </React.Fragment>
    )
  }
  const displayInvestmentTableHeading = () => {
    return selection === 'Investment' && (
      <React.Fragment>
        <th scope="col">Initial budget</th>
        <th scope="col">Execution budget</th>
        <th scope="col">Contribution Per Entity</th>
        <th scope="col">Drawdown</th>
        <th scope="col">Remaining Balance</th>
        <th scope="col">Outstanding Balance</th>
        <th scope="col">Loan To Cost</th>
      </React.Fragment>
    )
  }

  const displayCostTableRow = (elements, isParent = false) => {
      let costData
      if (isParent) {
          costData = elements.rootCategories.filter(category => category.primaryMonth.categoryType === 'COST')
      } else {
          costData = elements;
      }
    return selection === 'Cost' && costData.length > 0 && costData.map( (cd) => (
      <React.Fragment key={cd.primaryMonth.categoryId}>
      <tr className={isParent ? "bg-light" : "bg-white"}>
        <td className={"col-5 font-weight-bold" + (!isParent ? " pl-5" : "")}>
          {!isParent ? 
              <Link 
              className="text-primary" 
              to={"/manageExecution/" + cd.primaryMonth.categoryId + '/' + cd.primaryMonth.projectId + '/' + cd.primaryMonth.categoryType}>
              {cd.primaryMonth.name}
            </Link> 
          : cd.primaryMonth.name}
        </td>
        <td>
          <div>{prettyPrint(cd.primaryMonth.initialBudget)}</div>
          <div>{prettyPrint(cd.secondaryMonth.initialBudget)}</div>
        </td>
          <td>
              <div>{prettyPrint(cd.primaryMonth.executionBudget)}</div>
              <div>{prettyPrint(cd.secondaryMonth.executionBudget)}</div>
          </td>
          <td>
              <div>{prettyPrint(cd.primaryMonth.committed)}</div>
              <div>{prettyPrint(cd.secondaryMonth.committed)}</div>
          </td>
          <td>
              <div>{prettyPrint(cd.primaryMonth.invoiced)}</div>
              <div>{prettyPrint(cd.secondaryMonth.invoiced)}</div>
          </td>
          <td>
              <div>{prettyPrint(cd.primaryMonth.toBeInvoiced)}</div>
              <div>{prettyPrint(cd.secondaryMonth.toBeInvoiced)}</div>
          </td>
          <td>
              <div>{prettyPrint(cd.primaryMonth.uncommitted)}</div>
              <div>{prettyPrint(cd.secondaryMonth.uncommitted)}</div>
          </td>
          <td>
              <div>{prettyPrint(cd.primaryMonth.forecast)}</div>
              <div>{prettyPrint(cd.secondaryMonth.forecast)}</div>
          </td>
          <td>
              <div>{prettyPrint(cd.primaryMonth.total)}</div>
              <div>{prettyPrint(cd.secondaryMonth.total)}</div>
          </td>
          <td>
              <div>{prettyPrint(cd.primaryMonth.status)}</div>
              <div>{prettyPrint(cd.secondaryMonth.status)}</div>
          </td>
          </tr>
          {cd.subcategoriesAggregates ? displayCostTableRow(cd.subcategoriesAggregates) : <React.Fragment></React.Fragment>}

      </React.Fragment>
    ))
  }
  const displayIncomeTableRow = (elements, isParent = false) => {
      let incomeData
      if (isParent) {
          incomeData = elements.rootCategories.filter(category => category.primaryMonth.categoryType === 'INCOME')
      } else {
          incomeData = elements;
      }
      return selection === 'Income' && incomeData.length > 0 && incomeData.map( (cd) => (
          <React.Fragment key={cd.primaryMonth.categoryId}>
            <tr className={isParent ? "bg-light" : "bg-white"}>
              <td className={"col-5 font-weight-bold" + (!isParent ? " pl-5" : "")}>
                {!isParent ? 
                    <Link 
                    className="text-primary" 
                    to={"/manageExecution/" + cd.primaryMonth.categoryId + '/' + cd.primaryMonth.projectId + '/' + cd.primaryMonth.categoryType}>
                    {cd.primaryMonth.name}
                  </Link> 
                : cd.primaryMonth.name}
              </td>
              <td>
                  <div>{prettyPrint(cd.primaryMonth.initialBudget)}</div>
                  <div>{prettyPrint(cd.secondaryMonth.initialBudget)}</div>
              </td>
              <td>
                  <div>{prettyPrint(cd.primaryMonth.executionBudget)}</div>
                  <div>{prettyPrint(cd.secondaryMonth.executionBudget)}</div>
              </td>
              <td>
                  <div>{prettyPrint(cd.primaryMonth.committed)}</div>
                  <div>{prettyPrint(cd.secondaryMonth.committed)}</div>
              </td>
              <td>
                  <div>{prettyPrint(cd.primaryMonth.invoiced)}</div>
                  <div>{prettyPrint(cd.secondaryMonth.invoiced)}</div>
              </td>
              <td>
                  <div>{prettyPrint(cd.primaryMonth.toBeInvoiced)}</div>
                  <div>{prettyPrint(cd.secondaryMonth.toBeInvoiced)}</div>
              </td>
              <td>
                  <div>{prettyPrint(cd.primaryMonth.uncommitted)}</div>
                  <div>{prettyPrint(cd.secondaryMonth.uncommitted)}</div>
              </td>
              <td>
                  <div>{prettyPrint(cd.primaryMonth.forecast)}</div>
                  <div>{prettyPrint(cd.secondaryMonth.forecast)}</div>
              </td>
              <td>
                  <div>{prettyPrint(cd.primaryMonth.total)}</div>
                  <div>{prettyPrint(cd.secondaryMonth.total)}</div>
              </td>
              <td>
                  <div>{prettyPrint(cd.primaryMonth.status)}</div>
                  <div>{prettyPrint(cd.secondaryMonth.status)}</div>
              </td>
              </tr>
              {cd.subcategoriesAggregates ? displayIncomeTableRow(cd.subcategoriesAggregates) : <React.Fragment></React.Fragment>}
          </React.Fragment>
      ))
  }
  const displayFinancingTableRow = (elements, isParent = false) => {
      let financingData
      if (isParent) {
          financingData = elements.rootCategories.filter(category => category.primaryMonth.categoryType === 'FINANCING')
      } else {
          financingData = elements;
      }
    return selection === 'Financing' && financingData.length > 0  && financingData.map((cd) => (
      <React.Fragment key={cd.primaryMonth.categoryId}>
      <tr className={isParent ? "bg-light" : "bg-white"}>
          <td className={"col-5 font-weight-bold" + (!isParent ? " pl-5" : "")}>
            {!isParent ? 
                <Link 
                className="text-primary" 
                to={"/manageExecution/" + cd.primaryMonth.categoryId + '/' + cd.primaryMonth.projectId + '/' + cd.primaryMonth.categoryType}>
                {cd.primaryMonth.name}
              </Link> 
            : cd.primaryMonth.name}
          </td>
        <td>
          <div>{prettyPrint(cd.primaryMonth.initialBudget)}</div>
          <div>{prettyPrint(cd.secondaryMonth.initialBudget)}</div>
        </td>
          <td>
              <div>{prettyPrint(cd.primaryMonth.executionBudget)}</div>
              <div>{prettyPrint(cd.secondaryMonth.executionBudget)}</div>
          </td>
          <td>
              <div>{prettyPrint(cd.primaryMonth.contributionPerEntity)}%</div>
              <div>{prettyPrint(cd.secondaryMonth.contributionPerEntity)}%</div>
          </td>
          <td>
              <div>{prettyPrint(cd.primaryMonth.drawdown)}</div>
              <div>{prettyPrint(cd.secondaryMonth.drawdown)}</div>
          </td>
          <td>
              <div>{prettyPrint(cd.primaryMonth.remainingBalance)}</div>
              <div>{prettyPrint(cd.secondaryMonth.remainingBalance)}</div>
          </td>
          <td>
              <div>{prettyPrint(cd.primaryMonth.outstandingBalance)}</div>
              <div>{prettyPrint(cd.secondaryMonth.outstandingBalance)}</div>
          </td>
          <td>
              <div>{prettyPrint(cd.primaryMonth.loanToCost)}</div>
              <div>{prettyPrint(cd.secondaryMonth.loanToCost)}</div>
          </td>
          </tr>
          {cd.subcategoriesAggregates ? displayFinancingTableRow(cd.subcategoriesAggregates) : <React.Fragment></React.Fragment>}
      </React.Fragment>
    ))
  }
  const displayInvestmentTableRow = (elements, isParent = false) => {
      let investmentData
      if (isParent) {
          investmentData = elements.rootCategories.filter(category => category.primaryMonth.categoryType === 'INVESTMENT')
      } else {
          investmentData = elements;
      }
      return selection === 'Investment' && investmentData.length > 0  && investmentData.map((cd) => (
          <React.Fragment key={cd.primaryMonth.categoryId}>
          <tr className={isParent ? "bg-light" : "bg-white"}>
              <td className={"col-5 font-weight-bold" + (!isParent ? " pl-5" : "")}>
                {!isParent ? 
                    <Link 
                    className="text-primary" 
                    to={"/manageExecution/" + cd.primaryMonth.categoryId + '/' + cd.primaryMonth.projectId + '/' + cd.primaryMonth.categoryType}>
                    {cd.primaryMonth.name}
                  </Link> 
                : cd.primaryMonth.name}
              </td>
              <td>
                  <div>{prettyPrint(cd.primaryMonth.initialBudget)}</div>
                  <div>{prettyPrint(cd.secondaryMonth.initialBudget)}</div>
              </td>
              <td>
                  <div>{prettyPrint(cd.primaryMonth.executionBudget)}</div>
                  <div>{prettyPrint(cd.secondaryMonth.executionBudget)}</div>
              </td>
              <td>
                  <div>{prettyPrint(cd.primaryMonth.contributionPerEntity)}%</div>
                  <div>{prettyPrint(cd.secondaryMonth.contributionPerEntity)}%</div>
              </td>
              <td>
                  <div>{prettyPrint(cd.primaryMonth.drawdown)}</div>
                  <div>{prettyPrint(cd.secondaryMonth.drawdown)}</div>
              </td>
              <td>
                  <div>{prettyPrint(cd.primaryMonth.remainingBalance)}</div>
                  <div>{prettyPrint(cd.secondaryMonth.remainingBalanceprettyPrint)}</div>
              </td>
              <td>
                  <div>{prettyPrint(cd.primaryMonth.outstandingBalance)}</div>
                  <div>{prettyPrint(cd.secondaryMonth.outstandingBalance)}</div>
              </td>
              <td>
                  <div>{prettyPrint(cd.primaryMonth.loanToCost)}</div>
                  <div>{prettyPrint(cd.secondaryMonth.loanToCost)}</div>
              </td>
              </tr>
              {cd.subcategoriesAggregates ? displayInvestmentTableRow(cd.subcategoriesAggregates) : <React.Fragment></React.Fragment>}
          </React.Fragment>
      ))
  }

  const onCompare = () => {
      const primaryMonthData = closedMonths.filter(closedMonth => closedMonth.id === primaryMonth)[0];
      const secondaryMonthData = closedMonths.filter(closedMonth => closedMonth.id === secondaryMonth)[0];
      if(secondaryMonthData) {
          getEndOfMonthBalance(projectId,
              primaryMonthData.year,
              primaryMonthData.monthAsInteger,
              secondaryMonthData.year,
              secondaryMonthData.monthAsInteger).then((res) => {
              setData(res)
          });
      } else {
          getEndOfMonthBalance(projectId,
              primaryMonthData.year,
              primaryMonthData.monthAsInteger).then((res) => {
              setData(res)
          });
      }
  }

  const displayCostTotal = () => {
      const total = data && data.totals.filter(total=>total.primaryMonth.categoryType==='COST')[0]
      return selection === 'Cost' && total &&
      <React.Fragment>
          <tr className="bg-light">
              <td className="col-5 font-weight-bold">
                  <div>{total.primaryMonth.name}</div>
                  <div>{total.secondaryMonth.name}</div>
              </td>
              <td>
                  <div>{prettyPrint(total.primaryMonth.initialBudget)}</div>
                  <div>{prettyPrint(total.secondaryMonth.initialBudget)}</div>
              </td>
              <td>
                  <div>{prettyPrint(total.primaryMonth.executionBudget)}</div>
                  <div>{prettyPrint(total.secondaryMonth.executionBudget)}</div>
              </td>
              <td>
                  <div>{prettyPrint(total.primaryMonth.committed)}</div>
                  <div>{prettyPrint(total.secondaryMonth.committed)}</div>
              </td>
              <td>
                  <div>{prettyPrint(total.primaryMonth.invoiced)}</div>
                  <div>{prettyPrint(total.secondaryMonth.invoiced)}</div>
              </td>
              <td>
                  <div>{prettyPrint(total.primaryMonth.toBeInvoiced)}</div>
                  <div>{total.secondaryMonth.toBeInvoiced}</div>
              </td>
              <td>
                  <div>{prettyPrint(total.primaryMonth.uncommitted)}</div>
                  <div>{prettyPrint(total.secondaryMonth.uncommitted)}</div>
              </td>
              <td>
                  <div>{prettyPrint(total.primaryMonth.forecast)}</div>
                  <div>{prettyPrint(total.secondaryMonth.forecast)}</div>
              </td>
              <td>
                  <div>{prettyPrint(total.primaryMonth.total)}</div>
                  <div>{prettyPrint(total.secondaryMonth.total)}</div>
              </td>
              <td>
                  <div>{prettyPrint(total.primaryMonth.status)}</div>
                  <div>{prettyPrint(total.secondaryMonth.status)}</div>
              </td>
          </tr>
      </React.Fragment>
  }

    const displayIncomeTotal = () => {
        const total = data && data.totals.filter(total=>total.primaryMonth.categoryType==='INCOME')[0]
        return selection === 'Income' && total &&
            <React.Fragment>
                <tr className="bg-light">
                    <td>
                        <div>{total.primaryMonth.name}</div>
                        <div>{total.secondaryMonth.name}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.initialBudget)}</div>
                        <div>{prettyPrint(total.secondaryMonth.initialBudget)}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.executionBudget)}</div>
                        <div>{prettyPrint(total.secondaryMonth.executionBudget)}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.committed)}</div>
                        <div>{prettyPrint(total.secondaryMonth.committed)}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.invoiced)}</div>
                        <div>{prettyPrint(total.secondaryMonth.invoiced)}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.toBeInvoiced)}</div>
                        <div>{prettyPrint(total.secondaryMonth.toBeInvoiced)}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.uncommitted)}</div>
                        <div>{prettyPrint(total.secondaryMonth.uncommitted)}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.forecast)}</div>
                        <div>{prettyPrint(total.secondaryMonth.forecast)}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.total)}</div>
                        <div>{prettyPrint(total.secondaryMonth.total)}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.status)}</div>
                        <div>{prettyPrint(total.secondaryMonth.status)}</div>
                    </td>
                </tr>
            </React.Fragment>
    }

    const displayFinancingTotal = () => {
        const total = data && data.totals.filter(total=>total.primaryMonth.categoryType==='FINANCING')[0]
        return selection === 'Financing' && total &&
            <React.Fragment>
                <tr className="bg-light">
                    <td>
                        <div>{total.primaryMonth.name}</div>
                        <div>{total.secondaryMonth.name}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.initialBudget)}</div>
                        <div>{prettyPrint(total.secondaryMonth.initialBudget)}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.executionBudget)}</div>
                        <div>{prettyPrint(total.secondaryMonth.executionBudget)}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.contributionPerEntity)}%</div>
                        <div>{prettyPrint(total.secondaryMonth.contributionPerEntity)}%</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.drawdown)}</div>
                        <div>{prettyPrint(total.secondaryMonth.drawdown)}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.remainingBalance)}</div>
                        <div>{prettyPrint(total.secondaryMonth.remainingBalance)}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.outstandingBalance)}</div>
                        <div>{prettyPrint(total.secondaryMonth.outstandingBalance)}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.loanToCost)}</div>
                        <div>{prettyPrint(total.secondaryMonth.loanToCost)}</div>
                    </td>
                </tr>
            </React.Fragment>
    }

    const displayInvestmentTotal = () => {
        const total = data && data.totals.filter(total=>total.primaryMonth.categoryType==='INVESTMENT')[0]
        return selection === 'Investment' && total &&
            <React.Fragment>
                <tr className="bg-light">
                    <td>
                        <div>{total.primaryMonth.name}</div>
                        <div>{total.secondaryMonth.name}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.initialBudget)}</div>
                        <div>{prettyPrint(total.secondaryMonth.initialBudget)}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.executionBudget)}</div>
                        <div>{prettyPrint(total.secondaryMonth.executionBudget)}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.contributionPerEntity)}%</div>
                        <div>{prettyPrint(total.secondaryMonth.contributionPerEntity)}%</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.drawdown)}</div>
                        <div>{prettyPrint(total.secondaryMonth.drawdown)}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.remainingBalance)}</div>
                        <div>{prettyPrint(total.secondaryMonth.remainingBalance)}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.outstandingBalance)}</div>
                        <div>{prettyPrint(total.secondaryMonth.outstandingBalance)}</div>
                    </td>
                    <td>
                        <div>{prettyPrint(total.primaryMonth.loanToCost)}</div>
                        <div>{prettyPrint(total.secondaryMonth.loanToCost)}</div>
                    </td>
                </tr>
            </React.Fragment>
    }

    const getMonthDisplay = (monthId) => {
          const month = closedMonths && closedMonths.filter(closedMonth => closedMonth.id === monthId)[0]
          return month && <React.Fragment>
              <div>
                  {moment(new Date(month.month)).format('MMMM YYYY')}
              </div>
          </React.Fragment>
    }

  return (
    <React.Fragment>
      <Breadcrumbs breadcrumbs={[{url: '/projects', title: 'Home'}, {url: `/project/${projectId}`, title: props.projectDetails && props.projectDetails.name}, {url: `/reporting`, title: 'Reporting'}, {url: `/traceability`, title: 'Traceability'}]} />


      <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                    <ul className="kb-nav-link flex-column py-2 pl-0">
                        <li className="py-lg-2 d-inline mr-2">
                            <NavLink to={`/project/${projectId}/reporting/reporting`} className="btn btn-light font-size-3" activeClassName="bg-dark text-white" replace>
                            Reporting
                            </NavLink>
                        </li>
                        <li className="py-lg-2 d-inline mr-2">
                            <NavLink to={`/project/${projectId}/reporting/traceability`} className="btn btn-light font-size-3" activeClassName="bg-dark text-white" replace >
                              Traceability
                            </NavLink>
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
            </div>
          </div>
      </div>
      <main className="kb-main kb-main_statistics px-3 pb-4">

      <div className="container-fluid">
          <TraceabilityForm setPrimaryMonth={setPrimaryMonth} setBlurPrimaryMonth={setBlurPrimaryMonth} closedMonths={closedMonths} errorCheck={errorCheck} setSecondaryMonth={setSecondaryMonth} secondaryMonth={secondaryMonth} selectComponent={selectComponent} exportTraceability={exportTraceability} saved={saved} blurPrimaryMonth={blurPrimaryMonth} blurSelectComponent={blurSelectComponent} setSelectComponent={setSelectComponent} setSelection={setSelection}
          setBlurSelectComponent={setBlurSelectComponent} primaryMonth={primaryMonth} onCompare={onCompare}/>

          <div className="">
          <table className="table mt-3">
            <thead className="thead-dark">
              <tr>
                <th scope="col" className="col-5">
                  <div className="d-table">
                    <div className="d-table-cell pr-3">Display Order:</div>
                    <div className="d-table-cell">
                        {getMonthDisplay(primaryMonth)}
                        {getMonthDisplay(secondaryMonth)}
                    </div>
                  </div>
                </th>
                {displayCostTableHeading()}
                {displayIncomeTableHeading()}
                {displayFinancingTableHeading()}
                {displayInvestmentTableHeading()}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="12">
                  <div>
                    <div className="text-danger">
                        <small>{noClosedMonthError}</small>
                    </div>
                    {!noClosedMonthError && (<h2>{selection} Execution </h2>)}
                  </div>
                </td>
              </tr>

                {data && displayCostTableRow(data, true)}
                {data && displayCostTotal()}
                {data && displayIncomeTableRow(data, true)}
                {data && displayIncomeTotal()}
                {data && displayFinancingTableRow(data, true)}
                {data && displayFinancingTotal()}
                {data && displayInvestmentTableRow(data, true)}
                {data && displayInvestmentTotal()}
            </tbody>
          </table>
        </div>

        </div>
      </main>

      <RightSideModal
          items={['Dragée brownie chocolate bar oat cake. Icing tiramisu chocolate bar. Biscuit halvah pastry cotton candy croissant chocolate cake gummi bears wafer cake. ', 'Cotton candy lollipop topping marshmallow gummi bears.', 'Candy croissant gingerbread cookie sweet macaroon. Candy canes macaroon candy canes marshmallow sweet candy muffin donut. Pudding chocolate sweet cake toffee. ']}
      />
    </React.Fragment>
  );
}

export default ProjectTraceability;
