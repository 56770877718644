import React from "react";
import PropTypes from 'prop-types';

export default function DonutChart(props) {

    const halfsize = (props.size * 0.5);
    const radius = halfsize - (props.strokewidth * 0.5);
    const circumference = 2 * Math.PI * radius;
    const strokeval = ((props.value * circumference) / 100);
    const dashval = (strokeval + ' ' + circumference);

    const trackstyle = {strokeWidth: props.strokewidth};
    const indicatorstyle = {strokeWidth: props.strokewidth, strokeDasharray: dashval};
    const rotateval = 'rotate(-90 ' + halfsize + ',' + halfsize + ')';

    return (
        <svg width={props.size} height={props.size} className="donutchart">
            <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={trackstyle} className="donutchart-track"/>
            <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={indicatorstyle} className="donutchart-indicator"/>
            <text className="donutchart-text" x={halfsize} y={halfsize + 5} style={{textAnchor: 'middle'}}>
                <tspan className="donutchart-text-val">{props.value != null ? props.value : 'N/A'}</tspan>
                <tspan className="donutchart-text-percent">{props.value != null ? '%' : ''}</tspan>
            </text>
        </svg>
    );
}

DonutChart.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,        // value the chart should show
    size: PropTypes.number,         // diameter of chart
    strokewidth: PropTypes.number   // width of chart line
};

DonutChart.defaultProps = {
    value: 0,
    size: 116,
    strokewidth: 26
};
