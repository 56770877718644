import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";

function BackButtonNavigationBar(props) {

    // noinspection CheckTagEmptyBody
    return (
        <nav className={props.classNamePrefix + "-nav kb-nav_primary navbar bg-dark d-block"}>
            <button className="btn btn-link kb-close-link text-white mr-3" onClick={props.history.goBack}><i className="fas fa-arrow-left  mr-3"></i> Go back</button>
        </nav>


    );
}

BackButtonNavigationBar.propTypes = {
    classNamePrefix: PropTypes.string.isRequired
};

export default withRouter(BackButtonNavigationBar);