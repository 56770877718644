import {genericDelete, genericGet} from "../../../components-shared/fetch-wrapper";

const rolesEndpoint = '/roles';

export function getRoles() {
  return genericGet(rolesEndpoint)
}

export function deleteRole(userRoleId) {
    let url = rolesEndpoint + "/" + userRoleId;
    return genericDelete(url);
}
