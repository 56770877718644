import React, {Component} from "react";
import PropTypes from "prop-types";
import GroupedActions from "../../../components-shared/GroupedActions";
//import MoveItemArrows from "../../../components-shared/MoveItemArrows";
import {prettyPrint} from "../../common/numberOperations";
import CommaSeparatedInput from "../../../components-shared/CommaSeparatedInput/CommaSeparatedInput";

class TemplateCategoryViewAndEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            name: this.props.category.name,
            distributionPeriod: this.props.category.distributionPeriod,

            fieldValidations: {
                distributionPeriod: {
                    isValid: (value) => {
                        if (value) {
                            value = value + "";
                            const isNumeric = /^[0-9]+$/i.test(value);
                            return isNumeric && value < 10000;
                        }
                        return true;
                    }
                }
            },
            displayErrors: {
                distributionPeriod: false,
            }
        };



        this.handleChange = this.handleChange.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.isValid = this.isValid.bind(this);
        this.formIsValid = this.formIsValid.bind(this);
        this.name = React.createRef();
    }

    isValid(field, value) {
        return this.state.fieldValidations[field].isValid(value);
    }

    formIsValid() {
        return this.isValid("distributionPeriod", this.state.distributionPeriod);
    }

    handleChange(event) {
        let displayErrors = this.state.displayErrors;
        displayErrors[event.target.id] = !this.isValid(event.target.id, event.target.value);
        this.setState({
            [event.target.id]: event.target.value,
            displayErrors: displayErrors
        });
    };

    handleEdit() {
        this.setState({editing: !this.state.editing});
    };

    handleSave() {
        const name = this.name.current ? this.name.current.innerText : '';
        const stateObj = {
            name: name,
            distributionPeriod: this.state.distributionPeriod,
            editing: this.state.editing
        };
        this.setState(stateObj);
        const response = this.props.onEdit(stateObj);
        if (response === "") {
            this.setState({editing: !this.state.editing});
        }
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.handleSave();
        }
    }

    render() {

        return (
            <div className={"row expandable-row ml-0 w-100"}>
                {this.state.editing ?
                    <React.Fragment>
                    <div className={this.props.category.parentId ? "col-6 pr-0 pl-5 pt-2" : "col-6 pr-0 pt-2"}>
                        <div className="d-table">      
                            <div className="d-table-cell h-100 align-top position-relative">  
                                <div className="empty-spacebar"></div>           
                                <button className={this.props.category.parentId ? "kb-btn-drag btn btn-link px-3 drag-handle pl-4 d-table-cell absolute-drag-btn" : "kb-btn-drag btn btn-link px-3 drag-handle d-table-cell absolute-drag-btn"}> <i className="fas fa-ellipsis-v"></i></button>  
                            </div>
                            <div className={"kb-list-item-title_edit d-table-cell w-100"}>
                                <div className="dynamic-input-field-container"> 
                                    <span 
                                    id={"name"}
                                    ref={this.name}
                                    className={this.props.className + "-name-form kb-list-item-title_edit form-control textarea h-auto mb-1 no-max-width text-break dynamic-input-field"}
                                    role="textbox" 
                                    contentEditable 
                                    suppressContentEditableWarning={true}
                                    onKeyPress={this.handleKeyPress}
                                    >
                                        {this.state.name}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                        { this.props.category.parentId ?
                            (this.props.category.type === 'COST' || this.props.category.type === 'INCOME') ?
                                (<div className={"col-2 pr-0 pt-2"}>{prettyPrint(this.props.category.ibCode)}</div>) : (<div className="col-2 pr-0"></div>) : (<div className="col-2 pr-0"></div>)
                        }
                        {this.props.category.parentId ?
                            <React.Fragment>
                            <div className="col-2 pr-0 pt-2">
                                <div className={"kb-value-input-wrapper w-100"}>
                                    <CommaSeparatedInput
                                        elementType="FormControl"
                                        type="number"
                                        decimalsAfterDot={0}
                                        min={0}
                                        max={9999}
                                        id={"distributionPeriod"}
                                        value={this.state.distributionPeriod}
                                        handleChange={this.handleChange}
                                    />
                                    {this.state.displayErrors.distributionPeriod &&
                                    <div className="text-danger">Only numeric values are allowed. Max 4
                                        characters.</div>}
                                </div>
                            </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className="col-2 pr-0 pt-2"></div>
                            </React.Fragment>
                        }
                    </React.Fragment>
                    :
                    <React.Fragment>
                    <div className={this.props.category.parentId ? "col-6 pr-0 pl-5" : "col-6 pr-0"}>
                        <div className="d-table">   
                            <div className="d-table-cell h-100 align-top position-relative">
                                <div className="empty-spacebar"></div>
                                <button className={this.props.category.parentId ? "kb-btn-drag btn btn-link px-3 drag-handle pl-4 d-table-cell absolute-drag-btn" : "kb-btn-drag btn btn-link px-3 drag-handle d-table-cell absolute-drag-btn"}> <i className="fas fa-ellipsis-v"></i></button>  
                            </div>      
                            <div className="d-table-cell w-100">
                                <div className="dynamic-input-field-container">                   
                                    <span className="text-break dynamic-input-field pt-2">{this.props.category.name}
                                    {this.props.category.parentId ?
                                        "" : <span className="kb-name_badge text-primary ml-2">{this.props.category.children ? this.props.category.children.length : 0}</span>
                                    }</span>
                                </div> 
                            </div> 
                        </div> 
                    </div>
                        { this.props.category.parentId ?
                            (this.props.category.type === 'COST' || this.props.category.type === 'INCOME') ?
                                (<div className={"col-2 pr-0 pt-2"}>{prettyPrint(this.props.category.ibCode)}</div>) : (<div className="col-2 pr-0"></div>) : (<div className="col-2 pr-0"></div>)
                        }
                        { this.props.category.parentId ? 
                                (<div className={"col-2 pr-0 pt-2"}>{this.props.category.distributionPeriod}</div>) : (<div className="col-2 pr-0"></div>)
                        }
                    </React.Fragment>
                }



                <div className="col-2 pr-0 pl-0 expandable-col text-right">
                    <GroupedActions
                        classes="pr-3"
                    >
                        {this.state.editing ?
                            <button className={"kb-btn_save btn btn-link text-primary px-2 ml-1 d-inline-block"}
                                    onClick={this.handleSave}
                                    disabled={!this.formIsValid()}>
                                <i className="fas fa-check"></i>
                            </button>
                            :
                            <button className={"kb-btn_edit  btn btn-link px-2 ml-1 d-inline-block"}
                                    onClick={this.handleEdit}>
                                <i className="fas fa-pen"></i>
                            </button>
                        }
                        <button className={"kb-btn_delete  btn btn-link px-2 ml-1 d-inline-block"}
                                onClick={this.props.onDelete}>
                            <i className="fas fa-trash"></i>
                        </button>
                    </GroupedActions>
                </div>

            </div> 
        );
    }
}

TemplateCategoryViewAndEdit.propTypes = {
    category: PropTypes.shape({
        name: PropTypes.string,
        budget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        children: PropTypes.array
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onMoveUp: PropTypes.func.isRequired,
    onMoveDown: PropTypes.func.isRequired,
    className: PropTypes.string
};

TemplateCategoryViewAndEdit.defaultProps = {
    className: 'category-view'
};

export default TemplateCategoryViewAndEdit;
