import React, {Component} from "react";

import {Button, FormLabel, Form, FormControl, FormGroup} from "react-bootstrap";
import {withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import {confirmAlert} from "react-confirm-alert";

class EditTemplateForm extends Component {

    constructor(props) {
        super(props);
        this.state = EditTemplateForm.constructStateObject(props.template);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.dismissError = this.dismissError.bind(this);
        this.isNameValid = this.isNameValid.bind(this);
    };

    static constructStateObject(template) {
        return {
            sourceTemplateId: template && template.id ? template.id : "",
            id: template && template.id ? template.id : "",
            name: template && template.name ? template.name : "",
            description: template && template.description ? template.description : "",
            error: '',
            displayErrors: {
                name: false
            }
        };
    }
    componentDidMount () {
      const sourceTemplateId = this.props.match && this.props.match.params && this.props.match.params.sourceTemplateId ?
                                this.props.match.params.sourceTemplateId : "" ;
      this.setState({
        sourceTemplateId: sourceTemplateId
      })
    }
  
  handleChange(event) {
        let displayErrors = this.state.displayErrors;
        displayErrors[event.target.id] = true;
        this.setState({
            [event.target.id]: event.target.value,
            displayErrors: displayErrors
        });
    };

    handleSubmit(event) {
        event.preventDefault();

        if (!this.isNameValid()) {
            let displayErrors = this.state.displayErrors;
            displayErrors.name = true;
            this.setState({
                displayErrors: displayErrors
            });
            return;
        }

        if (this.state.error === '') {
            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure you want to save the changes for the template "' + this.state.name + '"?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                          const body = {
                            name: this.state.name,
                            description: this.state.description,
                            id: this.state.id
                          }
                          const sourceTemplateId = this.state.sourceTemplateId
                          if (sourceTemplateId !== undefined && sourceTemplateId !== "") {
                            return this.props.cloneFunction(body ,sourceTemplateId).then((json) => {
                              if (json.code) {
                                return this.setState({error: json.message});
                              } else {
                                this.props.afterSubmit ? this.props.afterSubmit() :
                                  this.props.history.push('/template/view/' + json.id);
                              }
                            })
                          } else {
                            return this.props.submitFunction(body).then((json) => {
                              if (json.code) {
                                return this.setState({error: json.message});
                              } else {
                                this.props.afterSubmit ? this.props.afterSubmit() :
                                  this.props.history.push('/template/view/' + json.id);
                              }
                            })
                          }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            this.props.afterSubmit ? this.props.afterSubmit() :
                                this.props.history.push('/template/view/' + this.state.id);
                        }
                    }
                ]
            });
        }

        this.setState({error: ''});
    }

    validateForm() {
        return this.state.name.length > 0;
    }

    isNameValid() {
        return this.state.name.length > 0;
    }

    dismissError() {
        this.setState({error: ''});
    }

    render() {
        const inputClassName = "";
        return (
            <div className="kb-template-form">
                <form onSubmit={this.handleSubmit}>
                    {
                        this.state.error &&
                        <h3 data-test="error" onClick={this.dismissError}>
                            <button onClick={this.dismissError}>✖</button>
                            {this.state.error}
                        </h3>
                    }

                    <FormGroup className={inputClassName} controlId="name">
                        <FormLabel>Template Name</FormLabel>
                        <FormControl
                            type="projectName"
                            value={this.state.name}
                            onChange={this.handleChange}
                        />
                        {(this.state.displayErrors.name && !this.isNameValid()) && <div className="text-danger input-error">Template name is mandatory</div>}
                    </FormGroup>

                    <FormGroup className={inputClassName} controlId="description">
                        <FormLabel>Template Description</FormLabel>
                        <FormControl
                            componentclass="textarea"
                            type="description"
                            value={this.state.description}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    {this.props.templates && (<FormGroup className={inputClassName} controlId="sourceTemplateId">
                        <FormLabel>Import from another template?</FormLabel>
                        <Form.Control
                            as="select"
                            value={this.state.sourceTemplateId}
                            onChange={this.handleChange}
                            className="w-100"
                        >
                            <option value="" >Select template</option>
                            {this.props.templates &&
                            this.props.templates.map(template => (
                                <option key={template.id}
                                        value={template.id}>{template.name}</option>
                            ))
                            }
                        </Form.Control>
                    </FormGroup>)}

                    <Button
                        variant="primary"
                        type="submit"
                    >
                        Save
                    </Button>

                    {this.props.setEditFalse && (<Button 
                        type="button"
                        onClick={() => this.props.setEditFalse() }
                        className="ml-3 btn-light"
                        variant='light'
                    >
                        Cancel
                    </Button>)}
                </form>
            </div>
        );
    }
}

EditTemplateForm.propTypes = {
    template: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.string
    }),
    submitFunction: PropTypes.func.isRequired,
    cloneFunction: PropTypes.func,
    afterSubmit: PropTypes.func
};

export default withRouter(EditTemplateForm);
