import React, {useEffect, useState} from 'react';
import {prettyPrint, prettyPrintWithPrecision} from '../../../common/numberOperations';
import DonutChart from "../../../../components-shared/DonutChart";

const CostIncomeProfitReport = (props) => {

  const  report  = props.report;
  return (
    <div className="col-md-6">
      <div className="bg-white p-4 mb-3">
        <div className="row">
          {report.shouldLoad ?  (
            <div className="col-md-12 font-weight-bold my-3"> 
              <div className='container-inline-div'>
                <div className='inline-div'> {props.title} </div> <div className="spinner" /> 
              </div>
            </div>
          ) : (
            <div className="col-md-12 font-weight-bold my-3">
              {props.title}
            </div>
          )}
          
          <div className="col-md-5 align-self-center mb-3">
            <div>Total cost</div>
            <div className="font-size-5 font-weight-bold">{report && prettyPrint(report.totalCost)} EUR
            </div>
          </div>
          <div className="col-md-7 align-self-center mb-3">
            <div>Profit before TAX</div>
            <div className="font-size-5 font-weight-bold">{report && prettyPrint(report.profit)} EUR
          </div>
          </div>
          <div className="col-md-5 align-self-center my-3 pb-3">
            <div>Total Income</div>
            <div className="font-size-5 font-weight-bold">{report && prettyPrint(report.totalIncome)} EUR
          </div>
          </div>
          <div className="col-md-7 align-self-center my-3 pb-3">
            <div className="row">
              <div className="d-inline-block mr-5 text-left">  
                <div>
                  <DonutChart
                      value={ report && report.roi && prettyPrintWithPrecision(report.roi * 100, 2)}
                      size={70}
                      strokewidth={5}
                  />
                  <div className="text-center">ROI</div>
                </div>
              </div>
              <div className="d-inline-block mr-5 text-left">
                <div>
                  <DonutChart
                      value={ report && report.roe && prettyPrintWithPrecision(report.roe * 100, 2) }
                      size={70}
                      strokewidth={5}
                  />
                  <div className="text-center">ROE</div>
                </div>
              </div>
              <div className="d-inline-block mr-5 text-left">
                <div>
                  <DonutChart
                      value={ report && report.irr && prettyPrintWithPrecision(report.irr * 100, 2)  }
                      size={70}
                      strokewidth={5}
                  />
                  <div className="text-center">IRR</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



export default CostIncomeProfitReport;
