import React, { useState, createContext } from 'react';

export const ModalContext = createContext();

export const ModalContextProvider = (props) => {
  const [isModalOpen, setModal] = useState(false)
  return (
    <ModalContext.Provider value={[isModalOpen, setModal]}>
      {props.children}
    </ModalContext.Provider>
  );
}

export default ModalContext;
