import {genericGet, genericGetNoLoading} from "../../../components-shared/fetch-wrapper";

export function getReporting(projectId) {
    return genericGet("/reporting/" + projectId);
}

export function saveAndGetHeader(projectId) {
    return genericGetNoLoading("/reporting/" + projectId + "/header");
}

export function saveAndGetCashPosition(projectId) {
    return genericGetNoLoading("/reporting/" + projectId + "/cashPosition");
}

export function saveAndGetCumulativeCashPostion(projectId) {
    return genericGetNoLoading("/reporting/" + projectId + "/cummulativeCashPostion");
}

export function saveAndGetFinancingInvestmentVat(projectId) {
    return genericGetNoLoading("/reporting/" + projectId + "/financingInvestmentVat");
}

export function saveAndGetExecution(projectId) {
    return genericGetNoLoading("/reporting/" + projectId + "/execution");
}

export function saveAndGetInitial(projectId) {
    return genericGetNoLoading("/reporting/" + projectId + "/initial");
}