import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";

class FixedScrollingYContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            top: 0,
            height: 0,
            scroll: 0
        };

        this.myFunction = this.myFunction.bind(this);
        this.header = React.createRef();
    }
    componentDidMount() {
        window.addEventListener('scroll', this.myFunction);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.myFunction);
    }
    
    myFunction() {
        const header = this.header.current;
        const sticky = header.offsetTop;
        if (window.pageYOffset > sticky) {
          header.classList.add("fixed-nav");
          header.classList.add("scroll-sticky");
          header.classList.add(this.props.additionalFixedPositionClass);
        } else {
          header.classList.remove("fixed-nav");
          header.classList.remove("scroll-sticky");
          header.classList.remove(this.props.additionalFixedPositionClass);
        }
      }



    render() {
        return (
            <Fragment>
                <div className={"sticky-reference"}/>
                <div ref={this.header} 
                className="scroll-sticky"
                >
                    {this.props.children}
                </div>
            </Fragment>
        );
    }
}

FixedScrollingYContainer.propTypes = {
    additionalFixedPositionClass: PropTypes.string,
};

export default FixedScrollingYContainer;