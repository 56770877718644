import {genericGet} from "../../../components-shared/fetch-wrapper";

const endOfMonthBalanceEndpoint = '/endOfMonthBalance?projectId={projectId}&primaryYear={primaryYear}&primaryMonth={primaryMonth}' +
    '&secondaryYear={secondaryYear}&secondaryMonth={secondaryMonth}';


export function getEndOfMonthBalance(projectId, primaryYear, primaryMonth, secondaryYear, secondaryMonth) {
    return genericGet(endOfMonthBalanceEndpoint.replace("{projectId}", projectId)
        .replace("{primaryYear}", primaryYear)
        .replace("{primaryMonth}", primaryMonth)
        .replace("{secondaryYear}", secondaryYear)
        .replace("{secondaryMonth}", secondaryMonth));
}
