import React, {Component, Fragment} from "react";
import PropTypes from 'prop-types';

import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import {replaceUrlParams} from "./url-utils";
import RenderIf from "../main-components/RenderIf";
import ProtectedRoute from "../main-components/ProtectedRoute";

const NavDescriptorPropType = PropTypes.shape({
    parentBaseUrl: PropTypes.string.isRequired,
    navigationTitle: PropTypes.string.isRequired,
    componentUrl: PropTypes.string.isRequired,
    component: PropTypes.func.isRequired,
    iconClassName: PropTypes.string.isRequired
}).isRequired;

export class NavDescriptor {

    constructor(parentBaseUrl, renderCondition, componentPath, navigationTitle, component, iconClassName, urlParams = []) {
        this._parentBaseUrl = parentBaseUrl;
        this._renderCondition = renderCondition;
        this._componentPath = componentPath;
        this._navigationTitle = navigationTitle;
        this._iconClassName = iconClassName;
        this._routeUrl = this._parentBaseUrl + this._componentPath;
        this._componentUrl = replaceUrlParams(this._routeUrl, urlParams);
        this._component = component;
    }

    get parentBaseUrl() {
        return this._parentBaseUrl;
    }

    get renderCondition() {
        return this._renderCondition;
    }

    get navigationTitle() {
        return this._navigationTitle;
    }

    get componentUrl() {
        return this._componentUrl;
    }

    get routeUrl() {
        return this._routeUrl;
    }

    get component() {
        return this._component;
    }

    get iconClassName() {
        return this._iconClassName;
    }
}

NavElement.propTypes = {
    navDescriptor: NavDescriptorPropType
};

function NavElement(props) {

    const {navDescriptor} = props;

    // noinspection CheckTagEmptyBody
    return (
        <NavLink to={navDescriptor.componentUrl} activeClassName="btn-primary" className="btn btn-link text-white mr-1" 
        isActive={(props) => { 
          if (navDescriptor.componentUrl.includes('real-time-budget') && (!props || (props && !props.isExact))){
            return window.location.href.includes('manageExecution');
          }
          if (navDescriptor.componentUrl.includes('roles') && (!props || (props && !props.isExact))){
            return window.location.href.includes('roles');
          }
          if (navDescriptor.componentUrl.includes('reporting') && (!props || (props && !props.isExact))){
            return window.location.href.includes('reporting/traceability') || window.location.href.includes('reporting/reporting');
          }
          if (props ? !props.isExact : !props) {
            return false;
          }
          return true;
        }}>
            <i className={"d-none " + navDescriptor.iconClassName}></i>
            {navDescriptor.navigationTitle}
        </NavLink>
    )
}

export class NavPanel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isProjectMenuClicked: false
        };

        this.toggleProjectMenu = this.toggleProjectMenu.bind(this);
    }

    toggleProjectMenu() {

        this.setState({
            isProjectMenuClicked: !this.state.isProjectMenuClicked
        })
    }

    render() {

        const {navDescriptors} = this.props;
        const isProjectMenuClicked = this.state.isProjectMenuClicked ? " visible" : "";

        return (
            <React.Fragment>
                {!this.props.hideNav && (<nav className={"kb-nav_primary navbar bg-dark d-block " + this.props.navBarClassName}>
                    <span className={"project-menu " + isProjectMenuClicked} onClick={this.toggleProjectMenu}>Project Navigation</span>
                    <div className={"d-block nav-elements-" + navDescriptors.length}>
                        {navDescriptors.map((navDescriptor) => {
                            return (
                                <Fragment key={navDescriptor.componentUrl}>
                                    <RenderIf condition={navDescriptor.renderCondition}>
                                        <NavElement navDescriptor={navDescriptor}/>
                                    </RenderIf>
                                </Fragment>
                            );
                        })}
                    </div>
                </nav>
                )}
                <div className={this.props.contentClassName}>
                    <Switch>
                        <Route exact path={navDescriptors[0].parentBaseUrl}
                               render={() => <Redirect replace to={navDescriptors[0].componentUrl}/>}/>

                        {navDescriptors.map((navDescriptor) => {
                            return (
                                <ProtectedRoute
                                    key={navDescriptor.componentUrl}
                                    condition={navDescriptor.renderCondition}
                                    path={navDescriptor.routeUrl}
                                    component={navDescriptor.component}/>
                            );
                        })}
                    </Switch>
                </div>
            </React.Fragment>
        )
    }
}

NavPanel.propTypes = {
    navDescriptors: PropTypes.arrayOf(NavDescriptorPropType),
    navBarClassName: PropTypes.string.isRequired,
    contentClassName: PropTypes.string.isRequired
};




