import React, {Component} from 'react';
import {NavLink, withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {getUserById} from "./actions/userActions";
//import PageSection from "../../components-shared/PageSection";
import EditUserDetailsForm from "./forms/EditUserDetailsForm";
import EditUserPasswordForm from "./forms/EditUserPasswordForm";
import Breadcrumbs from '../../components-shared/Breadcrumbs/Breadcrumbs';

class EditUserPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userDetails: null
        };

        this.getUserId = this.getUserId.bind(this);
    }

    getUserId() {
        return this.props.match.params.userId;
    }

    componentDidMount() {

        const userDetailsId = this.getUserId();

        if (userDetailsId) {
            // Even though the page receives the user details, just to be safe, a call will be made to the BE
            // based on the userId to retrieve the correct database data
            getUserById(userDetailsId).then((userDetails) => {
                this.setState({
                    userDetails: userDetails
                })
            });
        }
    }

    render() {

        if (this.state.userDetails) {
            // noinspection CheckTagEmptyBody
            return (
             <React.Fragment> 
                <nav className="kb-nav_primary navbar bg-dark d-block">
                    <button className="btn btn-link kb-close-link text-white mr-3" onClick={this.props.history.goBack}><i className="fas fa-arrow-left  mr-3"></i> Go back</button>
                </nav>
                 <Breadcrumbs breadcrumbs={[{url: '/projects', title: 'Home'}, {url: `/editUser/${this.getUserId()}`, title: `Edit User`}]} />
                    <header className="kb-header px-3">
                    <div className="container-fluid">
                        <div className="row">

                             <div className="col-12 d-flex align-items-center ">
                                        <div className="kb-title_page d-flex align-items-center py-4">
                                            <h1 className="h4 font-weight-normal m-0">Edit User</h1>
                                          </div>
                               <nav className="kb-nav_ctas ml-auto">
                                 
                                </nav> 
                            </div> 
                        </div>
                    </div>
                </header>
                 <main className="kb-main kb-main_user--edit bg-white px-3 py-4">
                <div className="container-fluid">
                    <div className="row">
                    <div className="col-12 col-md-6 col-lg-4 pb-5">
                     <h5 className="py-3">Edit User Information</h5>
                        <EditUserDetailsForm
                            userDetails={this.state.userDetails}
                            myAccountEdit={false}
                        />
                       </div>
                        <div className="col-12 col-md-4 col-lg-3 pb-5">
                         <h5 className="py-3">Edit User Password</h5>
                          <EditUserPasswordForm
                            userId={this.state.userDetails.id}
                            myAccountEdit={false}
                        />
                        </div>
                    </div> 
                    </div>
                    </main>
        </React.Fragment>







            );
        } else {
            return (
                <div className={"loading-placeholder"}>
                    <h2>No user selected. Go <NavLink to={"/userManagement"}>back</NavLink> and retry</h2>
                </div>
            );
        }

    }
}

EditUserPage.propTypes = {
    location: PropTypes.object.isRequired
};

export default withRouter(EditUserPage);
