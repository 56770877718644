import React from 'react';
import {NavLink, withRouter} from "react-router-dom";
import PropTypes from 'prop-types';
import {AppContextData} from "./AppContextData";
import {stringToFormattedDate} from "../components/common/dateFormatting";
import RenderIf from "./RenderIf";
import {
    canCreateNewProject,
    //canReportAProblem,
    viewAllNotifications,
    viewAllProjects,
    viewAndManageAppSettings,
    viewAndManageUsers
} from "./UserPermissionsFunctions";

function MenuListItem(props) {
    return (
        <li>
            <div className={props.className}>
                <NavLink to={props.link} className={props.isClicked() ? "active-item" : ""}>
                    <i className={props.icon}/>
                    <span>{props.text}</span>
                </NavLink>
            </div>
        </li>
    );
}

MenuListItem.propTypes = {
    className: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    isClicked: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
};

function Menu(props) {

    const startsWithAny = function (stringToCompare) {
        for (let i = 1; i < arguments.length; i++) {
            if (stringToCompare.startsWith(arguments[i])) {
                return true;
            }
        }
        return false;
    };

    return (
        <div className="side-nav expand-lg">
            <div className="side-nav-inner">
                <ul>
                    <RenderIf condition={viewAndManageUsers()}>
                        <MenuListItem
                            className="user-management"
                            link="/userManagement"
                            isClicked={() => startsWithAny(props.location.pathname, "/userManagement", "/editUser/", "/viewUser/")}
                            icon="fas fa-users"
                            text="User Management"
                        />
                    </RenderIf>
                    <RenderIf condition={viewAndManageAppSettings()}>
                        <MenuListItem
                            className="application-settings"
                            link="/settings"
                            isClicked={() => startsWithAny(props.location.pathname, "/settings", "/template/")}
                            icon="fas fa-cogs"
                            text="Settings"
                        />
                    </RenderIf>
                    <RenderIf condition={canCreateNewProject()}>
                        <MenuListItem
                            className="new-project"
                            link="/new-project"
                            isClicked={() => startsWithAny(props.location.pathname, "/new-project")}
                            icon="fas fa-plus-circle"
                            text="Create NEW Project"
                        />
                    </RenderIf>
                    <RenderIf condition={viewAllProjects()}>
                        <MenuListItem
                            className="projects-all"
                            link="/projects"
                            isClicked={() => startsWithAny(props.location.pathname, "/projects", "/project/", "/template-clone/")}
                            icon="fas fa-file-alt"
                            text="Projects"
                        />
                    </RenderIf>
                    <RenderIf condition={viewAllNotifications()}>
                        <MenuListItem
                            className="notifications"
                            link="/history"
                            isClicked={() => startsWithAny(props.location.pathname, "/history")}
                            icon="fas fa-bell"
                            text="Notifications"
                        />
                    </RenderIf>
                    {/*<RenderIf condition={canReportAProblem()}>
                        <MenuListItem
                            className="report-problem"
                            link="/report-a-problem"
                            isClicked={() => startsWithAny(props.location.pathname, "/report-a-problem")}
                            icon="fas fa-flag"
                            text="Report a problem"
                        />
                    </RenderIf>
                    <MenuListItem
                        className="Help"
                        link="/help"
                        isClicked={() => startsWithAny(props.location.pathname, "/help")}
                        icon="fas fa-question-circle font-weight-bold"
                        text="Help"
                    />*/}
                </ul>
            </div>
            <div className={"menu-footer"}>
                <label className={"menu-footer-app"}>Application details:</label>
                <div className={"menu-footer-version"}>
                    <label className={"menu-footer-version-label"}>App version: </label>
                    <label className={"menu-footer-version-value"}>{AppContextData.getApplicationSettings().version}</label>
                </div>
                <div className={"menu-footer-build-time"}>
                    <label className={"menu-footer-build-time-label"}>Build time: </label>
                    <label className={"menu-footer-build-time-value"}>{stringToFormattedDate(AppContextData.getApplicationSettings().buildTime)}</label>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Menu);
