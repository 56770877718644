import React, {Component} from "react";

import NewInvoiceForm from "./forms/NewInvoiceForm";
//import PageSection from "../../components-shared/PageSection";
import ProjectTitleDisplay from "../project/fragment/ProjectTitleDisplay";
import BackButtonNavigationBar from "../../components-shared/BackButtonNavigationBar";
import {getProjectById} from "../project/actions/projectActions";
import Breadcrumbs from "../../components-shared/Breadcrumbs/Breadcrumbs";
import RightSideModal from "../../components-shared/RightSideModal/RightSideModal";

class NewInvoicePage extends Component {

    constructor(props) {

        super(props);

        this.state = {
            projectDetails: undefined,
            error: undefined
        };

        this.getProjectId = this.getProjectId.bind(this);
    }

    componentDidMount() {

        const projectId = this.getProjectId();

        if (projectId) {
            getProjectById(projectId).then((projectDetails) => {
                if (projectDetails.code) {
                    return this.setState({error: projectDetails.message});
                } else {
                    this.setState({
                        projectDetails: projectDetails,
                        error: ''
                    })
                }
            });
        }
    }

    getProjectId() {
        return this.props.match.params.projectId;
    }

    render() {
        const projectId = this.getProjectId();

        return (
            <React.Fragment>
               
                <ProjectTitleDisplay projectDetails={this.state.projectDetails} showHelp={true}/>
                <BackButtonNavigationBar classNamePrefix={"project"}/>
                 <Breadcrumbs breadcrumbs={[{url: '/projects', title: 'Home'}, {url: `/newInvoice/${projectId}`, title: this.state.projectDetails && this.state.projectDetails.name}]} />
                            <NewInvoiceForm
                                        projectId={projectId}
                                        categoryType={""}
                                    />
                 <RightSideModal
                        items={['ootsie roll wafer wafer cotton candy marshmallow. Croissant topping liquorice wafer brownie marshmallow oat cake. ', 'Gummies wafer candy canes apple pie jelly beans pastry wafer. Chocolate bar pudding jelly-o sugar plum. Tiramisu pie donut sweet bear claw.', 'Candy cupcake carrot cake candy brownie jelly. Marzipan biscuit marzipan. Candy dragée pie lollipop jelly-o. Cotton candy danish dessert liquorice pastry caramels sugar plum powder toffee.']}
                    />
            </React.Fragment>

          
        


        );
    }
}

export default NewInvoicePage;
