import React, {Component} from "react";
import {AppContextData} from "../../../main-components/AppContextData";

class UserIdentity extends Component {

    constructor(props) {
        super(props);

        this.state = AppContextData.getUserDetails();
    }

    formatUserName() {
        return this.state.firstName + " " + this.state.lastName;
    }

    render() {
        return (
            <span>{this.formatUserName()}</span>
        );
    }
}

export default UserIdentity;
