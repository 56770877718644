// Default U.S. English locale
// If the value is negative it will be displayed (value), if the value is positive it will simply be displayed
// In case of null input => does nothing
// In case of String input => prints String (so try not to use String)
// In case of 0 => prints 0
export function prettyPrint(number) {
    return prettyPrintWithPrecision(number, 0);
}

export function prettyPrintWithPrecision(number, precision = 2) {
    return number && round(number, precision).toLocaleString();
}

// 12345.6789, 2 => 12345.68
// 12345.6789, 1 => 12345.7
// precision 0 => number with 0 decimals
// 12345.6789, -1 => 12350
export function round(value, precision) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}
