import {genericDelete, genericGet, genericPost, genericGetNoLoading,} from "../../../components-shared/fetch-wrapper";

const categoryForecastManagement = '/category-forecast-management';

const contractForecastManagement = '/contract-forecast-management';

const remainingForecastManagement = '/remaining-forecast-management';

const transferForecast = '/transfer-forecast';

const forecastsEndpoint = '/forecasts';
const projectsEndpoint = '/projects';

function buildUrl(categoryId, contractId, forecastType) {

    let url = "";

    switch (forecastType) {
        case "prognosis":
            url = categoryForecastManagement + "/" + categoryId;
            break;
        case "contractor":
            url = contractForecastManagement + "/" + categoryId + "/contract/" + contractId;
            break;
        case "remaining":
            url = remainingForecastManagement + "/" + categoryId;
            break;
        default:
            break;
    }

    return url;
}

export function getForecastsForProject(projectId) {
    return genericGet(projectsEndpoint + "/" + projectId + forecastsEndpoint);
}

export function getAllForecastsByCriteria(categoryId, contractId, forecastType) {

    let url = buildUrl(categoryId, contractId, forecastType);

    return genericGet(url);
}

export function getAllForecastsByCriteriaNoLoading(categoryId, contractId, forecastType) {

    let url = buildUrl(categoryId, contractId, forecastType);

    return genericGetNoLoading(url);
}

export function saveCategoryForecasts(data) {
    return genericPost(categoryForecastManagement + "/saveForecasts", data);
}

export function deleteAllForecastsByCriteria(categoryId, contractId, forecastType) {

    let url = buildUrl(categoryId, contractId, forecastType);

    return genericDelete(url)
}

export function isForecastTransferValid(forecastTransferType, categoryOrProjectId) {
    return genericGet(transferForecast + "/valid/" + forecastTransferType + "/" + categoryOrProjectId);
}

export function transferForecasts(forecastTransferType, categoryOrProjectId) {
    return genericPost(transferForecast + "/" + forecastTransferType + "/" + categoryOrProjectId);
}


export function getMonthsToClose(data) {
    return genericPost(forecastsEndpoint + "/closeMonthAction", data);
}

export function closeMonthAction(data) {
    return genericPost(forecastsEndpoint + "/closeMonthAction", data);
}