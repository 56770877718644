import {AppContextData} from "./AppContextData";
import {ACCOUNTING, ADMIN, PROJECT_MANAGER, SALES} from "../components/user/support/UserConstants";

// ################################## Menu permissions

export function viewAndManageUsers() {
    return (AppContextData.containsRole(ADMIN));
}

export function viewAndManageAppSettings() {
    return (AppContextData.containsRole(ADMIN));
}

export function canCreateNewProject() {
    return (AppContextData.containsRole(ADMIN));
}

export function viewAllProjects() {
    return (AppContextData.containsRole(ADMIN)
      || AppContextData.containsRole(PROJECT_MANAGER)
      || AppContextData.containsRole(ACCOUNTING)
      || AppContextData.containsRole(SALES));
}

export function viewAllNotifications() {
    return (AppContextData.containsRole(ADMIN)
      || AppContextData.containsRole(PROJECT_MANAGER)
      || AppContextData.containsRole(ACCOUNTING)
      || AppContextData.containsRole(SALES));
}

export function canReportAProblem() {
    return (AppContextData.containsRole(ADMIN) ||
      AppContextData.containsRole(PROJECT_MANAGER) ||
      AppContextData.containsRole(ACCOUNTING) ||
      AppContextData.containsRole(SALES));
}

// ################################## Project Management permissions

export function canManageProject() {
    return canManageProjectDefinition() || canManageProjectExecution();
}

export function canManageProjectUsers() {
    return (AppContextData.containsRole( ADMIN));
}

export function canManageProjectDefinition() {
    return (AppContextData.containsRole(ADMIN) || AppContextData.containsRole(PROJECT_MANAGER) || AppContextData.containsRole(ACCOUNTING) || AppContextData.containsRole(SALES));
}

export function canManageProjectExecution() {
    return (AppContextData.containsRole(ADMIN) || AppContextData.containsRole(PROJECT_MANAGER) || AppContextData.containsRole(ACCOUNTING));
}
