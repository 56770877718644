// Initial forecast
export const cashFlowPrognosis = {
    displayName: 'Cash Flow Prognosis',
    value: 'prognosis'
};

// Contractor forecast based on committed value
export const committedForecast = {
    displayName: 'Committed Forecast',
    value: 'contractor'
};

// Remaining to forecast on sub-category
export const remainingToForecast = {
    displayName: 'Remaining To Forecast',
    value: 'remaining'
};
