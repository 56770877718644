import DatePicker from "react-datepicker";
import React from "react";
import Moment from "moment"
import {dateDayMonthYearFormat} from "../components/common/dateFormatting";

export default function CustomDatePicker(props) {

    /**
     * Since version 2.0.0 react-datepicker uses native JS date instead of Moment dates.
     * Because we use moment extensively, we want a datepicker which works with moments too, so
     * convert the 'selected' property to JS date if it is a moment.
     * Also datepicker relies on slightly different date formats than moment so let's convert that too.
     * See https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
     * @param props properties
     */
    function processProps(props) {
        // Since props are read-only, we need to duplicate them.
        let clone = {};
        Object.keys(props).forEach(prop => {
            if (prop === 'selected' && props.selected instanceof Moment) {
                clone.selected = props.selected.toDate();
            } else if (prop === 'dateFormat') {
                clone.dateFormat = convertDateFormat(props.dateFormat);
            } else {
                clone[prop] = props[prop];
            }
        });
        return clone
    }

    function convertDateFormat(dateFormat) {
        return dateFormat
            .replace(/D/g, 'd')
            .replace(/Y/g, 'y');
    }

    return (
        <DatePicker
            dateFormat={convertDateFormat(dateDayMonthYearFormat)}
            dropdownMode={"select"}
            showYearDropdown
            showMonthDropdown
            todayButton={"Today"}
            {...processProps(props)}
        />
    );
}
