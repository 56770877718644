/**
 * Replaces a list of parameters from an url.
 * <p>
 * Example: /template/:templateId
 * :templateId is the parameter name
 * <p>
 * urlParams will contain a list of object that have :
 * - key = parameter name
 * - value = parameter value
 *
 * @param initialUrl the initial url with parameter names
 * @param urlParams the list of objects which define the params
 * @returns the url with the parameters replaced
 */
export function replaceUrlParams(initialUrl, urlParams) {
    let finalUrl = initialUrl;
    urlParams.forEach(param => {
        finalUrl = finalUrl.replace(param.key, param.value);
    });
    return finalUrl;
}