import React, {Component} from "react";
import {Link} from "react-router-dom";
import Breadcrumbs from "../../../components-shared/Breadcrumbs/Breadcrumbs";
import {getAllTemplates} from "../actions/templateActions";
import DisplayTemplateCard from "./fragments/DisplayTemplateCard";

class ViewAllTemplatesPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            templates: [],
        };
    }

    componentDidMount() {
        getAllTemplates().then(templates => {
            this.setState({
                templates: templates,
            })
        });
    }

    render() {
        const templates = this.state.templates.slice();
        return (
             <React.Fragment>
                <Breadcrumbs breadcrumbs={[{url: '/projects' , title: 'Home' }, {url: '/settings' , title: 'Settings' }, {url: '/settings/templates' , title: 'Templates' }]} />
                <header className="kb-header px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 d-flex align-items-center">
                                <div className="kb-title_page d-flex align-items-center py-4">
                                    <h1 className="h4 font-weight-normal m-0">Templates</h1>
                                </div>
                                <nav className="kb-nav_ctas ml-auto">
                                    <Link className="btn btn-primary btn-template_add" to="/template/new">Add new template</Link>
                                </nav>
                            </div>
                        </div>
                    </div>
                </header>
                <main className="kb-main kb-main_projects px-3 pb-5">
                    <div className="container-fluid">
                        <div className="row">
                            {templates.map(template => (
                            <DisplayTemplateCard key={template.id} template={template} />))
                            }
                            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                                <div className="card kb-card-template kb-card-template_add h-100 ">
                                    <Link className="link-overlay" to="/template/new">
                                    </Link>
                                    <div className="card-body d-flex align-items-center">
                                        <h5 className="add-title m-0 text-primary pl-4">
                                            <i className="fas fa-plus"></i><span className=" ml-3">Add new template</span> </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
    </React.Fragment>
        );
    }
}

export default ViewAllTemplatesPage;
