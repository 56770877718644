import React, {Component} from "react";
import PropTypes from "prop-types";
import {prettyPrint, prettyPrintWithPrecision} from "../../../../common/numberOperations";

class BudgetExecutionConfiguringTotalsView extends Component {

    generateKey(keyName) {
        let generatedKey = Math.floor(Math.random() * 101);
        if (keyName) {
            return keyName + generatedKey;
        }
        return generatedKey;
    }

    removeExtraCharacters(value) {
        let result = value;
        return result.substring(1, result.length - 1);
    }

    hasExecution() {
        return this.props.categories
            .flatMap(category => category.subCategoriesEndOfMonthBalance)
            .map(category => category.executionBudget)
            .find(execution => execution > 0) !== undefined
    }

    getTotalContributionPerEntityForConfiguredTotal(total) {
        const budgetComponentBudget = this.getBudgetComponentBudget();
        return budgetComponentBudget !== 0 ? this.getBudgetForConfiguredTotal(total) / budgetComponentBudget * 100 : 0;
    }

    getBudgetForConfiguredTotal(total) {
        return this.props.categories
            .flatMap(category => category.subCategoriesEndOfMonthBalance)
            .filter(category => total.categories.includes(category.categoryId))
            .map(category => this.hasExecution() ? category.executionBudget : category.initialBudget)
            .reduce(function (acumulator, budget) {
                return acumulator + budget;
            }, 0);
    }


    getBudgetComponentBudget() {
        return this.props.categories
            .flatMap(category => category.subCategoriesEndOfMonthBalance)
            .map(category => this.hasExecution() ? category.executionBudget : category.initialBudget)
            .reduce(function (acumulator, budget) {
                return acumulator + budget;
            }, 0);
    }

    getInitial(total) {
        return this.props.categories
            .flatMap(category => category.subCategoriesEndOfMonthBalance)
            .filter(category => total.categories.includes(category.categoryId))
            .map(category => category.initialBudget ? category.initialBudget : 0)
            .reduce(this.sumReducer(), 0)
    }

    getExecution(total) {
        return this.props.categories
            .flatMap(category => category.subCategoriesEndOfMonthBalance)
            .filter(category => total.categories.includes(category.categoryId))
            .map(category => category.executionBudget ? category.executionBudget : 0)
            .reduce(this.sumReducer(), 0)
    }

    getCommitted(total) {
        return this.props.categories
            .flatMap(category => category.subCategoriesEndOfMonthBalance)
            .filter(category => total.categories.includes(category.categoryId))
            .map(category => category.committed ? category.committed : 0)
            .reduce(this.sumReducer(), 0)
    }

    getInvoiced(total) {
        return this.props.categories
            .flatMap(category => category.subCategoriesEndOfMonthBalance)
            .filter(category => total.categories.includes(category.categoryId))
            .map(category => category.invoiced ? category.invoiced : 0)
            .reduce(this.sumReducer(), 0)
    }

    getToBeInvoiced(total) {
        return this.props.categories
            .flatMap(category => category.subCategoriesEndOfMonthBalance)
            .filter(category => total.categories.includes(category.categoryId))
            .map(category => category.toBeInvoiced ? category.toBeInvoiced : 0)
            .reduce(this.sumReducer(), 0)
    }

    getUncommitted(total) {
        return this.props.categories
            .flatMap(category => category.subCategoriesEndOfMonthBalance)
            .filter(category => total.categories.includes(category.categoryId))
            .map(category => category.uncommitted ? category.uncommitted : 0)
            .reduce(this.sumReducer(), 0)
    }

    getForecast(total) {
        return this.props.categories
            .flatMap(category => category.subCategoriesEndOfMonthBalance)
            .filter(category => total.categories.includes(category.categoryId))
            .map(category => category.forecast ? category.forecast : 0)
            .reduce(this.sumReducer(), 0)
    }

    getTotal(total) {
        return this.props.categories
            .flatMap(category => category.subCategoriesEndOfMonthBalance)
            .filter(category => total.categories.includes(category.categoryId))
            .map(category => category.total ? category.total : 0)
            .reduce(this.sumReducer(), 0)
    }

    getStatus(total) {
        return this.props.categories
            .flatMap(category => category.subCategoriesEndOfMonthBalance)
            .filter(category => total.categories.includes(category.categoryId))
            .map(category => category.status ? category.status : 0)
            .reduce(this.sumReducer(), 0)
    }

    getDrawdown(total) {
        return this.props.categories
            .flatMap(category => category.subCategoriesEndOfMonthBalance)
            .filter(category => total.categories.includes(category.categoryId))
            .map(category => category.drawdown)
            .reduce(function (acumulator, budget) {
                return acumulator + budget;
            }, 0);
    }

    getReimbursement(total) {
        return this.props.categories
            .flatMap(category => category.subCategoriesEndOfMonthBalance)
            .filter(category => total.categories.includes(category.categoryId))
            .map(category => category.reimbursement)
            .reduce(function (acumulator, budget) {
                return acumulator + budget;
            }, 0);
    }

    getRemainingBalance(total) {
        return this.getBudgetForConfiguredTotal(total) - (this.getDrawdown(total) - this.getReimbursement(total));
    }

    getOutstandingBalance(total) {
        return this.getBudgetForConfiguredTotal(total) - this.getReimbursement(total);
    }

    getLoanToCost(total) {
        return (this.getDrawdown(total) - this.getReimbursement(total)) / this.props.costTotal * 100
    }

    sumReducer() {
        return function (acumulator, budget) {
            return acumulator + budget;
        };
    }

    render() {
        //let defaultValue = 0.0;
        return (
            <div className="kb-category-entry kb-rtb-configs-border-line .kb-table .row">
                <div className="kb-table kb-totals-view kb-category-view">
                    {this.props.configuringTotals && this.props.configuringTotals.length > 0 && this.props.configuringTotals.map(
                        currentTotal => currentTotal && currentTotal.categoryTotalName && (
                            currentTotal.categories && currentTotal.categories.length > 0
                        ) ? (
                            <React.Fragment key={currentTotal.categoryTotalName}>
                                <div className="row kb-category-view_values kb-rtb-configs-border-line .kb-table .row"
                                     key={this.generateKey(currentTotal.categoryTotalName)}>
                                    <div className={"col-4 kb-col-name"}>
                                        <span className={"table-row-name-total-fragment"}>
                                        {currentTotal.categoryTotalName}
                                        </span>
                                    </div>
                                    <div className={"col table-col"} data-label="Total Initial budget">
                                        <span className="d-none">Total Initial budget</span>
                                        {this.getInitial(currentTotal) ? prettyPrint(this.getInitial(currentTotal)) : 0}
                                    </div>
                                    <div className={"col table-col"} data-label="Total Execution budget">
                                        <span className="d-none">Total Execution budget</span>
                                        {this.getExecution(currentTotal) ? prettyPrint(this.getExecution(currentTotal)) : 0}
                                    </div>
                                    {(this.props.categoryType === 'COST' || this.props.categoryType === 'INCOME') &&
                                        <React.Fragment>
                                            <div className={"col table-col"} data-label="Total Committed">
                                                <span className="d-none">Total Committed</span>
                                                {prettyPrint(this.getCommitted(currentTotal))}
                                            </div>
                                            <div className={"col table-col"} data-label="Total Invoiced">
                                                <span className="d-none">Invoiced</span>
                                                {prettyPrint(this.getInvoiced(currentTotal))}
                                            </div>
                                            <div className={"col table-col"} data-label="Total To be Invoiced">
                                                <span className="d-none">Total To be Invoiced</span>
                                                {prettyPrint(this.getToBeInvoiced(currentTotal))}
                                            </div>
                                            <div className={"col table-col"} data-label="Total Uncommitted">
                                                <span className="d-none">Uncommitted</span>
                                                {prettyPrint(this.getUncommitted(currentTotal))}
                                            </div>
                                            <div className={"col table-col"} data-label="Total Forecast">
                                                <span className="d-none">Total Forecast</span>
                                                {prettyPrint(this.getForecast(currentTotal))}
                                            </div>
                                            <div className={"col table-col"} data-label="Total">
                                                <span className="d-none">Total</span>
                                                {prettyPrint(this.getTotal(currentTotal))}
                                            </div>
                                            {this.getStatus(currentTotal) < 0 ?
                                                <div className={"col table-col kb-real-time-budget-overflow"}
                                                     data-label="Total Status)">
                                                    <span className="d-none">Total Status</span>
                                                    {prettyPrint(this.getStatus(currentTotal))}
                                                </div>
                                                :
                                                <div className={"col table-col"} data-label="Total Status)">
                                                    <span className="d-none">Total Status</span>
                                                    {prettyPrint(this.getStatus(currentTotal))}
                                                </div>
                                            }
                                        </React.Fragment>
                                    }
                                    {(this.props.categoryType === 'FINANCING' || this.props.categoryType === 'INVESTMENT') &&
                                        <React.Fragment>
                                            <div className={"col table-col"} data-label="Total Contribution Per Entity">
                                                <span className="d-none">Total Contribution Per Entity</span>
                                                {prettyPrintWithPrecision(this.getTotalContributionPerEntityForConfiguredTotal(currentTotal), 2)}%
                                            </div>
                                            <div className={"col table-col"} data-label="Total Drawdown">
                                                <span className="d-none">Total Drawdown</span>
                                                {prettyPrint(this.getDrawdown(currentTotal) - this.getReimbursement(currentTotal))}
                                            </div>
                                            <div className={"col table-col"} data-label="Total Remaining Balance">
                                                <span className="d-none">Total Remaining Balance</span>
                                                {prettyPrint(this.getRemainingBalance(currentTotal))}
                                            </div>
                                            <div className={"col table-col"} data-label="Total Outstanding Balance">
                                                <span className="d-none">Total Outstanding Balance</span>
                                                {prettyPrint(this.getOutstandingBalance(currentTotal))}
                                            </div>
                                            <div className={"col table-col"} data-label="Total Loan To Cost">
                                                <span className="d-none">Total Loan To Cost</span>
                                                {prettyPrint(this.getLoanToCost(currentTotal))}
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment/>
                        )
                    )}
                </div>
            </div>
        );
    }

}

BudgetExecutionConfiguringTotalsView.propTypes = {
    configuringTotals: PropTypes.array,
    categories: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        parentId: PropTypes.string,
        projectId: PropTypes.string,
        initialBudget: PropTypes.number,
        executionBudget: PropTypes.number,
        committed: PropTypes.number,
        invoiced: PropTypes.number,
        remainingForecast: PropTypes.number,
        cashFlowPrognosis: PropTypes.number,
    })).isRequired,
    categoryType: PropTypes.string,
    invoices: PropTypes.array,
    contracts: PropTypes.array,
    forecasts: PropTypes.array
};

export default BudgetExecutionConfiguringTotalsView;
