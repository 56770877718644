import React, {useCallback} from "react";
import PropTypes from 'prop-types';

import {withRouter} from 'react-router-dom';
import LinkButton from "../../components-shared/LinkButton";
import {confirmAlert} from "react-confirm-alert";

const ProjectButtons = (props) => {
    const {
        editProject,
        formIsEnabled,
        toggleFormIsEnabled,
        projectDetails,
        onEdit,
        onSave,
        createProject,
        onDelete,
        onCancel
    } = props

    const onCancelLocal = useCallback(() => {
        return confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to discard the changes?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        onCancel()
                    }
                },
                {
                    label: 'No'
                }
            ]
        })
    }, [onCancel]);

    const onDeleteLocal = useCallback(() => {
        return confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to delete the project? The operation cannot be undone!',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        onDelete()
                    }
                },
                {
                    label: 'No'
                }
            ]
        })
    }, [onDelete]);

    const createEditProject = useCallback(() => {
        return (
            <React.Fragment>
                <button className={"btn btn-primary kb-btn_edit--form mr-3"} onClick={onEdit}>
                    Save
                </button>
                <button className={"btn btn-light kb-btn_delete--form mr-3"} onClick={onCancelLocal}>
                    Cancel
                </button>
            </React.Fragment>
        )
    }, [onCancelLocal, onEdit])
    const createViewProject = useCallback(() => {
        return (
            <React.Fragment>
                <button className={"btn btn-primary kb-btn_edit--form mr-3"} onClick={toggleFormIsEnabled}>
                    Edit
                </button>
                <button className={"btn btn-light kb-btn_delete--form mr-3"} onClick={onDeleteLocal}>
                    Delete
                </button>
                {
                    'DRAFT' === projectDetails.projectDefinitionStatus
                        ?
                        <LinkButton className="btn btn-secondary ml-auto"
                                    to={"/template/clone/" + projectDetails.id}>
                            Load From Template
                        </LinkButton>
                        :
                        <label className={"template-loaded mb-0 ml-auto"}>
                            Template already loaded
                        </label>
                }
            </React.Fragment>
        )
    }, [onDeleteLocal, projectDetails.id, projectDetails.projectDefinitionStatus, toggleFormIsEnabled])
    const createEditProjectView = useCallback(() => {
        if (editProject) {
            if (!formIsEnabled) {
                return createViewProject()
            } else {
                return createEditProject()
            }
        }
    }, [createEditProject, createViewProject, editProject, formIsEnabled])

    return (
        <React.Fragment>
            {createEditProjectView()}
            {
                createProject &&
                <button className={"btn btn-primary"} onClick={onSave}>
                    Create Project
                </button>
            }
        </React.Fragment>
    );
}

ProjectButtons.propTypes = {
    projectDetails: PropTypes.object,
    formIsEnabled: PropTypes.bool,
    editProject: PropTypes.bool,
    createProject: PropTypes.bool,
    toggleFormIsEnabled: PropTypes.func,
    onEdit: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func
};

export default withRouter(ProjectButtons);