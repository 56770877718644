import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../components-shared/Breadcrumbs/Breadcrumbs';
import {getAllProjects} from "./actions/projectActions";
import ProjectCard from "./fragment/ProjectCard";

class ViewAllProjectsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projects: [],
        };
    }

    componentDidMount() {
        getAllProjects().then((projects) => {
            this.setState({
                projects: projects,
            })
        });
    }

    render() {
        return (
            <div className="view-project-all">
            
           
                <nav className="kb-nav_search navbar bg-dark">
                    <form className="text-white w-100">
                        <div className="form-group has-search m-0">
                            <span className="fa fa-search form-control-search"></span>
                            <input type="text" className="form-control text-white bg-secondary border-0" placeholder="Search"/>
                        </div>
                    </form>
                </nav>
               <Breadcrumbs breadcrumbs={[{url: '/projects', title: 'Home'}, {url: `/projects`, title: `My Projects`}]} />
                  <header className="kb-header px-3">
                    <div className="container-fluid">
                        <div className="row">

                             <div className="col-12 d-flex align-items-center ">
                                        <div className="kb-title_page d-flex align-items-center py-4">
                                            <h1 className="h4 font-weight-normal m-0">My Projects</h1>
                                          </div>
                               <nav className="kb-nav_ctas ml-auto">
                                    <Link to="/new-project/" className="btn btn-primary">
                                        <i className="fas fa-plus"></i><span className="ml-3">New project</span> 
                                     </Link>
                                </nav>
                            </div> 
                        </div>
                    </div>
                </header>

            <main className="kb-main kb-main_projects px-3 pb-5">
                <div className="container-fluid">
                    <div className="row">

                    {this.state.projects && this.state.projects instanceof Array && this.state.projects.map(project => (
                        <ProjectCard key={project.id} project={project}/>))
                    }

                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                    <div className="card kb-card-project kb-card-project_add  h-100 ">
                         <Link to="/new-project" className="link-overlay"> </Link>
                     <div className="card-body d-flex align-items-center">
                       <h5 className="add-title m-0 text-primary pl-4"><i className="fas fa-plus"></i><span className="ml-3">Add new project</span> </h5>
                   </div>
                    </div>
                </div>

                    </div>
                </div>
            </main>
            </div>
        );
    }
}

export default ViewAllProjectsPage;



