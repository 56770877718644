import React from "react";
import PropTypes from "prop-types";

export default function BudgetCategoryHeader(props) {

        return (
            <React.Fragment>
                {(props.categoryType === 'COST' || props.categoryType === 'INCOME') &&
                <div className="kb-table ">
                    <div className={"kb-thead bg-dark text-light"}>
                        <div className={"row "}>
                            <div className={"col-4 table-col"}/>
                            <div className={"col table-col"}>Initial budget</div>
                            <div className={"col table-col"}>Execution budget</div>
                            <div className={"col table-col"}>Committed</div>
                            <div className={"col table-col"}>Invoiced</div>
                            <div className={"col table-col"}>To be Invoiced</div>
                            <div className={"col table-col"}>Uncommitted</div>
                            <div className={"col table-col"}>
            
                                Forecast
                                {props.shouldLoad &&  
                                        <span className="spinner span-spinner" />
                                }
                            </div>
                            <div className={"col table-col"}>Total</div>
                            <div className={"col table-col"}>Status</div>
                        </div>
                    </div>
                </div>
                }
                {(props.categoryType === 'FINANCING' || props.categoryType === 'INVESTMENT') &&
                    <div className="kb-table ">
                        <div className={"kb-thead bg-dark text-light"}>
                            <div className={"row "}>
                                <div className={"col-4 table-col"}/>
                                <div className={"col table-col"}>Initial budget</div>
                                <div className={"col table-col"}>Execution budget</div>
                                <div className={"col table-col"}>Contribution Per Entity</div>
                                <div className={"col table-col"}>Drawdown</div>
                                <div className={"col table-col"}>Remaining Balance</div>
                                <div className={"col table-col"}>Outstanding Balance</div>
                                <div className={"col table-col"}>Loan To Cost</div>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
}

BudgetCategoryHeader.propTypes = {
    categoryType: PropTypes.string.isRequired
};
