import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {FormLabel, Form, FormControl, FormGroup} from "react-bootstrap";
import CustomDatePicker from "../../../components-shared/CustomDatePicker";
import PropTypes from "prop-types";
import moment from "moment";
import CommaSeparatedInput from "../../../components-shared/CommaSeparatedInput/CommaSeparatedInput";

class InvoiceDetailsForm extends Component {

  render () {
    const invoiceTypeNotSelected = !(this.props.invoiceType === 'COST' || this.props.invoiceType === 'INCOME');
    const inputClassName = " position-relative mb-5";
    
    return (
      <div className={this.props.inModal ? "col-md-12" : "col-md-6"}>
        <div className="row">
          <div className="col-md-12">
            <h5 className="py-3">Invoice details</h5>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <FormGroup className={inputClassName} controlId="invoiceType">
              <FormLabel>Invoice type</FormLabel>
              <Form.Control
                as="select"
                type="contract"
                value={this.props.invoiceType}
                onChange={this.props.handleChangeInvoiceType}
                disabled={this.props.isEditable || this.props.isMonthClosed}
              >
                <option key="noKey" value="">Select...</option>
                <option key="COST" value="COST">Cost</option>
                <option key="INCOME" value="INCOME">Income</option>
              </Form.Control>
              {this.props.displayErrors.invoiceType && this.props.errorCheck('invoiceType', false)}
            </FormGroup>
          </div>
          
          <div className="col-md-6">
            <FormGroup className={this.props.inputClassName} controlId="invoiceNo">
              <FormLabel>Invoice No.</FormLabel>
              <FormControl
                type="text"
                value={this.props.invoiceNo}
                onChange={(e) => this.props.handleChange(e, "invoiceNo", false)}
                autoComplete="off"
                disabled={invoiceTypeNotSelected || this.props.isMonthClosed}
              />
              {this.props.displayErrors.invoiceNo && this.props.errorCheck('invoiceNo', false)}
            </FormGroup>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <FormGroup className={inputClassName} controlId="invoiceDate">
              <FormLabel className="d-block">Invoice date</FormLabel>
              <CustomDatePicker
                selected={
                  this.props.invoiceDate ? moment(new Date(this.props.invoiceDate.year, this.props.invoiceDate.month, this.props.invoiceDate.day)) : ''
                }
                onChange={this.props.handleChangeInvoiceDate}
                disabled={invoiceTypeNotSelected || this.props.isMonthClosed}
              />
              {this.props.displayErrors.invoiceDate && this.props.errorCheck('invoiceDate', false)}
            </FormGroup>
          </div>
          
          <div className="col-md-6">
            <FormGroup className={inputClassName} controlId="budgetDate">
              <FormLabel className="d-block">Budget date</FormLabel>
              <CustomDatePicker
                selected={this.props.budgetDate ? moment(new Date(this.props.budgetDate.year, this.props.budgetDate.month, this.props.budgetDate.day)) : ''}
                onChange={this.props.handleChangeBudgetDate}
                disabled={invoiceTypeNotSelected || this.props.invoiceType === 'INCOME'  || this.props.isMonthClosed}
              />
              {this.props.displayErrors.budgetDate && this.props.errorCheck('budgetDate', false)}
            </FormGroup>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <FormGroup className={inputClassName} controlId="maturityDate">
              <FormLabel className="d-block">Maturity date</FormLabel>
              <CustomDatePicker
                selected={this.props.maturityDate ? moment(new Date(this.props.maturityDate.year, this.props.maturityDate.month, this.props.maturityDate.day)) : ''}
                onChange={this.props.handleChangeMaturityDate}
                disabled={invoiceTypeNotSelected  || this.props.isMonthClosed}
              />
              {this.props.errorCheck('maturityDate', false)}
            </FormGroup>
          </div>
          
          <div className="col-md-6">
            <FormGroup className={inputClassName} controlId="paymentDate">
              <FormLabel className="d-block">Payment date</FormLabel>
              <CustomDatePicker
                selected={this.props.paymentDate ? moment(new Date(this.props.paymentDate.year, this.props.paymentDate.month, this.props.paymentDate.day)) : ''}
                onChange={this.props.handleChangePaymentDate}
                disabled={invoiceTypeNotSelected}
              />
              {this.props.displayErrors.paymentDate && this.props.errorCheck('paymentDate', false)}
            </FormGroup>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-12">
            <FormGroup className={inputClassName} controlId="supplier">
              {this.props.invoiceType === '' && <FormLabel>Client or Supplier name</FormLabel>}
              {this.props.invoiceType === 'COST' && <FormLabel>Supplier name</FormLabel>}
              {this.props.invoiceType === 'INCOME' && <FormLabel>Client name</FormLabel>}
              <FormControl
                type="text"
                value={this.props.supplier}
                onChange={(e) => this.props.handleChange(e, "supplier", false)}
                autoComplete="off"
                disabled={invoiceTypeNotSelected || this.props.isMonthClosed}
              />
              {this.props.displayErrors.supplier && this.props.errorCheck('supplier', false)}
            </FormGroup>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-12">
            <FormGroup className={inputClassName} controlId="description">
              <FormLabel>Description</FormLabel>
              <FormControl
                type="text"
                value={this.props.description}
                onChange={(e) => this.props.handleChange(e, "description", false)}
                autoComplete="off"
                disabled={invoiceTypeNotSelected || this.props.isMonthClosed}
              />
              {this.props.displayErrors.description && this.props.errorCheck('description', false)}
            </FormGroup>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <FormGroup className={inputClassName}>
              <FormLabel>Amount with VAT (RON)</FormLabel>
              <CommaSeparatedInput
                  elementType="FormControl"
                  type="number"
                  decimalsAfterDot={2}
                  min={-9999999999}
                  id={"ammountWithVatRon"}
                  disabled={invoiceTypeNotSelected || this.props.isMonthClosed}
                  value={this.props.ammountWithVatRon}
                  handleChange={this.props.handleAmountWithVATChange}
              />
              {this.props.displayErrors.ammountWithVatRon && this.props.errorCheck('ammountWithVatRon', false)}
            </FormGroup>
          </div>
          
          <div className="col-md-3">
            <FormGroup className={inputClassName} controlId="vatRate">
              <FormLabel>VAT rate</FormLabel>
              <Form.Control
                as="select"
                type="contract"
                value={this.props.vatRate}
                onChange={this.props.handleVATRateChange}
                disabled={invoiceTypeNotSelected || this.props.isMonthClosed}
              >
                <option key="noKey" value="">Select...</option>
                {this.props.vats &&
                this.props.vats.map(vat => (
                  <option key={vat.id}
                          value={vat.id}>{vat.vatValue}</option>
                ))
                }
              </Form.Control>
              {this.props.displayErrors.vatRate && this.props.errorCheck('vatRate', false)}
            </FormGroup>
          </div>

          <div className="col-md-3">
            <FormGroup className={inputClassName} controlId="bnrRate">
              <FormLabel>BNR rate</FormLabel>
              <FormControl
                  type="number"
                  value={this.props.bnrRate}
                  onChange={this.props.handleBNRRateChange}
                  autoComplete="off"
                  disabled={invoiceTypeNotSelected || this.props.isMonthClosed}
                  ref={this.bnrRate}
              />
              {this.props.displayErrors.bnrRate && this.props.errorCheck('bnrRate', false)}
            </FormGroup>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <FormGroup className={inputClassName}>
              <FormLabel>Amount without VAT (RON)</FormLabel>
              <CommaSeparatedInput
                  elementType="FormControl"
                  type="number"
                  decimalsAfterDot={2}
                  min={0}
                  id={"ammountWithoutVatRon"}
                  disabled={true}
                  value={this.props.ammountWithoutVatRon}
                  handleChange={(e) => this.props.handleChange(e, "ammountWithoutVatRon", false)}
              />
              {this.props.displayErrors.ammountWithoutVatRon && this.props.errorCheck('ammountWithoutVatRon', false)}
            </FormGroup>
          </div>
          
          <div className="col-md-6">
            <FormGroup className={inputClassName}>
              <FormLabel>VAT amount (RON)</FormLabel>
              <CommaSeparatedInput
                  elementType="FormControl"
                  type="number"
                  decimalsAfterDot={2}
                  min={0}
                  id={"vatAmmountRon"}
                  disabled={true}
                  value={this.props.vatAmmountRon}
                  handleChange={(e) => this.props.handleChange(e, "vatAmmountRon", false)}
              />
              {this.props.displayErrors.vatAmmountRon && this.props.errorCheck('vatAmmountRon', false)}
            </FormGroup>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-6">
            <FormGroup className={inputClassName}>
              <FormLabel>Amount without VAT (EUR)</FormLabel>
              <CommaSeparatedInput
                  elementType="FormControl"
                  type="number"
                  decimalsAfterDot={2}
                  min={0}
                  id={"ammountWithoutVatEuro"}
                  disabled={true}
                  value={this.props.ammountWithoutVatEuro}
                  handleChange={(e) => this.props.handleChange(e, "ammountWithoutVatEuro", false)}
              />
              {this.props.checkTotalAmount()}
            </FormGroup>
          </div>
          
          <div className="col-md-6">
            <FormGroup className={inputClassName}>
              <FormLabel>VAT amount (EUR)</FormLabel>
              <CommaSeparatedInput
                  elementType="FormControl"
                  type="number"
                  decimalsAfterDot={2}
                  min={0}
                  id={"vatAmmountEuro"}
                  disabled={true}
                  value={this.props.vatAmmountEuro}
                  handleChange={(e) => this.props.handleChange(e, "vatAmmountEuro", false)}
              />
              {this.props.displayErrors.vatAmmountEuro && this.props.errorCheck('vatAmmountEuro', false)}
            </FormGroup>
          </div>
        </div>
      </div>
    )
  }
}

InvoiceDetailsForm.propTypes = {
  invoiceType: PropTypes.string.isRequired,
  invoiceNo: PropTypes.string.isRequired,
  invoiceDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  budgetDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  maturityDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  paymentDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  supplier: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  ammountWithVatRon: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  vatRate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bnrRate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ammountWithoutVatRon: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  vatAmmountRon: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ammountWithoutVatEuro: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  vatAmmountEuro: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  
  vats: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      vatValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ),
  
  isEditable: PropTypes.bool,
  displayErrors: PropTypes.shape({
    invoiceType: PropTypes.bool,
    invoiceNo: PropTypes.bool,
    budgetDate: PropTypes.bool,
    maturityDate: PropTypes.bool,
    paymentDate: PropTypes.bool,
    supplier: PropTypes.bool,
    description: PropTypes.bool,
    ammountWithVatRon: PropTypes.bool,
    vatRate: PropTypes.bool,
    bnrRate: PropTypes.bool,
    ammountWithoutVatRon: PropTypes.bool,
    vatAmmountRon: PropTypes.bool,
    ammountWithoutVatEuro: PropTypes.bool,
    vatAmmountEuro: PropTypes.bool,
  }),
  
  errorCheck: PropTypes.func,
  handleChangeInvoiceType: PropTypes.func,
  handleChangeInvoiceDate: PropTypes.func,
  handleChangeBudgetDate: PropTypes.func,
  handleChangeMaturityDate: PropTypes.func,
  handleChangePaymentDate: PropTypes.func,
  handleAmountWithVATChange: PropTypes.func,
  handleVATRateChange: PropTypes.func,
  handleBNRRateChange: PropTypes.func,
  handleChange: PropTypes.func,
  checkTotalAmount: PropTypes.func,
  isMonthClosed: PropTypes.bool
}

export default withRouter(InvoiceDetailsForm);
