import React from 'react';

import {
    //userRolesFilter,
     userStatesFilter} from "../support/UserConstants";

import PropTypes from "prop-types";
import {Button, FormLabel, FormControl, FormGroup, Form} from "react-bootstrap";

export default function UserSearchForm(props) {

    const inputClassName = "mr-md-3";

    return (
        <React.Fragment>
            <form onSubmit={props.handleSubmit} className="form-inline pt-4">
                <FormGroup className={inputClassName} controlId="name">
                    <FormLabel className="mr-3">Name/Company</FormLabel>
                    <FormControl
                        type="text"
                        value={props.filters && props.filters.name ? props.filters.name : ""}
                        onChange={props.handleChange}
                    />
                </FormGroup>

                <FormGroup className={inputClassName} controlId="project">
                    <FormLabel  className="mr-3">Project</FormLabel>
                    <FormControl
                        type="text"
                        value={props.filters && props.filters.project ? props.filters.project : ""}
                        onChange={props.handleChange}
                    />
                </FormGroup>

                <FormGroup className={inputClassName} controlId="userRole">
                    <FormLabel className="mr-3">UserRole</FormLabel>
                    <Form.Control
                        as="select"
                        type="userRole"
                        value={props.filters.userRole}
                        onChange={props.handleChange}
                    >
                        {props.roles.map((role) => (
                            <option key={role.id} value={role.id}>{role.name}</option>))}
                    </Form.Control>
                </FormGroup>

                <FormGroup className={"d-none " + inputClassName} controlId="userState">
                    <FormLabel>UserState</FormLabel>
                    <Form.Control
                        as="select"
                        type="userState"
                        value={props.filters.userState}
                        onChange={props.handleChange}
                    >
                        {userStatesFilter.map((item) => (
                            <option key={item.value} value={item.value}>{item.displayName}</option>))}
                    </Form.Control>
                </FormGroup>

                <Button variant="primary" type="submit"><i className="fas fa-search mr-3"></i> Search</Button>
            </form> 
         </React.Fragment>
    )
}

UserSearchForm.propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    filters: PropTypes.shape({
        name: PropTypes.string,
        project: PropTypes.string,
        userRole: PropTypes.string,
        userState: PropTypes.string,
    })
};
