import fetch from 'isomorphic-fetch';
import store from "../redux/store";
import { makeId } from '../components/user/UserPermissionsPage/functions';
import { addLoading, removeLoading } from '../redux/actions/actions';


const removeLoad = (uniqueId) => {
    setTimeout(() => {
        store.dispatch(removeLoading(uniqueId));
    }, 100);
}

function customFetch(url, body) {
    const apiURLOrigin = 'https://www.api.kybud.eu';
    const uiURLOrigin = 'https://www.kybud.eu';
    // const apiURLOrigin = 'http://localhost:8000';
    // const uiURLOrigin = 'http://localhost:3000';
    const loginUrlTest = apiURLOrigin + '/login';
    const loginUrlTestError = apiURLOrigin + '/login?error';
    const loginUrl = uiURLOrigin + '/#/login';
    const loginUrlError = uiURLOrigin + '/#/login?error=true';
    const currentLocation = window.location.href;
    // const BACKEND_PORT = 8000;
    // const __API__ = JSON.stringify('http://localhost:' + BACKEND_PORT);
    // When using webpack-dev-server, frontend is running on a different port than backend.
    // __API__ (defined in webpack's config file) points to the actual backend URL.
    //const actualUrl = typeof IS_SERVED_BY_WEBPACK_DEV_SERVER === "undefined" ? url : __API__ + url;
    //const actualUrl = 'https://www.api.kybud-testing.xyz' + url;
    const actualUrl = apiURLOrigin + url;
    return fetch(actualUrl,

        // Added so application will work on edge - for more details check (the set-cookie header on response issued an extra wrong request for edge)
        // https://stackoverflow.com/questions/46288437/set-cookie-header-has-no-effect-about-using-cookies-cors-including-for-local
        Object.assign(body, {credentials: 'include'})

    ).then((result) => {
        if (result.redirected && loginUrlTest === result.url) {
            if (currentLocation !== loginUrl) {
                window.location.href = loginUrl;
                window.location = loginUrl;
                window.location.reload();
            }
        } else if (result.redirected && loginUrlTestError === result.url) {
            if (currentLocation !== loginUrlError) {
                window.location.href = loginUrlError;
                return false;
            }
        }
        return result;
    });
}

/**
 * return a promise on top of a void, that won't have any object inside the json
 * @param url
 * @returns {Promise|*|Promise<T | never>|void}
 */
export function genericDelete(url) {
    let uniqueId = makeId();
    store.dispatch(addLoading(uniqueId));
    return customFetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((result) => {
        removeLoad(uniqueId);
        if (result.status !== 200) {
            console.log('Error: ' + result.status);
        }
        return result;
    }).catch((error) => {
        removeLoad(uniqueId);
        console.log('exception', error);
    });
}

export function genericPost(url, data) {
    let uniqueId = makeId();
    store.dispatch(addLoading(uniqueId));
    return customFetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }

    }).then((result) => {
        if (result.status !== 200) {
            console.log('Error: ' + result.status);
        }
        removeLoad(uniqueId);
        return result.json();
    }).then((json) => {
        removeLoad(uniqueId);
        console.log('post json', json);
        return json;
    }).catch((error) => {
        removeLoad(uniqueId);
        console.log('exception', error);
    });
}

export function genericPostWithDownload(url, data, headers) {
    let uniqueId = makeId();
    store.dispatch(addLoading(uniqueId));
    return customFetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: headers

    }).then((result) => {
        if (result.status !== 200) {
            console.log('Error: ' + result.status);
        }
        removeLoad(uniqueId);
        return result;
    }).catch((error) => {
        removeLoad(uniqueId);
        console.log('exception', error);
    });
}

export function genericPostMultipart(url, data) {
    let uniqueId = makeId();
    store.dispatch(addLoading(uniqueId));
    return customFetch(url, {
        method: 'POST',
        body: data
    }).then((result) => {
        if (result.status !== 200) {
            console.log('Error: ' + result.status);
        }
        removeLoad(uniqueId);
        return result && result.json() ? result.json() : {error: true};
    }).then((json) => {
        removeLoad(uniqueId);
        console.log('post json', json);
        return json;
    }).catch((error) => {
        removeLoad(uniqueId);
        console.log('exception', error);
        return error;
    });
}

export function genericGet(url) {
    let uniqueId = makeId();
    store.dispatch(addLoading(uniqueId));
    return customFetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((result) => {
        if (result.status !== 200) {
            console.log('Error: ' + result.status);
        }
        removeLoad(uniqueId);
        return result.json();
    }).then((json) => {
        removeLoad(uniqueId);
        return json;
    }).catch((error) => {
        removeLoad(uniqueId);
        console.log('exception', error);
        return 0;
    });
}

export function genericGetNoLoading(url) {
    return customFetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((result) => {
        if (result.status !== 200) {
            console.log('Error: ' + result.status);
        }
        return result.json();
    }).then((json) => {
        return json;
    }).catch((error) => {
        console.log('exception', error);
        return 0;
    });
}

export function genericGetMultipart(url) {
    let uniqueId = makeId();
    store.dispatch(addLoading(uniqueId));
    return customFetch(url, {
        method: 'GET'
    }).then((result) => {
        if (result.status !== 200) {
            console.log('Error: ' + result.status);
        }
        removeLoad(uniqueId);
        return result;
    }).catch((error) => {
        removeLoad(uniqueId);
        console.log('exception', error);
        return 0;
    });
}

export function genericPut(url, data) {
    let uniqueId = makeId();
    store.dispatch(addLoading(uniqueId));
    return customFetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((result) => {
        if (result.status !== 200) {
            console.log('Error: ' + result.status);
        }
        removeLoad(uniqueId);
        return result.json();
    }).then((json) => {
        removeLoad(uniqueId);
        console.log('put json', json);
        return json;
    }).catch((error) => {
        removeLoad(uniqueId);
        console.log('exception', error);
    });
}

export function genericPatch(url, data) {
    let uniqueId = makeId();
    store.dispatch(addLoading(uniqueId));
    return customFetch(url, {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((result) => {
        if (result.status !== 200) {
            console.log('Error: ' + result.status);
        }
        removeLoad(uniqueId);
        return result.json();
    }).then((json) => {
        removeLoad(uniqueId);
        console.log('put json', json);
        return json;
    }).catch((error) => {
        removeLoad(uniqueId);
        console.log('exception', error);
    });
}

export function genericPutVoidReturn(url, data) {
    let uniqueId = makeId();
    store.dispatch(addLoading(uniqueId));
    return customFetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((result) => {
        if (result.status !== 200) {
            console.log('Error: ' + result.status);
        }
        removeLoad(uniqueId);
        return result;
    }).catch((error) => {
        removeLoad(uniqueId);
        console.log('exception', error);
    });
}

export function genericPostVoidReturn(url, data) {
    let uniqueId = makeId();
    store.dispatch(addLoading(uniqueId));
    return customFetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((result) => {
        if (result.status !== 200) {
            console.log('Error: ' + result.status);
        }
        removeLoad(uniqueId);
        return result;
    }).catch((error) => {
        removeLoad(uniqueId);
        console.log('exception', error);
    });
}
