import React, {Component} from 'react';
import {getHistoryByFilter} from "./actions/historyActions";
import HistorySearchForm from "./HistorySearchForm";
import MD5 from "crypto-js/md5";
import Breadcrumbs from "../../components-shared/Breadcrumbs/Breadcrumbs";
import BackButtonNavigationBar from "../../components-shared/BackButtonNavigationBar";

class DisplayHistoryContainer extends Component {

    constructor(props) {
        super(props);

        this.state = DisplayHistoryContainer.constructStateObject();

        this.refreshList = this.refreshList.bind(this);
    }

    static constructStateObject() {
        return {
            history: {}
        };
    }

    componentDidMount() {
        this.refreshList({});
    }

    // Refresh list after something has been modified which may impact the display data.
    refreshList(filters) {

        getHistoryByFilter(filters).then((history) => {
            this.setState({
                history: history
            })
        });
    }

    render() {
        return (
                 <React.Fragment>
                 <BackButtonNavigationBar classNamePrefix={"projects"} />
                    <Breadcrumbs breadcrumbs={[{url: '/projects' , title: 'Home' }, {url: '/history' , title: 'Notifications' }]} />
                    <header className="kb-header px-3">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center ">
                                    <div className="kb-title_page d-flex align-items-center py-4">
                                        <h1 className="h4 font-weight-normal m-0">Notifications</h1>
                                    </div>
                                    <nav className="kb-nav_ctas ml-auto">
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </header>
                    <main className="kb-main kb-main_history px-3 py-4 bg-white">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 pb-5">
                                    <HistorySearchForm historyTotalElements={this.state.history && this.state.history.totalElements ? this.state.history.totalElements : 10} updateHistoryData={this.refreshList} />
                                    <ul className="list-group list-group-flush mt-4">
                                        {this.state.history && this.state.history.viewDtoList &&
                                        this.state.history.viewDtoList.map((historyElement) =>
                                        <li key={MD5(JSON.stringify(historyElement.historyDisplayInformation))} className="list-group-item bg-light border mb-2">
                                            <span>{historyElement.historyDisplayInformation}</span>
                                        </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </main>

             </React.Fragment>
        );
    }
}

export default DisplayHistoryContainer;
