import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Form, FormLabel, FormGroup} from "react-bootstrap";
import PropTypes from "prop-types";
import CommaSeparatedInput from "../../../components-shared/CommaSeparatedInput/CommaSeparatedInput";

class InvoiceBudgetLinesForm extends Component { 
  
    getContractsForInvoiceRecord(i) {
        const budgetLineId = this.props.invoiceRecords[i].budgetLine
        if (this.props.budgetLines) {
            const line = this.props.budgetLines.filter((budgetLine) => {
                return budgetLine.id === budgetLineId
            });
            if (line && line.length > 0) {
                return line[0].contracts;
            } else return [];
        } else return [];
    }

    invoiceRecordsAreInvalid() {
        return this.props.invoiceRecords.some(invoiceRecord => invoiceRecord.budgetLine === "" || invoiceRecord.budgetLineContract === "")
    }

    render() {
        const invoiceTypeNotSelected = !(this.props.invoiceType === 'COST' || this.props.invoiceType === 'INCOME');
        const inputClassName = " position-relative mb-5";

        return (
            <div className={this.props.inModal ? "col-md-12" : "col-md-6"}>
                <div className="row">
                    <div className="col-md-6">
                        <h5 className="py-3">Assign to budget line</h5>
                    </div>
                </div>
                {this.props.invoiceRecords instanceof Array &&
                this.props.invoiceRecords.length > 0 &&
                this.props.invoiceRecords.map((invoiceRecord, i) => (
                    <React.Fragment key={invoiceRecord + i}>
                        <div className={i > 0 ? "row border-top pt-5" : "row"}>
                            <div className="col-md-6">
                                <FormGroup className={inputClassName} controlId={"budgetLine" + i}>
                                    <FormLabel className="d-none">budget line</FormLabel>
                                    <Form.Control
                                        as="select"
                                        type="contract"
                                        value={this.props.invoiceRecords && this.props.invoiceRecords[i] ? this.props.invoiceRecords[i].budgetLine : ''}
                                        onChange={(e) => this.props.handleChange(e, "budgetLine", i)}
                                        disabled={invoiceTypeNotSelected || this.props.isMonthClosed}
                                    >
                                        <option key="noKey" value="">Select budget line</option>
                                        {this.props.budgetLines &&
                                        this.props.budgetLines.map(budgetLine => (
                                            <option key={budgetLine.id}
                                                    value={budgetLine.id}>IB Code  {budgetLine.ibCode}</option>
                                        ))
                                        }
                                    </Form.Control>
                                    {this.props.displayErrors.invoiceRecords[i].budgetLine && this.props.errorCheck('budgetLine', i)}
                                </FormGroup>
                            </div>

                            <div className="col-md-5">
                                <FormGroup className={inputClassName} controlId={"budgetLineContract" + i}>
                                    <FormLabel className="d-none">Contract</FormLabel>
                                    <Form.Control
                                        as="select"
                                        type="contract"
                                        value={this.props.invoiceRecords && this.props.invoiceRecords[i] ? this.props.invoiceRecords[i].budgetLineContract : ''}
                                        onChange={(e) => this.props.handleChange(e, "budgetLineContract", i)}
                                        disabled={invoiceTypeNotSelected || this.props.invoiceRecords[i].budgetLine === '' || this.props.isMonthClosed}
                                    >
                                        <option key="noKey" value="">Select contract</option>
                                        {this.getContractsForInvoiceRecord(i) &&
                                        this.getContractsForInvoiceRecord(i).map(contract => (
                                            <option key={contract.id}
                                                    value={contract.id}>{contract.agreementReference}</option>
                                        ))
                                        }
                                    </Form.Control>
                                    {this.props.displayErrors.invoiceRecords[i].budgetLineContract && this.props.errorCheck('budgetLineContract', i)}
                                </FormGroup>
                            </div>

                            <div className="col-md-1 align-self-end text-right pb-5">
                                {this.props.invoiceRecords.length > 1 &&
                                <button
                                    type="button"
                                    className="border-0 bg-transparent mb-0"
                                    onClick={() => this.props.handleDelete(i)}
                                    disabled={this.props.isMonthClosed}
                                >
                                    <i className="fas fa-trash"></i>
                                </button>
                                }
                                {this.props.invoiceRecords.length <= 1 &&
                                <button
                                    type="button"
                                    className="border-0 bg-transparent mb-0"
                                    disabled={this.props.isMonthClosed}
                                >
                                    <i className="fas fa-trash text-light-grey"></i>
                                </button>
                                }
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 align-self-end">
                                <FormGroup className={inputClassName}>
                                    <FormLabel>Amount without VAT (EUR)</FormLabel>
                                    <CommaSeparatedInput
                                        elementType="FormControl"
                                        type="number"
                                        decimalsAfterDot={2}
                                        min={-9999999999}
                                        id={"budgetLineAmmountWithoutVatEuro" + i}
                                        disabled={invoiceTypeNotSelected || this.props.invoiceRecords[i].budgetLine === '' || this.props.isMonthClosed}
                                        value={this.props.invoiceRecords && this.props.invoiceRecords[i] ? this.props.invoiceRecords[i].budgetLineAmmountWithoutVatEuro : ''}
                                        handleChange={(e) => this.props.handleChangeAmountWithoutVatEuro(e, i)}
                                    />
                                    {this.props.displayErrors.invoiceRecords[i].budgetLineAmmountWithoutVatEuro && this.props.errorCheck('budgetLineAmmountWithoutVatEuro', i)}
                                </FormGroup>
                            </div>

                            <div className="col-md-4 align-self-end">
                                <FormGroup className={inputClassName}>
                                    <FormLabel>Amount with VAT (EUR)</FormLabel>
                                    <CommaSeparatedInput
                                        elementType="FormControl"
                                        type="number"
                                        decimalsAfterDot={2}
                                        min={0}
                                        id={"budgetLineAmmountWithVatEuro" + i}
                                        disabled={true}
                                        value={this.props.invoiceRecords && this.props.invoiceRecords[i] ? this.props.invoiceRecords[i].budgetLineAmmountWithVatEuro : ''}
                                        handleChange={(e) => this.props.handleChange(e, "budgetLineAmmountWithVatEuro", i)}
                                    />
                                    {this.props.errorCheck('budgetLineAmmountWithVatEuro', i)}
                                </FormGroup>
                            </div>

                            <div className="col-md-4 align-self-end">
                                <FormGroup className={inputClassName}>
                                    <FormLabel>VAT amount (EUR)</FormLabel>
                                    <CommaSeparatedInput
                                        elementType="FormControl"
                                        type="number"
                                        decimalsAfterDot={2}
                                        min={0}
                                        id={"budgetLineVatAmmountEuro" + i}
                                        disabled={true}
                                        value={this.props.invoiceRecords && this.props.invoiceRecords[i] ? this.props.invoiceRecords[i].budgetLineVatAmmountEuro : ''}
                                        handleChange={(e) => this.props.handleChange(e, "budgetLineVatAmmountEuro", i)}
                                    />
                                    {this.props.errorCheck('budgetLineVatAmmountEuro', i)}
                                </FormGroup>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 align-self-end">
                                <FormGroup className={inputClassName}>
                                    <FormLabel>Amount without VAT (RON)</FormLabel>
                                    <CommaSeparatedInput
                                        elementType="FormControl"
                                        type="number"
                                        decimalsAfterDot={2}
                                        min={0}
                                        id={"budgetLineAmmountWithoutVatRon" + i}
                                        disabled={true}
                                        value={this.props.invoiceRecords && this.props.invoiceRecords[i] ? this.props.invoiceRecords[i].budgetLineAmmountWithoutVatRon : ''}
                                        handleChange={(e) => this.props.handleChange(e, "budgetLineAmmountWithoutVatRon", i)}
                                    />
                                    {this.props.errorCheck('budgetLineAmmountWithoutVatRon', i)}
                                </FormGroup>
                            </div>

                            <div className="col-md-4 align-self-end">
                                <FormGroup className={inputClassName}>
                                    <FormLabel>Amount with VAT (RON)</FormLabel>
                                    <CommaSeparatedInput
                                        elementType="FormControl"
                                        type="number"
                                        decimalsAfterDot={2}
                                        min={0}
                                        id={"budgetLineAmmountWithVatRon" + i}
                                        disabled={true}
                                        value={this.props.invoiceRecords && this.props.invoiceRecords[i] ? this.props.invoiceRecords[i].budgetLineAmmountWithVatRon : ''}
                                        handleChange={(e) => this.props.handleChange(e, "budgetLineAmmountWithVatRon", i)}
                                    />
                                    {this.props.errorCheck('budgetLineAmmountWithVatRon', i)}
                                </FormGroup>
                            </div>

                            <div className="col-md-4 align-self-end">
                                <FormGroup className={inputClassName}>
                                    <FormLabel>VAT amount (RON)</FormLabel>
                                    <CommaSeparatedInput
                                        elementType="FormControl"
                                        type="number"
                                        decimalsAfterDot={2}
                                        min={0}
                                        id={"budgetLineVatAmmountRon" + i}
                                        disabled={true}
                                        value={this.props.invoiceRecords && this.props.invoiceRecords[i] ? this.props.invoiceRecords[i].budgetLineVatAmmountRon : ''}
                                        handleChange={(e) => this.props.handleChange(e, "budgetLineVatAmmountRon", i)}
                                    />
                                    {this.props.errorCheck('budgetLineVatAmmountRon', i)}
                                </FormGroup>
                            </div>
                        </div>
                    </React.Fragment>
                ))}
                <div className="row mb-5">
                    <div className="col-md-6">
                        {(this.props.fromBudgetLine || this.props.editInvoice) &&
                        <React.Fragment>
                            <button type="button" className="btn btn-link kb-btn-cancel_permissions ml-3"
                                    onClick={this.props.cancelEdit}>Cancel
                            </button>
                            <button type="button" className="btn btn-primary kb-btn-save_permissions ml-3"
                                    onClick={this.props.addOrUpdate}>Save
                            </button>
                        </React.Fragment>
                        }
                    </div>
                    <div className="col-md-6 text-right">
                        <button type="button" className="btn btn-dark kb-btn-save_permissions"
                                onClick={this.props.addBudgetLine} disabled={this.invoiceRecordsAreInvalid() || this.props.isMonthClosed}>Add new
                            budget line
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

InvoiceBudgetLinesForm.propTypes = {
    invoiceType: PropTypes.string.isRequired,
    invoiceRecords: PropTypes.arrayOf(
        PropTypes.shape({
            budgetLine: PropTypes.string.isRequired,
            budgetLineContract: PropTypes.string.isRequired,
            budgetLineAmmountWithoutVatEuro: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            budgetLineAmmountWithVatEuro: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            budgetLineVatAmmountEuro: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            budgetLineAmmountWithoutVatRon: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            budgetLineAmmountWithVatRon: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            budgetLineVatAmmountRon: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        })
    ),
    budgetLines: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            ibCode: PropTypes.number.isRequired,
            contracts: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    agreementReference: PropTypes.string.isRequired
                })
            )
        })
    ),
    displayErrors: PropTypes.shape({
        invoiceRecords: PropTypes.arrayOf(
            PropTypes.shape({
                budgetLine: PropTypes.bool.isRequired,
                budgetLineContract: PropTypes.bool.isRequired,
                budgetLineAmmountWithoutVatEuro: PropTypes.bool.isRequired,
            })
        )
    }),
    handleChange: PropTypes.func,
    handleChangeAmountWithoutVatEuro: PropTypes.func,
    addBudgetLine: PropTypes.func,
    handleDelete: PropTypes.func,
    errorCheck: PropTypes.func,
    isMonthClosed: PropTypes.bool
}

export default withRouter(InvoiceBudgetLinesForm);
