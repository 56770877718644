import React from 'react';

import PropTypes from "prop-types";
import {Button, FormLabel, Form, FormControl, FormGroup} from "react-bootstrap";
import CustomDatePicker from "../../../components-shared/CustomDatePicker";
import InvoicesExcelExport from "./InvoicesExcelExport";

export default function InvoiceSearchForm(props) {

    const inputClassName = "postion-relative w-100";

    return (
        <div className="col-12 kb-search-wrapper">
            <form onSubmit={props.handleSubmit} className="form-inline">
            <div className="col-2 col-lg-2 pl-0 pr-0">
              <FormGroup className={inputClassName} controlId={"filter"}>
                  <FormLabel className="d-none">Select filter</FormLabel>
                  <Form.Control
                      as="select"
                      type="contract"
                      value={props.filters.filter}
                      onChange={props.handleChange}
                      className="w-100"
                  >
                      <option key="noKey" value="">Select filter</option>
                      {props.filtersList.map(filter => <option key={filter.value} value={filter.value}>{filter.name}</option>)}
                  </Form.Control>
              </FormGroup>
            </div>
            <div className="col-2 col-lg-2 pl-0 pr-0">
              <FormGroup className={inputClassName + " mr-3"} controlId="keyword">
                  <FormControl
                      type="text"
                      value={props.filters.keyword}
                      onChange={props.handleChange}
                      placeholder="Keyword"
                      className="w-100"
                  />
              </FormGroup>
            </div>
            <div className="col-2 col-lg-2 pr-0">
              <FormGroup className={inputClassName} controlId={"date"}>
                  <FormLabel className="d-none">Select date</FormLabel>
                  <Form.Control
                      as="select"
                      type="contract"
                      className="w-100"
                      value={props.filters.date}
                      onChange={props.handleChange}
                  >
                      <option key="noKey" value="">Select date</option>
                      {props.datesList.map(filter => <option key={filter.value} value={filter.value}>{filter.name}</option>)}
                  </Form.Control>
              </FormGroup>
            </div>
            <div className="col-2 col-md-1 col-lg-2 pl-0 pr-0">
              <FormGroup className={inputClassName} controlId="fromDate">
                  <CustomDatePicker
                      selected={props.filters.fromDate}
                      onChange={props.handleChangefromDate}
                      placeholderText="From Date"
                      className="w-100"
                  />
              </FormGroup>
            </div>
            <div className="col-2 col-md-1 col-lg-2 pl-0 pr-0">
              <FormGroup className={inputClassName + " mr-3"} controlId="toDate">
                  <CustomDatePicker
                      selected={props.filters.toDate}
                      onChange={props.handleChangetoDate}
                      placeholderText="To Date"
                      className="w-100"
                  />
              </FormGroup>
            </div>
            <div className="col-2 col-md-2 col-lg-1">
              <Button type="submit" variant="primary" className="kb-btn_search">Search</Button>
            </div>
                <div className="col-2 col-md-2 col-lg-1 text-right">
                    <InvoicesExcelExport
                        projectId = {props.projectId}
                        categoryType = {props.categoryType}
                        filters = {props.filters}
                    />
                </div>
            </form>
        </div>
    )
}

InvoiceSearchForm.propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    filters: PropTypes.shape({
      filter: PropTypes.string,
      keyword: PropTypes.string,
      date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      fromDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      toDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    handleChangefromDate: PropTypes.func.isRequired,
    handleChangetoDate: PropTypes.func.isRequired,
    filtersList: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })),
    datesList: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })),
    projectId: PropTypes.string.isRequired,
    categoryType: PropTypes.string.isRequired
};
