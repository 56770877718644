import React from "react";
import PropTypes from "prop-types";
import ForecastSplitTable from "./ForecastSplitTable";
//import {prettyPrint} from "../../../common/numberOperations";
import {remainingToForecast} from "../../../forecast/support/ForecastConstants";
import Moment from "moment";
import {subtractDoubles} from "../../../common/doubleOperations";
import ButtonContainer from "./ButtonContainer";
//import {Button} from "react-bootstrap";

function MonthlyExecutionRemainingForecastView(props) {

    return (
        <div className={"kb-forecast-months bg-white" + (props.noYPadding ? "" : " pt-3")}>
            <div className="container-fluid">
                {!props.shouldLoad && 
                    <div className="row pb-3">
                        <ForecastSplitTable
                            comma2={props.comma2}
                            forecasts={props.forecasts}
                            forecastUpdated={props.forecastUpdated}
                            cashPositionUpdated={props.cashPositionUpdated}
                            forecastType={remainingToForecast.value}
                            toBeForecasted={subtractDoubles(subtractDoubles(props.budget, props.committed), props.forecastSum)}
                            onSplitSave={props.onSplitSave}
                            onMoveRight={props.onMoveRight}
                            currentDate={props.currentDate}
                            toBeInvestedCashPosition={props.toBeInvestedCashPosition}
                            type={props.type}
                            readOnly={props.readOnly}
                            noYPadding={props.noYPadding}
                            cashPosition={props.cashPosition}
                            cashPositionValues={props.cashPositionValues}
                            contracts={props.contracts}
                            openedMonths={props.openedMonths}
                        />
                        {props.resetForecasts && props.clearAllForecasts && (<ButtonContainer
                            resetForecasts={props.resetForecasts} 
                            clearAllForecasts={props.clearAllForecasts}
                            classNameForReset={"reset-margin"}
                            addDrowdownReimbursment={props.addDrowdownReimbursment}
                            categoryId={props.categoryId}
                            refreshState={props.refreshState}
                        />)}
                        {!props.resetForecasts && !props.clearAllForecasts && props.addDrowdownReimbursment && (<ButtonContainer
                            classNameForReset={"reset-margin"}
                            addDrowdownReimbursment={props.addDrowdownReimbursment}
                            categoryId={props.categoryId}
                            refreshState={props.refreshState}
                        />)}
                        
                    </div>
                }
            </div> 
           
        </div>
    )
}

MonthlyExecutionRemainingForecastView.propTypes = {
    categoryName: PropTypes.string,
    type: PropTypes.string,
    forecasts: PropTypes.array,
    forecastSum: PropTypes.number,
    forecastUpdated: PropTypes.func,
    cashPositionUpdated: PropTypes.func,
    onSplitSave: PropTypes.func,
    onMoveRight: PropTypes.func,
    currentDate: PropTypes.instanceOf(Moment),
    budget: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    committed: PropTypes.number,
    resetForecasts: PropTypes.func,
    clearAllForecasts: PropTypes.func,
    toBeInvestedCashPosition: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    noYPadding: PropTypes.bool,
    contracts: PropTypes.array,
    cashPosition: PropTypes.bool,
    openedMonths: PropTypes.array
};

export default MonthlyExecutionRemainingForecastView;
