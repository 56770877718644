import React from 'react';
import { Button } from 'react-bootstrap';
import {Prompt} from 'react-router-dom';
import CentralModal from '../../../components-shared/CentralModal/CentralModal';
export class RouteLeavingGuard extends React.Component {
 state = {
   modalVisible: false,
   lastLocation: null,
   confirmedNavigation: false,
 }
 showModal = (location) => this.setState({
   modalVisible: true,
   lastLocation: location,
 })
 closeModal = (callback) => this.setState({
   modalVisible: false
 }, callback);
 handleBlockedNavigation = (nextLocation) => {
   const {confirmedNavigation} = this.state
   const {shouldBlockNavigation} = this.props
   if (!confirmedNavigation && shouldBlockNavigation(nextLocation)){
       this.showModal(nextLocation);
       return false;
   }
   
   return true;
 }
 handleConfirmNavigationClick = () => this.closeModal(() => {
   const {navigate} = this.props
   const {lastLocation} = this.state;
   if (lastLocation) {
      this.setState({
         confirmedNavigation: true
      }, () => {
         // Navigate to the previous blocked location with your navigate function     
         navigate(lastLocation.pathname);
      })
   }
 })
 render() {
   const {when} = this.props
   const {modalVisible} = this.state;
   return (
     <React.Fragment>
        <Prompt
           when={when}
           message={this.handleBlockedNavigation}/>
        <CentralModal
           visible={modalVisible}
           condition={modalVisible}
           toggleCloseMonthModal={() => this.closeModal(()=>false)}
           >
           <h1>Confirm to submit</h1>
           <div>{this.props.message}</div> 

           <div className="mt-4">
           <Button
               className="mr-3 no-min-width"
               variant="primary"
               onClick={this.handleConfirmNavigationClick}
           >
               Yes
           </Button>
           <Button
               variant="light"
               onClick={() => this.closeModal(()=>false)}
           >
               No
           </Button>
       </div>
           </CentralModal>
     </React.Fragment>
   )
 }
}
export default RouteLeavingGuard;