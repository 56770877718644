import React, {useState} from "react";
import {Button} from "react-bootstrap";
import RenderIf from "../../../../main-components/RenderIf";
import AddDrawdownReimbursment from "./AddDrawdownReimbursment";

export default function ButtonContainer (props) {
  
  const [addingDrawdownReimbursment, setAddingDrawdownReimbursment] = useState(false);
  
  const toggleAddingDrawdownReimbursment = () => {
    setAddingDrawdownReimbursment(!addingDrawdownReimbursment);
  };

  return (
    <div className={" kb-accordion-actions kb-accordion-actions py-3 w-100"}>
      <div className="row">
        <div className={"col-md-3 text-left"}>
          {props.categoryType && (props.categoryType === 'COST' || props.categoryType === 'INCOME') &&
          <React.Fragment>
            <Button className="btn-light mr-3" variant="light" onClick={() => props.addInvoice()}>Add invoice</Button>
            <Button className="btn-light " variant="light" onClick={() => props.seeAllInvoices(props.contractId, props.categoryType)}>See all invoices</Button>
          </React.Fragment>
          }
          {
            props.addDrowdownReimbursment && (
              <Button className="btn-light mr-3" variant="light" onClick={toggleAddingDrawdownReimbursment}>Add</Button>
            )
          }
        </div>
        <div className={"col-md-6 text-center text-danger align-self-center"}>
          {props.errorMessage}
        </div>
        <div className={"col-md-3 text-right align-self-center"}>
          <div className={"button-container"}>
            {props.resetForecasts && (<Button className={props.classNameForReset + " btn-light mr-3"} variant="light" onClick={() => props.resetForecasts(props.contractId)}>Reset</Button>)}
            {props.clearAllForecasts && (<Button className="btn-light " variant="light" onClick={() => props.clearAllForecasts(props.contractId)}>Clear All</Button>)}
          </div>
        </div>
      </div>

      
      <RenderIf condition={addingDrawdownReimbursment}>
        <div className='popup'>
          <Button
            className="btn btn-link kb-btn_close-popup"
            onClick={toggleAddingDrawdownReimbursment}
          >

          </Button>
          <div className="popup_inner variable-width-side-modal">
            <div className={"kb-manage-contractors kb-add-contractor-form"}>
                <AddDrawdownReimbursment 
                  toggleModal={toggleAddingDrawdownReimbursment} categoryId={props.categoryId}
                  refreshState={props.refreshState}
                />
            </div>
          </div>
        </div>
      </RenderIf>
    </div>
  )
}
