import React, {Component} from 'react';
import PropTypes from "prop-types";
import {confirmAlert} from "react-confirm-alert";
import {deleteInvoice} from "../../../actions/invoicesActions";
import EditInvoiceForm from "../../../forms/EditInvoiceForm";
import {stringToFormattedDate} from "../../../../common/dateFormatting";
import RenderIf from "../../../../../main-components/RenderIf";
import {Button} from "react-bootstrap";
import {toast} from "react-toastify";
import {prettyPrint} from "../../../../common/numberOperations";

class InvoiceViewAndEdit extends Component {
  
  constructor (props) {
    
    super(props);
    
    this.state = {
      selectedInvoice: {},
      editingInvoice: false
    };
    
    this.toggleEditInvoice = this.toggleEditInvoice.bind(this);
    this.deleteInvoice = this.deleteInvoice.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }
  
  toggleEditInvoice (invoice) {
    
    this.setState({
      selectedInvoice: invoice,
      editingInvoice: !this.state.editingInvoice
    })
  }
  
  deleteInvoice (invoiceId, recordId) {
    
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to delete invoice ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteInvoice(invoiceId, recordId).then(response => {
              if (response.status === 400) {
                toast.error("The selected invoice cannot be deleted as it is assigned to a closed month", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 5000,
                });
                return;
              }
              this.props.refreshState();
            });
          }
        },
        {
          label: 'No',
        }
      ]
    });
  }
  
  onCancel() {
    this.toggleEditInvoice();
    this.props.refreshState();
  }
  render () {
    
    const isEditingInvoiceClassName = this.state.editingInvoice ? " visible" : "";
    
    return (
      <React.Fragment>
        
        <td>
          <span className="d-block font-weight-bold">{this.props.invoice.invoiceNo}</span>
        </td>
        
        <td>{stringToFormattedDate(this.props.invoice.emissionDate)}</td>
        
        {this.props.path === 'cost' && <td>{stringToFormattedDate(this.props.invoice.budgetDate)}</td>}
        <td>{this.props.invoice.clientOrSupplierName}</td>
        <td>{this.props.invoice.description}</td>
        <td>
          {prettyPrint(this.props.invoice.amount.ronWithVat)} RON <br/>
          {prettyPrint(this.props.invoice.amount.euroWithVat)} EUR
        </td>
        <td>
          {prettyPrint(this.props.invoice.amount.ronWithoutVat)} RON <br/>
          {prettyPrint(this.props.invoice.amount.euroWithoutVat)} EUR
        </td>
        <td>
          {prettyPrint(this.props.invoice.amount.ronVat)} RON <br/>
          {prettyPrint(this.props.invoice.amount.euroVat)} EUR
        </td>
        {this.props.invoice.maturityDate ? (<td> {stringToFormattedDate(this.props.invoice.maturityDate)} </td>) : (<td></td>)}
        {this.props.invoice.paymentDate ? (<td> {stringToFormattedDate(this.props.invoice.paymentDate)} </td>) : (<td></td>)}
        <td>{this.props.invoice.contractNo}</td>
        <td>{this.props.invoice.ibCode}</td>
        <td className="action-btns-limit-width">
          <div className="display-inline">
            <button className="btn btn-link px-3"
                    onClick={() => this.toggleEditInvoice(this.props.invoice)}><i className="fas fa-pen"></i>
            </button>
            <button className="btn btn-link px-3"
                    onClick={() => this.deleteInvoice(this.props.invoice.id, this.props.invoice.recordId)}><i className="fas fa-trash"></i>
            </button>
          </div>
          <RenderIf condition={this.state.editingInvoice}>
            <div className='popup'>
              <Button
                className="btn btn-link kb-btn_close-popup"
                onClick={this.toggleEditInvoice}
              >
              </Button>
              <div className="popup_inner px-4">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <div className={"manage-invoice edit-invoice-form" + isEditingInvoiceClassName}>
                        <EditInvoiceForm
                          invoice={this.state.selectedInvoice}
                          refreshState={this.props.refreshState}
                          onCancel={this.onCancel}
                          inModal={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </RenderIf>
        </td>
      
      </React.Fragment>
    )
  }
}

InvoiceViewAndEdit.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    invoiceNo: PropTypes.string.isRequired,
    emissionDate: PropTypes.string.isRequired,
    budgetDate: PropTypes.string.isRequired,
    clientOrSupplierName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    maturityDate: PropTypes.string,
    paymentDate: PropTypes.string,
    contractNo: PropTypes.string.isRequired,
    ibCode: PropTypes.number.isRequired,
    recordId: PropTypes.string.isRequired,
    amount: PropTypes.shape({
      euroWithoutVat: PropTypes.number,
      euroWithVat: PropTypes.number,
      euroVat: PropTypes.number,
      ronWithoutVat: PropTypes.number,
      ronWithVat: PropTypes.number,
      ronVat: PropTypes.number,
    })
  }).isRequired,
  refreshState: PropTypes.func.isRequired
};

export default InvoiceViewAndEdit;
