import React, {Component} from 'react';
import {getAllUsersByFilter
  //, getAllContractorsByProjectId, getUserById, getAllUserDetailsByRoleId
} from "./actions/userActions";
import {userStatesFilter} from "./support/UserConstants"
//import ContentPage from "../../main-components/ContentPage";
import UserSearchForm from "./forms/UserSearchForm";
import update from "immutability-helper";
import UserManagementHeader from "./fragment/UserManagementHeader"
import {withRouter} from "react-router-dom";
import Breadcrumbs from '../../components-shared/Breadcrumbs/Breadcrumbs';
import BackButtonNavigationBar from "../../components-shared/BackButtonNavigationBar";
import {deleteUser} from "./actions/userActions";
import {confirmAlert} from "react-confirm-alert";
//import {unAssignCategoryToCategoryTotal} from "../category/actions/categoriesTotalsActions";
import {toast} from "react-toastify";
import {getRoles} from "./actions/rolesActions";
import Pagination from "react-js-pagination";

class UserManagementPage extends Component {
  
  constructor (props) {
    
    super(props);
    
    this.state = UserManagementPage.constructStateObject();
    
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    
    this.refreshList = this.refreshList.bind(this);
    this.viewUser = this.viewUser.bind(this);
    this.editUser = this.editUser.bind(this);
    this.onDeleteUser = this.onDeleteUser.bind(this);
    this.removeUserFromList = this.removeUserFromList.bind(this);
    this.handleDeleteResponse = this.handleDeleteResponse.bind(this);
    this.getConfirmUserDeleteOptions = this.getConfirmUserDeleteOptions.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.getFilterForPage = this.getFilterForPage.bind(this);
    this.refreshListAfterDelete = this.refreshListAfterDelete.bind(this);
    this.refreshListAfterAdd = this.refreshListAfterAdd.bind(this);
    this.componentIsMounted = false;
  }
  
  static constructStateObject () {
    
    return {
      rolesList: [],
      
      userList: [],
      totalItems: 0,
      
      filters: {
        name: '',
        project: '',
        // objects
        userRole: undefined,
        userState: undefined
      },
      
      pageNumber: 0,
      pageSize: 10,
      
      linkFilters: {
        active: false,
        inactive: false,
        unassigned: false,
      },
      
      defaultLinkFiltersState: {
        active: false,
        inactive: false,
        unassigned: false,
      }
    };
  }

  componentDidMount() {
    if (!this.componentIsMounted) {
      this.componentIsMounted = true;
    getRoles().then(response => {
      const userRoles = response;
      userRoles.splice(0, 0, {id: "ALL", name: "All"});
      const userRole = this.props.match && this.props.match.params && this.props.match.params.userRoleId ? this.props.match.params.userRoleId : '';
      if (this.componentIsMounted) {
        this.setState({
          rolesList: userRoles,
          filters: {
            userRole: userRole
          }
        });
      }
      this.refreshList();
    })
   }
  }

  componentWillUnmount () {
    this.componentIsMounted = false;
  }

  // Search Flow
  handleChange (event) {
    
    if (event.target.id === 'userState') {
      this.setState({
        linkFilters: update(this.state.defaultLinkFiltersState, {}),
      })
    }
    
    this.setState({
      filters: update(this.state.filters, {
        [event.target.id]: {$set: event.target.value}
      })
    });
  };
  
  handleSubmit (event) {
    
    event.preventDefault();
    
    getAllUsersByFilter(this.getFilterForPage(0)).then((response) => {
      this.setState({
        userList: response.users,
        totalItems: response.totalItems,
        pageNumber: 0
      })
    });
  }
  
  getFilterForPage (pageNumber) {
    return {
      name: this.state.filters.name,
      project: this.state.filters.project,
      // objects
      userRole: this.state.filters.userRole !== "ALL" ? this.state.filters.userRole : null,
      userState: this.state.filters.userState,
      pageNumber: pageNumber,
      pageSize: this.state.pageSize
    }
  }

// ViewAll Links Flow
  handleClick (target, checked) {
    
    if (!checked) {
      this.updateUserStateFilter("ALL");
    } else {
      const userStateValue = userStatesFilter.find((e) => e.value === target.toUpperCase());
      
      this.updateUserStateFilter(userStateValue.value);
    }
    
    this.updateLinkFilterValues(target, checked);
  }
  
  updateUserStateFilter (targetValue) {
    
    this.setState({
      filters: update(this.state.filters, {
        "userState": {$set: targetValue}
      }),
    }, () => {
      getAllUsersByFilter(this.getFilterForPage(this.state.pageNumber)).then((response) => {
        this.setState({
          userList: response.users,
          totalItems: response.totalItems
        })
      });
    });
  }
  
  updateLinkFilterValues (targetFilter, targetValue) {
    
    this.setState({
      linkFilters: update(this.state.defaultLinkFiltersState, {
        [targetFilter]: {$set: targetValue}
      })
    });
  }
  
  // Refresh list after something has been modified which may impact the display data.
  refreshList () {
    
    getAllUsersByFilter(this.getFilterForPage(0)).then((response) => {
      this.setState({
        userList: response.users,
        totalItems: response.totalItems,
        pageNumber: 0
      })
    });
  }
  
  refreshListAfterAdd () {
    
    getAllUsersByFilter(this.getFilterForPage(this.state.pageNumber)).then((response) => {
      this.setState({
        userList: response.users,
        totalItems: response.totalItems
      })
    });
  }
  
  viewUser (userId) {
    this.props.history.push('/viewUser/' + userId);
  }
  
  editUser (userId) {
    this.props.history.push('/editUser/' + userId);
  }
  
  onDeleteUser (userId) {
    const confirmUserDeleteOptions = this.getConfirmUserDeleteOptions(userId);
    confirmAlert(confirmUserDeleteOptions);
  }
  
  onPageChange (pageNumber) {
    getAllUsersByFilter(this.getFilterForPage(pageNumber - 1)).then((response) => {
      this.setState({
        userList: response.users,
        totalItems: response.totalItems,
        pageNumber: pageNumber - 1
      })
    });
  }
  
  getConfirmUserDeleteOptions (userId) {
    return {
      title: 'Confirm to submit',
      message: 'Are you sure you want to delete the user?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteUser(userId).then((response) => {
              this.handleDeleteResponse(response, userId);
            });
          }
        },
        {
          label: 'No'
        }
      ]
    };
  }
  
  handleDeleteResponse (response, userId) {
    if (response.status === 200) {
      this.refreshListAfterDelete();
      toast.info("User deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else if (response.status === 400) {
      toast.error("User could not be deleted", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else if (response.status === 403) {
      toast.error("You are not authorized to delete users", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else {
      toast.error("Unable to delete user", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }
  
  refreshListAfterDelete () {
    const filterStillHasUsers = this.state.totalItems > 1;
    const pageStillHasUsers = this.state.userList.length > 1;
    const pageNumber = filterStillHasUsers ? (pageStillHasUsers ? this.state.pageNumber : this.state.pageNumber - 1) : 0;
    getAllUsersByFilter(this.getFilterForPage(pageNumber)).then((result) => {
      this.setState({
        userList: result.users,
        totalItems: result.totalItems,
        pageNumber: pageNumber
      })
    });
  }
  
  removeUserFromList (userId) {
    const userList = this.state.userList
    const newUserList = userList.filter(user => user.id !== userId)
    this.setState({
      userList: newUserList
    })
  }
  
  render () {
    
    return (
      <React.Fragment>
        <BackButtonNavigationBar classNamePrefix={"projects"}/>
        <Breadcrumbs breadcrumbs={[{url: '/projects', title: 'Home'}, {url: `/userManagement`, title: `User Management`}]}/>
        <header className="kb-header px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 d-flex align-items-center">
                <UserManagementHeader handleClick={this.handleClick} linkFilters={this.state.linkFilters} refreshList={this.refreshListAfterAdd}/>
              </div>
            </div>
          </div>
        </header>
        <main className="kb-main kb-main_user--management bg-white px-3 pb-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <UserSearchForm handleSubmit={this.handleSubmit} handleChange={this.handleChange} filters={this.state.filters} roles={this.state.rolesList}/>
              </div>
              <div className="col-12">
                
                
                <div className="kb-table kb-table_users pt-4">
                  <div className="kb-thead bg-dark text-light">
                    <div className="row">
                      <div className="col kb-col-id">#</div>
                      <div className="col kb-col-name">Name</div>
                      <div className="col kb-col-2">Company</div>
                      <div className="col kb-col-3">User</div>
                      <div className="col kb-col-4">Phone</div>
                      <div className="col pl-col-5">User Role</div>
                      <div className="col kb-col-actions">Actions</div>
                    </div>
                  </div>
                  
                  
                  <div className="kb-tbody">
                    {this.state.userList &&
                    this.state.userList.map((user, index) =>
                      <div key={user.id} className="row">
                        <div className="col kb-col-id">{(this.state.pageNumber * this.state.pageSize) + index + 1}</div>
                        <div className="col kb-col-name" data-label="Name">
                          
                          <button className="btn btn-link p-0 font-weight-bold" onClick={() => this.viewUser(user.id)}>
                            {user.firstName + " " + user.lastName}
                          </button>
                        
                        </div>
                        <div className="col kb-col-2" data-label="Company">{user.companyName}</div>
                        <div className="col kb-col-3" data-label="Email">{user.email}</div>
                        <div className="col kb-col-4" data-label="Phone">{user.phoneNumber}</div>
                        <div className="col kb-col-5" data-label="Role">{user.userRoles.join(", ")}</div>
                        <div className="col kb-col-actions">
                          
                          <div className="kb-table-action">
                            <button onClick={() => this.editUser(user.id)} type="button" className="kb-btn_edit btn btn-link  px-3" data-toggle="tooltip"
                                    data-placement="top" title="" data-original-title="Edit">
                              <i className="fas fa-pen"></i>
                            </button>
                            <button onClick={() => this.onDeleteUser(user.id)} type="button" className="kb-btn_delete btn btn-link px-3 " data-toggle="tooltip"
                                    data-placement="top" title="" data-original-title="Delete">
                              <i className="fas fa-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                    } {
                    this.state.userList && this.state.userList.length === 0 && (
                      <div className="col-12">
                        <h5 className="py-5">There are no users that match the search criteria</h5>
                      </div>
                    )
                  }
                  </div>
                  {this.state.userList && this.state.userList.length !== 0 &&
                  (
                    <div className="row">
                      <div className="col-12">
                        <div className="pagination-wrapper relative-pagination">
                          <Pagination
                            activePage={this.state.pageNumber + 1}
                            itemsCountPerPage={this.state.pageSize}
                            totalItemsCount={this.state.totalItems}
                            pageRangeDisplayed={5}
                            onChange={this.onPageChange}/>
                        </div>
                      </div>
                    </div>
                  )
                  }
                </div>
              </div>
            </div>
          </div>
        </main>
      
      
      </React.Fragment>
    );
  }
}

export default withRouter(UserManagementPage);
