import React from 'react';
import PropTypes from "prop-types";
import {FormLabel, Form, FormControl, FormGroup} from "react-bootstrap";
//import {userRoles} from "../support/UserConstants";
import PhoneInput from 'react-phone-number-input';
import {withRouter} from "react-router-dom";

function ViewUserForm (props) {
  
  const inputClassName = " ";
  
  return (
    <form>
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-12">
            
            <FormGroup className={inputClassName} controlId="email">
              <FormLabel>Email</FormLabel>
              <FormControl
                type="email"
                defaultValue={props.userDetails.email}
                disabled={true}
              />
            </FormGroup>
          </div>
          <div className="col-12">
            <FormGroup className={inputClassName} controlId="firstName">
              <FormLabel>First Name</FormLabel>
              <FormControl
                type="firstName"
                defaultValue={props.userDetails.firstName}
                disabled={true}
              />
            </FormGroup>
          </div>
          <div className="col-12">
            <FormGroup className={inputClassName} controlId="lastName">
              <FormLabel>Last Name</FormLabel>
              <FormControl
                type="lastName"
                defaultValue={props.userDetails.lastName}
                disabled={true}
              />
            </FormGroup>
          </div>
          <div className="col-12">
            <FormGroup className={inputClassName} controlId="companyName">
              <FormLabel>Company Name</FormLabel>
              <FormControl
                type="companyName"
                defaultValue={props.userDetails.companyName}
                disabled={true}
              />
            </FormGroup>
          </div>
          <div className="col-12">
            <FormGroup className={inputClassName} controlId="phoneNumber">
              <FormLabel>Phone Number</FormLabel>
              <PhoneInput
                placeholder="Enter phone number"
                value={props.userDetails.phoneNumber}
                onChange={() => void (0)}
                disabled={true}
              />
            </FormGroup>
          </div>
          <div className="col-12">
            <FormGroup className={inputClassName} controlId="additionalInfo">
              <FormLabel>Additional info</FormLabel>
              <FormControl
                type="additionalInfo"
                componentclass="textarea"
                defaultValue={props.userDetails.additionalInfo}
                disabled={true}
              />
            </FormGroup>
          </div>
          <div className="col-12 mt-3 mb-5">
            <FormLabel>User Role</FormLabel>
            {props.userDetails && props.userDetails.userRoles && props.userDetails.userRoles.length > 0 &&
            props.userDetails.userRoles.map((userRole, i) => (
              <React.Fragment key={userRole + i}>
                
                <FormGroup className={inputClassName} controlId={"userRole" + i}>
                  <Form.Control
                    as="select"
                    value={props.userDetails.userRoles[i]}
                    disabled={true}
                  >
                      <option key={userRole} value={userRole}>{userRole}</option>)
                  </Form.Control>
                </FormGroup>
              
              </React.Fragment>
            ))
            }
          </div>
        </div>
      </div>
    </form>
  );
}

ViewUserForm.propTypes = {
  userDetails: PropTypes.object.isRequired,
};

export default withRouter(ViewUserForm);
