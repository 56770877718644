import {genericGet, genericPost, genericPut} from "../../../components-shared/fetch-wrapper";

const templateManagementEndpoint = '/template-management';
const cloneTemplateEndpoint = '/template-management/from/';
const getTemplateByIdEndpoint = templateManagementEndpoint + '/';

export function createTemplate(data) {
    return genericPost(templateManagementEndpoint, data);
}
export function cloneTemplate(data, sourceTemplateId) {
  return genericPost(cloneTemplateEndpoint + sourceTemplateId, data);
}
export function updateTemplate(data) {
    return genericPut(templateManagementEndpoint, data);
}

export function getAllTemplates() {
    return genericGet(templateManagementEndpoint);
}

export function getTemplateById(templateId) {
    const url = getTemplateByIdEndpoint + templateId;
    return genericGet(url);
}
