import React, { useState, useEffect } from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import CommaSeparatedInput from '../../../../components-shared/CommaSeparatedInput/CommaSeparatedInput';
import CustomDatePicker from '../../../../components-shared/CustomDatePicker';
import { postDrawdownAction, putDrawdownAction } from '../../../category/actions/budgetExecutionActions';
import { confirmAlert } from 'react-confirm-alert';

const AddDrawdownReimbursment = (props) => {
  const [isMounted, setIsMounted] = useState(false);
  const [addType, setAddType] = useState('DRAWDOWN');
  const [typeNumber, setTypeNumber] = useState('');
  const [typeDate, setTypeDate] = useState('');
  const [typeAmount, setTypeAmount] = useState('');

  const [blurAddType, setBlurAddType] = useState(false);
  const [blurTypeNumber, setBlurTypeNumber] = useState(false);
  const [blurTypeDate, setBlurTypeDate] = useState(false);
  const [blurTypeAmount, setBlurTypeAmount] = useState(false);

  const [saved, setSaved] = useState(false);

  const [errorsToCheck] = useState([
    {
      errMsg: "The field is mandatory",
      isValid: (value) => {
        return value !== '' && value !== null && value !== undefined;
      },
      usedFor: ['addType', 'typeAmount', 'typeDate']
    },
    {
      errMsg: `The field should have only alphanumeric characters`,
      isValid: (value) => {
          return !value || (value && /^[a-z0-9]+$/i.test(value));
      },
      usedFor: ['typeNumber']
    },
    {
      errMsg: `The field should have only numeric characters`,
      isValid: (value) => {
          return !value || (value && /^[0-9]+$/i.test(value));
      },
      usedFor: ['typeAmount']
    },
    {
      errMsg: `Max 10 characters.`,
      isValid: (value) => {
          return !value || ((value && /^[0-9]+$/i.test(value)) && (parseFloat(value) <= 9999999999));
      },
      usedFor: ['typeAmount']
    }
  ]);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
      if (props.drawdownToEdit) {
        setAddType(props.drawdownToEdit.drawdownType);
        setTypeNumber(props.drawdownToEdit.name);
        setTypeDate(parseISO(props.drawdownToEdit.date));
        setTypeAmount(props.drawdownToEdit.amount);
        setBlurAddType(true);
        setBlurTypeNumber(true);
        setBlurTypeDate(true);
        setBlurTypeAmount(true);
      }
    }
  }, [props.drawdownToEdit, isMounted]);

  const saveForm = () => {
    const addTypeHasErrors = errorCheck(addType, 'addType');
    const typeNumberHasErrors = errorCheck(typeNumber, "typeNumber");
    const typeDateHasErrors = errorCheck(typeDate, 'typeDate');
    const typeAmountHasErrors = errorCheck(typeAmount, 'typeAmount');

    setSaved(true);

    if (!addTypeHasErrors && !typeNumberHasErrors && !typeDateHasErrors && !typeAmountHasErrors) {
      let sendObject = {
        drawdownType: addType,
        name: typeNumber,
        date: typeDate,
        year: typeDate.getFullYear(),
        month: typeDate.getMonth() + 1,
        day: typeDate.getDate(),
        amount: parseFloat(typeAmount),
        categoryId: props.categoryId
      };
      if (props.drawdownToEdit) {
        sendObject.created = props.drawdownToEdit.created; 
        sendObject.id = props.drawdownToEdit.id; 
        //delete sendObject.categoryId;
        putDrawdownAction(sendObject).then(res => {
          if (!res || (res && (res.code || res.code === 0))) {
            if (res.code === 14005){
              toast.error("Budget exceeded!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000,
              });
            } else {
              toast.error("There was an error during saving!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000,
              });
            }
          } else {
            toast.success("Your changes were saved successfully!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
            });
            props.refreshState();
          }
        }); 
      } else {
        postDrawdownAction(sendObject).then(res => {
          if (!res || (res && (res.code || res.code === 0))) {
            if (res.code === 14005){
              toast.error("Budget exceeded!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000,
              });
            } else {
              toast.error("There was an error during saving!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000,
              });
            }
          } else {
            toast.success("Your changes were saved successfully!", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
            });
            props.refreshState();
          }
        }); 
      }
      props.toggleModal();
    }
  }

  const cancelForm = () => {
    confirmAlert({
        title: 'Confirm to submit',
        message: 'Are you sure you want to cancel?',
        buttons: [
            {
                label: 'Yes',
                onClick: () => {
                  setSaved(false);
                  props.toggleModal();
                }
            },
            {
                label: 'No',
            }
        ]
    });

    
  }

  const errorCheck = (fieldValue, fieldName) => {
    let errorToShow = '';
    errorsToCheck.forEach(err => {
      if (err.usedFor.includes(fieldName)) {
        if (!err.isValid(fieldValue)) {
          errorToShow = (<div className="text-danger"><small>{err.errMsg}</small></div>);
        }
      }
    })
    return errorToShow;
  }

  const inputClassName = 'mb-0';

  return (
    <div>          
    <div className="col-md-12 text-left mt-5 mb-2">
        <div className="d-table">
          <div className="d-table-cell">
            <label htmlFor="addType" className="mb-0 text-dark mr-3 font-size-5">Add new</label>
          </div>
          <div className="d-table-cell">            
            <div className="form-group mb-0">
              <select 
                onChange={(e) => {setAddType(e.target.value); setBlurAddType(true);}} 
                onBlur={() => setBlurAddType(true)}
                id="addType"
                className="form-control"
                value={addType}
              >
                  <option value="DRAWDOWN">Drawdown</option>
                  <option value="REIMBURSEMENT">Reimbursement</option>
              </select>
            </div>
          </div>
        </div>
        {(saved || blurAddType) && errorCheck(addType, 'addType')}
      </div>
      <div className="col-md-12 text-left mb-2">
        <FormGroup className={inputClassName}>
          <FormLabel className="text-dark">Number</FormLabel>
          <FormControl
            id={"typeNumber"}
            type="text"
            value={typeNumber}
            onChange={(e) => {setTypeNumber(e.target.value); setBlurTypeNumber(true);}}
            onBlur={() => setBlurTypeNumber(true)}
            autoComplete="off"
          />
          {(saved || blurTypeNumber) && errorCheck(typeNumber, "typeNumber")}
        </FormGroup>
      </div>
      
      <div className="col-md-12 text-left mb-2">
        <FormGroup className={inputClassName} controlId="typeDate">
          <FormLabel className="text-dark">Date</FormLabel>
          <CustomDatePicker
              selected={typeDate}
              onChange={setTypeDate}
              onBlur={() => setBlurTypeDate(true)}
              placeholderText=""
              className="w-100"
          />
      </FormGroup>
      {(saved || blurTypeDate) && errorCheck(typeDate, 'typeDate')}
      </div>
      <div className="col-md-12 text-left mb-2">
        <FormGroup className={inputClassName}>
          <FormLabel className="text-dark">Amount</FormLabel>
          <CommaSeparatedInput
              elementType="FormControl"
              type="number"
              decimalsAfterDot={0}
              min={0}
              id={"typeAmount"}
              value={typeAmount}
              handleChange={(e) => {setTypeAmount(e.target.value); setBlurTypeAmount(true);}}
              handleBlur={() => setBlurTypeAmount(true)}
          />
          {(saved || blurTypeAmount) && errorCheck(typeAmount, 'typeAmount')}
        </FormGroup>
      </div>
      <div className="col-12 text-left pr-4 mt-4">
        <button type="button" className="btn btn-primary kb-btn-save_permissions" onClick={saveForm}>Save</button>
        <button type="button" variant='light' className="btn btn-link kb-btn-cancel_permissions ml-3" onClick={cancelForm}>Cancel</button>
      </div>
    </div>
  );
}

export default AddDrawdownReimbursment;
