import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

export default function GenericTemplateCard(props) {
    return (
        <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                    <div className="kb-card-template card h-100">
                        <div className="card-body ">
                             <Link className="link-overlay" to={"/template/view/" + props.template.id}/>
                            <h5 className="card-title mb-2">{props.template.name} </h5>
                            <p className="text-muted m-0"> <span>{props.template.description}</span></p>
                        </div>
                        <div className="card-footer bg-white d-flex align-items-center ">
                        {props.children}
                        </div>
                    </div>
                </div>
    )
}

GenericTemplateCard.propTypes = {
    template: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string
    }).isRequired
};









