import React, {Fragment, useEffect, useState, useRef} from 'react';
import {FormLabel, FormControl, FormGroup, Button} from 'react-bootstrap';
import {confirmAlert} from 'react-confirm-alert';
import {getAllPermissions, getAlluserRoles, 
    setAlluserRoles
} from "../actions/userPermissionsActions";
import {deleteRole} from "../actions/rolesActions";
import {getAllUserDetailsByRoleId} from "../actions/userActions";
import {makeId, titleToUrl} from './functions';
import PermissionsTableHeader from './PermissionsTableHeader';
import RouteLeavingGuard from './RouteLeavingGuard';
import { withRouter } from 'react-router-dom';


const RolePermissionPage = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [statePermissions, setStatePermissions] = useState({});
    const [initialStatePermissions, setInitialStatePermissions] = useState({});
    const [permissions, setPermissions] = useState([]);
    const [savedPermissions, setSavedPermissions] = useState([]);
    const [permissionGroups, setPermissionGroups] = useState([]);
    const [roles, setRoles] = useState([]);
    const [initialRoles, setInitialRoles] = useState([]);
    const [roleName, setRoleName] = useState('');
    const [uniqueRoleId] = useState(makeId(15));
    const [nameFromProps, setNameFromProps] = useState('');
    const [nameFieldError, setNameFieldError] = useState('');
    const [nameFieldErrors] = useState(
        [
            {
                errMsg: "The name should have only alphanumeric characters and white spaces",
                isValid: (value) => {
                    return /^[a-z 0-9]+$/i.test(value);
                }
            },
            {
                errMsg: "The name field shold not be larger than 30 characters",
                isValid: (value) => {
                    return value.length <= 30;
                }
            },
            {
                errMsg: "The name field should not be empty or have only whitespaces",
                isValid: (value) => {
                    return value.replace(/\s/g, '').length > 0;
                }
            },
            {
                errMsg: "The name field should not start or end with a whitespace",
                isValid: (value) => {
                    return !value.startsWith(' ') && !value.endsWith(' ');
                }
            },
            {
                errMsg: "Role name must be unique",
                isValid: (value, roles, pageName) => {
                    return !checkIfPresent(value, roles, pageName);
                }
            }
        ]
    );

        
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const isNotAdmin = props.pageName !== "ADMIN" && props.pageName !== "Admin" && props.pageName !== "admin";

    let isMounted = useRef(true);
    



    const resyncPermissions = () => {
        getAllPermissions().then(permissions => {
            if (isMounted.current) {
                setSavedPermissions(permissions.permissions);
            }
        });
    }

    useEffect(() => {
        isMounted.current = true;
        const getRoles = () => {
            getAlluserRoles().then(roles => {
                if (isMounted.current) {
                    const rolesToSet = roles.map(roleToSet => {
                        if ( roleToSet.name === props.pageName ) {
                            roleToSet.name = uniqueRoleId;
                        }
                        return roleToSet;
                    });
                    setRoles(rolesToSet);
                    setInitialRoles(rolesToSet);
                }
            });
        }

        const getPermissions = () => {
            getAllPermissions().then(permissions => {
                if (isMounted.current) {
                    const permissionsToSet = permissions.permissions.map(permissionToSet => {
                        if (permissionToSet.assignedUserRoles && permissionToSet.assignedUserRoles instanceof Array) {
                            permissionToSet.assignedUserRoles.map(assignedUserRole => {
                                if (assignedUserRole === props.pageName) {
                                    assignedUserRole = uniqueRoleId;
                                }
                                return assignedUserRole;
                            });
                            
                        }
                        return permissionToSet;
                    });
                    setPermissions(permissionsToSet);
                    setPermissionGroups(permissions.permissionGroups);

                    const statePermissions = {};
                    permissions.permissions.forEach(perm => {
                        const boolValue = perm.assignedUserRoles.includes(props.pageName);
                        statePermissions[perm.permissionName] = boolValue;
                    });
                    setStatePermissions(statePermissions);
                    setInitialStatePermissions(statePermissions);
                }
            }); 
        } 
            getPermissions();
            getRoles();
            resyncPermissions();
        return () => {
            isMounted.current = false;
        }
    }, [isMounted, props.pageName, uniqueRoleId]);

    useEffect(() => {
        if (props.pageName !== nameFromProps) {
            setRoleName(props.pageName);
            setNameFromProps(props.pageName);
        }
    }, [props.pageName, roleName, nameFromProps]);

    const confirmCancelObj = {
        title: 'Confirm to submit',
        message: 'Are you sure you want to cancel?',
        buttons: [
            {
                label: 'Yes',
                onClick: () => {
                    onClickCancel();
                }
            },
            {
                label: 'No',
            }
        ]
    }

    const onClickCancel = () => {
        let newRoles = initialRoles.slice();
        let newSavedPermissions = savedPermissions.slice();

        newRoles = newRoles.map(role => {
            const roleNameValue = role.name;
            if (roleNameValue === roleName) {
                role.name = props.pageName;
            }
            return role;
        });

        newSavedPermissions = newSavedPermissions.map(permission => {
            if (permission.assignedUserRoles.includes(roleName)) {
                permission.assignedUserRoles = permission.assignedUserRoles.filter(role => role !== roleName);
                permission.assignedUserRoles.push(props.pageName);
            }
            return permission;
        });

        setEditMode(false);
        setRoleName(props.pageName);
        setPermissions(newSavedPermissions);
        setRoles(newRoles);
        setStatePermissions(initialStatePermissions);
    }

    const cancelEdit = () => {
        confirmAlert(confirmCancelObj);
    }
    const startEdit = () => {
        setEditMode(true);
    }

    const getAllPermissionsForGroup = (group) => {
        const permissionsSliced = permissions.slice();
        const wantedPermissions = [];
        permissionsSliced.forEach(permission => {
            if (permission.permissionGroup === group) {
                wantedPermissions.push(permission);
            }
        });
        return wantedPermissions;
    }

    const roleArray = [];
    if (roles) {
        for (let index = 0; index < roles.length; index++) {
            const element = roles[index];
            if (element.name === uniqueRoleId) {
                roleArray.push(element);
            } else if (!uniqueRoleId && element.name === props.pageName) {
                roleArray.push(element);
            }
            
        }
    }

    const confirmUpdateObj = {
        title: 'Confirm to submit',
        message: 'Are you sure you want to save?',
        buttons: [
            {
                label: 'Yes',
                onClick: () => {
                    confirmUpdateFunction();
                }
            },
            {
                label: 'No',
            }
        ]
    }

    const confirmUpdateFunction = () => {
        let newRoles = roles.slice();
        newRoles = newRoles.map(role => {
            let newRole = {...role};
            if (newRole.name === uniqueRoleId) {
                newRole.name = roleName;
                let permissionsIds = [];
                permissions.forEach(perm => {
                    if (statePermissions[perm.permissionName]) {
                        permissionsIds.push(perm.id);
                    }
                });
                newRole.permissionIds = permissionsIds; 
                
            }
            return newRole;
        });
        setAlluserRoles(newRoles).then(() => {
            props.getRoles();
            setEditMode(false);
            const urlPath = titleToUrl(roleName);
            window.location.replace(`${window.location.origin}/#/settings/roles/${urlPath}`);
        });
    }

    

    
    const confirmNotAllowedObj = (errorMesasge) => {
        return {
            title: 'Validation error',
            message: errorMesasge,
            buttons: [
                {
                    label: 'Ok'
                }
            ]
        }
    }

    const updateRoles = () => {
        if (!nameFieldError) {
            confirmAlert(confirmUpdateObj);
        } else {
            confirmAlert(confirmNotAllowedObj(nameFieldError));
        }
    }

    const checkIfPresent = (roleName, roles, pageName) => {
        let isPresent = false;
        if (roleName && roles && roles instanceof Array && roles.length > 0) {
            roles.forEach(role => {
                if (role.name === roleName && role.name !== pageName) {
                    isPresent = true;
                }
            })
        }
        return isPresent;
    }

    const inputClassName = "form-group d-inline-block ml-3 position-relative";

    const setInputNameValue = e => {
        const value = e.target.value;
        setRoleName(value);
        errorcheck(value, nameFieldErrors, setNameFieldError);
    }

    const errorcheck = (value, errors, setError) => {
        let errorsNo = 0;
        errors.forEach((error, i) => {
            if (i === errors.length - 1) {
                if (!error.isValid(value, roles, props.pageName)) {
                    setError(error.errMsg);
                    errorsNo = errorsNo + 1;
                }

            } else {                
                if (!error.isValid(value)) {
                    setError(error.errMsg);
                    errorsNo = errorsNo + 1;
                }
            }
        });

        if (errorsNo === 0) {
            setError("");
        }
    }


    const confirmDeleteObjWithoutUsers = {
        title: 'Confirm to submit',
        message: 'Are you sure you want to delete this role?',
        buttons: [
            {
                label: 'Yes',
                onClick: () => {
                    confirmDeleteRoleFunction();
                }
            },
            {
                label: 'No',
            }
        ]
    }


    const deleteRoleWithoutUsers = () => {
        confirmAlert(confirmDeleteObjWithoutUsers);
    }

    const deleteRoleWithUsers = (userRoleId) => {

        const confirmDeleteObjWithUsers = {
            title: 'Confirm to submit',
            message: 'THE ROLE CANNOT BE DELETED! The selected role is assigned to several users.' +
                'Do you want to delete these users ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        redirectToUserManagement(userRoleId);
                    }
                },
                {
                    label: 'No',
                }
            ]
        }

        confirmAlert(confirmDeleteObjWithUsers);

    }

    const confirmDeleteRoleFunction = () => {
        deleteRole(roleArray[0].id).then(res => {
            props.getRoles();
            props.getPermissions();
            props.history.push('/settings/roles/show-all');
        });
    }

    const redirectToUserManagement = (userRoleId) => {
        props.history.push(`/userManagement/${userRoleId}`);
    }

    const checkUserRole = () => {
        if (roleArray.length === 1) {
            getAllUserDetailsByRoleId(roleArray[0].id).then(res => {
                if (res.length > 0) {
                    deleteRoleWithUsers(roleArray[0].id);
                } else {
                    deleteRoleWithoutUsers();
                }
            });
        }
    }

    const setSingleStatePermissions = (field) => {
        setStatePermissions({...statePermissions, [field]: statePermissions[field] ? false : true})
    }

    return (
        <div className="kb-table_permissions w-100">
            <RouteLeavingGuard
                //shouldBlockNavigation={editMode} 
                message="You have unsaved changes, are you sure you want to leave?"
                when={editMode}
                // Navigate function
                navigate={path => props.history.push(path)}
                    // Use as "message" prop of Prompt of React-Router
                shouldBlockNavigation={location => {
                        // This case it blocks the navigation when: 
                        if (editMode) {
                            return true
                        } 
                        return false
                    }
                }
            />
            <div className="row w-100">
                <div className="col-12 d-flex align-items-center py-3">
                    <div className="kb-title_page d-flex align-items-center">
                        <h1 className="kb-title_page h4 font-weight-normal m-0 text-dark pl-2">Permissions for
                            {editMode && isNotAdmin ?
                                (<Fragment>
                                    <FormGroup className={inputClassName} controlId="roleName">
                                        <FormLabel className="d-none">Role name</FormLabel>
                                        <FormControl
                                            type="text"
                                            value={roleName}
                                            onChange={(e) => setInputNameValue(e)}
                                            autoComplete="off"
                                        />
                                        <div className="text-danger input-error">{nameFieldError}</div>
                                    </FormGroup>
                                </Fragment>) :
                                (
                                    <Fragment>
                                        <span
                                            className="font-weight-bold ml-3">{props.pageName}</span>
                                    </Fragment>
                                )}
                        </h1>
                    </div>
                    <nav className="kb-nav_ctas ml-auto">
                        {editMode ? (
                            <Fragment>
                                <Button className="btn-link ml-3" variant="link" onClick={cancelEdit}>Cancel</Button>
                                <button type="button" className="btn btn-primary kb-btn-save_permissions ml-3"
                                        onClick={updateRoles}>Save
                                </button>
                            </Fragment>
                        ) : isNotAdmin ? (
                            <Fragment>
                                <button type="button" className="btn btn-secondary kb-btn-edit_permissions ml-3"
                                        onClick={startEdit}>Edit Permissions
                                </button>
                                <button className="btn btn-link px-3 mr-3"
                                        onClick={checkUserRole}><i className="fas fa-trash"></i>
                                </button>
                            </Fragment>

                        ) : <Fragment/>}
                    </nav>
                </div>
                <div className="col-12 bg-white py-4">
                    <table className=".kb-table-permissions  table table-hover">

                        {roleArray && roleArray.length > 0 && (<PermissionsTableHeader uniqueRoleId={uniqueRoleId} roleName={props.pageName} referencePermissions={roleArray}/>)}

                        <tbody>
                        {permissionGroups.map((permissionGroup) => {
                            const allPermissionsForGroup = getAllPermissionsForGroup(permissionGroup.permissionGroup);
                            return (
                                <Fragment key={permissionGroup.permissionGroup}>
                                    <tr>
                                        <td className="font-weight-bold border-none">{permissionGroup.humanReadableDescription}</td>
                                    </tr>
                                {roleArray && allPermissionsForGroup.map(permission => {
                                    return (
                                        
                                        <tr key={permission.id}>
                                            <td className="custom-col-1">{permission.humanReadableDescription}</td>
                                            <td>
                                                {
                                                <label className="fancy" htmlFor={permission.permissionName}>
                                                    <input  
                                                        name={permission.permissionName} type="checkbox" 
                                                        value={statePermissions[permission.permissionName] === true ? true : false} 
                                                        checked={statePermissions[permission.permissionName] === true ? true : false} 
                                                        disabled={!editMode} 
                                                        onChange={()=>{setSingleStatePermissions(permission.permissionName)}} 
                                                    />
                                                </label>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                                </Fragment>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default withRouter(RolePermissionPage);
