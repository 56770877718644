import {genericDelete, genericGet, genericPost, genericPostVoidReturn, genericPut} from "../../../components-shared/fetch-wrapper";
import {replaceUrlParams} from "../../../components-shared/url-utils";

const categoryTotalUrl = '/category-total-management';
const getCategoryTotalsForProjectEndpoint = categoryTotalUrl +
    "/category-type/:categoryType/project/:projectId";
const assignCategoryToCategoryTotalEndpoint = categoryTotalUrl +
    "/assigned-total/:categoryTotalId/assigned-category/:categoryId";
const removeCategoryTotalEndpoint = categoryTotalUrl + '/:categoryTotalId';

export function insertCategoryTotal(data) {
    return genericPost(categoryTotalUrl, data);
}

export function getCategoryTotals(entityId, categoryType) {
    const urlParams = [{key: ":categoryType", value: categoryType}, {key: ":projectId", value: entityId}];
    const url = replaceUrlParams(getCategoryTotalsForProjectEndpoint, urlParams);
    return genericGet(url);
}

export function removeCategoryTotal(categoryTotalId) {
    const urlParams = [{key: ":categoryTotalId", value: categoryTotalId}];
    const url = replaceUrlParams(removeCategoryTotalEndpoint, urlParams);
    return genericDelete(url);
}

export function updateCategoryTotal(categoryObject) {
    return genericPut(categoryTotalUrl, categoryObject);
}

export function assignCategoryToCategoryTotal(categoryTotalId, categoryId) {
    const urlParams = [{key: ":categoryTotalId", value: categoryTotalId}, {key: ":categoryId", value: categoryId}];
    const url = replaceUrlParams(assignCategoryToCategoryTotalEndpoint, urlParams);
    return genericPostVoidReturn(url, {});
}

export function unAssignCategoryToCategoryTotal(categoryTotalId, categoryId) {
    const urlParams = [{key: ":categoryTotalId", value: categoryTotalId}, {key: ":categoryId", value: categoryId}];
    const url = replaceUrlParams(assignCategoryToCategoryTotalEndpoint, urlParams);
    return genericDelete(url);
}
