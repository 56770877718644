import {
    genericDelete,
    genericGet,
    genericPost,
    genericPostWithDownload,
    genericPut,
    genericPutVoidReturn
} from "../../../components-shared/fetch-wrapper";

const projectManagementEndpoint = '/project-management';
const endOfMonthBalanceEndpoint = '/endOfMonthBalance';
const getProjectByIdEndpoint = projectManagementEndpoint + '/';
const loadProjectFromTemplateEndpoint = projectManagementEndpoint + '/clone/';
const financialMonthsEndpoint = "/financial-months";
const projectsRootName = "/projects"


export function createProject(data) {
    return genericPost(projectManagementEndpoint, data);
}

export function getAllProjects() {
    return genericGet(projectManagementEndpoint);
}

export function getProjectById(projectId) {
    const url = getProjectByIdEndpoint + projectId;
    return genericGet(url);
}

export function getProjectOpenedMonthsById(projectId) {
    const url = projectsRootName + "/" + projectId + financialMonthsEndpoint;
    return genericGet(url);
}

export function postClosedMonth(projectId, monthValue){
    const url = projectsRootName + "/" + projectId + financialMonthsEndpoint;
    return genericPost(url, monthValue);
}

export function openMonth(projectId, financialMonthId){
    const url = `${projectsRootName}/${projectId}${financialMonthsEndpoint}/${financialMonthId}`;
    return genericDelete(url);
}

export function getClosedMonths(projectId){
    const url = `${projectsRootName}/${projectId}${financialMonthsEndpoint}/closed`;
    return genericGet(url);
}

export function updateProject(projectObject) {
    return genericPut(projectManagementEndpoint, projectObject);
}

export function loadProjectFromTemplate(projectId, templateId) {
    const url = loadProjectFromTemplateEndpoint + projectId + '/template/' + templateId;
    return genericPutVoidReturn(url, {});
}

export function assignUserToProject(projectId, userId) {
    const url = projectManagementEndpoint + "/" + projectId + "/assigned-user/" + userId;
    return genericPost(url, null);
}

export function unAssignUserFromProject(projectId, userId) {
    const url = projectManagementEndpoint + "/" + projectId + "/assigned-user/" + userId;
    return genericDelete(url);
}

export function deletePictureFromProject(projectId) {
    return genericDelete(projectManagementEndpoint + '/picture-meta/' + projectId);
}

export function createPictureMetadataForProject(projectId, metadata) {
    return genericPost(projectManagementEndpoint + '/picture-meta/' + projectId, metadata);
}

export function deleteProject(projectId) {
    return genericDelete(projectManagementEndpoint + "/" + projectId);
}



export function exportTraceabilityAction(projectId, data) {
    let url = endOfMonthBalanceEndpoint + "/excel";
    let headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/octet-stream',
      'Cache-Control': 'no-cache, no-store, must-revalidate'
    }

    return genericPostWithDownload(url, data, headers);
  }

export function freezeIRR(projectId) {
    return genericPost(projectManagementEndpoint + "/irr/" + projectId , null);
}