import React, {Component} from "react";
import {FormLabel, FormGroup} from "react-bootstrap";
import PropTypes from "prop-types";
import Moment from "moment";
import AdvancedFormulaValuesForm from "./AdvancedFormulaValuesForm";
import {prettyPrint} from "../../common/numberOperations";
import CommaSeparatedInput from "../../../components-shared/CommaSeparatedInput/CommaSeparatedInput";

class AdvancedFormulaSplitForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            months: 1,
            amount: this.props.toBeForecasted > 0 ? this.props.toBeForecasted : 0,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    render() {

        const inputClassName = "form-group form-group-lg form-inline";

        return (
            <React.Fragment>

                <label>To be forecasted: {this.props.toBeForecasted ? prettyPrint(this.props.toBeForecasted) : 0} </label>

                <FormGroup className={inputClassName}>
                    <FormLabel>Amount to split</FormLabel>
                    <CommaSeparatedInput
                        elementType="FormControl"
                        type="number"
                        decimalsAfterDot={2}
                        min={0}
                        id={"amount"}
                        value={this.state.amount}
                        handleChange={this.handleChange}
                        parentClassName="w-100"
                        className="w-100"
                    />
                </FormGroup>

                <FormGroup className={inputClassName}>
                    <FormLabel>Nr of months to split</FormLabel>
                    <CommaSeparatedInput
                        elementType="FormControl"
                        type="number"
                        decimalsAfterDot={0}
                        min={1}
                        id={"months"}
                        value={this.state.months}
                        handleChange={this.handleChange}
                        parentClassName="w-100"
                        className="w-100"
                    />
                </FormGroup>


                {/*advanced formula values*/}
                <div key={"" + this.state.months + this.state.amount} className={"manage-advanced-formula add-advanced-formula-form"}>
                    <AdvancedFormulaValuesForm
                        numberOfMonths={this.state.months}
                        startDate={this.props.fixedDate}
                        amount={this.state.amount}
                        forecastType={this.props.forecastType}
                        contractId={this.props.contractId}
                        onSave={this.props.onSave}
                        closeSplitForm={this.props.onCancel}
                        activeTab={this.props.activeTab}
                    />
                </div>
            </React.Fragment>
        );
    }
}

AdvancedFormulaSplitForm.propTypes = {
    fixedDate: PropTypes.instanceOf(Moment).isRequired,
    toBeForecasted: PropTypes.number.isRequired,
    forecastType: PropTypes.string.isRequired,
    contractId: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default AdvancedFormulaSplitForm;
