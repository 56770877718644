import React, {Component} from 'react';
import ViewUserForm from "./forms/ViewUserForm";
import PropTypes from "prop-types";
import {deleteUser, getUserById} from "./actions/userActions";
import {Link, NavLink, withRouter} from "react-router-dom";
//import PageSection from "../../components-shared/PageSection";
import Breadcrumbs from '../../components-shared/Breadcrumbs/Breadcrumbs';
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";

class ViewUserPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userDetails: null
        };

        this.getUserId = this.getUserId.bind(this);
    }


    getUserId() {
        return this.props.match.params.userId;
    }
  
  deleteUser (userId) {
    const confirmUserDeleteOptions = this.getConfirmUserDeleteOptions(userId);
    confirmAlert(confirmUserDeleteOptions);
  }
  
  getConfirmUserDeleteOptions (userId) {
    return {
      title: 'Confirm to submit',
      message: 'Are you sure you want to delete the user?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteUser(userId).then((response) => {
              this.handleResponse(response);
            });
          }
        },
        {
          label: 'No'
        }
      ]
    };
  }
  
  handleResponse (response) {
    if (response.status === 200) {
      this.props.history.push(`/userManagement`);
      toast.info("User deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else if (response.status === 400) {
      toast.error("User could not be deleted", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else if (response.status === 403) {
      toast.error("You are not authorized to delete users", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else {
      toast.error("Unable to delete user", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }
  
  componentDidMount() {

        let userDetailsId = this.getUserId();

        if (userDetailsId) {
            // Even though the page receives the user details, just to be save, a call will be made to the BE
            // based on the userId to retrieve the correct database data
            getUserById(userDetailsId).then((userDetails) => {
                this.setState({
                    userDetails: userDetails
                })
            });
        }
    }

    render() {

        if (this.state.userDetails) {
            // noinspection CheckTagEmptyBody
            return (
                     <React.Fragment> 
                <nav className="kb-nav_primary navbar bg-dark d-block">
                    <button className="btn btn-link kb-close-link text-white mr-3" onClick={this.props.history.goBack}><i className="fas fa-arrow-left  mr-3"></i> Go back</button>
                </nav>
                <Breadcrumbs breadcrumbs={[{url: '/projects', title: 'Home'}, {url: `/viewUser/${this.getUserId()}`, title: `View User`}]} />
                    <header className="kb-header px-3">
                    <div className="container-fluid">
                        <div className="row">

                             <div className="col-12 d-flex align-items-center ">
                                        <div className="kb-title_page d-flex align-items-center py-4">
                                            <h1 className="h4 font-weight-normal m-0">View User</h1>
                                          </div>  
                               <nav className="kb-nav_ctas ml-auto">
                                    <Link className="btn btn-primary" to={'/editUser/' + this.getUserId()}>Edit User Info</Link>
                                     <button onClick={()=> this.deleteUser(this.getUserId())} type="button" className="kb-btn_delete kb-hide-on-edit btn btn-link ml-3 px-3 " data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit">
                                                <i className="fas fa-trash"></i>
                                                </button>
                                </nav> 
                            </div>  
                        </div>
                    </div>
                </header>
                 <main className="kb-main kb-main_user--view bg-white px-3 py-4">
                <div className="container-fluid">
                    <div className="row"> 
                    <div className="col-12 col-md-6 col-lg-4 pb-5">
                    <h5 className="py-3">View User Information</h5>
                         <ViewUserForm userDetails={this.state.userDetails}/>
                       </div>
                    </div> 
                    </div>
                    </main>
        </React.Fragment>
               
            );
        } else {
            return (
                <div className={"loading-placeholder"}>
                    <h2>No user selected. Go <NavLink to={"/userManagement"}>back</NavLink> and retry</h2>
                </div>
            );
        }
    }
}

ViewUserPage.propTypes = {
    location: PropTypes.object.isRequired
};

export default withRouter(ViewUserPage);
