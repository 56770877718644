import React from 'react';
import { Button } from 'react-bootstrap';

const CentralModal = (props) => {
  return props.condition ? (
        <div>
            <div className="react-confirm-alert-overlay">
                <div className="react-confirm-alert">
                    <div className="react-confirm-alert-body p-3">
                        
                        <div className="text-right">
                            <Button
                                className="text-dark text-right p-1"
                                variant="light"
                                onClick={props.toggleCloseMonthModal}
                            >
                                <span className="fas fa-times font-weight-bold font-size-4"></span>
                            </Button>
                        </div>
                        <div className="p-3">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
  ) : <div></div>;
}

export default CentralModal;
