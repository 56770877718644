import React, {useState, useRef, useEffect, useCallback} from 'react';
import {XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Area, Brush, AreaChart} from "recharts";
import { CustomLineDotDemo } from '../../../statistics/StatisticsProfitAndCashDisplay';
import {monthsArray} from '../../../budget/support/forecast/CloseMonth';

const ChartGraph = (props) => {

    const [fields, setFields] = useState(['Cost']);
    const [dropDown, setDropDown] = useState(false);
    const [graphValues, setGraphValues] = useState([]);
    const graphValuesBE = props.cumulativeCashPosition;

    const mapGrpahValues = (grValsBE) => {
        let newGraphValues = [...graphValues];
        
        const singleMapping = (elString) => {
            const el = elString.toLowerCase();
            if (grValsBE[el]) {
                grValsBE[el].map(obj => {
                    let monthExists = false;
                    newGraphValues = newGraphValues.map(grVal => {
                        if (grVal.name === monthsArray[obj.month - 1] + " " + obj.year) {
                            monthExists = true;
                            grVal[elString] = obj.value;
                        }
                        return grVal;
                    });
                    if (!monthExists) {
                        newGraphValues.push({
                            name: monthsArray[obj.month - 1] + " " + obj.year,
                            [elString]: obj.value
                        })
                    }
                })
            }
        }
        singleMapping("Cost");
        singleMapping("Income");
        singleMapping("Financing");
        singleMapping("Investment");
        setGraphValues(newGraphValues);
    }


    const componentIsMounted = useRef(false);
    const setComponentIsMounted = useCallback(value => {
        componentIsMounted.current = value;
    }, []);

    useEffect(() => {
        return () => {
            if (componentIsMounted.current) {
                setComponentIsMounted(false);
            }   
        }
    }, [setComponentIsMounted])

    useEffect(() => {
        if (graphValuesBE === null ){
            return
        }
        if (!componentIsMounted.current) {
            setComponentIsMounted(true);
        }   
        // const getGraphVals = () => {
        //     getGraphValues().then(result => {
        //         if (componentIsMounted.current) {
        //             setGraphValuesBE(result);
        //             mapGrpahValues(result);
        //         }
        //     })
        // }
        //getGraphVals();
        if (componentIsMounted.current && graphValuesBE) {
            mapGrpahValues(graphValuesBE);
        }
    }, [setComponentIsMounted,graphValuesBE]);
    
//   const [startIndex, setStartIndex] = useState(0);
//   const [endIndex, setEndIndex] = useState(11);
  const colorCost = "#000";
  const colorIncome = "#03b274";
  const colorInvestment = "#0191f2";
  const colorFinancing = "#ec518d";
  let so = null;
//   const handleOnChangeGraph = (e) => { 
//       clearTimeout(so);
//       so = setTimeout(()=>{
//           setStartIndex(e.startIndex);
//           setEndIndex(e.endIndex); 
//       },500); 
//   }

let maxValue = 0;
graphValues.forEach(dt => {
    if (dt.value > maxValue) { 
      maxValue = dt.value; 
    }
})

const setFieldValue = el => {
    if (fields.includes(el)) {
        //if (fields.length > 1) {
            setFields(fields.filter(cat => cat !== el));
        //}
    } else {
        setFields([...fields, el]);
    }
}

const DataFormater = (number) => {
    if(number > 1000000000){
      return (number/1000000000).toString() + 'B';
    }else if(number > 1000000){
      return (number/1000000).toString() + 'M';
    }else if(number > 1000){
      return (number/1000).toString() + 'K';
    }else{
      return number.toString();
    }
  }
  return (    
    <div className="line-chart-wrapper pt-3" style={{background: "rgb(248,248,250)"}}>
      <div className="container-fluid">
          <div className="row mb-3">
              <div className="col-4 align-self-center pl-0">
                <div className="dropdown">
                    <button className="btn btn-light dropdown-toggle select-style-button" type="button" onClick={() => setDropDown(!dropDown)}>
                        {fields.length > 0 ? fields.join(", ") : "Select category"}
                    </button>
                    {dropDown && <div className="dropdown-select-menu">
                        {graphValuesBE && graphValuesBE.cost && graphValuesBE.cost.length > 0 &&
                        <div className={"dropdown-item" + (fields.includes("Cost") ? " active" : "")} onClick={() => setFieldValue("Cost")}>Cost</div>}
                        {graphValuesBE && graphValuesBE.income && graphValuesBE.income.length > 0 &&
                        <div className={"dropdown-item" + (fields.includes("Income") ? " active" : "")} onClick={() => setFieldValue("Income")}>Income</div>}
                        {graphValuesBE && graphValuesBE.investment && graphValuesBE.investment.length > 0 &&
                        <div className={"dropdown-item" + (fields.includes("Investment") ? " active" : "")} onClick={() => setFieldValue("Investment")}>Investment</div>}
                        {graphValuesBE && graphValuesBE.financing && graphValuesBE.financing.length > 0 &&
                        <div className={"dropdown-item" + (fields.includes("Financing") ? " active" : "")} onClick={() => setFieldValue("Financing")}>Financing</div>
                        }
                    </div>}
                </div>
              </div>
              <div className="col-2 align-self-center">
                {graphValuesBE.shouldLoad && (<div className='spinner' />)}
              </div>
              <div className="col-6 text-right align-self-center">
                  <div className="text-right mr-3">
                      {fields.includes("Cost") && <span className="mr-2">
                          <i className={"fas fa-circle"} style={{color: colorCost}}></i> {'Cost'}
                      </span>}
                      {fields.includes("Income") && <span className="mr-2">
                          <i className={"fas fa-circle"} style={{color: colorIncome}}></i> {'Income'}
                      </span>}
                      {fields.includes("Investment") && <span className="mr-2">
                          <i className={"fas fa-circle"} style={{color: colorInvestment}}></i> {'Investment'}
                      </span>}
                      {fields.includes("Financing") && <span className="mr-2">
                          <i className={"fas fa-circle"} style={{color: colorFinancing}}></i> {'Financing'}
                      </span>}
                  </div>
              </div>
          </div>
      </div>
      <ResponsiveContainer width="100%" height={300} key={Math.random()}>
        <AreaChart
            data={graphValues}
            margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis  unit="€" 
                tickFormatter={tick => { 
                    return tick.toLocaleString();
                }}
                tickFormatter={DataFormater}
            />
            <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)}/>
            {fields.includes("Cost") && <Area dataKey="Cost" stroke={colorCost} fill={colorCost} strokeWidth={2} dot={<CustomLineDotDemo stroke={colorCost}/>}  unit="€"/>}
            {fields.includes("Income") && <Area dataKey="Income" stroke={colorIncome} fill={colorIncome}  strokeWidth={2} dot={<CustomLineDotDemo stroke={colorIncome}/>} unit="€"/>}
            {fields.includes("Investment") && <Area dataKey="Investment" stroke={colorInvestment} fill={colorInvestment}  strokeWidth={2} dot={<CustomLineDotDemo stroke={colorInvestment}/>} unit="€"/>}
            {fields.includes("Financing") && <Area dataKey="Financing" stroke={colorFinancing} fill={colorFinancing}  strokeWidth={2} dot={<CustomLineDotDemo stroke={colorFinancing}/>} unit="€"/>}
            {graphValues.length > 0 && <Brush dataKey="name" 
            startIndex={0}
            endIndex={graphValues.length >= 11 ? 11 : graphValues.lenght}
            //   startIndex={this.state.startIndex} 
            //   endIndex={this.state.endIndex} 
            //   onChange={this.handleOnChangeGraph} 
            >
            <AreaChart>
                <CartesianGrid />
                <YAxis hide domain={[0, maxValue]} />
                <Area dataKey="value" stroke='#000' fill='rgb(230,231,235)' dot={false} />
            </AreaChart>
            </Brush>}
        </AreaChart>
    </ResponsiveContainer>
  </div>
  );
}

export default ChartGraph;