import React from 'react';
import {AppContextData} from "../../main-components/AppContextData";
import ViewUserForm from "./forms/ViewUserForm";
//import PropTypes from "prop-types";
import {Link} from "react-router-dom";
//import PageSection from "../../components-shared/PageSection";
import Breadcrumbs from '../../components-shared/Breadcrumbs/Breadcrumbs';
import BackButtonNavigationBar from "../../components-shared/BackButtonNavigationBar";

export default function MyAccountPage() {

    const userDetails = AppContextData.getUserDetails();

    return (
             <React.Fragment>
      <BackButtonNavigationBar classNamePrefix={"account"}/>
            <Breadcrumbs breadcrumbs={[{url: '/projects', title: 'Home'}, {url: '/myAccount', title: 'My Account'}]} />
              <header className="kb-header px-3">
                    <div className="container-fluid">
                        <div className="row">

                             <div className="col-12 d-flex align-items-center ">
                                        <div className="kb-title_page d-flex align-items-center py-4">
                                            <h1 className="h4 font-weight-normal m-0">My Account</h1>
                                          </div>
                               <nav className="kb-nav_ctas ml-auto">
                                     <Link className="btn btn-primary" to={'/edit-my-account'}>Edit Info</Link>
                                </nav>
                            </div> 
                        </div>
                    </div>
                </header>
                 <main className="kb-main kb-main_account--view bg-white px-3 py-4">
                <div className="container-fluid">
                    <div className="row"> 
                    <div className="col-12 col-md-6 col-lg-4 pb-5">
                    <h5 className="py-3">View User Information</h5>
                         <ViewUserForm userDetails={userDetails}/>
                       </div>
                    </div> 
                    </div>
                    </main>
        </React.Fragment>
    );
}
