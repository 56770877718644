import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FormControl } from 'react-bootstrap';
import { makeId } from '../../components/user/UserPermissionsPage/functions';

const CommaSeparatedInput = (props) => { 
  //To accept negative values set a negative minimum value. Eg: -9887342442
  const [inputValue, setInputValue] = useState('');
  const inputEl = useRef(null);
  const [touched, setTouched] = useState(false);
  const separatorRegex = /\B(?=(\d{3})+(?!\d))/g;
  const separatorNegativeRegex = /\B(?=(\d{3})+(?!\d))/g;
  const positiveFloatregex = /[^0-9/.]/g;
  const positiveNegativeFloatregex = /[^-?0-9/.]/g;
  const positiveIntregex = /[^0-9]/g;
  const positiveNegativeIntregex = /[^-?0-9]/g;

  const {decimalsAfterDot, id, min, max, handleChange} = props;
  const conditionalRegex = useCallback(() => {
    return (decimalsAfterDot && decimalsAfterDot > 0) ? 
            min && min < 0 ? positiveNegativeFloatregex : positiveFloatregex : 
            min && min < 0 ? positiveNegativeIntregex : positiveIntregex;
  }, [decimalsAfterDot, min, positiveNegativeFloatregex, positiveNegativeIntregex, positiveFloatregex, positiveIntregex]);

  
  const blockUpDownArrows = (e) => {
    if(e.which === 38 || e.which === 40) {
      e.preventDefault();
    }
  }
  
  const componentIsMounted = useRef(false);
  const setComponentIsMounted = useCallback((val) => {
      componentIsMounted.current = val;
  }, []);

  useEffect(() => {
    return () => {
      if (componentIsMounted.current) {
        setComponentIsMounted(false);
      }
    };
  }, [setComponentIsMounted]);


  const removeComma = useCallback((el) => {
    const elArray = el.split("");
    const newElArray = elArray.filter(element => element !== ",");
    let stringEl = newElArray.join("");
    stringEl = stringEl.replace(conditionalRegex(), "");
    if (stringEl[0] === '.') {
      let strinElArr = [];
      strinElArr = stringEl.split("");
      strinElArr.shift();
      stringEl = strinElArr.join("");
    }
    if (stringEl[stringEl.length] === '.') {
      let stringElArrLast = [];
      stringElArrLast = stringEl.split("");
      stringElArrLast.pop();
      stringEl = stringElArrLast.join("");
    }
    return stringEl;
  }, [conditionalRegex]);

  const numberWithCommas = useCallback((e, changeEvent) => {
    const {value, selectionEnd} = e;
    let newSelectionEnd = selectionEnd;
    let valueToUse = value;
    let numberToReturn = '';
    let newPosition = 0;

    if (valueToUse) {
      if (valueToUse[0] === '.') {
        let valArr = [];
        valArr = valueToUse.split("");
        valArr.shift();
        valueToUse = valArr.join("");
        newSelectionEnd = newSelectionEnd - 1;
      }
      let valueToUseArr = valueToUse.toString().split("");
      let decimalDots = [];
      valueToUseArr.forEach((val, i) => {
        if(val === '.') {
          decimalDots.push(i);
        }
      });
  
      let newValueToUseArr = [];
      if (decimalDots.length > 1) {
          valueToUseArr.forEach((el, i) => {
            if (el === '.'){
              if (i !== decimalDots[0]) {
                newValueToUseArr = [...newValueToUseArr, el];
              }
            } else {
              newValueToUseArr = [...newValueToUseArr, el];
            }
          });
          newSelectionEnd = selectionEnd - decimalDots.length + 1;
          valueToUse = newValueToUseArr.join("");
      } else {
        valueToUse = valueToUseArr.join("");
      }
  
      
      let valueToUseArr2 = valueToUse.split("");
      let negativeSigns = [];
      valueToUseArr2.forEach((val, i) => {
        if(val === '-') {
          negativeSigns.push(i);
        }
      });
  
      let newValueToUseArr2 = [];
      if (negativeSigns.length > 1) {
          valueToUseArr2.forEach((el, i) => {
            if (el === '-'){
              if (i === negativeSigns[0]) {
                newValueToUseArr2 = [...newValueToUseArr2, el];
              } 
            } else {
              newValueToUseArr2 = [...newValueToUseArr2, el];
            }
          });
          newSelectionEnd = selectionEnd - negativeSigns.length + 1;
          valueToUse = newValueToUseArr2.join("");
      } else {
        valueToUse = valueToUseArr2.join("");
      }
  
    
      if (decimalsAfterDot && decimalsAfterDot > 0) {
        let dotPosition = null;
        valueToUseArr.forEach((char, i) => {
          if (char === '.') {
            dotPosition = i;
          }
        });
        if (dotPosition && ( dotPosition < valueToUseArr.length - 1)) {
          if (((valueToUseArr.length - 1) - dotPosition) > decimalsAfterDot) {
            newSelectionEnd = valueToUseArr.length - 1;
            let newValueToUseArr = [];
            valueToUseArr.forEach((el, i) => {
              if (i <= dotPosition + decimalsAfterDot){
                  newValueToUseArr = [...newValueToUseArr, el];
              }
            });
            valueToUse = newValueToUseArr.join("");
          }
        }
      }
  
  
      let pureNumber = valueToUse.replace(conditionalRegex(), "");
      if (min && parseFloat(pureNumber) < min) {
        valueToUse = min.toString();
      }
      if (max && parseFloat(pureNumber) > max) {
        valueToUse = max.toString();
      }
      pureNumber = valueToUse.replace(conditionalRegex(), "");
      let rightCharsCount = valueToUse.length - newSelectionEnd;
      numberToReturn = pureNumber.toString().replace(min && min < 0 ? separatorNegativeRegex : separatorRegex, ",");
      newPosition = newSelectionEnd === 0 ? 0 : numberToReturn.length - rightCharsCount;
      if (numberToReturn[newPosition] && numberToReturn[newPosition-1] === ',' && valueToUse.length === inputValue.length - 1) {
        let valueToUseArr = valueToUse.split("");
        const index = valueToUseArr.indexOf(valueToUse[newSelectionEnd-1]);
        if (index > -1) {
          valueToUseArr.splice(index, 1);
          valueToUse = valueToUseArr.join("");
          pureNumber = valueToUse.replace(conditionalRegex(), "");
          if (min && parseFloat(pureNumber) < min) {
            valueToUse = min.toString();
          }
          if (max && parseFloat(pureNumber) > max) {
            valueToUse = max.toString();
          }
          rightCharsCount = valueToUse.length - newSelectionEnd - 1;
          numberToReturn = pureNumber.toString().replace(min && min < 0 ? separatorNegativeRegex : separatorRegex, ",");
          newPosition = newSelectionEnd === 0 ? 0 : numberToReturn.length - rightCharsCount - 2;
        }
      };
    }
    const newEvent = {...e, 
      target: {...e.target, 
        value: valueToUse ? removeComma(valueToUse) : '', 
        id: id
      }
    };
    if (changeEvent) {
      handleChange(newEvent);
    }
    setTimeout(()=>{
      inputEl && inputEl.current && inputEl.current.setSelectionRange(newPosition ? newPosition : 0, newPosition ? newPosition : 0);
    }, 0);
    return numberToReturn ? numberToReturn : '';
  }, [conditionalRegex, inputValue.length, decimalsAfterDot, id, min, max, handleChange, removeComma, separatorNegativeRegex, separatorRegex]);
  

  useEffect(() => {    
    const setInitialInputValue = (value) => {
      const evObj = {        
        value: value,
        selectionEnd: value.length
      };
      let updatedNumberFromBe = numberWithCommas(evObj, false);
      if (decimalsAfterDot) {
        let dotIndex = updatedNumberFromBe.indexOf(".");
        let leftOver = updatedNumberFromBe.length - 1 - dotIndex;
        if(!touched) {
          if (dotIndex > -1 && leftOver < decimalsAfterDot) {
            for (let i = 0; i < leftOver; i++) {
              updatedNumberFromBe = updatedNumberFromBe + "0";
            }
          } 
        }
      }
      setInputValue(updatedNumberFromBe, false);

    }

      const referenceEl = inputEl;
     if (!componentIsMounted.current) {
        setComponentIsMounted(true);
        if (props.value) {
          setInitialInputValue(props.value);
        }
        referenceEl && referenceEl.current && referenceEl.current.addEventListener('keydown', blockUpDownArrows);
      }

      if (props.value || props.value === 0) {
       setInitialInputValue(props.value);
       const newPosition = inputEl.current.selectionEnd;
       setTimeout(()=>{
         inputEl && inputEl.current && inputEl.current.setSelectionRange(newPosition ? newPosition : 0, newPosition ? newPosition : 0);
       }, 0);
      }

     return  () => {
      referenceEl && referenceEl.current && referenceEl.current.addEventListener('removeEventListener', blockUpDownArrows);
    }
  }, [props.value, componentIsMounted, numberWithCommas, decimalsAfterDot, setComponentIsMounted]);
   


  const handleElChange = (e) => {
      e.preventDefault();
      e.persist();
      setTouched(true);
      const newValue = numberWithCommas(e.target, true);
      setInputValue(newValue);
  }
  const blurElement = (e) => {
    setTouched(true);
    if (props.handleBlur) {
      props.handleBlur(e)
    }
    return false;
  }
  const typeNumber = (
    <div className={props.parentClassName}>
      <FormControl
          type={"text"}
          value={inputValue && inputValue !== 'null' ? inputValue : props.value && !touched && props.value !== 'null' ? props.value.toString().replace(min && min < 0 ? separatorNegativeRegex : separatorRegex, ",") : ''}
          onChange={e => handleElChange(e)}
          onBlur={e => blurElement(e) }
          disabled={props.disabled}
          ref={inputEl}
          className={props.className}
          id={id ? id : makeId()}
          autoComplete="off"
      />
    </div>
  );
  
  return props.type === "number" ? typeNumber : '';
}

export default CommaSeparatedInput;
