import {genericDelete, genericGet, genericPost, genericPut, genericPostMultipart} from "../../../components-shared/fetch-wrapper";

export const userManagementPage = '/userManagement';
export const myAccountPage = '/myAccount';

const userManagementEndpoint = '/user-management';
const userDetailsEndpoint = '/user-details';
const contractManagement = "/contract-management";
const getUserActivityCountersEndpoint = userManagementEndpoint + '/activity-counters';
const getUserLoggedInEndpoint = userManagementEndpoint + '/logged-in';
const getAllUsersByFilterEndpoint = userManagementEndpoint + '/search';
const logoutEndpoint = '/logout';

const authenticationManagement = '/user-login';
const changePasswordEndpoint = authenticationManagement + '/change-password';

export function logout() {
    return genericPost(logoutEndpoint, {});
}
export function login(data) {
    let formData = new FormData();
    formData.append('email', data.email);
    formData.append('password', data.password);
    formData.append('submit', 'Login');
    return genericPostMultipart(authenticationManagement, formData);
}

export function quickAddContractorUser(data) {
    return genericPost(userManagementEndpoint + "/anonymous-contractor", data);
}

export function registerUser(data) {
    return genericPost(userManagementEndpoint, data);
}

export function getUserActivityCounters() {
    return genericGet(getUserActivityCountersEndpoint);
}

export function getUserLoggedIn() {
    return genericGet(getUserLoggedInEndpoint);
}

export function updateUser(data) {
    return genericPut(userManagementEndpoint, data);
}

export function deleteUser(userId) {
    return genericDelete(userManagementEndpoint + "/" + userId);
}

export function getAllUsersByFilter(data) {
    return genericPost(getAllUsersByFilterEndpoint, data);
}

export function getUserById(userId) {
    const url = userManagementEndpoint + "/" + userId;
    return genericGet(url);
}

export function getAllUsers() {
    return genericGet(userManagementEndpoint);
}

export function changePasswordForUser(data, userId) {
    const url = changePasswordEndpoint + "/" + userId;
    return genericPost(url, data);
}

export function changeOwnPassword(data) {
    return genericPost(changePasswordEndpoint, data);
}

export function getAllContractors() {
    const url = "/contractors";
    return genericGet(url);
}

export function getAllContractorsByProjectId(projectId, name, pageNumber, pageSize) {
    const url = "/contractors?projectId=" + projectId + "&name=" + name + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize;
    return genericGet(url);
}

export function getAllVATs() {
    const url = contractManagement + "/vat";
    return genericGet(url);
}

export function getAllUserDetailsByRoleId(userRoleId) {
    const url = userDetailsEndpoint + "/" + userRoleId;
    return genericGet(url);
}
