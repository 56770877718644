import React from "react";
import { NavDescriptor, NavPanel } from "../../components-shared/NavPanel";
import { canManageProjectExecution } from "../../main-components/UserPermissionsFunctions";
import ProjectReporting from "./reporting/reporting/ProjectReporting";
import ProjectTraceability from "./reporting/traceability/ProjectTraceability";

export default function ManageProjectStatisticsPage(props) {
    const {parentBaseUrl} = props; 
    const pageProps = {
        projectDetails: props.projectDetails,
        projectId: props.projectId,
        parentBaseUrl: parentBaseUrl
    }

    const navDescriptors = [
        new NavDescriptor(`/project/${props.projectId}/reporting`, canManageProjectExecution(), '/reporting', 'Reporting', (props) => <ProjectReporting {...props} {...pageProps} />, "fas fa-gavel"),
        new NavDescriptor(`/project/${props.projectId}/reporting`, canManageProjectExecution(), '/traceability', 'Traceability', (props) => <ProjectTraceability {...props}  {...pageProps} />, "fas fa-gavel")
    ];
    return (
       <React.Fragment>  
        <div className="col-12 col-md-12 col-lg-12 col-xl-12">
            <div className="row">
                <div className="col-12 py-4">
                        <NavPanel navDescriptors={navDescriptors}
                            navBarClassName={"settings-permissions-navigation"}
                            contentClassName={"settings-permissions-content-wrapper"}
                            {...props}
                            hideNav={true}
                        />
                </div>
            </div>
        </div>    
        </React.Fragment>
    );
}
