import React, {useEffect, useState} from 'react';
import {monthsArray} from '../../../budget/support/forecast/CloseMonth';
import {prettyPrint} from "../../../common/numberOperations";

export let containerStyle = {
  marginLeft: '-15px',
  marginRight: '-15px'
}
export let leftStyle = {
  paddingLeft: '45px'
}
export let rightStyle = {
  paddingRight: '45px'
}
const TableDataReport = (props) => {

  const cashPosition = props.cashPosition.values;
  const shouldLoad = props.cashPosition.shouldLoad;

  let colStyle = {
    minWidth: `calc(80vw / 12.5 )`,
    textAlign: 'right'
  }

  const tableRows = ["Cost net (forecast + cashflow)", "Actual paid", "Not paid from current month", "Cost cummulative", "Income", "Income cummulative", "VAT deductible", "VAT collectable", "VAT payable", "VAT recoverable", "Cash needed", "Equity/investment", "Bank financing", "Cash position / month", "Cash position cumulated"];

  const tableRowsSelectors = ["costNet", "actualPaid", "notPaid", "costCumulative", "income", "incomeCumulative", "vatDeductible", "vatCollectable", "vatPayable", "vatRecoverable", "cashNeed", "equity", "bankFinancing", "cashPosition", "cashPositionCumulated"];

  const generateTableHeading = () => {
    if (cashPosition) {
      return cashPosition.map((tableVal, i) => {
        const dateDisplay = monthsArray[tableVal.month - 1] + " " + tableVal.year;
          if (i === tableVal.length - 1) {
          return (<th scope="col" style={{...rightStyle, ...colStyle}}>{dateDisplay}</th>);
        } else {
          return (<th scope="col" style={{...colStyle}}>{dateDisplay}</th>);   
        }
      });
    } else {
      return "";
    }
  }

  const generateTableRows = () => {
    return tableRows.map((tRowName, index) => {
      const tableRowCells = () => {        
        if (cashPosition) {
          return cashPosition.map((tableVal, i) => {
            if (i === tableVal.length - 1) {
              return (<td scope="col" style={{...rightStyle, ...colStyle}}>{prettyPrint(tableVal[tableRowsSelectors[index]])}</td>);
            } else {
              return (<td scope="col" style={{...colStyle}}>{prettyPrint(tableVal[tableRowsSelectors[index]])}</td>);
            }
          });
        } else {
          return "";
        }
      }
      return (
        <tr key={index}>
          <th scope="row" style={leftStyle}>{tRowName}</th>
          {tableRowCells()}
        </tr>)
    });
  }

  return (
    <div className="bg-white mb-5" style={containerStyle}>
      <div className="tscroll">        
        <table className="table">
          <thead className="table-dark">
            <tr>
              <th scope="col" style={leftStyle}>{shouldLoad ? (<div className="spinner"></div>) : (<div></div>)}</th>
              {generateTableHeading()}
            </tr>
          </thead>
          <tbody>
              {generateTableRows()}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TableDataReport;
