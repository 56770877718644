import React, {useEffect, useState, useRef, useCallback} from "react";
import {confirmAlert} from "react-confirm-alert";
import {categoryTypes,} from "../category/constant/CategoryConstants";
import BudgetExecutionComponentContainer from "../budget/support/project/v2/BudgetExecutionComponentContainer";
import BudgetCategoryHeader from "../budget/support/project/v2/BudgetCategoryHeader";
import FixedScrollingYContainer from "../../components-shared/FixedScrollingYContainer";
import Breadcrumbs from "../../components-shared/Breadcrumbs/Breadcrumbs";
import RightSideModal from "../../components-shared/RightSideModal/RightSideModal";
import {
    getClosedMonths,
    getProjectOpenedMonthsById
} from "./actions/projectActions";
import {Button} from "react-bootstrap";
import {Tab, Tabs} from "react-bootstrap";
import CloseMonth from "../budget/support/forecast/CloseMonth";
import {getRTBAndCalculateForecastHistory, getRTBWithForecastHistory} from "../category/actions/budgetExecutionActions";

export default function ManageProjectRealTimeBudgetPage(props) {

    const [key, setKey] = useState('cost');

    const [rtb, setRtb] = useState(null);

    const [costTotal, setCostTotal] = useState(null);

    const [shouldLoad, setShouldLoad] = useState(true);

    const [hasExecution, setHasExecution] = useState(null);

    const [shouldOpenModal, setShouldOpenModal] = useState(false);

    const [openedMonths, setOpenedMonths] = useState([]);
    
    const [closedMonths, setClosedMonths] = useState([]);


    const getProjectId = () => {
        return props.match.params.projectId;
    }

    useEffect(() => {
        getRTBWithForecastHistory(props.match.params.projectId).then(result => {
            calculateRTBData(result);
        });
        getRTBAndCalculateForecastHistory(props.match.params.projectId).then(result => {
            calculateRTBData(result);
            setShouldLoad(false);
        });
    }, [props.match.params.projectId]);

    function calculateRTBData(result) {
        setRtb(result);
        const executionBudget = result['cost'].map(c => c.executionBudget ? c.executionBudget : 0)
            .reduce(function (acumulator, budget) {
                return acumulator + budget;
            }, 0);
        if (executionBudget && executionBudget > 0) {
            setHasExecution(true);
            setCostTotal(executionBudget);
        } else {
            const initialBudget = result['cost'].map(c => c.initialBudget ? c.initialBudget : 0)
                .reduce(function (acumulator, budget) {
                    return acumulator + budget;
                }, 0);
            setHasExecution(false);
            setCostTotal(initialBudget);
        }
    }
    

    function retrieveOpenedMonthsAndSetToggle(projectId) {
        if (projectId) {
            getProjectOpenedMonthsById(projectId).then((result) => {
                if (!result.code) setOpenedMonths(result);
                if (result.length === 0) {
                    confirmAlert({
                        title: 'Notification message',
                        message: 'All months are financially closed !',
                        buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {}
                        }
                        ]
                    });
                }
            });
            getClosedMonths(projectId).then((result) => {
                if (!result.code) setClosedMonths(result);
            });
        }
        toggleMonthClosureModal();
    }

    function toggleMonthClosureModal() {
        setShouldOpenModal(!shouldOpenModal);
    }

    function toggleCloseMonthModal() {
        setShouldOpenModal(false);
    }

    return (
        <React.Fragment>
            <Breadcrumbs breadcrumbs={[{url: '/projects', title: 'Home'}, {
                url: `/project/${getProjectId()}`,
                title: props.projectDetails && props.projectDetails.name
            }, {url: `/real-time-budget`, title: 'View Budget'}]}/>
            <header className="kb-header px-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-8 d-flex align-items-center ">
                            <div className="kb-title_page d-flex align-items-center py-4">
                                <h1 className="h4 font-weight-normal m-0">View Budget</h1>
                            </div>
                        </div>
                        <div className="col-4 d-flex align-items-center">
                            <React.Fragment>
                                <Button className="ml-3 align-right" onClick={() => {
                                    retrieveOpenedMonthsAndSetToggle(getProjectId());
                                }}>Month closure</Button>
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </header>
            <CloseMonth
                condition={shouldOpenModal}
                toggleCloseMonthModal={toggleCloseMonthModal}
                openedMonthsList={openedMonths}
                closedMonths={closedMonths}
                projectId={getProjectId()}
            />
            <Tabs
                transition={false}
                id="controlled-tab" variant='pills'
                activeKey={key}
                onSelect={key => setKey(key)}
                className="kb-tabs-nav kb-real-time-budget-navbar ul.nav.nav-tabs px-3"
            >
                {
                    categoryTypes.map(categoryType =>
                        (
                            <Tab tabClassName="px-4 mr-2" eventKey={categoryType.type.toLowerCase()}
                                 title={categoryType.displayValue}
                                 key={categoryType.type.toLowerCase()}>
                                <React.Fragment>
                                    <main className="kb-main kb-main_execution bg-white px-3 py-4 mb-5">
                                        <FixedScrollingYContainer additionalFixedPositionClass={"color-white"}>
                                            <BudgetCategoryHeader categoryType={categoryType.type} shouldLoad={shouldLoad}/>
                                        </FixedScrollingYContainer>
                                        <React.Fragment key={categoryType.type}>
                                            {categoryType.categoryBudgetAvailable &&
                                                rtb && costTotal !== null &&
                                                <BudgetExecutionComponentContainer
                                                    projectId={props.match.params.projectId}
                                                    categoryType={categoryType}
                                                    categories={rtb[categoryType.type.toLowerCase()]}
                                                    hasExecution={hasExecution}
                                                    costTotal={costTotal}
                                                />
                                            }
                                        </React.Fragment>
                                    </main>
                                </React.Fragment>
                            </Tab>
                        ))
                }

            </Tabs>
            <RightSideModal
                items={['Chocolate liquorice danish tootsie roll sesame snaps cheesecake gummi bears croissant dragée. ',
                    'Cookie caramels jujubes tart lollipop marzipan sweet. Croissant lemon drops donut. Sweet cupcake sweet chupa chups cake ' +
                    'lollipop fruitcake bonbon. Carrot cake fruitcake chocolate cake cookie jujubes gummi bears. Oat cake chocolate cake pudding ' +
                    'jelly dessert jelly-o chocolate bar. Carrot cake jelly-o pie cake cheesecake icing ice cream. ', 'Cake cupcake candy sugar plum. ' +
                    'Soufflé halvah gummies cake croissant jelly beans jelly.', 'Jelly-o chupa chups cookie jujubes caramels. Fruitcake icing marshmallow ' +
                    'liquorice powder fruitcake. Marzipan gummi bears fruitcake caramels bonbon sweet roll marzipan dessert.']}
            />
        </React.Fragment>

    );
}
