import React from "react";
import PropTypes from 'prop-types';
import CategoryTotalEntryForm from "../forms/CategoryTotalEntryForm";
import CategoryTotalViewAndEdit from "./CategoryTotalViewAndEdit";
import AssignedCategoriesListGrid from "./AssignedCategoriesListGrid";
import AssignCategoriesToCategoryTotalForm from "../forms/AssignCategoriesToCategoryTotalForm";
import ExpandCollapseChildren from "../../../components-shared/ExpandCollapseChildren";

export default function CategoryTotalManagementComponentView(props) {

    return (

        <div className={"kb-view_total"}>
            {props.categoryTotals &&
            props.categoryTotals.map((categoryTotal) => (
                <div className={"category-total-entry kb-list-item bg-white border-top"} key={categoryTotal.id}>
                    <CategoryTotalViewAndEdit categoryTotal={categoryTotal}
                                              onDelete={() => props.onDelete(categoryTotal)}
                                              onEdit={(state) => props.onEdit(categoryTotal, state)}/>
                    <ExpandCollapseChildren>
                     
                        <AssignedCategoriesListGrid categories={categoryTotal.assignedCategories}
                                                    categoryTotal={categoryTotal}
                                                    refreshCategoryTotalsList={props.refreshCategoryTotalsList}
                                                    categoryType={props.categoryType}/>
                        <AssignCategoriesToCategoryTotalForm categoryTotal={categoryTotal}
                                                             assignableCategories={categoryTotal.assignableCategories}
                                                             refreshCategoryTotalsList={props.refreshCategoryTotalsList}
                                                             categoryType={props.categoryType}/>
                    </ExpandCollapseChildren>
                </div>
            ))}

            <CategoryTotalEntryForm projectId={props.projectId}
                                    onSuccessfulSubmit={props.onSubmit}
                                    categoryType={props.categoryType}/>
        </div>
    );
}

CategoryTotalManagementComponentView.propTypes = {
    categoryTotals: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            assignableCategories: PropTypes.array,
            assignedCategories: PropTypes.array,
        }).isRequired
    ),
    projectId: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    categoryType: PropTypes.shape({
        displayValue: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    }).isRequired,
    refreshCategoryTotalsList: PropTypes.func.isRequired
};
