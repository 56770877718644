import React from "react";
import {NavLink} from "react-router-dom";
import {AppContextData} from "../main-components/AppContextData";
import {ADMIN} from "../components/user/support/UserConstants";
import {replaceUrlParams} from "./url-utils";
import PropTypes from "prop-types";

export default function ProjectUnauthorizedPanel(props) {

    if (props.error === 'Unauthorized' && AppContextData.getCurrentRoles() === ADMIN) {
        const assignUsersUrl =
            replaceUrlParams('/project/:projectId/users', [{key: ':projectId', value: props.projectId}]);

        return (
            <div className="loading-placeholder">
                <h2>To see more details about the
                    project <NavLink to={assignUsersUrl}>assign your user</NavLink> to the project</h2>
            </div>
        )

    } else {
        return (
            <div className="loading-placeholder">
                <h2>Error "{props.error}" while getting the project</h2>
                <h2>Go <NavLink to={"/projects"}>back</NavLink> and retry</h2>
            </div>
        );
    }
}

ProjectUnauthorizedPanel.propTypes = {
    projectId: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired
};
