import {genericGet, genericPost, genericPut} from "../../../components-shared/fetch-wrapper";


export const permissionsEndpoint = '/permissions';
export const rolesEndpoint = '/roles';

export function getAllPermissions() {
    return genericGet(permissionsEndpoint);
}

export function getAlluserRoles() {
    return genericGet(rolesEndpoint);
}

export function setAlluserRoles(data) {
    return genericPut(rolesEndpoint, data);
}

export function createUserRole(data) {
    return genericPost(rolesEndpoint, data);
}

