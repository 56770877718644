import React, {Component} from "react";

import {Button, FormLabel, FormControl, FormGroup} from "react-bootstrap";
import {changeOwnPassword, changePasswordForUser, myAccountPage, userManagementPage} from "../actions/userActions";
import PropTypes from "prop-types";
import update from "immutability-helper";
import {confirmAlert} from 'react-confirm-alert';
import {withRouter} from "react-router-dom";
import {toast} from 'react-toastify';

class EditUserPasswordForm extends Component {

    constructor(props) {
        super(props);

        this.state = EditUserPasswordForm.constructStateObject();

        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);

        this.validateFormPassword = this.validateFormPassword.bind(this);

        this.dismissErrorPassword = this.dismissErrorPassword.bind(this);
    };

    static constructStateObject() {
        return {
            passwordInfo: {
                newPassword: '',
                newPasswordRepeat: '',
                oldPassword: '',
                submitted: false,
                error: '',
            },
        };
    }

    handlePasswordChange(event) {
        this.setState({
            passwordInfo: update(this.state.passwordInfo, {
                [event.target.id]: {$set: event.target.value}
            })
        });
    };

    handlePasswordSubmit(event) {
        event.preventDefault();

        if (!this.state.passwordInfo.newPassword) {
            return this.setState({error: 'New Password is required!'});
        }

        if (!this.state.passwordInfo.newPasswordRepeat) {
            return this.setState({error: 'Repeat New Password is required!'});
        }

        if (this.state.passwordInfo.newPassword !== this.state.passwordInfo.newPasswordRepeat) {
            return this.setState({error: 'Passwords must match!'});
        }

        if (!this.state.passwordInfo.error && !this.state.passwordInfo.submitted) {

            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure you want to update the user password?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            // submitted becomes true only on YES
                            this.setState({
                                passwordInfo: update(this.state.passwordInfo, {
                                    submitted: {$set: true}
                                })
                            });
                            if (this.props.myAccountEdit) {
                                if (!this.state.passwordInfo.oldPassword) {
                                    return this.setState({error: 'Old Password is required!'});
                                }

                                changeOwnPassword(this.state.passwordInfo).then((json) => {
                                    if (json.code) {
                                        return update(this.state.passwordInfo, {
                                            error: {$set: json.message}
                                        });
                                    } else {
                                        if (json === false) {
                                            this.setState({
                                                passwordInfo: update(this.state.passwordInfo, {
                                                    submitted: {$set: false}
                                                })
                                            });
                                            toast.error("Failed to change password", {
                                                position: toast.POSITION.BOTTOM_RIGHT
                                            });
                                        } else {
                                            toast.info("Changed the password successfully", {
                                                position: toast.POSITION.BOTTOM_RIGHT,
                                            });
                                            this.props.history.push(myAccountPage);
                                        }
                                    }
                                });
                            } else {
                                changePasswordForUser(this.state.passwordInfo, this.props.userId).then((json) => {
                                    if (json.code) {
                                        return update(this.state.passwordInfo, {
                                            error: {$set: json.message}
                                        });
                                    } else {
                                        if (json === false) {
                                            this.setState({
                                                passwordInfo: update(this.state.passwordInfo, {
                                                    submitted: {$set: false}
                                                })
                                            });
                                            toast.error("Failed to change password", {
                                                position: toast.POSITION.BOTTOM_RIGHT
                                            });
                                        } else {
                                            toast.info("Changed the password successfully", {
                                                position: toast.POSITION.BOTTOM_RIGHT,
                                            });
                                            this.props.history.push(userManagementPage);
                                        }
                                    }
                                });
                            }
                        }
                    },
                    {
                        label: 'No',
                    }
                ]
            })
        }
    }

    validateFormPassword() {
        let st = this.state.passwordInfo;
        let valid;
        if (this.props.myAccountEdit) {
            valid = st.newPassword &&
                st.newPasswordRepeat &&
                st.oldPassword;
        } else {
            valid = st.newPassword &&
                st.newPasswordRepeat;
        }

        return valid && valid.length > 0 && !st.submitted;
    }

    dismissErrorPassword() {
        this.setState({
            passwordInfo: update(this.state.passwordInfo, {
                error: {$set: ''}
            })
        });
    }

    render() {
        const inputClassName = " ";

        return (

        <form onSubmit={this.handlePasswordSubmit}>
            <div className="container-fluid px-0">
                <div className="row">
                    <div className="col-12">
                        {
                        this.state.passwordInfo.error &&
                        <div className="alert alert-danger mb-4" role="alert" data-test="error" onClick={this.dismissError}>
                            {this.state.passwordInfo.error}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.dismissError}>
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        }
                        {
                        this.props.myAccountEdit &&
                        <FormGroup className={inputClassName} controlId="oldPassword">
                            <FormLabel>Old Password</FormLabel>
                            <FormControl
                                type="password"
                                value={this.state.passwordInfo.oldPassword}
                                onChange={this.handlePasswordChange}
                                />
                            </FormGroup>
                            }
                        </div>
                        <div className="col-12">
                            <FormGroup className={inputClassName} controlId="newPassword">
                                <FormLabel>New Password</FormLabel>
                                <FormControl
                                    type="password"
                                    value={this.state.passwordInfo.newPassword}
                                    onChange={this.handlePasswordChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-12">
                                <FormGroup className={inputClassName} controlId="newPasswordRepeat">
                                    <FormLabel>Repeat New Password</FormLabel>
                                    <FormControl
                                        type="password"
                                        value={this.state.passwordInfo.newPasswordRepeat}
                                        onChange={this.handlePasswordChange}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-12 mt-3 mb-5">
                                    <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={!this.validateFormPassword()}
                                    >
                                    Save
                                    </Button>
                                </div>
                            </div>
                        </div>
            </form>
        );
    }
}

EditUserPasswordForm.propTypes = {
    userId: PropTypes.string.isRequired,
    myAccountEdit: PropTypes.bool.isRequired,
};

export default withRouter(EditUserPasswordForm);
