import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Button, Form, FormGroup, InputGroup} from "react-bootstrap";

class AssignableUsersForm extends Component {

    constructor(props) {
        super(props);

        this.state = AssignableUsersForm.constructStateObject();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static constructStateObject() {
        return {
            userId: '',
        };
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            userId: '',
        })
        this.props.assignUser(this.state.userId);
    }

    render() {

        const inputClassName = "";

        return (
            <form className="add-assignable-users" onSubmit={this.handleSubmit}>
                <p>Assign user as {this.props.userRole.displayName}</p>

                <FormGroup className={inputClassName} controlId="userId">
                <InputGroup>
                    <Form.Control
                        as="select"
                        value={this.state.userId}
                        onChange={this.handleChange}
                        disabled={!this.props.formIsEnabled}
                        >
                        <option key="noKey" value="noValue">Please select user...</option>
                       {this.props.assignableUsers &&
                        this.props.assignableUsers.map(user => (
                            <option key={user.id}
                                    value={user.id}>{user.firstName} {user.lastName}</option>
                        ))
                        }
                    </Form.Control>
                <span className="input-group-btn">
                    <Button type="submit" className="btn-secondary rounded-0" variant="secondary" disabled={!this.props.formIsEnabled}>Assign</Button> 
                </span>
                  
                </InputGroup>
              </FormGroup>
            </form>
        );
    }

}

AssignableUsersForm.propTypes = {
    userRole: PropTypes.object.isRequired,
    assignableUsers: PropTypes.array,
    assignUser: PropTypes.func,
    formIsEnabled: PropTypes.bool,
};

export default AssignableUsersForm;
