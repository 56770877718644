import React from "react";
import PropTypes from "prop-types";
import GenericTemplateCard from "./GenericTemplateCard";
import {Link, withRouter} from "react-router-dom";

function DisplayTemplateCard(props) {
  
    const cloneTemplate = () => {
      props.history.push('/template/new/' + props.template.id);
    }
  
    return (
        <GenericTemplateCard template={props.template}>
             <Link className="btn btn-secondary mr-3" to={"/template/view/" + props.template.id}>View</Link>
             <button className="btn btn-link ml-auto px-2" onClick={cloneTemplate}> <i className="fas fa-clone"></i></button>
        </GenericTemplateCard>
    )
}

DisplayTemplateCard.propTypes = {
    template: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string
    }).isRequired
};
export default withRouter(DisplayTemplateCard)



 