import React, {Component} from "react";
import PropTypes from 'prop-types';
//import ContentPage from "../../../../main-components/ContentPage";
import {getInvoicesByFilter, getInvoicesForContract} from "../../actions/invoicesActions";
import InvoiceViewAndEdit from "./view/InvoiceViewAndEdit";
import InvoiceSearchForm from "../../forms/InvoiceSearchForm";
//import InvoicesExcelExport from "../../forms/InvoicesExcelExport";
import update from "immutability-helper";
import {isNullOrUndefined} from "../../../common/checks";
import {Button} from "react-bootstrap";
import Pagination from "react-js-pagination";
import {withRouter} from "react-router-dom";

class InvoiceManagementComponentContainer extends Component {
  
  constructor (props) {
    
    super(props);
    
    this.state = {
      costInvoices: {
        totalItems: '',
        invoices: []
      },
      incomeInvoices: {
        totalItems: '',
        invoices: []
      },
      error: '',
      contentHeader: 'Invoices',
      isRedirectedFromContract: !isNullOrUndefined(this.props.optionalRequestParams),
      
      costPageNumber: 0,
      incomePageNumber: 0,
      pageSize: 10,
      
      filters: {
        agreementReference: '',
        emissionDate: undefined,
        contractorName: '',
        subcategoryName: '',
      },
      path: '',
      costFilters: {
        date: "",
        filter: "",
        fromDate: "",
        keyword: "",
        toDate: ""
      },
      incomeFilters: {
        date: "",
        filter: "",
        fromDate: "",
        keyword: "",
        toDate: ""
      },
      costFiltersList: [
        {
          name: "Invoice No.",
          value: "invoiceNo"
        },
        {
          name: "Supplier",
          value: "clientOrSupplierName"
        },
        {
          name: "IB Code",
          value: "ibCode"
        },
        {
          name: "Contract No.",
          value: "contractNo"
        }
      ],
      costDatesList: [
        {
          name: "Invoice Date",
          value: "emissionDateInterval"
        },
        {
          name: "Maturity Date",
          value: "maturityDateInterval"
        },
        {
          name: "Payment Date",
          value: "paymentDateInterval"
        },
        {
          name: "BudgetDate",
          value: "budgetDateInterval"
        }
      ],
      incomeFiltersList: [
        {
          name: "Invoice No.",
          value: "invoiceNo"
        },
        {
          name: "Client Name",
          value: "clientOrSupplierName"
        },
        {
          name: "IB Code",
          value: "ibCode"
        },
        {
          name: "Contract No.",
          value: "contractNo"
        }
      ],
      incomeDatesList: [
        {
          name: "Invoice Date",
          value: "emissionDateInterval"
        },
        {
          name: "Maturity Date",
          value: "maturityDateInterval"
        },
        {
          name: "Payment Date",
          value: "paymentDateInterval"
        }
      ],
    };
    
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangefromDateCost = this.handleChangefromDateCost.bind(this);
    this.handleChangetoDateCost = this.handleChangetoDateCost.bind(this);
    this.handleChangefromDateIncome = this.handleChangefromDateIncome.bind(this);
    this.handleChangetoDateIncome = this.handleChangetoDateIncome.bind(this);
    this.handleChangeCost = this.handleChangeCost.bind(this);
    this.handleChangeIncome = this.handleChangeIncome.bind(this);
    this.dismissError = this.dismissError.bind(this);
    this.setPath = this.setPath.bind(this);
    
    this.refreshStateInformation = this.refreshStateInformation.bind(this);
    this.loadInvoicesByFilter = this.loadInvoicesByFilter.bind(this);
    this.loadInvoicesForContract = this.loadInvoicesForContract.bind(this);
    
    this.resetPageType = this.resetPageType.bind(this);
    this.handleSubmitIncome = this.handleSubmitIncome.bind(this);
    this.handleSubmitCost = this.handleSubmitCost.bind(this);
    this.getInvoices = this.getInvoices.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.componentIsMounted = false;
  };
  
  componentDidMount () {
    if (!this.componentIsMounted) {
      this.componentIsMounted = true;
      this.loadInvoicesByFilter();
    }
  }

  componentWillUnmount () {
    this.componentIsMounted = false;
  }
  
  
  handleSubmit (event) {
    
    event.preventDefault();
    
    this.resetPageType();
    
    const projectId = this.props.projectId;
    
    getInvoicesByFilter(projectId, this.state.filters).then((result) => {
      if (result.code) {
        this.setState({
          invoices: [],
          error: result.message
        });
      } else {
        this.setState({
          invoices: result,
          error: ''
        })
      }
    });
  }
  
  refreshStateInformation () {
    if (this.state.isRedirectedFromContract) {
      this.loadInvoicesForContract();
    } else {
      this.loadInvoicesByFilter();
    }
  }
  
  loadInvoicesForContract () {
    const {optionalRequestParams} = this.props;
    if (optionalRequestParams) {
      getInvoicesForContract(optionalRequestParams.contractId, optionalRequestParams.month, optionalRequestParams.year)
        .then((invoices) => {
          this.setState({
            invoices: invoices,
            contentHeader: "Invoices for " + optionalRequestParams.contractorName + " and category: " + optionalRequestParams.categoryName
              + " in " + optionalRequestParams.monthString + " " + optionalRequestParams.year
          })
        });
    }
  }
  
  loadInvoicesByFilter () {
    const {projectId} = this.props;
    const invoiceType = this.props.match.params.categoryType;
    const contractId = this.props.match.params.contractId;
    if (contractId !== ':contractId') {
      getInvoicesByFilter(projectId, 'COST', {pageNumber: this.state.costPageNumber, pageSize: this.state.pageSize, contractId: contractId}).then((costInvoices) => {
        getInvoicesByFilter(projectId, 'INCOME', {pageNumber: this.state.incomePageNumber, pageSize: this.state.pageSize, contractId: contractId}).then((incomeInvoices) => {
          if (this.componentIsMounted) {
            this.setState({
              costInvoices: costInvoices,
              incomeInvoices: incomeInvoices,
              path: invoiceType === 'COST' ? 'cost' : 'income'
            })
          }
        });
      });
    } else if (projectId) {
      getInvoicesByFilter(projectId, 'COST', {pageNumber: this.state.costPageNumber, pageSize: this.state.pageSize}).then((costInvoices) => {
        getInvoicesByFilter(projectId, 'INCOME', {pageNumber: this.state.incomePageNumber, pageSize: this.state.pageSize}).then((incomeInvoices) => {
          if (this.componentIsMounted) {
            this.setState({
              costInvoices: costInvoices,
              incomeInvoices: incomeInvoices,
              path: 'cost'
            })
          }
        });
      });
    }
  }
  
  resetPageType () {
    this.setState({
      isRedirectedFromContract: false,
      contentHeader: "Invoices"
    });
  }
  
  dismissError () {
    this.setState({error: ''});
  }
  
  setPath (path) {
    this.setState({path: path});
  }
  
  handleChangeCost (event) {
    this.setState({
      costFilters: update(this.state.costFilters, {
        [event.target.id]: {$set: event.target.value}
      })
    });
  };
  
  handleChangeIncome (event) {
    this.setState({
      incomeFilters: update(this.state.incomeFilters, {
        [event.target.id]: {$set: event.target.value}
      })
    });
  };
  
  handleChangefromDateCost (fromDate) {
    this.setState({
      costFilters: update(this.state.costFilters, {
        fromDate: {$set: fromDate}
      })
    });
  }
  
  handleChangetoDateCost (toDate) {
    this.setState({
      costFilters: update(this.state.costFilters, {
        toDate: {$set: toDate}
      })
    });
  }
  
  handleChangefromDateIncome (fromDate) {
    this.setState({
      incomeFilters: update(this.state.incomeFilters, {
        fromDate: {$set: fromDate}
      })
    });
  }
  
  handleChangetoDateIncome (toDate) {
    this.setState({
      incomeFilters: update(this.state.incomeFilters, {
        toDate: {$set: toDate}
      })
    });
  }
  
  handleSubmitIncome (event) {
    event.preventDefault();
    const body = {
      [this.state.incomeFilters.filter]: this.state.incomeFilters.keyword,
      [this.state.incomeFilters.date]: {
        from: this.state.incomeFilters.fromDate,
        to: this.state.incomeFilters.toDate
      },
      pageNumber: 0,
      pageSize: this.state.pageSize
    }
    getInvoicesByFilter(this.props.projectId, 'INCOME', body).then((incomeInvoices) => {
      this.setState({
        incomeInvoices: incomeInvoices,
        incomePageNumber: 0
      })
    });
  }
  
  handleSubmitCost (event) {
    event.preventDefault();
    const body = {
      [this.state.costFilters.filter]: this.state.costFilters.keyword,
      [this.state.costFilters.date]: {
        from: this.state.costFilters.fromDate,
        to: this.state.costFilters.toDate
      },
      pageNumber: 0,
      pageSize: this.state.pageSize
    }
    getInvoicesByFilter(this.props.projectId, 'COST', body).then((costInvoices) => {
      this.setState({
        costInvoices: costInvoices,
        costPageNumber: 0
      })
    });
  }
  
  onPageChange (pageNumber) {
    if (this.state.path === 'cost') {
      const body = {
        [this.state.costFilters.filter]: this.state.costFilters.keyword,
        [this.state.costFilters.date]: {
          from: this.state.costFilters.fromDate,
          to: this.state.costFilters.toDate
        },
        pageNumber: pageNumber - 1,
        pageSize: this.state.pageSize
      }
      getInvoicesByFilter(this.props.projectId, 'COST', body).then((costInvoices) => {
        this.setState({
          costInvoices: costInvoices,
          costPageNumber: pageNumber - 1
        })
      });
    } else {
      const body = {
        [this.state.incomeFilters.filter]: this.state.incomeFilters.keyword,
        [this.state.incomeFilters.date]: {
          from: this.state.incomeFilters.fromDate,
          to: this.state.incomeFilters.toDate
        },
        pageNumber: pageNumber - 1,
        pageSize: this.state.pageSize
      }
      getInvoicesByFilter(this.props.projectId, 'INCOME', body).then((incomeInvoices) => {
        this.setState({
          incomeInvoices: incomeInvoices,
          incomePageNumber: pageNumber - 1
        })
      });
    }
  }
  
  render () {
    
    return (
      
      <div className="row">
        {this.state.error &&
        <div className="col-12">
          <h3 data-test="error" onClick={this.dismissError}>
            <button onClick={this.dismissError}>✖</button>
            {this.state.error}
          </h3>
        </div>
        }
        <div className="col-12 mb-4">
          <Button variant={this.state.path === "cost" ? "secondary" : "light"} className={this.state.path === "cost" ? "btn-secondary mr-3" : "btn-light mr-3"} onClick={() => this.setPath("cost")}>Cost Invoices</Button>
          <Button variant={this.state.path === "income" ? "secondary" : "light"} className={this.state.path === "income" ? "btn-secondary mr-3" : "btn-light mr-3"} onClick={() => this.setPath("income")}>Income
            Invoices</Button>
        </div>
        {
          this.state.path === 'cost' ? (
              <React.Fragment>
                <InvoiceSearchForm
                    handleChange={this.handleChangeCost}
                    handleSubmit={this.handleSubmitCost}
                    handleChangefromDate={this.handleChangefromDateCost}
                    filters={this.state.costFilters}
                    handleChangetoDate={this.handleChangetoDateCost}
                    filtersList={this.state.costFiltersList}
                    datesList={this.state.costDatesList}
                    projectId = {this.props.projectId}
                    categoryType = {this.state.path.toUpperCase()}
                />
              </React.Fragment>
              ) : this.state.path === 'income' ? (
                  <React.Fragment>
                    <InvoiceSearchForm
                        handleChange={this.handleChangeIncome}
                        handleSubmit={this.handleSubmitIncome}
                        handleChangefromDate={this.handleChangefromDateIncome}
                        filters={this.state.incomeFilters}
                        handleChangetoDate={this.handleChangetoDateIncome}
                        filtersList={this.state.incomeFiltersList}
                        datesList={this.state.incomeDatesList}
                        projectId = {this.props.projectId}
                        categoryType = {this.state.path.toUpperCase()}
                    />
                  </React.Fragment>
              ) : ''
        }
        <div className="col-12">
          <table className="table mt-3">
            <thead className="thead-dark">
            <tr>
              <th scope="col">No.</th>
              <th scope="col">Invoice Date</th>
              {this.state.path === 'cost' && <th scope="col">Budget Date</th>}
              {this.state.path === 'cost' ? (<th scope="col">Supplier</th>) : (<th scope="col">Client</th>)}
              <th scope="col">Description</th>
              <th scope="col">Amount with VAT</th>
              <th scope="col">Amount without VAT</th>
              <th scope="col">VAT Amount</th>
              <th scope="col">Maturity Date</th>
              <th scope="col">Payment Date</th>
              <th scope="col">Contract no.</th>
              <th scope="col">IB Code</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            {this.getInvoices() && this.getInvoices().length > 0 &&
            this.getInvoices().map((invoice) =>
              <tr key={invoice.recordId}>
                <InvoiceViewAndEdit
                  invoice={invoice}
                  refreshState={this.refreshStateInformation}
                  path={this.state.path}
                />
              </tr>
            )}
            
            </tbody>
          </table>
          {this.getInvoices().length === 0 && <div>No invoices found</div>}
          {this.getInvoices() && this.getInvoices().length > 0 &&
          <div className="row">
            <div className="col-12">
              <div className="pagination-wrapper relative-pagination">
                <Pagination
                  activePage={this.state.path === 'cost' ? this.state.costPageNumber + 1 : this.state.incomePageNumber + 1}
                  itemsCountPerPage={10}
                  totalItemsCount={this.getTotalItemsCount()}
                  pageRangeDisplayed={5}
                  onChange={this.onPageChange}/>
              </div>
            </div>
          </div>
        }
        </div>
      </div>
    );
  }
  
  getInvoices () {
    if (this.state.path === 'cost' && this.state.costInvoices) {
      return this.state.costInvoices.invoices;
    } else if (this.state.path === 'income' && this.state.incomeInvoices) {
      return this.state.incomeInvoices.invoices;
    }
    return [];
  }
  
  getTotalItemsCount () {
    if (this.state.path === 'cost' && this.state.costInvoices) {
      return this.state.costInvoices.totalItems;
    } else if (this.state.path === 'income' && this.state.incomeInvoices) {
      return this.state.incomeInvoices.totalItems;
    }
    return 0;
  }
}

InvoiceManagementComponentContainer.propTypes = {
  projectId: PropTypes.string.isRequired,
  optionalRequestParams: PropTypes.shape({
    categoryName: PropTypes.string.isRequired,
    contractId: PropTypes.string.isRequired,
    contractorName: PropTypes.string.isRequired,
    month: PropTypes.number.isRequired,
    monthString: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
  }),
};

export default withRouter(InvoiceManagementComponentContainer);
