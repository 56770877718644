import moment from "moment";
import {dateMonthYearFormat, isMomentMonthAndYearEqual} from "../../common/dateFormatting";
import {deepClone} from "../../common/deepClone";
import {cashFlowPrognosis, remainingToForecast} from "../../forecast/support/ForecastConstants";
import { availableMonths } from "../../forecast/view/GraphMonthConstants";

//Global forecast state amounts are updated by id so synthetic forecasts must have unique id.
//let syntheticId = 0;

export function getForecastMoment(forecast) {
    if (forecast) {
        return moment(forecast.month + ' ' + forecast.year, dateMonthYearFormat);
    }
    return null;
}

export function getLatestForecastMoment(forecasts) {
    return getForecastMoment(forecasts[forecasts.length - 1]);
}

export function computeDateRangeLimit(remainingForecasts, contractForecasts, projectStartMoment, projectEndMoment) {
    const allForecasts = [remainingForecasts];
    for (let i = 0; i < contractForecasts.length; i++) {
        allForecasts.push(contractForecasts[i].forecasts);
    }
    return computeDateRangeLimitForForecastMatrix(allForecasts, projectStartMoment, projectEndMoment);
}

/**
 * Computes the date range limits for the date navigator.
 * Lower range is always project start date.
 * Upper range is either estimated project end date, latest date between all (remaining + contract)
 * forecast, or project start date + 11 months (to have a 12-month range), depending on which of them is higher.
 * @param forecastsMatrix array of all (remaining + contract) forecast arrays
 * @param projectStartDate project start date
 * @param projectEndDate estimated project end date
 * @returns {{start: *, end: *}} date navigator start and end moments
 */
export function computeDateRangeLimitForForecastMatrix(forecastsMatrix, projectStartDate, projectEndDate) {
    let endMoment = projectEndDate.clone();
    for (let i = 0; i < forecastsMatrix.length; i++) {
        let latestForecastMoment = getLatestForecastMoment(forecastsMatrix[i]);
        if (latestForecastMoment && latestForecastMoment.isAfter(endMoment)) {
            endMoment = latestForecastMoment;
        }
    }
    //let projectDurationInMonths = projectEndDate.diff(projectStartDate, 'months');
/*    if (projectDurationInMonths < 12) {
        let augmentedEndDate = projectStartDate.clone().add(11, 'months');
        if (augmentedEndDate.isAfter(endMoment)) {
            endMoment = augmentedEndDate;
        }
    }*/
    return {
        start: projectStartDate,
        end: endMoment
    };
}

//Augments forecast array with empty forecasts having negative id
export function augmentForecasts(forecasts, dateRangeLimit) {
    let currentMoment = dateRangeLimit.start.clone();
    let augmentedForecasts = [];
    while (currentMoment.isBefore(dateRangeLimit.end.clone().add(1, 'months'))) {
        const forecastForMoment = findForecastWithMoment(forecasts, currentMoment) || generateSyntheticForecast(currentMoment);
        augmentedForecasts.push(forecastForMoment);
        currentMoment.add(1, 'month');
    }
    return augmentedForecasts;
}

export function augmentContractForecasts(contractForecasts, dateRangeLimit) {
    for (let i = 0; i < contractForecasts.length; i++) {
        contractForecasts[i].forecasts = augmentForecasts(contractForecasts[i].forecasts, dateRangeLimit);
    }
    return contractForecasts;
}

/**
 * Compute the initially displayed date range. The goal is to make the current month the first one.
 * If we cannot (there are less than 11 months left) display the last 12 months of the project.
 * Special cases: project start date is in future (display starts from project start date) and
 * date range limit end is already exceeded (display the last 12 months).
 * @param currentMonthMoment current month in moment
 * @param dateRangeLimit the already compute date range limit
 */
export function computeDisplayedDateRange(currentMonthMoment, dateRangeLimit) {
    if (dateRangeLimit.start.isAfter(currentMonthMoment)) {
        // project starts in the future
        return oneYearIntervalStartingFrom(dateRangeLimit.start, 11)
    }
    const numberOfMonthsLeft = dateRangeLimit.end.diff(currentMonthMoment, 'months');
    if (dateRangeLimit.end.isBefore(currentMonthMoment)) {
        // project already ended or there are less than 12 months left
        return oneYearIntervalEndingWith(dateRangeLimit.end, 11);
    } else if ( numberOfMonthsLeft < 12) {
        return oneYearIntervalEndingWith(dateRangeLimit.end, numberOfMonthsLeft);
    }
    // there are more than 11 months left
    return oneYearIntervalStartingFrom(currentMonthMoment, 11);
}

export function computeDisplayedDateRangeDefault(currentMonthMoment) {
    return oneYearIntervalStartingFrom(currentMonthMoment, 11);
}


function oneYearIntervalStartingFrom(startMoment, end) {
    return {
        start: startMoment.clone(),
        end: startMoment.clone().add(end, 'months'),
    }
}

function oneYearIntervalEndingWith(endMoment, end) {
    return {
        start: endMoment.clone().subtract(end, 'months'),
        end: endMoment.clone(),
    }
}

function findForecastWithMoment(forecasts, moment) {
    return forecasts && forecasts.find((forecast) => {
        return (getForecastMoment(forecast).month() === moment.month()) && (getForecastMoment(forecast).year() === moment.year());
        //return getForecastMoment(forecast).isSame(moment, 'month')
    });
}

export function computeForecastSum(forecasts, type) {
    if (forecasts && forecasts.length > 0) {
        let sum = 0;
        if (type === 'Initial') {
            for (let i = 0; i < forecasts.length; i++) {
                sum += forecasts[i].initial;
            }
        } else {
            for (let i = 0; i < forecasts.length; i++) {
                sum += forecasts[i].actualForecast
            }
        }
        return sum;
    }
    
    return 0;
}

export function forecastsForCurrentDateRange(forecasts, displayedDateRange) {
    let split = [];
    if (forecasts) {
        let startIndex = forecasts.findIndex(forecast =>
            isMomentMonthAndYearEqual(displayedDateRange.start, getForecastMoment(forecast))
        );
        if (startIndex > -1) { // do not crash if forecasts are not yet fetched
            for (let i = 0; i < 12; i++) {
                split.push(forecasts[startIndex + i]);
            }
        }
    }
    return split;
}

export function generateSyntheticForecast(moment) {
    const forecast = {
        id: "00000000-0000-0000-0000-000000000000",
        amount: 0,
        initial: null,
        execution: null,
        date: moment.utcOffset(0, true).format(), // get rid of timezone to prevent bugs like KYBUD-441
        month: moment.format('MMM'),
        monthAsInteger: parseInt(moment.format('M')),
        year: parseInt(moment.format('YYYY')),
        synthetic: true
    };
    //syntheticId++;
    return forecast;
}

/**
 * Identify the target forecast by month and year and set it's amount to the split amount.
 */
export function updateForecastArrayWithSplit(forecastArray, forecastSplits, forecastPosition = false) {
    forecastSplits.forEach(forecastUpdate => {
        // console.log(forecastUpdate);
        // console.log(forecastSplits);
        let forecastToUpdate = findForecastWithMoment(forecastArray, forecastUpdate.date);
        if (forecastPosition === 'Initial') {
            forecastToUpdate.initial = forecastUpdate.amount;
        } else if (forecastPosition === 'Actual' || forecastPosition === 'Execution') {
            forecastToUpdate.actualForecast = parseFloat(forecastUpdate.amount);
            forecastToUpdate.execution = parseFloat(forecastUpdate.amount);
        } else {
            forecastToUpdate.amount = forecastUpdate.amount;
        }
    });
}

export function findContractForecastArray(contractsWithForecasts, contractId) {
    return contractsWithForecasts.find(contract => contract.id === contractId).forecasts
}

export function findForecastArray(state, forecastType, contractId) {
    switch (forecastType) {
        case remainingToForecast.value:
            return state.remainingForecasts;
        case cashFlowPrognosis.value:
            return state.forecasts;
        default:
            return findContractForecastArray(state.contractsWithForecasts, contractId);
    }
}

function hasAmount(forecast) {
    return forecast.initial !== null || forecast.execution !== null;
}

// Do not submit synthetic forecasts which have no amount.
export function prepareForecastsForSave(forecasts) {
    const processedForecasts = forecasts
        .filter(forecast => !forecast.synthetic || (forecast.synthetic && hasAmount(forecast)))
        .map(setDefaultUuidIfSynthetic);
    return processedForecasts;
}

// To pass validation/DTO transformation at backend.
function setDefaultUuidIfSynthetic(forecast) {
    let clone = deepClone(forecast);
    if (forecast.synthetic) {
        clone.id = "00000000-0000-0000-0000-000000000000";
    }
    return clone;
}

export function removeExtraCharacters(value) {
    let result = value;
    return result.substring(1, result.length - 1);
}

export const computeTotalActualCashflow = (forecastsArray, invoicedPerMonthArray ) => {
    let totalActualCashflow = 0;
    forecastsArray.forEach(element => {
            let totalInvoicedPerMonth = 0;
            invoicedPerMonthArray.forEach(invoice => {
                let monthOrder = null;
                
                if (('' + invoice.month).length > 1 ) {
                    monthOrder = '' + invoice.month;
                } else {
                    monthOrder = '0' + invoice.month;
                }
                
                availableMonths.forEach(avMonth => {
                    if (monthOrder && (avMonth.displayKey === monthOrder)) {
                        if (avMonth.displayName === element.month) {
                            totalInvoicedPerMonth = totalInvoicedPerMonth + invoice.amount;
                        }
                    }
                })
            });
            let valueToAdd = 0;
            let displayedValue = 0;
            if (element.execution !== null) {
                displayedValue = element.execution - totalInvoicedPerMonth;
                valueToAdd = displayedValue + totalInvoicedPerMonth;
                totalActualCashflow = totalActualCashflow + valueToAdd;
            } else if (element.initial !== null) {
                displayedValue = element.initial - totalInvoicedPerMonth;
                valueToAdd = displayedValue + totalInvoicedPerMonth;
                totalActualCashflow = totalActualCashflow + valueToAdd;
            }
        });
    return totalActualCashflow;
}
