import React, {Fragment} from "react";
import PropTypes from 'prop-types';
import {Route} from "react-router-dom";

export default function ProtectedRoute(props) {
    return (
        <Fragment>
            {props.condition ? <Route {...props}/> : <Fragment/>}
        </Fragment>
    );
}

ProtectedRoute.propTypes = {
    condition: PropTypes.bool.isRequired
};