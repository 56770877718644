import React, {useContext} from "react";
import PropTypes from "prop-types";
import ModalContext from "../../../main-components/ModalContext";

export default function ProjectTitleDisplay(props) {
    const [isModalOpen, setModal] = useContext(ModalContext);

   const projectDetails = props.projectDetails ? props.projectDetails : {name: "", location: ""};
    return (
        <nav className="kb-nav_details d-flex align-items-center navbar px-4">
            <div className="text-white d-md-flex">
                <h6 className="h6 text-white mb-0">Project: {projectDetails.name}</h6>
                  <h6 className="h6 text-white mb-0 ml-md-4"> <i className="fas fa-map-marker-alt m-0 mr-3"></i>{projectDetails.location}</h6>
            </div>
             {/*{props.showHelp && (*/}
             {/*                    <div className="ml-auto">*/}
             {/*                    <button className="btn btn-link text-white">*/}
             {/*       <i className="fas fa-question-circle font-weight-bold text-light h5 mb-0 cursor-pointer" onClick={() => setModal(!isModalOpen)}></i>*/}
             {/*   </button>*/}
             {/*   </div>)} */}
        </nav>
    )
}

ProjectTitleDisplay.propTypes = {
    projectDetails: PropTypes.shape({
        name: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired
    }),
    showHelp: PropTypes.bool
};   