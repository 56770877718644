import React, { Component } from 'react';

import Logout from "../components-shared/Logout";
import UserIdentity from "../components/user/support/UserIdentity";
import LinkButton from "../components-shared/LinkButton";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';

// noinspection JSUnresolvedFunction
const keybudPng = require('../assets/images/kybud-logo.svg');

// if the height in here grows, make sure to look at FixedScrollingYContainer as well
class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showMenu: false,
            isUserMenuClicked: false,
            dropdownOpen: false
        };

        this.toggleMenu = this.toggleMenu.bind(this);

        this.toggleUserMenu = this.toggleUserMenu.bind(this);
    }

    toggleMenu() {

        // Bad PRACTICE! 2 reasons, a parent should not be altered by a child, using document. (anything) is bad.
        if (this.state.showMenu === true) {
            document.body.className = "";
        } else {
            document.body.className = "mini-sidebar";
        }

        this.setState({ showMenu: !this.state.showMenu });
    }

    toggleUserMenu() {

        this.setState({
            isUserMenuClicked: !this.state.isUserMenuClicked
        })
    }

    render() {

        const isUserMenuClicked = this.state.isUserMenuClicked ? " visible" : "";
        const toggle = () => this.setState({dropdownOpen: !this.state.dropdownOpen});


        return (
            <nav className="kb-nav navbar navbar-fix px-4 py-0">

                <span className="menu-collapse d-none">
                    <i className="fas fa-bars" onClick={this.toggleMenu}/>
                </span>

                <Link className="kb-nav_logo navbar-brand" to={"/projects"}>
                    <img src={keybudPng} alt="keybud logo"/>
                </Link>
                

                <nav className="kb-nav_icons  px-3 ml-auto">
                    <Link to="/userManagement" className="btn btn-link btn-lg text-secondary" data-toggle="tooltip" data-placement="bottom" title="User settings">
                        <i className="fas fa-users-cog"></i>
                    </Link>
                    <Link to="/settings/" className="btn btn-link btn-lg text-secondary" data-toggle="tooltip" data-placement="bottom" title="App Settings">
                        <i className="fas fa-cog"></i>
                    </Link>
                    <Link to="/history/" className="btn btn-link btn-lg text-secondary" data-toggle="tooltip" data-placement="bottom" title="Notifications">
                        <i className="fas fa-bell"></i>
                    </Link>
                </nav>
                   
                <nav className="kb-nav_user">
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={toggle}>
                        <DropdownToggle className='btn-default' color='white' caret>
                            <span className="d-none d-md-inline"> <UserIdentity/>  </span> <i className="fas fa-user d-md-none"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <Link className="dropdown-item" to="/myAccount/">Account details</Link>
                            </DropdownItem>
                            <DropdownItem><Logout/></DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </nav>


                <div className="collapse navbar-collapse d-none" id="navbarsExampleDefault">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">

                        </li>
                    </ul>

                    <span className={"user-menu" + isUserMenuClicked} onClick={this.toggleUserMenu}></span>
                    <ul className="form-inline my-2 my-lg-0">
                        <li className="logged-user">
                            welcome <UserIdentity/>
                        </li>
                        <li className="my-account">
                            <LinkButton to={"/myAccount"}>My Account</LinkButton>
                        </li>
                        <li>
                         
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default Header;