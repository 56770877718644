import React from "react";

import {Route, Switch} from "react-router-dom";
import EmptyPage from "../staticpages/EmptyPage";
import EditUserPage from "../components/user/EditUserPage";
import MyAccountPage from "../components/user/MyAccountPage";
import SettingsNavigation from "../components/settings/SettingsNavigation";
import UserManagementPage from "../components/user/UserManagementPage";
import NewProjectPage from "../components/project/NewProjectPage";
import ViewAllProjectsPage from "../components/project/ViewAllProjectsPage";
import NewTemplatePage from "../components/template/pages/NewTemplatePage";
import ManageTemplatePage from "../components/template/pages/ManageTemplatePage";
import ViewUserPage from "../components/user/ViewUserPage";
import NewInvoicePage from "../components/invoice/NewInvoicePage";
import CloneProjectFromTemplatePage from "../components/template/pages/CloneProjectFromTemplatePage";
import ProjectNavigation from "../components/project/ProjectNavigation";
import EditMyAccountPage from "../components/user/EditMyAccountPage";
import DisplayHistoryContainer from "../components/history/DisplayHistoryContainer";
import {
    canCreateNewProject,
    canManageProject,
    canManageProjectExecution,
    viewAllNotifications,
    viewAllProjects,
    viewAndManageAppSettings,
    viewAndManageUsers
} from "./UserPermissionsFunctions";
import ProtectedRoute from "./ProtectedRoute";
import ManageExecutionOnSubcategoryPage from "../components/contract/v2/ManageExecutionOnSubcategoryPage";
import { ModalContextProvider } from "./ModalContext";
import { CategoriesContextProvider } from "./CategoriesContext";

export default function ContentRouter() {
    return (
        <React.Fragment>
            <Switch>
                {/*remove dashboard*/}
                {/*<Route path="/dashboard" component={Dashboard}/>*/}
                <Route path="/myAccount" component={MyAccountPage}/>
                <Route path="/edit-my-account" component={EditMyAccountPage}/>
                <ProtectedRoute condition={viewAllNotifications()} path="/history" component={DisplayHistoryContainer}/>
                <Route path="/emptyPage" component={EmptyPage}/>
                {/*<Route path="/help" component={Help}/>*/}
                {/*<Route path="/help-knowledgebase" component={HelpKnowledgeBase}/>*/}
                {/*<Route path="/help-glossary" component={HelpGlossary}/>*/}
                {/*<ProtectedRoute condition={canReportAProblem()} path="/report-a-problem" component={ReportAProblemPage}/>*/}
                <ProtectedRoute condition={viewAndManageAppSettings()} path="/settings" component={SettingsNavigation}/>
                <ProtectedRoute condition={viewAndManageAppSettings()} path="/template/view/:templateId" component={(props)=> <CategoriesContextProvider> <ManageTemplatePage {...props} /></CategoriesContextProvider>}/>
                <ProtectedRoute condition={viewAndManageAppSettings()} path="/template/new/:sourceTemplateId?" component={NewTemplatePage}/>
                <ProtectedRoute condition={viewAndManageAppSettings()} path="/template/clone/:projectId" component={(props) => <ModalContextProvider><CloneProjectFromTemplatePage {...props}/></ModalContextProvider>}/>
                <ProtectedRoute condition={canManageProjectExecution()} path="/manageExecution/:categoryId/:projectId/:categoryType" component={(props) => <ModalContextProvider><ManageExecutionOnSubcategoryPage {...props} /></ModalContextProvider>}/>
                <ProtectedRoute condition={canManageProjectExecution()} path="/newInvoice/:projectId" component={(props) => <ModalContextProvider><NewInvoicePage {...props} /></ModalContextProvider>}/>
                <ProtectedRoute condition={canManageProject()} path="/project/:projectId" component={(props) => <ModalContextProvider><ProjectNavigation {...props} /></ModalContextProvider>}/>
                <ProtectedRoute condition={viewAndManageUsers()} path="/userManagement/:userRoleId?" component={UserManagementPage}/>
                <ProtectedRoute condition={viewAndManageUsers()} path="/editUser/:userId" component={EditUserPage}/>
                <ProtectedRoute condition={viewAndManageUsers()} path="/viewUser/:userId" component={ViewUserPage}/>
                <ProtectedRoute condition={canCreateNewProject()} path="/new-project" component={NewProjectPage}/>
                <ProtectedRoute condition={viewAllProjects()} path="/projects" component={ViewAllProjectsPage}/>
            </Switch>
        </React.Fragment>
    );
}
