import React, {Fragment} from "react";
import PropTypes from 'prop-types';

export default function RenderIf(props) {
    return (
        <Fragment>
            {props.condition ? props.children : props.orElse}
        </Fragment>
    );
}

RenderIf.propTypes = {
    condition: PropTypes.bool.isRequired,
    orElse: PropTypes.element
};

RenderIf.defaultProps = {
    orElse: <Fragment/>
};
