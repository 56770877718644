import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import logo from '../assets/images/kybud-logo.svg';

const Login = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const searchParamsString = props.location ? props.location.search : '';
    const searchParams = new URLSearchParams(searchParamsString);
    const error = searchParams.get("error");
    return (
            <main className="kb-login">
                <a className="kb-nav_logo" href="/"><img src={logo} alt="keybud logo"/></a>
                <section className="kb-login_form">
                    <h1>Login</h1>
                    {error && (<div className="error-message text-center text-danger mb-3">
                        <i className="fas fa-exclamation-circle mr-3"></i>
                        <span>
                            Username or password incorrect
                        </span>
                    </div>)}
                    <form>
                        <div className="form-group form-group-lg form-inline form-group">
                            <label htmlFor="username" className="control-label">Email</label>
                            <input type="text" name="email" value={email} onChange={e => setEmail(e.target.value)} id="email" className="form-control"/>
                        </div>
                        <div className="form-group form-group-lg form-inline form-group">
                            <label htmlFor="password" className="control-label">Password</label>
                            <input type="password" name="password" id="password" value={password} onChange={e => setPassword(e.target.value)} className="form-control"/>
                        </div>
                        <Button variant="primary" type="button" onClick={() => props.login(email, password)}>Login</Button>
                    </form>
                </section>
                <section className="kb-login_image">
                </section>
            </main>
    );
}

export default withRouter(Login);
