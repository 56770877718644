import React from "react";
import PropTypes from 'prop-types';
import BudgetExecutionCategoryView from "./BudgetExecutionCategoryView";

export default function BudgetExecutionComponentView(props) {

    const componentType = props.isChild ? "subcategory" : "category";

    return (

        <React.Fragment>
            {props.categories &&
            props.categories.length > 0 &&
            props.categories.map((category, i) => (
                <div className={componentType + "-entry"} key={category.id + category.index + i}>
                    <BudgetExecutionCategoryView category={category}
                                                 className={componentType + "-view"}
                    />
                    {category.subCategoriesEndOfMonthBalance === null ||
                    // self call
                    <BudgetExecutionComponentView
                        categories={category.subCategoriesEndOfMonthBalance}
                        projectId={props.projectId}
                        isChild={true}
                    />
                    }
                </div>)
            )}
        </React.Fragment>
    );
}

BudgetExecutionComponentView.propTypes = {
    categories: PropTypes.array,
    projectId: PropTypes.string.isRequired,
    isChild: PropTypes.bool,
};

BudgetExecutionComponentView.defaultProps = {
    categories: [],
    isChild: false
};
