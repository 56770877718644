import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Button, FormGroup, InputGroup, Form} from "react-bootstrap";
import {assignCategoryToCategoryTotal} from "../actions/categoriesTotalsActions";

class AssignCategoriesToCategoryTotalForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            categoryId: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    handleSubmit(event) {

        event.preventDefault();

        if (this.state.categoryId) {
            assignCategoryToCategoryTotal(this.props.categoryTotal.id, this.state.categoryId)
                .then(this.props.refreshCategoryTotalsList);
            this.setState({
                categoryId: ''
            })
        }
    }

    render() {

        const inputClassName = "";

        return (
            <form className="add-assignable-categories ml-5 mr-3 py-4" onSubmit={this.handleSubmit}>
             <label>Assign {this.props.categoryType.displayValue} Category to "{this.props.categoryTotal.name}"</label>
               <FormGroup className={inputClassName} controlId="categoryId">
                <InputGroup>
                <Form.Control
                        as="select"
                        value={this.state.categoryId}
                        onChange={this.handleChange}
                    >
                        <option key="noKey" value="noValue">Please select category...</option>
                        {this.props.assignableCategories &&
                        this.props.assignableCategories.map(category => (
                            <option key={this.props.categoryTotal.id + category.id}
                                    value={category.id}>{category.name}</option>
                        ))
                        }
                    </Form.Control>
                    <span className="input-group-btn">
                    <Button  type="submit" className="btn-secondary rounded-0" variant="secondary">Assign</Button>
                  </span>
                  
                </InputGroup>
              </FormGroup>
            </form>
        );
    }
}

AssignCategoriesToCategoryTotalForm.propTypes = {
    categoryTotal: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    }).isRequired,
    assignableCategories: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired
    ),
    refreshCategoryTotalsList: PropTypes.func.isRequired,
    categoryType: PropTypes.shape({
        displayValue: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    }).isRequired,
};

export default AssignCategoriesToCategoryTotalForm;
