import React from "react";
import NewProjectForm from "./forms/NewProjectForm";
//import ContentPage from "../../main-components/ContentPage";
import Breadcrumbs from "../../components-shared/Breadcrumbs/Breadcrumbs";
import BackButtonNavigationBar from "../../components-shared/BackButtonNavigationBar";

function NewProjectPage() {
    return (
        <React.Fragment>
         <BackButtonNavigationBar classNamePrefix={"projects"}/>
       <Breadcrumbs breadcrumbs={[{url: '/projects', title: 'Home'}, {url: `/new-project`, title: `New project`}]} />
        <header className="kb-header px-3">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 d-flex align-items-center ">
                        <div className="kb-title_page d-flex align-items-center py-4">
                            <h1 className="h4 font-weight-normal m-0">New project</h1>
                        </div>
                        <nav className="kb-nav_ctas ml-auto">
                        </nav>
                    </div>
                </div>
            </div>
        </header>
        <main className="kb-main kb-main_project--new px-3 py-4 bg-white">
            <div className="container-fluid">
                <div className="row">
                 <div className="col-12 pb-5">
                    <h5 className="py-3">Generic info about the project</h5>
                    <NewProjectForm/>
                </div>
            </div>
            </div>
        </main> 
        </React.Fragment>
    );
}

export default NewProjectPage;




