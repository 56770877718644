import React, {Component} from "react";
import PropTypes from 'prop-types';
import {getBusinessPlan} from "../../../../category/actions/budgetExecutionActions";
import {Tab, Tabs} from "react-bootstrap";
import {initialBudget, executionBudget} from "../../../../category/constant/CategoryConstants";
import CollapsiblePageSection from "../../../../../components-shared/CollapsiblePageSection";
import {prettyPrint} from "../../../../common/numberOperations";
import { getCategoryTotals } from "../../../../category/actions/categoriesTotalsActions";
import {categoryTypes} from "../../../../category/constant/CategoryConstants";

class BudgetBusinessPlanComponentContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            businessPlan: {},
            initialBudget: [],
            executionBudget: [],
            initialLoading: true,
            categoryTotals: [],
            error: '',
            costDisplay: "TOTAL COST",
            incomeDisplay: "TOTAL INCOME",
            financingDisplay: "TOTAL FINANCING",
            investmentDisplay: "TOTAL INVESTMENT",
            collapseAll: true
        };

        this.categoryTypes = ['COST', 'INCOME', 'FINANCING', 'INVESTMENT'];
        this.onSelect = this.onSelect.bind(this);
        this.dispalyTotal = this.dispalyTotal.bind(this);
    }


    componentDidMount() {

        const projectId = this.props.projectId();

        if (projectId) {
            getBusinessPlan(projectId).then((data) => {
                if (data.code) {
                    return this.setState({error: data.message});
                } else {
                    let result = data;
                    let resultInitialBudget = data.initialBudget;
                    let resultExecutionBudget = data.executionBudget;
                    this.setState({
                        businessPlan: result,
                        initialBudget: resultInitialBudget,
                        executionBudget: resultExecutionBudget,
                        initialLoading: false
                    })
                }
            })
        }

        categoryTypes && categoryTypes.forEach(catType => {
            getCategoryTotals(projectId, catType.type).then((categoryTotals) => {
                if (categoryTotals.code) {
                    return this.setState({error: categoryTotals.message});
                } else {
                    this.setState({
                        categoryTotals: {
                            ...this.state.categoryTotals,
                            [catType.type]: categoryTotals
                        },
                        error: ''
                    })
                }
            });
        })

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.initialLoading === false && prevState.initialLoading === true) {
            return;
        }
    }

    dispalyTotal(budgetType, categoryTotalName) {
        let shouldDisplay = false;
        if (this.state.categoryTotals && this.state.categoryTotals[budgetType]) { 
            this.state.categoryTotals[budgetType].forEach(catTotal => {
                if (catTotal.name === categoryTotalName) {
                    if (catTotal.assignedCategories && catTotal.assignedCategories.length > 0) {
                        shouldDisplay = true;
                    }
                }
            })
        } 
        return shouldDisplay;
    }

    formatNumber(value) {
        var formatedNumber = "0.00";
        if (value === 100) {
            formatedNumber = "100.00";
        } else if (value > 0) {
            formatedNumber = value.toFixed(2);
        } else {
            return formatedNumber;
        }
        return formatedNumber;
    }
    onSelect(value) {
        this.setState({
            collapseAll: value
        })
    }


    render() {
        return (
            <React.Fragment>
                <main className="kb-main bg-white">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <Tabs transition={false} 
                                    id="businessplan-tab" variant='pills' 
                                    className="kb-businessplan-navbar ul.nav.nav-tabs bg-dark p-2" 
                                    onSelect={() => this.onSelect(true)}>
                                    <Tab className="kb-tabs-nav mr-2" eventKey={initialBudget.type.toLowerCase()}
                                         title={initialBudget.displayValue} key={initialBudget.type.toLowerCase()}>
                                        <div className="kb-businessplan-section-border">
                                            <CollapsiblePageSection title={
                                                <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-12">
                                                                {this.categoryTypes[0]}
                                                        </div>
                                                    </div>
                                                </React.Fragment>}
                                                                    collapsed={this.state.collapseAll ? true : false}
                                                                    onSelect={this.onSelect}
                                                                    collapseAll={this.state.collapseAll}
                                                                    showArrow={true}
                                                                    noTitlePadding={true}
                                                                    mlChildren={true}
                                                                    headingSize={3}
                                            >
                                                <div className="kb-view_categories">
                                                    <div id="costBudgetCategoryInitial" className="row ml-0 w-100">
                                                        <React.Fragment>
                                                            {
                                                                this.state.initialBudget && this.state.initialBudget[0]
                                                                && this.state.initialBudget[0].budgetType === this.categoryTypes[0]
                                                                && this.state.initialBudget[0].budgetComponents
                                                                && this.state.initialBudget[0].budgetComponents.map(currentComponent => currentComponent ?
                                                                    (
                                                                        <React.Fragment key={1+currentComponent.budgetComponentName}>
                                                                            <div
                                                                                className="col-4 text-break font-weight-bold kb-businessplan-section-cell">
                                                                                {currentComponent.budgetComponentName}
                                                                            </div>
                                                                            <div
                                                                                className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                {prettyPrint(currentComponent.budgetComponentValue) ? prettyPrint(currentComponent.budgetComponentValue) : 0} €
                                                                            </div>
                                                                            {
                                                                                currentComponent.budgetComponentPercentage ? (
                                                                                    <div
                                                                                        className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                        {this.formatNumber(currentComponent.budgetComponentPercentage)}%
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                        {this.formatNumber()}%
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            <div className="col-6 kb-businessplan-section-cell"></div>
                                                                            {
                                                                                currentComponent.budgetLines
                                                                                && currentComponent.budgetLines.map(currentBudgetLine => (
                                                                                    <React.Fragment key={2+currentBudgetLine.budgetLineName}>
                                                                                        {
                                                                                            this.state.initialBudget[0].budgetComponents.indexOf(currentComponent)
                                                                                            === this.state.initialBudget[0].budgetComponents.length -1
                                                                                            && currentComponent.budgetLines
                                                                                            && currentComponent.budgetLines.length > 0
                                                                                            && currentComponent.budgetLines.indexOf(currentBudgetLine) ===
                                                                                            currentComponent.budgetLines.length -1 ?
                                                                                                (
                                                                                                    <React.Fragment>
                                                                                                        <div
                                                                                                            className="col-4 text-break">
                                                                                                        <span className="kb-businessplan-ibcode">
                                                                                                            {currentBudgetLine.ibCode}
                                                                                                        </span>{currentBudgetLine.budgetLineName}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-1 text-right">
                                                                                                            {prettyPrint(currentBudgetLine.budgetLineValue) ? prettyPrint(currentBudgetLine.budgetLineValue) : 0} €
                                                                                                        </div>
                                                                                                        <div className="col-7"></div>
                                                                                                    </React.Fragment>
                                                                                                ) :
                                                                                                (
                                                                                                    <React.Fragment>
                                                                                                        <div
                                                                                                            className="col-4 text-break kb-businessplan-section-cell">
                                                                                                        <span className="kb-businessplan-ibcode">
                                                                                                            {currentBudgetLine.ibCode}
                                                                                                        </span>{currentBudgetLine.budgetLineName}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-1 text-right kb-businessplan-section-cell">
                                                                                                            {prettyPrint(currentBudgetLine.budgetLineValue) ? prettyPrint(currentBudgetLine.budgetLineValue) : 0} €
                                                                                                        </div>
                                                                                                        <div className="col-7 kb-businessplan-section-cell"></div>
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                        }
                                                                                    </React.Fragment>
                                                                                ))
                                                                            }
                                                                        </React.Fragment>
                                                                    ) : (<React.Fragment/>)
                                                                )
                                                            }
                                                        </React.Fragment>
                                                        <div id="costInitialCategoryTotal"
                                                             className="row ml-0 pr-0 bg-light w-100 kb-businessplan-config-total-row">
                                                            <React.Fragment key="costInitialCategoryTotalKey">
                                                                {
                                                                    this.state.initialBudget && this.state.initialBudget[0]
                                                                    && this.state.initialBudget[0].budgetType === this.categoryTypes[0]
                                                                    && this.state.initialBudget[0].budgetComponentTotal ?
                                                                        (
                                                                            <React.Fragment>
                                                                                <div
                                                                                    className="col-4 font-weight-bold kb-businessplan-section-cell">
                                                                                    {this.state.costDisplay}
                                                                                </div>
                                                                                <div
                                                                                    className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                    {prettyPrint(this.state.initialBudget[0].budgetComponentTotal)} €
                                                                                </div>
                                                                                {
                                                                                    this.state.initialBudget[0].budgetComponentTotal
                                                                                    && this.state.initialBudget[0].budgetComponentTotal > 0 ? (
                                                                                        <React.Fragment>
                                                                                            <div
                                                                                                className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                                {this.formatNumber(this.state.initialBudget[0].percentage)}%
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    ) : (<React.Fragment>
                                                                                        <div
                                                                                            className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {this.formatNumber()}%
                                                                                        </div>
                                                                                    </React.Fragment>)
                                                                                }
                                                                                <div className="col-6 kb-businessplan-section-cell"></div>
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                <div
                                                                                    className="col-4 font-weight-bold kb-businessplan-section-cell">{this.state.costDisplay}
                                                                                </div>
                                                                                <div className="col-1 font-weight-bold text-right kb-businessplan-section-cell">0 €
                                                                                </div>
                                                                                <div className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                    {this.formatNumber()}%
                                                                                </div>
                                                                                <div className="col-6 kb-businessplan-section-cell"></div>
                                                                            </React.Fragment>
                                                                        )
                                                                }
                                                            </React.Fragment>
                                                        </div>
                                                        <div id="costInitialTotalConfigured"
                                                             className="row ml-0 pr-0 w-100">
                                                            {
                                                                this.state.initialBudget && this.state.initialBudget[0]
                                                                && this.state.initialBudget[0].totalForCategoryTotals.map(currentTotal => this.dispalyTotal(this.state.initialBudget[0].budgetType, currentTotal.categoryTotalName) &&  (
                                                                    <React.Fragment key={3+currentTotal.categoryTotalName}>
                                                                        {
                                                                            this.state.initialBudget[0].totalForCategoryTotals
                                                                            && this.state.initialBudget[0].totalForCategoryTotals.length > 0
                                                                            && this.state.initialBudget[0].totalForCategoryTotals.indexOf(currentTotal) ===
                                                                            this.state.initialBudget[0].totalForCategoryTotals.length -1 ?
                                                                                (
                                                                                    <React.Fragment>
                                                                                        <div
                                                                                            className="col-4 text-break kb-businessplan-config-total font-weight-bold">
                                                                                            {currentTotal.categoryTotalName}
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right">
                                                                                            {prettyPrint(currentTotal.totalForCategoryTotal)} €
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right">
                                                                                            {this.formatNumber(currentTotal.percentage)}%
                                                                                        </div>
                                                                                        <div className="col-6"></div>
                                                                                    </React.Fragment>
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <div
                                                                                            className="col-4 text-break kb-businessplan-config-total font-weight-bold kb-businessplan-section-cell">
                                                                                            {currentTotal.categoryTotalName}
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {prettyPrint(currentTotal.totalForCategoryTotal)} €
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {this.formatNumber(currentTotal.percentage)}%
                                                                                        </div>
                                                                                        <div className="col-6 kb-businessplan-section-cell"></div>
                                                                                    </React.Fragment>
                                                                                )
                                                                        }
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </CollapsiblePageSection>
                                        </div>

                                        <div className="kb-businessplan-section-border">
                                            <CollapsiblePageSection title={
                                                <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            {this.categoryTypes[1]}
                                                        </div>
                                                    </div>
                                                </React.Fragment>}
                                                                    collapsed={this.state.collapseAll ? true : false}
                                                                    onSelect={this.onSelect}
                                                                    collapseAll={this.state.collapseAll}
                                                                    showArrow={true}
                                                                    noTitlePadding={true}
                                                                    mlChildren={true}
                                                                    headingSize={3}
                                            >
                                                <div className="kb-view_categories">
                                                    <div id="incomeBudgetCategoryInitial" className="row ml-0 w-100">
                                                        <React.Fragment>
                                                            {
                                                                this.state.initialBudget && this.state.initialBudget[1]
                                                                && this.state.initialBudget[1].budgetType === this.categoryTypes[1]
                                                                && this.state.initialBudget[1].budgetComponents
                                                                && this.state.initialBudget[1].budgetComponents.map(currentComponent => currentComponent ?
                                                                    (
                                                                        <React.Fragment key={4+currentComponent.budgetComponentName}>
                                                                            <div
                                                                                className="col-4 text-break font-weight-bold kb-businessplan-section-cell">
                                                                                {currentComponent.budgetComponentName}
                                                                            </div>
                                                                            <div
                                                                                className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                {prettyPrint(currentComponent.budgetComponentValue) ? prettyPrint(currentComponent.budgetComponentValue) : 0} €
                                                                            </div>
                                                                            {
                                                                                currentComponent.budgetComponentPercentage ? (
                                                                                    <div
                                                                                        className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                        {this.formatNumber(currentComponent.budgetComponentPercentage)}%
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                        {this.formatNumber()}%
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            <div className="col-6 kb-businessplan-section-cell"></div>
                                                                            {
                                                                                currentComponent.budgetLines
                                                                                && currentComponent.budgetLines.map(currentBudgetLine => (
                                                                                    <React.Fragment key={5+currentBudgetLine.budgetLineName}>
                                                                                        {
                                                                                            this.state.initialBudget[1].budgetComponents.indexOf(currentComponent)
                                                                                            === this.state.initialBudget[1].budgetComponents.length -1
                                                                                            && currentComponent.budgetLines
                                                                                            && currentComponent.budgetLines.length > 0
                                                                                            && currentComponent.budgetLines.indexOf(currentBudgetLine) ===
                                                                                            currentComponent.budgetLines.length -1 ?
                                                                                                (
                                                                                                    <React.Fragment>
                                                                                                        <div
                                                                                                            className="col-4 text-break">
                                                                                                        <span className="kb-businessplan-ibcode">
                                                                                                            {currentBudgetLine.ibCode}
                                                                                                        </span>{currentBudgetLine.budgetLineName}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-1 text-right">
                                                                                                            {prettyPrint(currentBudgetLine.budgetLineValue) ? prettyPrint(currentBudgetLine.budgetLineValue) : 0} €
                                                                                                        </div>
                                                                                                        <div className="col-7"></div>
                                                                                                    </React.Fragment>
                                                                                                ) :
                                                                                                (
                                                                                                    <React.Fragment>
                                                                                                        <div
                                                                                                            className="col-4 text-break kb-businessplan-section-cell">
                                                                                                        <span className="kb-businessplan-ibcode">
                                                                                                            {currentBudgetLine.ibCode}
                                                                                                        </span>{currentBudgetLine.budgetLineName}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-1 text-right kb-businessplan-section-cell">
                                                                                                            {prettyPrint(currentBudgetLine.budgetLineValue) ? prettyPrint(currentBudgetLine.budgetLineValue) : 0} €
                                                                                                        </div>
                                                                                                        <div className="col-7 kb-businessplan-section-cell"></div>
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                        }
                                                                                    </React.Fragment>
                                                                                ))
                                                                            }
                                                                        </React.Fragment>
                                                                    ) : (<React.Fragment/>)
                                                                )
                                                            }
                                                        </React.Fragment>
                                                        <div id="incomeInitialCategoryTotal"
                                                             className="row ml-0 pr-0 bg-light w-100 kb-businessplan-config-total-row">
                                                            <React.Fragment key="incomeInitialCategoryTotalKey">
                                                                {
                                                                    this.state.initialBudget && this.state.initialBudget[1]
                                                                    && this.state.initialBudget[1].budgetType === this.categoryTypes[1]
                                                                    && this.state.initialBudget[1].budgetComponentTotal ?
                                                                        (
                                                                            <React.Fragment>
                                                                                <div
                                                                                    className="col-4 font-weight-bold kb-businessplan-section-cell">
                                                                                    {this.state.incomeDisplay}
                                                                                </div>
                                                                                <div
                                                                                    className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                    {prettyPrint(this.state.initialBudget[1].budgetComponentTotal)} €
                                                                                </div>
                                                                                {
                                                                                    this.state.initialBudget[1].budgetComponentTotal
                                                                                    && this.state.initialBudget[1].budgetComponentTotal > 0 ? (
                                                                                        <React.Fragment>
                                                                                            <div
                                                                                                className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                                {this.formatNumber(this.state.initialBudget[1].percentage)}%
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    ) : (<React.Fragment>
                                                                                        <div
                                                                                            className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {this.formatNumber()}%
                                                                                        </div>
                                                                                    </React.Fragment>)
                                                                                }
                                                                                <div className="col-6 kb-businessplan-section-cell"></div>
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                <div
                                                                                    className="col-4 font-weight-bold kb-businessplan-section-cell" >{this.state.incomeDisplay}
                                                                                </div>
                                                                                <div className="col-1 font-weight-bold text-right kb-businessplan-section-cell">0 €
                                                                                </div>
                                                                                <div className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                    {this.formatNumber()}%
                                                                                </div>
                                                                                <div className="col-6 kb-businessplan-section-cell"></div>
                                                                            </React.Fragment>
                                                                        )
                                                                }
                                                            </React.Fragment>
                                                        </div>
                                                        <div id="incomeInitialTotalConfigured"
                                                             className="row ml-0 pr-0 w-100">
                                                            {
                                                                this.state.initialBudget && this.state.initialBudget[1]
                                                                && this.state.initialBudget[1].totalForCategoryTotals.map(currentTotal => this.dispalyTotal(this.state.initialBudget[1].budgetType, currentTotal.categoryTotalName) && (
                                                                    <React.Fragment key={6+currentTotal.categoryTotalName}>
                                                                        {
                                                                            this.state.initialBudget[1].totalForCategoryTotals
                                                                            && this.state.initialBudget[1].totalForCategoryTotals.length > 0
                                                                            && this.state.initialBudget[1].totalForCategoryTotals.indexOf(currentTotal) ===
                                                                            this.state.initialBudget[1].totalForCategoryTotals.length -1 ?
                                                                                (
                                                                                    <React.Fragment>
                                                                                        <div
                                                                                            className="col-4 text-break kb-businessplan-config-total font-weight-bold">
                                                                                            {currentTotal.categoryTotalName}
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right">
                                                                                            {prettyPrint(currentTotal.totalForCategoryTotal)} €
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right">
                                                                                            {this.formatNumber(currentTotal.percentage)}%
                                                                                        </div>
                                                                                        <div className="col-6"></div>
                                                                                    </React.Fragment>
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <div
                                                                                            className="col-4 text-break kb-businessplan-config-total font-weight-bold kb-businessplan-section-cell">
                                                                                            {currentTotal.categoryTotalName}
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {prettyPrint(currentTotal.totalForCategoryTotal)} €
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {this.formatNumber(currentTotal.percentage)}%
                                                                                        </div>
                                                                                        <div className="col-6 kb-businessplan-section-cell"></div>
                                                                                    </React.Fragment>
                                                                                )
                                                                        }
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </CollapsiblePageSection>
                                        </div>

                                        <div className="kb-businessplan-section-border">
                                            <CollapsiblePageSection title={
                                                <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            {this.categoryTypes[2]}
                                                        </div>
                                                    </div>
                                                </React.Fragment>}
                                                                    collapsed={this.state.collapseAll ? true : false}
                                                                    onSelect={this.onSelect}
                                                                    collapseAll={this.state.collapseAll}
                                                                    showArrow={true}
                                                                    noTitlePadding={true}
                                                                    mlChildren={true}
                                                                    headingSize={3}
                                            >
                                                <div className="kb-view_categories">
                                                    <div id="financingBudgetCategoryInitial" className="row ml-0 w-100">
                                                        <React.Fragment>
                                                            {
                                                                this.state.initialBudget && this.state.initialBudget[2]
                                                                && this.state.initialBudget[2].budgetType === this.categoryTypes[2]
                                                                && this.state.initialBudget[2].budgetComponents
                                                                && this.state.initialBudget[2].budgetComponents.map(currentComponent => currentComponent ?
                                                                    (
                                                                        <React.Fragment key={7+currentComponent.budgetComponentName}>
                                                                            <div
                                                                                className="col-4 text-break font-weight-bold kb-businessplan-section-cell">
                                                                                {currentComponent.budgetComponentName}
                                                                            </div>
                                                                            <div
                                                                                className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                {prettyPrint(currentComponent.budgetComponentValue) ? prettyPrint(currentComponent.budgetComponentValue) : 0} €
                                                                            </div>
                                                                            {
                                                                                currentComponent.budgetComponentPercentage ? (
                                                                                    <div
                                                                                        className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                        {this.formatNumber(currentComponent.budgetComponentPercentage)}%
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                        {this.formatNumber()}%
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            <div className="col-6 kb-businessplan-section-cell"></div>
                                                                            {
                                                                                currentComponent.budgetLines
                                                                                && currentComponent.budgetLines.map(currentBudgetLine => (
                                                                                    <React.Fragment key={8+currentBudgetLine.budgetLineName}>
                                                                                        {
                                                                                            this.state.initialBudget[2].budgetComponents.indexOf(currentComponent)
                                                                                            === this.state.initialBudget[2].budgetComponents.length -1
                                                                                            && currentComponent.budgetLines
                                                                                            && currentComponent.budgetLines.length > 0
                                                                                            && currentComponent.budgetLines.indexOf(currentBudgetLine) ===
                                                                                            currentComponent.budgetLines.length -1 ?
                                                                                                (
                                                                                                    <React.Fragment>
                                                                                                        <div
                                                                                                            className="col-4 text-break">
                                                                                                        <span className="kb-businessplan-ibcode">
                                                                                                            {currentBudgetLine.ibCode}
                                                                                                        </span>{currentBudgetLine.budgetLineName}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-1 text-right">
                                                                                                            {prettyPrint(currentBudgetLine.budgetLineValue) ? prettyPrint(currentBudgetLine.budgetLineValue) : 0} €
                                                                                                        </div>
                                                                                                        <div className="col-7"></div>
                                                                                                    </React.Fragment>
                                                                                                ) :
                                                                                                (
                                                                                                    <React.Fragment>
                                                                                                        <div
                                                                                                            className="col-4 text-break kb-businessplan-section-cell">
                                                                                                        <span className="kb-businessplan-ibcode">
                                                                                                            {currentBudgetLine.ibCode}
                                                                                                        </span>{currentBudgetLine.budgetLineName}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-1 text-right kb-businessplan-section-cell">
                                                                                                            {prettyPrint(currentBudgetLine.budgetLineValue) ? prettyPrint(currentBudgetLine.budgetLineValue) : 0} €
                                                                                                        </div>
                                                                                                        <div className="col-7 kb-businessplan-section-cell"></div>
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                        }
                                                                                    </React.Fragment>
                                                                                ))
                                                                            }
                                                                        </React.Fragment>
                                                                    ) : (<React.Fragment/>)
                                                                )
                                                            }
                                                        </React.Fragment>
                                                        <div id="financingInitialCategoryTotal"
                                                             className="row ml-0 pr-0 bg-light w-100 kb-businessplan-config-total-row">
                                                            <React.Fragment key="financingInitialCategoryTotalKey">
                                                                {
                                                                    this.state.initialBudget && this.state.initialBudget[2]
                                                                    && this.state.initialBudget[2].budgetType === this.categoryTypes[2]
                                                                    && this.state.initialBudget[2].budgetComponentTotal ?
                                                                        (
                                                                            <React.Fragment>
                                                                                <div
                                                                                    className="col-4 font-weight-bold kb-businessplan-section-cell">
                                                                                    {this.state.financingDisplay}
                                                                                </div>
                                                                                <div
                                                                                    className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                    {prettyPrint(this.state.initialBudget[2].budgetComponentTotal)} €
                                                                                </div>
                                                                                {
                                                                                    this.state.initialBudget[2].budgetComponentTotal
                                                                                    && this.state.initialBudget[2].budgetComponentTotal > 0 ? (
                                                                                        <React.Fragment>
                                                                                            <div
                                                                                                className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                                {this.formatNumber(this.state.initialBudget[2].percentage)}%
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    ) : (<React.Fragment>
                                                                                        <div
                                                                                            className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {this.formatNumber()}%
                                                                                        </div>
                                                                                    </React.Fragment>)
                                                                                }
                                                                                <div className="col-6 kb-businessplan-section-cell"></div>
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                <div
                                                                                    className="col-4 font-weight-bold kb-businessplan-section-cell">{this.state.financingDisplay}
                                                                                </div>
                                                                                <div className="col-1 font-weight-bold text-right kb-businessplan-section-cell">0 €
                                                                                </div>
                                                                                <div className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                    {this.formatNumber()}%
                                                                                </div>
                                                                                <div className="col-6 kb-businessplan-section-cell"></div>
                                                                            </React.Fragment>
                                                                        )
                                                                }
                                                            </React.Fragment>
                                                        </div>
                                                        <div id="financingInitialTotalConfigured"
                                                             className="row ml-0 pr-0 w-100">
                                                            {
                                                                this.state.initialBudget && this.state.initialBudget[2]
                                                                && this.state.initialBudget[2].totalForCategoryTotals.map(currentTotal => this.dispalyTotal(this.state.initialBudget[2].budgetType, currentTotal.categoryTotalName) && (
                                                                    <React.Fragment key={9+currentTotal.categoryTotalName}>
                                                                        {
                                                                            this.state.initialBudget[2].totalForCategoryTotals
                                                                            && this.state.initialBudget[2].totalForCategoryTotals.length > 0
                                                                            && this.state.initialBudget[2].totalForCategoryTotals.indexOf(currentTotal) ===
                                                                            this.state.initialBudget[2].totalForCategoryTotals.length -1 ?
                                                                                (
                                                                                    <React.Fragment>
                                                                                        <div
                                                                                            className="col-4 text-break kb-businessplan-config-total font-weight-bold">
                                                                                            {currentTotal.categoryTotalName}
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right">
                                                                                            {prettyPrint(currentTotal.totalForCategoryTotal)} €
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right">
                                                                                            {this.formatNumber(currentTotal.percentage)}%
                                                                                        </div>
                                                                                        <div className="col-6"></div>
                                                                                    </React.Fragment>
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <div
                                                                                            className="col-4 text-break kb-businessplan-config-total font-weight-bold kb-businessplan-section-cell">
                                                                                            {currentTotal.categoryTotalName}
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {prettyPrint(currentTotal.totalForCategoryTotal)} €
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {this.formatNumber(currentTotal.percentage)}%
                                                                                        </div>
                                                                                        <div className="col-6 kb-businessplan-section-cell"></div>
                                                                                    </React.Fragment>
                                                                                )
                                                                        }
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </CollapsiblePageSection>
                                        </div>

                                            <CollapsiblePageSection title={
                                                <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            {this.categoryTypes[3]}
                                                        </div>
                                                    </div>
                                                </React.Fragment>}
                                                                    collapsed={this.state.collapseAll ? true : false}
                                                                    onSelect={this.onSelect}
                                                                    collapseAll={this.state.collapseAll}
                                                                    showArrow={true}
                                                                    noTitlePadding={true}
                                                                    mlChildren={true}
                                                                    headingSize={3}
                                            >
                                                <div className="kb-view_categories">
                                                    <div id="investmentBudgetCategoryInitial" className="row ml-0 w-100">
                                                        <React.Fragment>
                                                            {
                                                                this.state.initialBudget && this.state.initialBudget[3]
                                                                && this.state.initialBudget[3].budgetType === this.categoryTypes[3]
                                                                && this.state.initialBudget[3].budgetComponents
                                                                && this.state.initialBudget[3].budgetComponents.map(currentComponent => currentComponent ?
                                                                    (
                                                                        <React.Fragment key={10+currentComponent.budgetComponentName}>
                                                                            <div
                                                                                className="col-4 text-break font-weight-bold kb-businessplan-section-cell">
                                                                                {currentComponent.budgetComponentName}
                                                                            </div>
                                                                            <div
                                                                                className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                {prettyPrint(currentComponent.budgetComponentValue) ? prettyPrint(currentComponent.budgetComponentValue) : 0} €
                                                                            </div>
                                                                            {
                                                                                currentComponent.budgetComponentPercentage ? (
                                                                                    <div
                                                                                        className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                        {this.formatNumber(currentComponent.budgetComponentPercentage)}%
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                        {this.formatNumber()}%
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            <div className="col-6 kb-businessplan-section-cell"></div>
                                                                            {
                                                                                currentComponent.budgetLines
                                                                                && currentComponent.budgetLines.map(currentBudgetLine => (
                                                                                    <React.Fragment key={11+currentBudgetLine.budgetLineName}>
                                                                                        {
                                                                                            this.state.initialBudget[3].budgetComponents.indexOf(currentComponent)
                                                                                            === this.state.initialBudget[3].budgetComponents.length -1
                                                                                            && currentComponent.budgetLines
                                                                                            && currentComponent.budgetLines.length > 0
                                                                                            && currentComponent.budgetLines.indexOf(currentBudgetLine) ===
                                                                                            currentComponent.budgetLines.length -1 ?
                                                                                                (
                                                                                                    <React.Fragment>
                                                                                                        <div
                                                                                                            className="col-4 text-break">
                                                                                                        <span className="kb-businessplan-ibcode">
                                                                                                            {currentBudgetLine.ibCode}
                                                                                                        </span>{currentBudgetLine.budgetLineName}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-1 text-right">
                                                                                                            {prettyPrint(currentBudgetLine.budgetLineValue) ? prettyPrint(currentBudgetLine.budgetLineValue) : 0} €
                                                                                                        </div>
                                                                                                        <div className="col-7"></div>
                                                                                                    </React.Fragment>
                                                                                                ) :
                                                                                                (
                                                                                                    <React.Fragment>
                                                                                                        <div
                                                                                                            className="col-4 text-break kb-businessplan-section-cell">
                                                                                                        <span className="kb-businessplan-ibcode">
                                                                                                            {currentBudgetLine.ibCode}
                                                                                                        </span>{currentBudgetLine.budgetLineName}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-1 text-right kb-businessplan-section-cell">
                                                                                                            {prettyPrint(currentBudgetLine.budgetLineValue) ? prettyPrint(currentBudgetLine.budgetLineValue) : 0} €
                                                                                                        </div>
                                                                                                        <div className="col-7 kb-businessplan-section-cell"></div>
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                        }
                                                                                    </React.Fragment>
                                                                                ))
                                                                            }
                                                                        </React.Fragment>
                                                                    ) : (<React.Fragment/>)
                                                                )
                                                            }
                                                        </React.Fragment>
                                                        <div id="investmentInitialCategoryTotal"
                                                             className="row ml-0 pr-0 bg-light w-100 kb-businessplan-config-total-row">
                                                            <React.Fragment key="investmentInitialCategoryTotalKey">
                                                                {
                                                                    this.state.initialBudget && this.state.initialBudget[3]
                                                                    && this.state.initialBudget[3].budgetType === this.categoryTypes[3] ?
                                                                        (
                                                                            <React.Fragment>
                                                                                <div
                                                                                    className="col-4 font-weight-bold kb-businessplan-section-cell">
                                                                                    {this.state.investmentDisplay}</div>
                                                                                <div
                                                                                    className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                    {prettyPrint(this.state.initialBudget[3].budgetComponentTotal)} €
                                                                                </div>
                                                                                {
                                                                                    this.state.initialBudget[3].budgetComponentTotal
                                                                                    && this.state.initialBudget[3].budgetComponentTotal > 0 ? (
                                                                                        <React.Fragment>
                                                                                            <div
                                                                                                className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                                {this.formatNumber(this.state.initialBudget[3].percentage)}%
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    ) : (<React.Fragment>
                                                                                        <div
                                                                                            className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {this.formatNumber()}%
                                                                                        </div>
                                                                                    </React.Fragment>)
                                                                                }
                                                                                <div className="col-6 kb-businessplan-section-cell"></div>
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                <div
                                                                                    className="col-4 font-weight-bold kb-businessplan-section-cell">
                                                                                    {this.state.investmentDisplay}
                                                                                </div>
                                                                                <div className="col-1 font-weight-bold text-right kb-businessplan-section-cell">0 €
                                                                                </div>
                                                                                <div className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                    {this.formatNumber()}%
                                                                                </div>
                                                                                <div className="col-6 kb-businessplan-section-cell"></div>
                                                                            </React.Fragment>
                                                                        )
                                                                }
                                                            </React.Fragment>
                                                        </div>
                                                        <div id="investmentInitialTotalConfigured"
                                                             className="row ml-0 pr-0 w-100">
                                                            {
                                                                this.state.initialBudget && this.state.initialBudget[3]
                                                                && this.state.initialBudget[3].totalForCategoryTotals.map(currentTotal => this.dispalyTotal(this.state.initialBudget[3].budgetType, currentTotal.categoryTotalName) && (
                                                                    <React.Fragment key={12+currentTotal.categoryTotalName}>
                                                                        {
                                                                            this.state.initialBudget[3].totalForCategoryTotals
                                                                            && this.state.initialBudget[3].totalForCategoryTotals.length > 0
                                                                            && this.state.initialBudget[3].totalForCategoryTotals.indexOf(currentTotal) ===
                                                                            this.state.initialBudget[3].totalForCategoryTotals.length -1 ?
                                                                                (
                                                                                    <React.Fragment>
                                                                                        <div
                                                                                            className="col-4 text-break kb-businessplan-config-total font-weight-bold">
                                                                                            {currentTotal.categoryTotalName}
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right">
                                                                                            {prettyPrint(currentTotal.totalForCategoryTotal)} €
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right">
                                                                                            {this.formatNumber(currentTotal.percentage)}%
                                                                                        </div>
                                                                                        <div className="col-6"></div>
                                                                                    </React.Fragment>
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <div
                                                                                            className="col-4 text-break kb-businessplan-config-total font-weight-bold kb-businessplan-section-cell">
                                                                                            {currentTotal.categoryTotalName}
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {prettyPrint(currentTotal.totalForCategoryTotal)} €
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {this.formatNumber(currentTotal.percentage)}%
                                                                                        </div>
                                                                                        <div className="col-6 kb-businessplan-section-cell"></div>
                                                                                    </React.Fragment>
                                                                                )
                                                                        }
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </CollapsiblePageSection>
                                    </Tab>
                                    <Tab className="kb-tabs-nav mr-2" eventKey={executionBudget.type.toLowerCase()}
                                         title={executionBudget.displayValue} key={executionBudget.type.toLowerCase()}>
                                        <div className="kb-businessplan-section-border">
                                            <CollapsiblePageSection title={
                                                <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            {this.categoryTypes[0]}
                                                        </div>
                                                    </div>
                                                </React.Fragment>}
                                                                    collapsed={this.state.collapseAll ? true : false}
                                                                    onSelect={this.onSelect}
                                                                    collapseAll={this.state.collapseAll}
                                                                    showArrow={true}
                                                                    noTitlePadding={true}
                                                                    mlChildren={true}
                                                                    headingSize={3}
                                            >
                                                <div className="kb-view_categories">
                                                    <div id="costBudgetCategoryExec" className="row ml-0 w-100">
                                                        <React.Fragment>
                                                            {
                                                                this.state.executionBudget && this.state.executionBudget[0]
                                                                && this.state.executionBudget[0].budgetType === this.categoryTypes[0]
                                                                && this.state.executionBudget[0].budgetComponents
                                                                && this.state.executionBudget[0].budgetComponents.map(currentComponent => currentComponent ?
                                                                    (
                                                                        <React.Fragment key={13+currentComponent.budgetComponentName}>
                                                                            <div
                                                                                className="col-4 text-break font-weight-bold kb-businessplan-section-cell">
                                                                                {currentComponent.budgetComponentName}
                                                                            </div>
                                                                            <div
                                                                                className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                {prettyPrint(currentComponent.budgetComponentValue) ? prettyPrint(currentComponent.budgetComponentValue) : 0} €
                                                                            </div>
                                                                            {
                                                                                currentComponent.budgetComponentPercentage ? (
                                                                                    <div
                                                                                        className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                        {this.formatNumber(currentComponent.budgetComponentPercentage)}%
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                        {this.formatNumber()}%
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            <div className="col-6 kb-businessplan-section-cell"></div>
                                                                            {
                                                                                currentComponent.budgetLines
                                                                                && currentComponent.budgetLines.map(currentBudgetLine => (
                                                                                    <React.Fragment key={14+currentBudgetLine.budgetLineName}>
                                                                                        {
                                                                                            this.state.executionBudget[0].budgetComponents.indexOf(currentComponent)
                                                                                            === this.state.executionBudget[0].budgetComponents.length -1
                                                                                            && currentComponent.budgetLines
                                                                                            && currentComponent.budgetLines.length > 0
                                                                                            && currentComponent.budgetLines.indexOf(currentBudgetLine) ===
                                                                                            currentComponent.budgetLines.length -1 ?
                                                                                                (
                                                                                                    <React.Fragment>
                                                                                                        <div
                                                                                                            className="col-4 text-break">
                                                                                                        <span className="kb-businessplan-ibcode">
                                                                                                            {currentBudgetLine.ibCode}
                                                                                                        </span>{currentBudgetLine.budgetLineName}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-1 text-right">
                                                                                                            {prettyPrint(currentBudgetLine.budgetLineValue) ? prettyPrint(currentBudgetLine.budgetLineValue) : 0} €
                                                                                                        </div>
                                                                                                        <div className="col-7"></div>
                                                                                                    </React.Fragment>
                                                                                                ) :
                                                                                                (
                                                                                                    <React.Fragment>
                                                                                                        <div
                                                                                                            className="col-4 text-break kb-businessplan-section-cell">
                                                                                                        <span className="kb-businessplan-ibcode">
                                                                                                            {currentBudgetLine.ibCode}
                                                                                                        </span>{currentBudgetLine.budgetLineName}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-1 text-right kb-businessplan-section-cell">
                                                                                                            {prettyPrint(currentBudgetLine.budgetLineValue) ? prettyPrint(currentBudgetLine.budgetLineValue) : 0} €
                                                                                                        </div>
                                                                                                        <div className="col-7 kb-businessplan-section-cell"></div>
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                        }
                                                                                    </React.Fragment>
                                                                                ))
                                                                            }
                                                                        </React.Fragment>
                                                                    ) : (<React.Fragment/>)
                                                                )
                                                            }
                                                        </React.Fragment>
                                                        <div className="col-2 pr-0 pr-0"/>
                                                        <div id="costExecutionCategoryTotal"
                                                             className="row ml-0 pr-0 bg-light w-100 kb-businessplan-config-total-row">
                                                            <React.Fragment key="costExecutionCategoryTotalKey">
                                                                {
                                                                    this.state.executionBudget && this.state.executionBudget[0]
                                                                    && this.state.executionBudget[0].budgetType === this.categoryTypes[0] ?
                                                                        (
                                                                            <React.Fragment>
                                                                                <div
                                                                                    className="col-4 font-weight-bold kb-businessplan-section-cell">
                                                                                    {this.state.costDisplay}
                                                                                </div>
                                                                                <div
                                                                                    className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                    {prettyPrint(this.state.executionBudget[0].budgetComponentTotal)} €
                                                                                </div>
                                                                                {
                                                                                    this.state.executionBudget[0].budgetComponentTotal
                                                                                    && this.state.executionBudget[0].budgetComponentTotal > 0 ? (
                                                                                        <React.Fragment>
                                                                                            <div
                                                                                                className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                                {this.formatNumber(this.state.executionBudget[0].percentage)}%
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    ) : (<React.Fragment>
                                                                                        <div
                                                                                            className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {this.formatNumber()}%
                                                                                        </div>
                                                                                    </React.Fragment>)
                                                                                }
                                                                                <div className="col-6 kb-businessplan-section-cell"></div>
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                <div
                                                                                    className="col-4 font-weight-bold kb-businessplan-section-cell">
                                                                                    {this.state.costDisplay}
                                                                                </div>
                                                                                <div className="col-1 font-weight-bold text-right kb-businessplan-section-cell">0 €
                                                                                </div>
                                                                                <div className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                    {this.formatNumber()}%
                                                                                </div>
                                                                                <div className="col-6"></div>
                                                                            </React.Fragment>
                                                                        )
                                                                }
                                                            </React.Fragment>
                                                        </div>
                                                        <div id="costExecutionTotalConfigured"
                                                             className="row ml-0 pr-0 w-100">
                                                            {
                                                                this.state.executionBudget && this.state.executionBudget[0]
                                                                && this.state.executionBudget[0].totalForCategoryTotals.map(currentTotal => {
                                                                    return this.dispalyTotal(this.state.executionBudget[0].budgetType, currentTotal.categoryTotalName) ?  (
                                                                    <React.Fragment>
                                                                        {
                                                                            this.state.executionBudget[0].totalForCategoryTotals
                                                                            && this.state.executionBudget[0].totalForCategoryTotals.length > 0
                                                                            && this.state.executionBudget[0].totalForCategoryTotals.indexOf(currentTotal) ===
                                                                            this.state.executionBudget[0].totalForCategoryTotals.length -1 ?
                                                                                (
                                                                                    <React.Fragment key={15+currentTotal.categoryTotalName}>
                                                                                        <div
                                                                                            className="col-4 text-break kb-businessplan-config-total font-weight-bold">
                                                                                            {currentTotal.categoryTotalName}
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right">
                                                                                            {prettyPrint(currentTotal.totalForCategoryTotal)} €
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right">
                                                                                            {this.formatNumber(currentTotal.percentage)}%
                                                                                        </div>
                                                                                        <div className="col-6"></div>
                                                                                    </React.Fragment>
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <div
                                                                                            className="col-4 text-break kb-businessplan-config-total font-weight-bold kb-businessplan-section-cell">
                                                                                            {currentTotal.categoryTotalName}
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {prettyPrint(currentTotal.totalForCategoryTotal)} €
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {this.formatNumber(currentTotal.percentage)}%
                                                                                        </div>
                                                                                        <div className="col-6 kb-businessplan-section-cell"></div>
                                                                                    </React.Fragment>
                                                                                )
                                                                        }
                                                                    </React.Fragment>
                                                                ) : <React.Fragment></React.Fragment>;})
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </CollapsiblePageSection>
                                        </div>

                                        <div className="kb-businessplan-section-border">
                                            <CollapsiblePageSection title={
                                                <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            {this.categoryTypes[1]}
                                                        </div>
                                                    </div>
                                                </React.Fragment>}
                                                                    collapsed={this.state.collapseAll ? true : false}
                                                                    onSelect={this.onSelect}
                                                                    collapseAll={this.state.collapseAll}
                                                                    showArrow={true}
                                                                    noTitlePadding={true}
                                                                    mlChildren={true}
                                                                    headingSize={3}
                                            >
                                                <div className="kb-view_categories">
                                                    <div id="incomeBudgetCategoryExec" className="row ml-0 w-100">
                                                        <React.Fragment>
                                                            {
                                                                this.state.executionBudget && this.state.executionBudget[1]
                                                                && this.state.executionBudget[1].budgetType === this.categoryTypes[1]
                                                                && this.state.executionBudget[1].budgetComponents
                                                                && this.state.executionBudget[1].budgetComponents.map(currentComponent => currentComponent ?
                                                                    (
                                                                        <React.Fragment key={16+currentComponent.budgetComponentName}>
                                                                            <div
                                                                                className="col-4 text-break font-weight-bold kb-businessplan-section-cell">
                                                                                {currentComponent.budgetComponentName}
                                                                            </div>
                                                                            <div
                                                                                className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                {prettyPrint(currentComponent.budgetComponentValue) ? prettyPrint(currentComponent.budgetComponentValue) : 0} €
                                                                            </div>
                                                                            {
                                                                                currentComponent.budgetComponentPercentage ? (
                                                                                    <div
                                                                                        className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                        {this.formatNumber(currentComponent.budgetComponentPercentage)}%
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                        {this.formatNumber()}%
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            <div className="col-6 kb-businessplan-section-cell"></div>
                                                                            {
                                                                                currentComponent.budgetLines
                                                                                && currentComponent.budgetLines.map(currentBudgetLine => (
                                                                                    <React.Fragment key={17+currentBudgetLine.budgetLineName}>
                                                                                        {
                                                                                            this.state.executionBudget[1].budgetComponents.indexOf(currentComponent)
                                                                                            === this.state.executionBudget[1].budgetComponents.length -1
                                                                                            && currentComponent.budgetLines
                                                                                            && currentComponent.budgetLines.length > 0
                                                                                            && currentComponent.budgetLines.indexOf(currentBudgetLine) ===
                                                                                            currentComponent.budgetLines.length -1 ?
                                                                                                (
                                                                                                    <React.Fragment>
                                                                                                        <div
                                                                                                            className="col-4 text-break">
                                                                                                        <span className="kb-businessplan-ibcode">
                                                                                                            {currentBudgetLine.ibCode}
                                                                                                        </span>{currentBudgetLine.budgetLineName}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-1 text-right">
                                                                                                            {prettyPrint(currentBudgetLine.budgetLineValue) ? prettyPrint(currentBudgetLine.budgetLineValue) : 0} €
                                                                                                        </div>
                                                                                                        <div className="col-7"></div>
                                                                                                    </React.Fragment>
                                                                                                ) :
                                                                                                (
                                                                                                    <React.Fragment>
                                                                                                        <div
                                                                                                            className="col-4 text-break kb-businessplan-section-cell">
                                                                                                        <span className="kb-businessplan-ibcode">
                                                                                                            {currentBudgetLine.ibCode}
                                                                                                        </span>{currentBudgetLine.budgetLineName}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-1 text-right kb-businessplan-section-cell">
                                                                                                            {prettyPrint(currentBudgetLine.budgetLineValue) ? prettyPrint(currentBudgetLine.budgetLineValue) : 0} €
                                                                                                        </div>
                                                                                                        <div className="col-7 kb-businessplan-section-cell"></div>
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                        }
                                                                                    </React.Fragment>
                                                                                ))
                                                                            }
                                                                        </React.Fragment>
                                                                    ) : (<React.Fragment/>)
                                                                )
                                                            }
                                                        </React.Fragment>
                                                        <div className="col-2 pr-0 pr-0"/>
                                                        <div id="incomeExecutionCategoryTotal"
                                                             className="row ml-0 pr-0 bg-light w-100 kb-businessplan-config-total-row">
                                                            <React.Fragment key="incomeExecutionCategoryTotalKey">
                                                                {
                                                                    this.state.executionBudget && this.state.executionBudget[1]
                                                                    && this.state.executionBudget[1].budgetType === this.categoryTypes[1] ?
                                                                        (
                                                                            <React.Fragment>
                                                                                <div
                                                                                    className="col-4 font-weight-bold kb-businessplan-section-cell">
                                                                                    {this.state.incomeDisplay}
                                                                                </div>
                                                                                <div
                                                                                    className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                    {prettyPrint(this.state.executionBudget[1].budgetComponentTotal)} €
                                                                                </div>
                                                                                {
                                                                                    this.state.executionBudget[1].budgetComponentTotal
                                                                                    && this.state.executionBudget[1].budgetComponentTotal > 0 ? (
                                                                                        <React.Fragment>
                                                                                            <div
                                                                                                className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                                {this.formatNumber(this.state.executionBudget[1].percentage)}%
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    ) : (<React.Fragment>
                                                                                        <div
                                                                                            className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {this.formatNumber()}%
                                                                                        </div>
                                                                                    </React.Fragment>)
                                                                                }
                                                                                <div className="col-6 kb-businessplan-section-cell"></div>
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                <div
                                                                                    className="col-4 font-weight-bold kb-businessplan-section-cell">
                                                                                    {this.state.incomeDisplay}
                                                                                </div>
                                                                                <div className="col-1 font-weight-bold text-right kb-businessplan-section-cell">0 €
                                                                                </div>
                                                                                <div className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                    {this.formatNumber()}%
                                                                                </div>
                                                                                <div className="col-6 kb-businessplan-section-cell"></div>
                                                                            </React.Fragment>
                                                                        )
                                                                }
                                                            </React.Fragment>
                                                        </div>
                                                        <div id="incomeExecutionTotalConfigured"
                                                             className="row ml-0 pr-0 w-100">
                                                            {
                                                                this.state.executionBudget && this.state.executionBudget[1]
                                                                && this.state.executionBudget[1].totalForCategoryTotals.map(currentTotal => this.dispalyTotal(this.state.executionBudget[1].budgetType, currentTotal.categoryTotalName) &&  (
                                                                    <React.Fragment key={18+currentTotal.categoryTotalName}>
                                                                        {
                                                                            this.state.executionBudget[1].totalForCategoryTotals
                                                                            && this.state.executionBudget[1].totalForCategoryTotals.length > 0
                                                                            && this.state.executionBudget[1].totalForCategoryTotals.indexOf(currentTotal) ===
                                                                            this.state.executionBudget[1].totalForCategoryTotals.length -1 ?
                                                                                (
                                                                                    <React.Fragment>
                                                                                        <div
                                                                                            className="col-4 text-break kb-businessplan-config-total font-weight-bold">
                                                                                            {currentTotal.categoryTotalName}
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right">
                                                                                            {prettyPrint(currentTotal.totalForCategoryTotal)} €
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right">
                                                                                            {this.formatNumber(currentTotal.percentage)}%
                                                                                        </div>
                                                                                        <div className="col-6"></div>
                                                                                    </React.Fragment>
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <div
                                                                                            className="col-4 text-break kb-businessplan-config-total font-weight-bold kb-businessplan-section-cell">
                                                                                            {currentTotal.categoryTotalName}
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {prettyPrint(currentTotal.totalForCategoryTotal)} €
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {this.formatNumber(currentTotal.percentage)}%
                                                                                        </div>
                                                                                        <div className="col-6 kb-businessplan-section-cell"></div>
                                                                                    </React.Fragment>
                                                                                )
                                                                        }
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </CollapsiblePageSection>
                                        </div>

                                        <div className="kb-businessplan-section-border">
                                            <CollapsiblePageSection title={
                                                <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            {this.categoryTypes[2]}
                                                        </div>
                                                    </div>
                                                </React.Fragment>}
                                                                    collapsed={this.state.collapseAll ? true : false}
                                                                    onSelect={this.onSelect}
                                                                    collapseAll={this.state.collapseAll}
                                                                    showArrow={true}
                                                                    noTitlePadding={true}
                                                                    mlChildren={true}
                                                                    headingSize={3}
                                            >
                                                <div className="kb-view_categories">
                                                    <div id="financingBudgetCategoryExec" className="row ml-0 w-100">
                                                        <React.Fragment>
                                                            {
                                                                this.state.executionBudget && this.state.executionBudget[2]
                                                                && this.state.executionBudget[2].budgetType === this.categoryTypes[2]
                                                                && this.state.executionBudget[2].budgetComponents
                                                                && this.state.executionBudget[2].budgetComponents.map(currentComponent => currentComponent ?
                                                                    (
                                                                        <React.Fragment key={19+currentComponent.budgetComponentName}>
                                                                            <div
                                                                                className="col-4 text-break font-weight-bold kb-businessplan-section-cell">
                                                                                {currentComponent.budgetComponentName}
                                                                            </div>
                                                                            <div
                                                                                className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                {prettyPrint(currentComponent.budgetComponentValue) ? prettyPrint(currentComponent.budgetComponentValue) : 0} €
                                                                            </div>
                                                                            {
                                                                                currentComponent.budgetComponentPercentage ? (
                                                                                    <div
                                                                                        className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                        {this.formatNumber(currentComponent.budgetComponentPercentage)}%
                                                                                    </div>
                                                                                ) : (
                                                                                    <div
                                                                                        className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                        {this.formatNumber()}%
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            <div className="col-6 kb-businessplan-section-cell"></div>
                                                                            {
                                                                                currentComponent.budgetLines
                                                                                && currentComponent.budgetLines.map(currentBudgetLine => (
                                                                                    <React.Fragment key={20+currentBudgetLine.budgetLineName}>
                                                                                        {
                                                                                            this.state.executionBudget[2].budgetComponents.indexOf(currentComponent)
                                                                                            === this.state.executionBudget[2].budgetComponents.length -1
                                                                                            && currentComponent.budgetLines
                                                                                            && currentComponent.budgetLines.length > 0
                                                                                            && currentComponent.budgetLines.indexOf(currentBudgetLine) ===
                                                                                            currentComponent.budgetLines.length -1 ?
                                                                                                (
                                                                                                    <React.Fragment>
                                                                                                        <div
                                                                                                            className="col-4 text-break">
                                                                                                        <span className="kb-businessplan-ibcode">
                                                                                                            {currentBudgetLine.ibCode}
                                                                                                        </span>{currentBudgetLine.budgetLineName}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-1 text-right">
                                                                                                            {prettyPrint(currentBudgetLine.budgetLineValue) ? prettyPrint(currentBudgetLine.budgetLineValue) : 0} €
                                                                                                        </div>
                                                                                                        <div className="col-7"></div>
                                                                                                    </React.Fragment>
                                                                                                ) :
                                                                                                (
                                                                                                    <React.Fragment>
                                                                                                        <div
                                                                                                            className="col-4 text-break kb-businessplan-section-cell">
                                                                                                        <span className="kb-businessplan-ibcode">
                                                                                                            {currentBudgetLine.ibCode}
                                                                                                        </span>{currentBudgetLine.budgetLineName}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-1 text-right kb-businessplan-section-cell">
                                                                                                            {prettyPrint(currentBudgetLine.budgetLineValue) ? prettyPrint(currentBudgetLine.budgetLineValue) : 0} €
                                                                                                        </div>
                                                                                                        <div className="col-7 kb-businessplan-section-cell"></div>
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                        }
                                                                                    </React.Fragment>
                                                                                ))
                                                                            }
                                                                        </React.Fragment>
                                                                    ) : (<React.Fragment/>)
                                                                )
                                                            }
                                                        </React.Fragment>
                                                        <div className="col-2 pr-0 pr-0"/>
                                                        <div id="financingExecutionCategoryTotal"
                                                             className="row ml-0 pr-0 bg-light w-100 kb-businessplan-config-total-row">
                                                            <React.Fragment key="financingExecutionCategoryTotalKey">
                                                                {
                                                                    this.state.executionBudget && this.state.executionBudget[2]
                                                                    && this.state.executionBudget[2].budgetType === this.categoryTypes[2] ?
                                                                        (
                                                                            <React.Fragment>
                                                                                <div
                                                                                    className="col-4 font-weight-bold kb-businessplan-section-cell">
                                                                                    {this.state.financingDisplay}
                                                                                </div>
                                                                                <div
                                                                                    className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                    {prettyPrint(this.state.executionBudget[2].budgetComponentTotal)} €
                                                                                </div>
                                                                                {
                                                                                    this.state.executionBudget[2].budgetComponentTotal
                                                                                    && this.state.executionBudget[2].budgetComponentTotal > 0 ? (
                                                                                        <React.Fragment>
                                                                                            <div
                                                                                                className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                                {this.formatNumber(this.state.executionBudget[2].percentage)}%
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    ) : (<React.Fragment>
                                                                                        <div
                                                                                            className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {this.formatNumber()}%
                                                                                        </div>
                                                                                    </React.Fragment>)
                                                                                }
                                                                                <div className="col-6 kb-businessplan-section-cell"></div>
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                <div
                                                                                    className="col-4 font-weight-bold kb-businessplan-section-cell">
                                                                                    {this.state.financingDisplay}
                                                                                </div>
                                                                                <div className="col-1 font-weight-bold text-right kb-businessplan-section-cell">0 €
                                                                                </div>
                                                                                <div className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                    {this.formatNumber()}%
                                                                                </div>
                                                                                <div className="col-6 kb-businessplan-section-cell"></div>
                                                                            </React.Fragment>
                                                                        )
                                                                }
                                                            </React.Fragment>
                                                        </div>
                                                        <div id="financingExecutionTotalConfigured"
                                                             className="row ml-0 pr-0 w-100">
                                                            {
                                                                this.state.executionBudget && this.state.executionBudget[2]
                                                                && this.state.executionBudget[2].totalForCategoryTotals.map(currentTotal => this.dispalyTotal(this.state.executionBudget[2].budgetType, currentTotal.categoryTotalName) && (
                                                                    <React.Fragment key={21+currentTotal.categoryTotalName}>
                                                                        {
                                                                            this.state.executionBudget[2].totalForCategoryTotals
                                                                            && this.state.executionBudget[2].totalForCategoryTotals.length > 0
                                                                            && this.state.executionBudget[2].totalForCategoryTotals.indexOf(currentTotal) ===
                                                                            this.state.executionBudget[2].totalForCategoryTotals.length -1 ?
                                                                                (
                                                                                    <React.Fragment>
                                                                                        <div
                                                                                            className="col-4 text-break kb-businessplan-config-total font-weight-bold">
                                                                                            {currentTotal.categoryTotalName}
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right">
                                                                                            {prettyPrint(currentTotal.totalForCategoryTotal)} €
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right">
                                                                                            {this.formatNumber(currentTotal.percentage)}%
                                                                                        </div>
                                                                                        <div className="col-6"></div>
                                                                                    </React.Fragment>
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <div
                                                                                            className="col-4 text-break kb-businessplan-config-total font-weight-bold kb-businessplan-section-cell">
                                                                                            {currentTotal.categoryTotalName}
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {prettyPrint(currentTotal.totalForCategoryTotal)} €
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-1 kb-businessplan-config-total font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {this.formatNumber(currentTotal.percentage)}%
                                                                                        </div>
                                                                                        <div className="col-6 kb-businessplan-section-cell"></div>
                                                                                    </React.Fragment>
                                                                                )
                                                                        }
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </CollapsiblePageSection>
                                        </div>

                                        <CollapsiblePageSection title={
                                            <React.Fragment>
                                                <div className="row">
                                                    <div className="col-12">
                                                        {this.categoryTypes[3]}
                                                    </div>
                                                </div>
                                            </React.Fragment>}
                                                                collapsed={this.state.collapseAll ? true : false}
                                                                onSelect={this.onSelect}
                                                                collapseAll={this.state.collapseAll}
                                                                showArrow={true}
                                                                noTitlePadding={true}
                                                                mlChildren={true}
                                                                headingSize={3}
                                        >
                                            <div className="kb-view_categories">
                                                <div id="investmentBudgetCategoryExec" className="row ml-0 w-100">
                                                    <React.Fragment>
                                                        {
                                                            this.state.executionBudget && this.state.executionBudget[3]
                                                            && this.state.executionBudget[3].budgetType === this.categoryTypes[3]
                                                            && this.state.executionBudget[3].budgetComponents
                                                            && this.state.executionBudget[3].budgetComponents.map(currentComponent => currentComponent ?
                                                                (
                                                                    <React.Fragment key={22+currentComponent.budgetComponentName}>
                                                                        <div
                                                                            className="col-4 text-break font-weight-bold kb-businessplan-section-cell">
                                                                            {currentComponent.budgetComponentName}
                                                                        </div>
                                                                        <div
                                                                            className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                            {prettyPrint(currentComponent.budgetComponentValue) ? prettyPrint(currentComponent.budgetComponentValue) : 0} €
                                                                        </div>
                                                                        {
                                                                            currentComponent.budgetComponentPercentage ? (
                                                                                <div
                                                                                    className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                    {this.formatNumber(currentComponent.budgetComponentPercentage)}%
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                    {this.formatNumber()}%
                                                                                </div>
                                                                            )
                                                                        }
                                                                        <div className="col-6 kb-businessplan-section-cell"></div>
                                                                        {
                                                                            currentComponent.budgetLines
                                                                            && currentComponent.budgetLines.map(currentBudgetLine => (
                                                                                <React.Fragment key={23+currentBudgetLine.budgetLineName}>
                                                                                    {
                                                                                        this.state.executionBudget[3].budgetComponents.indexOf(currentComponent)
                                                                                        === this.state.executionBudget[3].budgetComponents.length -1
                                                                                        && currentComponent.budgetLines
                                                                                        && currentComponent.budgetLines.length > 0
                                                                                        && currentComponent.budgetLines.indexOf(currentBudgetLine) ===
                                                                                        currentComponent.budgetLines.length -1 ?
                                                                                            (
                                                                                                <React.Fragment>
                                                                                                    <div
                                                                                                        className="col-4 text-break">
                                                                                                        <span className="kb-businessplan-ibcode">
                                                                                                            {currentBudgetLine.ibCode}
                                                                                                        </span>{currentBudgetLine.budgetLineName}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-1 text-right">
                                                                                                        {prettyPrint(currentBudgetLine.budgetLineValue) ? prettyPrint(currentBudgetLine.budgetLineValue) : 0} €
                                                                                                    </div>
                                                                                                    <div className="col-7"></div>
                                                                                                </React.Fragment>
                                                                                            ) :
                                                                                            (
                                                                                                <React.Fragment>
                                                                                                    <div
                                                                                                        className="col-4 text-break kb-businessplan-section-cell">
                                                                                                        <span className="kb-businessplan-ibcode">
                                                                                                            {currentBudgetLine.ibCode}
                                                                                                        </span>{currentBudgetLine.budgetLineName}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-1 text-right kb-businessplan-section-cell">
                                                                                                        {prettyPrint(currentBudgetLine.budgetLineValue) ? prettyPrint(currentBudgetLine.budgetLineValue) : 0} €
                                                                                                    </div>
                                                                                                    <div className="col-7 kb-businessplan-section-cell"></div>
                                                                                                </React.Fragment>
                                                                                            )
                                                                                    }
                                                                                </React.Fragment>
                                                                            ))
                                                                        }
                                                                    </React.Fragment>
                                                                ) : (<React.Fragment/>)
                                                            )
                                                        }
                                                    </React.Fragment>
                                                    <div className="col-2 pr-0 pr-0"/>
                                                    <div id="investmentExecutionCategoryTotal"
                                                         className="row ml-0 pr-0 bg-light w-100 kb-businessplan-config-total-row">
                                                        <React.Fragment key="investmentExecutionCategoryTotalKey">
                                                            {
                                                                this.state.executionBudget && this.state.executionBudget[3]
                                                                && this.state.executionBudget[3].budgetType === this.categoryTypes[3] ?
                                                                    (
                                                                        <React.Fragment>
                                                                            <div
                                                                                className="col-4 font-weight-bold kb-businessplan-section-cell">
                                                                                {this.state.investmentDisplay}
                                                                            </div>
                                                                            <div
                                                                                className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                {prettyPrint(this.state.executionBudget[3].budgetComponentTotal)} €
                                                                            </div>
                                                                            {
                                                                                this.state.executionBudget[3].budgetComponentTotal
                                                                                && this.state.executionBudget[3].budgetComponentTotal > 0 ? (
                                                                                    <React.Fragment>
                                                                                        <div
                                                                                            className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                            {this.formatNumber(this.state.executionBudget[3].percentage)}%
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                ) : (<React.Fragment>
                                                                                    <div
                                                                                        className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                        {this.formatNumber()}%
                                                                                    </div>
                                                                                </React.Fragment>)
                                                                            }
                                                                            <div className="col-6 kb-businessplan-section-cell"></div>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        <React.Fragment>
                                                                            <div
                                                                                className="col-4 font-weight-bold kb-businessplan-section-cell">
                                                                                {this.state.investmentDisplay}
                                                                            </div>
                                                                            <div className="col-1 font-weight-bold text-right kb-businessplan-section-cell">0 €
                                                                            </div>
                                                                            <div className="col-1 font-weight-bold text-right kb-businessplan-section-cell">
                                                                                {this.formatNumber()}%
                                                                            </div>
                                                                            <div className="col-6 kb-businessplan-section-cell"></div>
                                                                        </React.Fragment>
                                                                    )
                                                            }
                                                        </React.Fragment>
                                                    </div>
                                                    <div id="investmentExecutionTotalConfigured"
                                                         className="row ml-0 pr-0 w-100">
                                                        {
                                                            this.state.executionBudget && this.state.executionBudget[3]
                                                            && this.state.executionBudget[3].totalForCategoryTotals.map(currentTotal => this.dispalyTotal(this.state.executionBudget[3].budgetType, currentTotal.categoryTotalName) && (
                                                                <React.Fragment key={24+currentTotal.categoryTotalName}>
                                                                    {
                                                                        this.state.executionBudget[3].totalForCategoryTotals
                                                                        && this.state.executionBudget[3].totalForCategoryTotals.length > 0
                                                                        && this.state.executionBudget[3].totalForCategoryTotals.indexOf(currentTotal) ===
                                                                        this.state.executionBudget[3].totalForCategoryTotals.length -1 ?
                                                                            (
                                                                                <React.Fragment>
                                                                                    <div
                                                                                        className="col-4 text-break kb-businessplan-config-total font-weight-bold">
                                                                                        {currentTotal.categoryTotalName}
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-1 kb-businessplan-config-total font-weight-bold text-right">
                                                                                        {prettyPrint(currentTotal.totalForCategoryTotal)} €
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-1 kb-businessplan-config-total font-weight-bold text-right">
                                                                                        {this.formatNumber(currentTotal.percentage)}%
                                                                                    </div>
                                                                                    <div className="col-6"></div>
                                                                                </React.Fragment>
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <div
                                                                                        className="col-4 text-break kb-businessplan-config-total font-weight-bold kb-businessplan-section-cell">
                                                                                        {currentTotal.categoryTotalName}
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-1 kb-businessplan-config-total font-weight-bold text-right kb-businessplan-section-cell">
                                                                                        {prettyPrint(currentTotal.totalForCategoryTotal)} €
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-1 kb-businessplan-config-total font-weight-bold text-right kb-businessplan-section-cell">
                                                                                        {this.formatNumber(currentTotal.percentage)}%
                                                                                    </div>
                                                                                    <div className="col-6 kb-businessplan-section-cell"></div>
                                                                                </React.Fragment>
                                                                            )
                                                                    }
                                                                </React.Fragment>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </CollapsiblePageSection>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

BudgetBusinessPlanComponentContainer.propTypes = {
    projectId: PropTypes.func.isRequired,
    categoryType: PropTypes.shape({
        displayValue: PropTypes.string,
        type: PropTypes.string
    })
};

export default BudgetBusinessPlanComponentContainer;