import {
    genericDelete,
    genericPost,
    genericPut,
    genericGet,
    genericPostWithDownload,
    genericPatch
} from "../../../components-shared/fetch-wrapper";

const invoiceManagementEndpoint = '/invoice-management';
const invoicesEndpoint = '/invoices';
const getInvoicesByFilterEndpoint = invoiceManagementEndpoint + '/search';
const getInvoicesForContractEndpoint = invoiceManagementEndpoint + '/contract';
const invoicesExcelExportEndpoint = '/excel';

export function getInvoicesForProject(projectId) {
    return genericGet(invoicesEndpoint + "/" + projectId);
}

export function exportInvoices(projectId, data) {
    let url = invoicesEndpoint + "/" + projectId + invoicesExcelExportEndpoint;
    let headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/octet-stream',
        'Cache-Control': 'no-cache, no-store, must-revalidate'
    }

    return genericPostWithDownload(url, data, headers);
}

function setDates(data) {

    data.budgetDate = data.budgetDate ? {
        year: data.budgetDate.year,
        month: data.budgetDate.month + 1,
        day: data.budgetDate.day
    } : null;
    data.emissionDate = data.emissionDate ? {
        year: data.emissionDate.year,
        month: data.emissionDate.month + 1,
        day: data.emissionDate.day
    } : null;
    data.maturityDate = data.maturityDate ? {
        year: data.maturityDate.year,
        month: data.maturityDate.month + 1,
        day: data.maturityDate.day
    } : null


    data.paymentDate = data.paymentDate ? {
        year: data.paymentDate.year,
        month: data.paymentDate.month + 1,
        day: data.paymentDate.day
    } : null;

}

export function saveInvoice(data) {
    setDates(data);
    return genericPost(invoiceManagementEndpoint, data);
}

export function invoiceNeedsForecastConfirmation(data) {
    return genericPost(invoiceManagementEndpoint + "/invoice-needs-forecast-confirmation", data);
}

export function getInvoicesByFilter(projectId, type, data) {
    return genericPost(getInvoicesByFilterEndpoint + "/" + projectId + "?invoiceType=" + type, data);
}

export function getInvoicesById(invoiceId) {
    return genericGet(invoiceManagementEndpoint + "/" + invoiceId);
}

export function getInvoicesForContract(contractId, month, year) {
    return genericGet(getInvoicesForContractEndpoint + "/" + contractId + "/" + month + "/" + year);
}

export function deleteInvoice(invoiceId, recordId) {
    const url = invoiceManagementEndpoint + "/" + invoiceId + "/records/" + recordId;

    return genericDelete(url);
}

export function updateInvoice(invoice) {
    setDates(invoice);
    return genericPut(invoiceManagementEndpoint, invoice);
}

export function patchInvoice(invoice) {
    setDates(invoice);
    return genericPatch(invoiceManagementEndpoint, invoice);
}
