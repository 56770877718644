import React from 'react';

const LineGraph = (props) => {
    return (
        <div className="container-graph-bar w-100" style={{background: props.background, height: props.height}}>
            <div className="graph-bar" style={{
                width: props.percentage <= 100 ? props.percentage + '%' : 100 + '%',
                background: props.color,
                height: props.height
            }}>
            </div>
        </div>
    );
}

export default LineGraph;
