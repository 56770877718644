import React, {Component} from "react";
//import PageSection from "../../../../components-shared/PageSection";
import PropTypes from "prop-types";
import EditTemplateForm from "../../forms/EditTemplateForm";
import {updateTemplate} from "../../actions/templateActions";
import ViewTemplateForm from "../../forms/ViewTemplateForm";

class DisplayTemplateInfoSection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            editing: false
        };

        this.toggleEditing = this.toggleEditing.bind(this);
        this.setEditFalse = this.setEditFalse.bind(this);
    }

    toggleEditing() {

        this.setState({editing: !this.state.editing}, () => {
            this.props.refreshState();
        });
    }

    setEditFalse() {
        this.setState({editing: false}, () => {
            this.props.refreshState();
        });
        this.props.cancelEdit();
    }

    render() {
        return (
           <React.Fragment>
           <div className="container-fluid py-4 px-4">
             <div className="row">
                    <div className="col-12 col-md-6 col-xl-4 pb-5">
                    <h5 className="py-3">{"Template Info for \"" + this.props.template.name + "\""}</h5>
                    {this.state.editing ?
                    <EditTemplateForm submitFunction={updateTemplate}
                                    afterSubmit={this.toggleEditing}
                                    setEditFalse={this.setEditFalse}
                                    {...this.props}
                                    />
                    :
                    <ViewTemplateForm editClickedFunc={this.toggleEditing}
                                    {...this.props}/>
                }
                </div>
                <div className="col-12 col-md-5 offset-lg-1">
                    <h5 className="py-3">How to use a template?</h5>
                    <ul className="pl-3">
                        <li className="pb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </li>
                        <li className="pb-3">Nunc vestibulum orci ac sodales dapibus. </li>
                        <li className="pb-3">Phasellus quis justo sagittis, faucibus ex sed, elementum ex. </li>
                        <li className="pb-3">Etiam ac risus ac quam viverra vulputate ac quis dolor. </li>
                        <li className="pb-3">Nam non sapien tristique, suscipit metus eget, tempus elit. </li>
                    </ul>
                </div>
            </div>
            </div>
        </React.Fragment>
            
        );
    }
}

DisplayTemplateInfoSection.propTypes = {
    template: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.string.isRequired
    }).isRequired,
    refreshState: PropTypes.func.isRequired
};

export default DisplayTemplateInfoSection;