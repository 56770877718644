import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Button, FormLabel, FormControl, FormGroup} from "react-bootstrap";
import CustomDatePicker from "../../components-shared/CustomDatePicker";
import update from "immutability-helper";
import moment from "moment";
import Pagination from "react-js-pagination";

class HistorySearchForm extends Component {

    constructor(props) {
        super(props);

        this.state = HistorySearchForm.constructStateObject();

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.handlePageChange = this.handlePageChange.bind(this);
    }

    static constructStateObject() {
        return {
            filters: HistorySearchForm.initializeFilters(),
            pageRangeDisplayed: 5,
            searched: false
        };
    }

    static initializeFilters() {
        return {
            userName: '',
            projectName: '',
            previousValue: '',
            newValue: '',
            startDate: moment().subtract(1, 'months'),
            endDate: moment().add(1, 'months'),
            page: 1,
            // stable values
            size: 10,
        }
    }

    handleChange(event) {

        this.setState({
            filters: update(this.state.filters, {
                [event.target.id]: {$set: event.target.value}
            })
        });
    };

    handleChangeStartDate(date) {

        this.setState({
            filters: update(this.state.filters, {
                startDate: {$set: date}
            })
        });
    }

    handleChangeEndDate(date) {

        this.setState({
            filters: update(this.state.filters, {
                endDate: {$set: date}
            })
        });
    }

    handleSubmit() {

        if (this.state.searched) {
            this.setState({
                filters: HistorySearchForm.initializeFilters(),
                searched: false
            }, () => {
                this.props.updateHistoryData(this.state.filters);
            });
        }
        else {
            this.setState({
                searched: true
            }, () => {
                this.props.updateHistoryData(this.state.filters);
            });
        }
    }

    /**
     * The page number must actually be decremented with 1, seeing as in the BE the pagination starts as an array, from 0.
     */
    handlePageChange(pageNumber) {

        this.setState({
            filters: update(this.state.filters, {
                page: {$set: pageNumber}
            })
        }, () => {
            this.props.updateHistoryData(this.state.filters);
        });
    }

    render() {

        const inputClassName = "history-field-width";
        const hideLabel = "w-100 text-left d-block";
        const inputFieldClassName = "w-100 mr-2";
        //const widthInput = 100 / 7;

        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit} className="form-inline">
                    <FormGroup className={inputClassName} controlId="userName">
                        <FormLabel className={hideLabel}>User Name</FormLabel>
                        <FormControl
                            type="text"
                            value={this.state.filters.userName}
                            onChange={this.handleChange}
                            className={inputFieldClassName}
                        />
                    </FormGroup>

                    <FormGroup className={inputClassName} controlId="projectName">
                        <FormLabel className={hideLabel}>Project Name</FormLabel>
                        <FormControl
                            type="text"
                            value={this.state.filters.projectName}
                            onChange={this.handleChange}
                            className={inputFieldClassName}
                        />
                    </FormGroup>

                    <FormGroup className={inputClassName} controlId="previousValue">
                        <FormLabel className={hideLabel}>Previous Value</FormLabel>
                        <FormControl
                            type="text"
                            value={this.state.filters.previousValue}
                            onChange={this.handleChange}
                            className={inputFieldClassName}
                        />
                    </FormGroup>

                    <FormGroup className={inputClassName} controlId="newValue">
                        <FormLabel className={hideLabel}>New Value</FormLabel>
                        <FormControl
                            type="text"
                            value={this.state.filters.newValue}
                            onChange={this.handleChange}
                            className={inputFieldClassName}
                        />
                    </FormGroup>

                    <FormGroup className={inputClassName} controlId="startDate">
                        <FormLabel className={hideLabel}>From Date:</FormLabel>
                        <CustomDatePicker
                            selected={this.state.filters.startDate}
                            onChange={this.handleChangeStartDate}
                            className={inputFieldClassName}
                        />
                    </FormGroup>

                    <FormGroup className={inputClassName} controlId="endDate">
                        <FormLabel className={hideLabel}>To Date:</FormLabel>
                        <CustomDatePicker
                            selected={this.state.filters.endDate}
                            onChange={this.handleChangeEndDate}
                            className={inputFieldClassName}
                        />
                    </FormGroup>
                      <FormGroup className={inputClassName + " align-self-end"}>
                    {this.state.searched === true ?
                        <Button variant="link" type="submit">Reset</Button> :
                        <Button variant="primary" type="submit">Search</Button>
                    }
                    </FormGroup>
                </form>

                <div className="pagination-wrapper history-pagination mt-2">
                    <Pagination
                        activePage={this.state.filters.page}
                        itemsCountPerPage={this.state.filters.size}
                        totalItemsCount={this.props.historyTotalElements}
                        pageRangeDisplayed={this.state.pageRangeDisplayed}
                        onChange={this.handlePageChange}
                    />
                </div>
             </React.Fragment>
        )
    } 
}


HistorySearchForm.propTypes = {
    historyTotalElements: PropTypes.number.isRequired,
    updateHistoryData: PropTypes.func.isRequired,
};

export default HistorySearchForm;
