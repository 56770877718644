import {genericDelete, genericGet, genericPost, genericPut, genericGetNoLoading} from "../../../components-shared/fetch-wrapper";

const projectsEndpoint = '/projects'
const contractManagementEndpoint = '/contract-management';
const contractsEndpoint = '/contracts'

export function getContractsForProject(projectId) {
    return genericGet(projectsEndpoint + "/" + projectId + contractsEndpoint)
}

export function saveContract(data) {
    return genericPost(contractManagementEndpoint, data);
}

export function isContractGreaterThanForecast(data) {
    return genericPost(contractManagementEndpoint + "/is-contract-greater-than-forecast", data);
}

export function getAllContractsByCategoryId(categoryId) {
    const url = contractsEndpoint + "?categoryId=" + categoryId;

    return genericGet(url);
}

export function getAllContractsByCategoryIdNoForecasts(categoryId) {
    const url = contractManagementEndpoint + "/no-forecasts/" + categoryId;

    return genericGet(url);
}

export function getAllContractsWithForecastsByCategoryId(categoryId) {
    const url = contractManagementEndpoint + "/with-forecasts/" + categoryId;

    return genericGetNoLoading(url);
}

export function getAllContractsByProjectIdAndCategoryType(projectId, categoryType) {
    const url = contractManagementEndpoint + "/project/" + projectId + "/category-type/" + categoryType;

    return genericGet(url);
}

export function deleteContract(contractId, categoryId) {
    const url = contractManagementEndpoint + "/" + contractId + '/categories/' + categoryId;

    return genericDelete(url);
}

export function getContract(projectId, contractId) {
    const url = projectsEndpoint + "/" + projectId + contractsEndpoint + "/" + contractId;

    return genericGet(url);
}

export function updateContract(data) {
    return genericPut(contractManagementEndpoint, data);
}
