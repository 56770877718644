import React, {Component} from "react";
import {Button, Form, FormLabel, FormControl, FormGroup} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {confirmAlert} from "react-confirm-alert";
import {getContract, updateContract} from "../../actions/contractActions";
import UrlInputGroup from "./UrlInputGroup/UrlInputGroup";
import {getBudgetLinesForProject} from "../../../category/actions/budgetLineActions";
import CommaSeparatedInput from "../../../../components-shared/CommaSeparatedInput/CommaSeparatedInput";

class EditContractForm extends Component {
  
  constructor (props) {
    
    super(props);
    
    this.state = {
      budgetLines: [],
      id: props.contract.id || '',
      agreementReference: props.contract.agreementReference || '',
      amount: props.contract.amount || undefined,
      vatId: undefined,
      description: props.contract.description || '',
      pdfLink: props.contract.pdfLink || '',
      
      error: '',
      categoryId: props.categoryId,
      contractType: '',
      contractNumber: '',
      contractAmount: '',
      contractFile: '',
      assignedBudgetLines: [
        {
          budgetLine: '',
          amount: ''
        }
      ],
      fieldsValidations: {
        contractNumber: [
          {
            errMsg: "The contract number is mandatory",
            isValid: (value, mandatory) => {
              return value !== '';
            }
          },
          {
            errMsg: "The contract number should not be longer that 100 characters",
            isValid: (value, mandatory) => {
              return value.length <= 100;
            }
          }
        ],
        contractAmount: [
          {
            errMsg: "The contract amount is mandatory",
            isValid: (value, mandatory) => {
              return !(mandatory && value === '');
            }
          },
          {
            errMsg: "The contract amount DOES NOT match the assigned budget lines",
            isValid: (value, mandatory) => {
              if (!mandatory) {
                return true;
              }
              let sum = 0;
              this.state && this.state.assignedBudgetLines && this.state.assignedBudgetLines.forEach(line => {
                sum = sum + parseInt(line.amount);
              });
              return parseInt(value) === sum;
            }
          }
        ],
        description: [
          {
            errMsg: "The description should not be longer that 5000 characters",
            isValid: (value, mandatory) => {
              return value.length <= 5000;
            }
          },
        ],
        pdfLink: [
          {
            errMsg: "The link should not be longer that 255 characters",
            isValid: (value, mandatory) => {
              return value.length <= 255;
            }
          },
        ],
        budgetLine: [
          {
            errMsg: "The budget line is mandatory",
            isValid: (value, mandatory) => {
              return !(mandatory && value === '');
            }
          },
        ],
        amount: [
          {
            errMsg: "The amount is mandatory",
            isValid: (value, mandatory) => {
              return this.state.contractType === 'AGREEMENT' ? !(mandatory && value === '') : true;
            }
          },
        ]
      },
      displayErrors: {
        contractType: false,
        contractNumber: false,
        contractAmount: false,
        description: false,
        contractFile: false,
        pdfLink: false,
        assignedBudgetLines: [
          {
            budgetLine: false,
            amount: false,
          }
        ]
      },
    };
    
    this.validation = {
    };
    this.emptyBudgetLine = {
      budgetLine: '',
      amount: ''
    };
    
    this.errorsBudgetLine = {
      budgetLine: false,
      amount: false,
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    
    this.dismissError = this.dismissError.bind(this);
    
    this.handleBlur = this.handleBlur.bind(this);
    this.addNewBudgetLine = this.addNewBudgetLine.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.errorCheck = this.errorCheck.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.isValid = this.isValid.bind(this);
    this.displayErrors = this.displayErrors.bind(this);
  }
  
  
  componentDidMount () {
    getContract(this.props.match.params.projectId, this.props.contract.id).then(contract => {
      getBudgetLinesForProject(this.props.match.params.projectId, this.props.match.params.categoryType).then((budgetLines) => {
        this.setState({
          budgetLines: budgetLines,
          id: contract.id,
          contractType: contract.contractType,
          contractNumber: contract.agreementReference,
          contractAmount: contract.amount,
          description: contract.description,
          pdfLink: contract.pdfLink,
          assignedBudgetLines: contract.assignedBudgetLines
        })
      })
    })
  }
  
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.vatList !== this.props.vatList || prevProps.contract.vat !== this.props.contract.vat) {
      const vatId = this.getDefaultVat(this.props.vatList, this.props.contract.vat);
      this.setState({vatId: vatId});
    }
  }
  
  
  getDefaultVat (vatList, defaultVat) {
    const defaultVatIdArray = vatList &&
      vatList.filter(el => el.vatValue === defaultVat);
    const defaultVatId = defaultVatIdArray.length > 0 ? defaultVatIdArray[0].id : undefined;
    return defaultVatId;
  };
  
  handleChange (event, i, field) {
    let newDisplayErrors = Object.create(this.state.displayErrors);
    if(field === "budgetLine") {
      newDisplayErrors["contractAmount"] = true;
    }
    if ((i && i > 0) || i === 0) {
      let newAssignedBudgetLines = this.state.assignedBudgetLines.slice();
      newAssignedBudgetLines[i][field] = event.target.value;
      if(newDisplayErrors.assignedBudgetLines[i]) {
        newDisplayErrors.assignedBudgetLines[i][field] = true;
      } else {
        // Array.prototype.insert = function ( index, item ) {
        //   this.splice( index, 0, item );
        // };
        // newDisplayErrors.assignedBudgetLines.insert(i, {[field]: true});
        function insert ( array, index, item ) {
          array.splice( index, 0, item );
        };
        insert(newDisplayErrors.assignedBudgetLines, i, {[field]: true} );
      }
      this.setState({
        assignedBudgetLines: newAssignedBudgetLines,
        displayErrors: newDisplayErrors
      });
    } else {
      newDisplayErrors[field] = true;
      this.setState({
        [event.target.id]: event.target.value,
        displayErrors: newDisplayErrors
      });
    }
  };
  
  handleBlur (i, field) {
    let newDisplayErrors = Object.create(this.state.displayErrors);
    if ((i && i > 0) || i === 0) {
      if(newDisplayErrors.assignedBudgetLines[i]) {
        newDisplayErrors.assignedBudgetLines[i][field] = true;
      } else {
        // Array.prototype.insert = function ( index, item ) {
        //   this.splice( index, 0, item );
        // };
        // newDisplayErrors.assignedBudgetLines.insert(i, {[field]: true});
        function insert ( array, index, item ) {
          array.splice( index, 0, item );
        };
        insert(newDisplayErrors.assignedBudgetLines, i, {[field]: true} );
      }
      this.setState({
        displayErrors: newDisplayErrors
      });
    } else {
      newDisplayErrors[field] = true;
      this.setState({
        displayErrors: newDisplayErrors
      });
    }
  };
  
  onCancel () {
    confirmAlert({
      title: 'Confirm to discard',
      message: 'Are you sure you want to discard changes?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.onCancel();
          }
        },
        {
          label: 'No',
        }
      ]
    });
  }
  
  handleSubmit (event) {
    event.preventDefault();
    if (this.isValid()) {
      
      const body = {
        id: this.state.id,
        contractorId: this.state.contractorId,
        agreementReference: this.state.contractNumber,
        amount: this.state.contractAmount,
        description: this.state.description,
        contractFile: this.state.contractFile,
        contractType: this.state.contractType,
        assignedBudgetLines: this.state.assignedBudgetLines
      }
      confirmAlert({
        title: 'Confirm to submit',
        message: 'Are you sure you want to update the contract?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              updateContract(body).then((json) => {
                if (json.code) {
                  return this.setState({error: json.message});
                } else {
                  this.props.refreshState();
                  this.props.onCancel();
                }
              });
            }
          },
          {
            label: 'No',
          }
        ]
      });
    } else {
      this.displayErrors();
    }
  }
  
  displayErrors () {
    let displayErrors = this.state.displayErrors;
    displayErrors.contractNumber = true;
    displayErrors.contractAmount = this.state.contractType === 'AGREEMENT';
    displayErrors.assignedBudgetLines.forEach(record => {
      record.budgetLine = true;
      record.amount = true;
    })
    this.setState({
      displayErrors: displayErrors
    })
  }
  
  isValid () {
    if (this.errorCheck('contractNumber', false, true).props.children !== '') {
      return false;
    }
    if (this.errorCheck('contractAmount', false, this.state.contractType === 'AGREEMENT').props.children !== '') {
      return false;
    }


      let isValid = true;
      let i = 0;
      this.state.assignedBudgetLines.forEach(record => {
        if (this.errorCheck('budgetLine', i, true).props.children !== '') {
          isValid = false;
        }
        if (this.errorCheck('amount', i, true).props.children !== '') {
          if (this.state.contractType === 'AGREEMENT') {
            isValid = false;
          }
        }
        i++;
      })
      return isValid;
  }
  
  dismissError () {
    this.setState({error: ''});
  }
  
  addNewBudgetLine () {
    let newAssignedBudgetLines = this.state.assignedBudgetLines.slice();
    let newDisplayErrors = this.state.displayErrors;
    newDisplayErrors.assignedBudgetLines.push(Object.create(this.errorsBudgetLine));
    newAssignedBudgetLines.push(Object.create(this.emptyBudgetLine));
    this.setState({
      assignedBudgetLines: newAssignedBudgetLines,
      displayErrors: newDisplayErrors
    })
  }
  
  handleDelete (i) {
    this.handleBlur(false, "contractAmount");
    this.handleChange({target: {value: this.state.contractAmount}}, false, "contractAmount");
    if (this.state.assignedBudgetLines.length > 1) {
      const newAssignedBudgetLines = this.state.assignedBudgetLines.slice();
      let newDisplayErrors = this.state.displayErrors;
      newDisplayErrors.assignedBudgetLines.splice(i, 1);
      newAssignedBudgetLines.splice(i, 1);
      this.setState({
        assignedBudgetLines: newAssignedBudgetLines,
        displayErrors: newDisplayErrors
      });
    }
  }
  
  errorCheck (field, order, mandatory) {
    if (order || order === 0) {
      let error = '';
      this.state.fieldsValidations[field].forEach(validation => {
        if (!validation.isValid(this.state.assignedBudgetLines[order][field], mandatory)) {
          error = validation.errMsg;
        }
      });
      return <div className="text-danger input-error">{error}</div>;
    } else {
      let error = '';
      this.state.fieldsValidations && this.state.fieldsValidations[field] && this.state.fieldsValidations[field].forEach(validation => {
        if (!validation.isValid(this.state[field], mandatory)) {
          error = validation.errMsg;
        }
      });
      return <div className="text-danger input-error">{error}</div>;
    }
  }
  
  
  render () {
    
    const inputClassName = " mb-5 position-relative";
    
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-6">
              <FormGroup className={inputClassName} controlId="contractType">
                <FormLabel>Contract type</FormLabel>
                <Form.Control
                  as="select"
                  type="contract"
                  value={this.state.contractType}
                  onChange={(e) => this.handleChange(e, false, "contractType")}
                  onBlur={() => this.handleBlur(false, "contractType")}
                >
                  <option key="AGREEMENT" value="AGREEMENT">Agreement</option>
                  <option key="FICTIVE" value="FICTIVE">Fictive</option>
                </Form.Control>
              </FormGroup>
            </div>
            <div className="col-6">
              <FormGroup className={inputClassName} controlId="contractNumber">
                <FormLabel>Contract number</FormLabel>
                <FormControl
                  type="text"
                  value={this.state.contractNumber}
                  onChange={(e) => this.handleChange(e, false, "contractNumber")}
                  onBlur={() => this.handleBlur(false, "contractNumber")}
                />
                {this.state.displayErrors.contractNumber && this.errorCheck('contractNumber', false, true)}
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup className={inputClassName}>
                <FormLabel>Contract Amount</FormLabel>
                <CommaSeparatedInput
                    elementType="FormControl"
                    type="number"
                    decimalsAfterDot={0}
                    min={0}
                    id="contractAmount"
                    value={this.state.contractAmount}
                    handleChange={(e) => this.handleChange(e, false, "contractAmount")}
                    handleBlur={() => this.handleBlur(false, "contractAmount")}
                />
                {this.state.displayErrors.contractAmount && this.errorCheck('contractAmount', false, this.state.contractType === 'AGREEMENT')}
              </FormGroup>
            </div>
            <div className="col-md-6">
            </div>
            <div className="col-12">
              <FormGroup className={inputClassName} controlId="description">
                <FormLabel>Description</FormLabel>
                <FormControl
                  componentclass="textarea"
                  placeholder=""
                  value={this.state.description}
                  onChange={(e) => this.handleChange(e, false, "description")}
                  onBlur={() => this.handleBlur(false, "description")}
                />
                {this.state.displayErrors.description && this.errorCheck('description', false, false)}
              </FormGroup>
            </div>
            <div className="col-12">
              <UrlInputGroup inputClassName={inputClassName} label="Contract File" pdfLink={this.state.pdfLink}
                             handleChange={(e) => this.handleChange(e, false, "pdfLink")}
                             onBlur={() => this.handleBlur(false, "pdfLink")}
              />
              {this.state.displayErrors.pdfLink && this.errorCheck('pdfLink', false, false)}
            </div>
            <div className="col-12 mt-4">
              <h5>Assigned budget lines</h5>
              {
                this.state.assignedBudgetLines && this.state.assignedBudgetLines.map((budgetLine, i) =>
                  <React.Fragment key={i}>
                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup className={inputClassName} controlId={"budgetLine" + i}>
                          <FormLabel className="d-none">Budget line</FormLabel>
                          <Form.Control
                            as="select"
                            //defaultValue={this.state.assignedBudgetLines && this.state.assignedBudgetLines[i] ? this.state.assignedBudgetLines[i].budgetLine : ''}
                            value={this.state.assignedBudgetLines && this.state.assignedBudgetLines[i] ? this.state.assignedBudgetLines[i].budgetLine : ''}
                            onChange={(e) => this.handleChange(e, i, "budgetLine")}
                            onBlur={() => this.handleBlur(i, "budgetLine")}
                          >
                            <option key="" value="">Select budget line</option>
                            {this.state.budgetLines &&
                            this.state.budgetLines.map(budgetLine => (
                              <option key={budgetLine.id}
                                      value={budgetLine.id}>IB Code {budgetLine.ibCode}</option>
                            ))
                            }
                          </Form.Control>
                          {this.state.displayErrors.assignedBudgetLines && this.state.displayErrors.assignedBudgetLines[i] &&
                          this.state.displayErrors.assignedBudgetLines[i].budgetLine && this.errorCheck('budgetLine', i, true)}
                        </FormGroup>
                      </div>
                      <div className="col-md-5">
                        <FormGroup className={inputClassName}>
                          <FormLabel className="d-none">Amount</FormLabel>
                          <CommaSeparatedInput
                              elementType="FormControl"
                              type="number"
                              decimalsAfterDot={0}
                              min={0}
                              id={"amount" + i}
                              value={this.state.assignedBudgetLines && this.state.assignedBudgetLines[i] ? this.state.assignedBudgetLines[i].amount : ''}
                              handleChange={(e) => this.handleChange(e, i, "amount")}
                              handleBlur={() => this.handleBlur(i, "budgetLine")}
                          />
                          {this.state.displayErrors.assignedBudgetLines && this.state.displayErrors.assignedBudgetLines[i] &&
                          this.state.displayErrors.assignedBudgetLines[i].amount && this.errorCheck('amount', i, this.state.contractType === 'AGREEMENT')}
                        </FormGroup>
                      </div>
                      <div className="col-md-1 align-self-end mb-5">
                        <button
                          className="border-0 bg-transparent mb-0 pb-0"
                          type="button"
                          onClick={() => this.handleDelete(i)}>
                          <i className={this.state.assignedBudgetLines.length > 1 ? "fas fa-trash" : "fas fa-trash text-light-grey"}></i>
                        </button>
                      </div>
                    </div>
                  </React.Fragment>)
              }
              <Button 
                      type="button"
                      variant="dark"
                      onClick={this.addNewBudgetLine}
              >
                Add new budget line
              </Button>
            </div>
            <div className="col-12 mb-5 mt-5 pb-5">
              <Button className="btn btn-primary kb-btn_add" type="submit">
                Update
              </Button>
              
              <span className={"btn btn-light kb-btn_cancel ml-3"} onClick={this.onCancel}>Cancel</span>
            </div>
          </div>
        </div>
      
      </form>
    );
  }
}

EditContractForm.propTypes = {
  contract: PropTypes.object.isRequired,
  refreshState: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default withRouter(EditContractForm);
