import React, {Component} from "react";
import PropTypes from 'prop-types';

class CollapsiblePageSection extends Component {

    constructor(props) {

        super(props);

        this.state = {
            collapsed: this.props.collapsed === undefined ? true : this.props.collapsed,
        };

        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.myRef = React.createRef();
    };
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.collapseAll !== this.props.collapseAll && this.props.collapseAll){
            this.setState({
                collapsed: this.props.collapseAll
            })
        }
    }

    toggleCollapse() {
        this.setState({collapsed: !this.state.collapsed});
        if(this.props.onSelect) {
            this.props.onSelect(false);
        }
        if(this.state.collapsed) {
            this.myRef.current.scrollIntoView();
        }
    }

    render() {
        return (
            <div className={ this.props.borderBottom ? "kb-section-container border-bottom pb-3":"kb-section-container py-3"} 
                ref={this.myRef}>
                <div onClick={this.toggleCollapse} className="kb-section-title cursor-pointer user-select-none">
                    <h5 className={(this.props.noTitlePadding ? "p-0 m-0" : "py-3") + (this.props.headingSize ? ` font-size-${this.props.headingSize}`: "")}>
                        {this.props.showArrow ? (
                            <div className="d-table">
                                <div className="d-table-cell">
                                    {this.state.collapsed ? (<i className="fas fa-chevron-down pl-2 pr-2 kb-businessplan-section-arrow"/>) :
                                        (<i className="fas fa-chevron-up pl-2 pr-2 kb-businessplan-section-arrow"/>)}
                                </div>
                                <div className="d-table-cell">
                                    {this.props.title}
                                </div>
                            </div>
                        ) : this.props.title }
                    </h5>
                </div>
                {this.state.collapsed ||
                <div className={this.props.mlChildren ? "kb-section-body py-3 pl-5" : "kb-section-body py-3"}>
                    {this.props.children}
                </div>}
            </div>
        );
    }
}

CollapsiblePageSection.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    collapsed: PropTypes.bool,
    showArrow: PropTypes.bool,
    noTitlePadding: PropTypes.bool,
    mlChildren: PropTypes.bool,
    headingSize: PropTypes.number
};

export default CollapsiblePageSection;
