import React from "react";
import CompanySettingsForm from "./forms/CompanySettingsForm";
import PageSection from "../../components-shared/PageSection";
import Breadcrumbs from "../../components-shared/Breadcrumbs/Breadcrumbs";

function CompanySettingsPage() {

    return (
        <div className={"settings"}>
            <Breadcrumbs breadcrumbs={[{url: '/projects', title: 'Home'}, {url: '/settings', title: 'Settings'}, {url: '/settings/company', title: 'Company settings'}]} />

             <header className="kb-header px-3">
                    <div className="container-fluid">
                        <div className="row">

                             <div className="col-12 d-flex align-items-center ">
                                        <div className="kb-title_page d-flex align-items-center py-4">
                                            <h1 className="h4 font-weight-normal m-0">Settings</h1>
                                          </div>
                               <nav className="kb-nav_ctas ml-auto">
                                   
                                </nav>
                            </div> 
                        </div>
                    </div>
                </header>
             <main className="kb-main kb-main_settings--company px-3 py-4 bg-white">
      							 <PageSection title={"Company settings"}>
				                <CompanySettingsForm/>
				            </PageSection> 
            </main>
            
        </div>
    );
}

export default CompanySettingsPage;
 