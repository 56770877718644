import React from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

export default function LinkButton(props) {

    return (
        <Link className={props.className} to={props.to} {...props}>
           {props.children}
        </Link>
    )
}

LinkButton.propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
};

LinkButton.defaultProps = {
    className: 'btn btn-primary'
};
