import {genericGet, genericPut} from "../../../components-shared/fetch-wrapper";

const settingsManagementEndpoint = '/company-settings';

export function updateSettings(data) {
    return genericPut(settingsManagementEndpoint, data);
}

export function getCompanySettings() {
    return genericGet(settingsManagementEndpoint);
}

export function getApplicationSettings() {
    return genericGet("/application-settings");
}
