import React, {Component} from "react";
// import {FormLabel, FormControl, FormGroup} from "react-bootstrap";
// import {saveInvoice} from "../actions/invoicesActions";
import {withRouter} from "react-router-dom";
// import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from "prop-types";
// import CustomDatePicker from "../../../components-shared/CustomDatePicker";
// import {getBudgetLinesForProject} from "../../category/actions/budgetLineActions";
// import {getAllVATs} from "../../user/actions/userActions";
// import {confirmAlert} from "react-confirm-alert";
import InvoiceFormWrapper from "./InvoiceFormWrapper";

class NewInvoiceForm extends Component {
  
  constructor (props) {
    
    super(props);
    
    this.cancelForm = this.cancelForm.bind(this);
    
  }
  
  cancelForm() {
    this.props.history.goBack();
  }
  
  render () {
    
    return (
      <InvoiceFormWrapper
        projectId={this.props.projectId}
        categoryType={this.props.categoryType}
        onCancel={this.props.onCancel}
        cancelForm={this.cancelForm}
      />
    );
  }
}

NewInvoiceForm.propTypes = {
  projectId: PropTypes.string.isRequired,
  categoryType: PropTypes.string.isRequired,
  contractId: PropTypes.string,
  onCancel: PropTypes.func,
  afterSubmit: PropTypes.func
};

export default withRouter(NewInvoiceForm);
