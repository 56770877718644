import React, {useState, useEffect, Fragment} from 'react';
import {FormLabel, FormControl, FormGroup } from "react-bootstrap";
import {confirmAlert} from 'react-confirm-alert';
import {makeId, titleToUrl} from "./functions";
import {createUserRole
} from "../actions/userPermissionsActions";
import PermissionsTableHeader from "./PermissionsTableHeader";
import {toast} from "react-toastify";
import RouteLeavingGuard from './RouteLeavingGuard';
import { withRouter } from 'react-router-dom';

const AddUserRole = (props) => {
    const [roleName, setRoleName] = useState('');
    const [statePermissions, setStatePermissions] = useState({});
    const [uniqueRoleId] = useState(makeId(15));
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [editMode, setEditMode] = useState(true);
    const [userPermissionsIds] = useState([]);
    
    const [nameFieldError, setNameFieldError] = useState('');
    const [nameFieldErrors] = useState(
        [
            {
                errMsg: "The name should have only alphanumeric characters and white spaces",
                isValid: (value) => {
                    return /^[a-z 0-9]+$/i.test(value);
                }
            },
            {
                errMsg: "The name field shold not be larger than 30 characters",
                isValid: (value) => {
                    return value.length <= 30;
                }
            },
            {
                errMsg: "The name field should not be empty or have only whitespaces",
                isValid: (value) => {
                    return value.replace(/\s/g, '').length > 0;
                }
            },
            {
                errMsg: "The name field should not start or end with a whitespace",
                isValid: (value) => {
                    return !value.startsWith(' ') && !value.endsWith(' ');
                }
            },
            {
                errMsg: "Role name must be unique",
                isValid: (value, roles) => {
                    return !checkIfPresent(value, roles);
                }
            }
        ]
    );
    
    const setInputNameValue = e => {
      const value = e.target.value;
        setRoleName(e.target.value);
        errorcheck(value, nameFieldErrors, setNameFieldError);
    }
  
    const errorcheck = (value, errors, setError) => {
        let errorsNo = 0;
        errors.forEach((error, i) => {
            if (i === errors.length - 1) {
                if (!error.isValid(value, roles)) {
                    setError(error.errMsg);
                    errorsNo = errorsNo + 1;
                }

            } else {                
                if (!error.isValid(value)) {
                    setError(error.errMsg);
                    errorsNo = errorsNo + 1;
                }
            }
        });

        if (errorsNo === 0) {
            setError("");
        }
    }
  const checkIfPresent = (roleName, roles) => {
    let isPresent = false;
    if (roleName && roles) {
      roles.forEach(role => {
        if (role.name === roleName) {
          isPresent = true;
        }
      })
    }
    return isPresent;
  }

    const inputClassName = "form-group d-inline-block ml-3 position-relative";

    const newUserRole = {
        name: uniqueRoleId,
        permissionIds: userPermissionsIds
    }

    const confirmNotAllowedObj = (errorMesasge) => {
        return {
            title: 'Validation error',
            message: errorMesasge,
            buttons: [
                {
                    label: 'Ok'
                }
            ]
        }
    }

    const addRole = () => {
        if (!nameFieldError && roleName) {
            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure you want to add new role?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            populateRolePermissionIds(permissions, roleName);
                            let newUserPerm = [];
                            permissions.forEach(perm => {
                                if (statePermissions[perm.permissionName]) {
                                    newUserPerm.push(perm.id);
                                }
                            });
                            newUserRole.name = roleName;
                            newUserRole.permissionIds = newUserPerm;
                            createUserRole(newUserRole).then((res) => {
                                if (res.code) {
                                    if (res.code === 10010) {
                                        toast.error("Role name already exists", {
                                        position: toast.POSITION.BOTTOM_RIGHT
                                        });
                                    }
                                } else {
                                    props.getRoles();
                                    props.getPermissions();
                                    setEditMode(false);
                                }
                            });
                        }
                    },
                    {
                        label: 'No',
                    }
                ]
            });
        } else {
            if (!nameFieldError && !roleName) {
                errorcheck(roleName, nameFieldErrors, setNameFieldError);
                confirmAlert(confirmNotAllowedObj(nameFieldErrors[2].errMsg));
            } else { 
                confirmAlert(confirmNotAllowedObj(nameFieldError));
            }
        }
    }

    const cancelAddRole = () => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to cancel adding new role?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: onClickCancel
                },
                {
                    label: 'No',
                }
            ]
        });
    }


    const onClickCancel = () => {
        setEditMode(false);
        setPermissions(props.permissions.slice());
        setRoles(props.roles.slice());
        props.history.push(`/settings/roles/show-all`);
    }


    useEffect(() => {
        setRoles(props.roles);
        setPermissions(props.permissions);
        const statePermissions = {};
        props.permissions.forEach(perm => {
            statePermissions[perm.permissionName] = false;
        });
        setStatePermissions(statePermissions);
    }, [props.roles, props.permissions])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!editMode) {
            let urlPath = titleToUrl(roleName);
            props.history.push(`/settings/roles/${urlPath}`);
        }
    }, [editMode, props.history, roleName]);


    const getAllPermissionsForGroup = (group) => {
        const permissionsArr = permissions.slice();
        const wantedPermissions = [];
        permissionsArr.forEach(permission => {
            if (permission.permissionGroup === group) {
                wantedPermissions.push(permission);
            }
        });
        return wantedPermissions;
    }


    const roleArray = [];
    roleArray.push(newUserRole);

    const populateRolePermissionIds = (permissions, roleName) => {
        let newPermissionIds = [];
        permissions.forEach(permission => {
            if (permission.assignedUserRoles.includes(roleName)) {
                newPermissionIds.push(permission.id);
            }
        });
        newUserRole.permissionIds = newPermissionIds;
    }

    
    const setSingleStatePermissions = (field) => {
        setStatePermissions({...statePermissions, [field]: statePermissions[field] ? false : true})
    }

    return (
        <div className="kb-table_permissions w-100">
            <RouteLeavingGuard 
                //shouldBlockNavigation={editMode} 
                message="You have unsaved changes, are you sure you want to leave?"
                when={editMode}
                // Navigate function
                navigate={path => props.history.push(path)}
                    // Use as "message" prop of Prompt of React-Router
                shouldBlockNavigation={location => {
                        // This case it blocks the navigation when: 
                        if (editMode) {
                            return true
                        } 
                        return false
                    }
                }
            />
        
            <div className="row w-100">
                <div className="col-6">
                    <FormLabel className="h4 font-weight-normal m-0 text-dark pl-3">Permissions for</FormLabel>
                    <FormGroup className={inputClassName} controlId="roleName">
                        <FormLabel className="d-none">Role name</FormLabel>
                        <FormControl
                            type="text"
                            autoComplete='off'
                            value={roleName}
                            onChange={(e) => setInputNameValue(e)}/>
                            <div className="text-danger input-error">{nameFieldError}</div>
                    </FormGroup>
                </div>

                <div className="col-6 text-right">
                    <button type="button" className="btn btn-link kb-btn-cancel_permissions ml-3"
                            onClick={cancelAddRole}>Cancel
                    </button>
                    <button type="button" className="btn btn-primary kb-btn-save_permissions ml-3"
                            onClick={addRole}>Save
                    </button>
                </div>

                <div className="col-12 bg-white py-4">
                    <table className=".kb-table-permissions  table table-hover">

                        {roleArray && roleArray.length > 0 && (<PermissionsTableHeader roleName={roleName} uniqueRoleId={uniqueRoleId} referencePermissions={roleArray}/>)}

                        <tbody>

                        {permissions && props.permissionGroups.map(permissionGroup => {
                            const allPermissionsForGroup = getAllPermissionsForGroup(permissionGroup.permissionGroup);
                            return (
                                <Fragment key={permissionGroup.permissionGroup}>
                                    <tr>
                                        <td className="font-weight-bold border-none">{permissionGroup.humanReadableDescription}</td>
                                    </tr>

                                {roleArray && allPermissionsForGroup.map(permission => {
                                    return (
                                        
                                        <tr key={permission.id}>
                                            <td className="custom-col-1">{permission.humanReadableDescription}</td>
                                            <td>
                                                {
                                                <label className="fancy" htmlFor={permission.permissionName}>
                                                    <input  
                                                        name={permission.permissionName} type="checkbox" 
                                                        value={statePermissions[permission.permissionName] === true ? true : false} 
                                                        checked={statePermissions[permission.permissionName] === true ? true : false} 
                                                        disabled={!editMode} 
                                                        onChange={()=>{setSingleStatePermissions(permission.permissionName)}} 
                                                    />
                                                </label>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                                </Fragment>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default withRouter(AddUserRole);
