  
import React, {Component} from "react";
import {NavDescriptor, NavPanel} from "../../components-shared/NavPanel";
import ManageProjectDetailsPage from "./ManageProjectDetailsPage";
import ManageProjectCategoryPage from "./ManageProjectCategoryPage";
import ManageProjectContractorsPage from "./ManageProjectContractorsPage";
import ManageProjectInvoicesPage from "./ManageProjectInvoicesPage";
import ManageProjectStatisticsPage from "./ManageProjectStatisticsPage";
import ProjectTitleDisplay from "./fragment/ProjectTitleDisplay";
import {getProjectById} from "./actions/projectActions";
import {canManageProjectDefinition, canManageProjectExecution
    //, canManageProjectUsers
} from "../../main-components/UserPermissionsFunctions";
// import ManageProjectForecastPage from "./ManageProjectForecastPage";
// import ManageProjectBudgetExecutionPage from "./ManageProjectBudgetExecutionPage";
import {withRouter} from "react-router-dom";
import { CategoriesContextProvider } from "../../main-components/CategoriesContext";
import ManageProjectRealTimeBudgetPage from "./ManageProjectRealTimeBudgetPage";

class ProjectNavigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projectDetails: null,
            error: ''
        };

        this.getProjectId = this.getProjectId.bind(this);
        this.componentIsMounted = false;
    }

    getProjectId() {
        return this.props.match.params.projectId;
    }
    componentDidMount() {
        const projectId = this.getProjectId();
        this.componentIsMounted = true;
        if (projectId) {
            getProjectById(projectId).then((projectDetails) => {
                if (this.componentIsMounted) {
                    if (projectDetails.code) {
                        return this.setState({error: projectDetails.message, isDataFetched: true});
                    } else {
                        this.setState({
                            projectDetails: projectDetails,
                            error: '',
                            isDataFetched: true,
                        })
                    }
                }
            });
        }
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
    }

    render() {
        if (this.state.isDataFetched) {
            const parentBaseUrl = '/project/:projectId';
            const urlParams = [{key: ':projectId', value: this.getProjectId()}];       
            const globalProps = {
                projectDetails: this.state.projectDetails,
                projectId: this.getProjectId(),
                parentBaseUrl: parentBaseUrl,
            }
            const navDescriptors = [
                new NavDescriptor(parentBaseUrl, canManageProjectDefinition(), '/view', 'Settings', (props) => <ManageProjectDetailsPage {...globalProps} {...props} />, "fas fa-info-circle", urlParams),
                new NavDescriptor(parentBaseUrl, canManageProjectDefinition(), '/budget', 'Budget', (props) => <CategoriesContextProvider><ManageProjectCategoryPage {...globalProps} {...props} /></CategoriesContextProvider>, "fas fa-stream", urlParams),
                // new NavDescriptor(parentBaseUrl, canManageProjectDefinition(), '/cash-flow', 'CashFlow Prognosis', (props) => <ManageProjectForecastPage {...globalProps} {...props} />, "fas fa-chart-line", urlParams),
                new NavDescriptor(parentBaseUrl, canManageProjectExecution(), '/real-time-budget', 'Real Time Budget', (props) => <ManageProjectRealTimeBudgetPage {...globalProps} {...props} />,
                    "fas fa-play-circle", urlParams),
                new NavDescriptor(parentBaseUrl, canManageProjectExecution(), '/contractors', 'Contractors', (props) => <ManageProjectContractorsPage {...globalProps} {...props} />, "fas fa-address-card", urlParams),
                new NavDescriptor(parentBaseUrl, canManageProjectExecution(), '/invoices/:categoryType/:contractId?', 'Invoices', (props) => <ManageProjectInvoicesPage {...globalProps} {...props} />, "fas fa-file-alt", urlParams),
                new NavDescriptor(parentBaseUrl, canManageProjectExecution(), '/reporting', 'Reporting', (props) => <ManageProjectStatisticsPage {...globalProps} {...props} />, "fas fa-chart-pie", urlParams)
            ];
            return (
                <React.Fragment>
                    <ProjectTitleDisplay projectDetails={this.state.projectDetails} showHelp={true}/>
                    <NavPanel navDescriptors={navDescriptors}
                              navBarClassName={"project-navigation"}
                              contentClassName={"project-content-wrapper"}
                              {...this.props}/>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <ProjectTitleDisplay projectDetails={this.state.projectDetails} showHelp={true}/>
                </React.Fragment>
            );
        }
    }
}

export default withRouter(ProjectNavigation);