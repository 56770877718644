import React, {Component} from "react";
import PropTypes from "prop-types";
import {prettyPrint} from "../../../common/numberOperations";
import {dateMonthYearFormat, momentFromMonthAndYear} from "../../../common/dateFormatting";
import RenderIf from "../../../../main-components/RenderIf";
import AdvancedFormulaSplitForm from "../../../forecast/forms/AdvancedFormulaSplitForm";
import {isEmptyString, isNullOrUndefined} from "../../../common/checks";
import {confirmAlert} from "react-confirm-alert";
import CommaSeparatedInput from "../../../../components-shared/CommaSeparatedInput/CommaSeparatedInput";
import { withRouter } from "react-router-dom";
import CommaSeparatedInput2 from "../../../../components-shared/CommaSeparatedInput/CommaSeparatedInput2";

class ForecastSplitCell extends Component {

    constructor(props) {

        super(props);

        this.state = {
            amountText: '',
            dropdown: false,
            splitting: false,
            shouldDisplayInvoicesLink: !isNullOrUndefined(this.props.invoicesRequestParams)
                && !isEmptyString(this.props.monthExtraDetail)
                && this.props.monthExtraDetail !== '0' && (this.props.category ? this.props.category === 'Cashflow' && this.props.type === 'Actual' : true),
        };

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.toggleSplit = this.toggleSplit.bind(this);
        this.showSplitForm = this.showSplitForm.bind(this);
        this.moveRight = this.moveRight.bind(this);
        this.forecastUpdated = this.forecastUpdated.bind(this);
        this.cashPositionUpdated = this.cashPositionUpdated.bind(this);
        this.update = this.update.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.navigateToInvoices = this.navigateToInvoices.bind(this);
        this.getCashflowSum = this.getCashflowSum.bind(this);
        this.getFinancingEquityValue = this.getFinancingEquityValue.bind(this);
        this.getTooltipText = this.getTooltipText.bind(this);
        this.isClosed = this.isClosed.bind(this);
    }

    // ensure that prop changes (reset) are visible
    componentDidUpdate(previousPops) {
        if (this.props.forecast !== previousPops.forecast) {
            this.refreshAmountText(this.props.forecast);
        }
    }

    getCashflowSum() {
        const year = this.props.forecast.year;
        const month = this.props.forecast.monthAsInteger;
        let cashflowSum = null;
        if (this.props.cashPositionValues) {
            cashflowSum = this.props.cashPositionValues.filter(chPosition => chPosition.year === year && chPosition.month === month);
            cashflowSum = cashflowSum && (cashflowSum[0] || cashflowSum[0] === 0) ? cashflowSum[0].cashPositionCumulated : '';
        } else {
            cashflowSum = this.props.contracts
                .flatMap(contract => contract.forecasts)
                .filter(forecast => forecast.year === year && forecast.monthAsInteger === month)
                .map(forecast => forecast.actualForecast)
                .reduce(function (acumulator, actualForecast) {
                    return actualForecast ? acumulator + actualForecast : acumulator;
                }, 0);
        }
        return cashflowSum ? cashflowSum : '';
    }

    getFinancingEquityValue = () => {
        const year = this.props.forecast.year;
        const month = this.props.forecast.monthAsInteger;
        let cashflowSum = null;
        if (this.props.cashPositionValues) {
            cashflowSum = this.props.cashPositionValues.filter(chPosition => chPosition.year === year && chPosition.month === month);
            cashflowSum = cashflowSum && (cashflowSum[0] || cashflowSum[0] === 0) ? cashflowSum[0].manualValue != null ? cashflowSum[0].manualValue : cashflowSum[0].withdrawnAmount : '';
        }
        return cashflowSum ? cashflowSum : 0;
    }

    refreshAmountText(forecast) {
        // we need to store the amount text in the state to make react handle negative numbers in the input
        // see https://stackoverflow.com/questions/54654292/allow-minus-symbol-in-value-bound-react-inputs
        if (this.props.cashPosition === true) {
            const forecastValue = forecast.actualForecast
            return forecastValue + this.getCashflowSum() + '';
        } 
        if (this.props.type === 'Initial' || this.props.type === undefined) {
            return forecast.initial + '';
        } else {
            return forecast.actualForecast + '';
        }
    }

    getAmountText(value) {
        return value === null ? '' : value + '';
    }

    toggleDropdown() {
        this.setState({dropdown: !this.state.dropdown});
    }


    toggleSplit() {
        this.setState({splitting: !this.state.splitting});
    }

    // let's hide the dropdown when opening the split form
    showSplitForm() {
        this.toggleSplit();
        this.toggleDropdown();
    }

    moveRight() {
        this.props.onMoveRight(this.props.forecast.id, this.props.forecastType, this.props.contractId, this.props.type, this.props.activeTab);
        this.toggleDropdown();
    }

    forecastUpdated(e) {
        let amountText = e.target.value;
        this.setState({
            amountText: amountText,
        });

        if (amountText === '') {
            amountText = 0;
        }
        const amountAsNumber = parseFloat(amountText);
        this.props.forecastUpdated(this.props.forecast.id, amountAsNumber, this.props.type);
    }

    cashPositionUpdated(e) {
        let amountText = e.target.value;
        this.setState({
            amountText: amountText,
        });

        if (amountText === '') {
            amountText = 0;
        }
        const amountAsNumber = parseFloat(amountText);
        const year = this.props.forecast.year;
        const month = this.props.forecast.monthAsInteger;
        this.props.cashPositionUpdated(year, month, amountAsNumber);
    }

    update(e) {
        if (this.props.cashPosition) {
            this.cashPositionUpdated(e);
        } else {
            this.forecastUpdated(e)
        }
    }

    // set NaN values to 0 when done editing the cell
    onBlur(e) {
        if (Object.is(NaN, parseInt(e.target.value))) {
            this.props.forecastUpdated(this.props.forecast.id, 0, this.props.type);
            this.refreshAmountText(0);
        }
    }

    navigateToInvoices() {

        confirmAlert({
            title: 'Confirm navigation',
            message: 'If you have unsaved changes on the page you will lose them! Are you sure you want to navigate to the invoices page?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        if (this.props.invoicesRequestParams) {
                            const location = {
                            pathname: "/project/" + this.props.invoicesRequestParams.projectId + "/invoices/COST",
                            state: {
                                categoryName: this.props.invoicesRequestParams.categoryName,
                                contractId: this.props.invoicesRequestParams.contractId,
                                contractorName: this.props.invoicesRequestParams.contractorName,
                                month: this.props.forecast.monthAsInteger,
                                monthString: this.props.forecast.month,
                                year: this.props.forecast.year
                            }
                            }
                            this.props.history.push(location);
                        }
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    isClosed() {
        return !this.props.openedMonths.some(month=> month.year === this.props.forecast.year
            && month.monthAsInteger === this.props.forecast.monthAsInteger);
    }

    render() {
        const dropdownClassName = this.state.dropdown ? "dots-cta active" : "dots-cta";

        // noinspection CheckTagEmptyBody
        return (
            <React.Fragment>
                <div className={"col pr-0 pl-0" + (this.props.isCurrentMonth ? " current-month" : "")}
                    style={{maxWidth: 100/12 + '%'}}
                >
                    {!this.props.cashPositionValues && <RenderIf
                        condition={this.state.shouldDisplayInvoicesLink && (this.props.category && this.props.category !== 'Cashflow')}
                        orElse={<label
                            className={this.props.monthExtraDetail && this.props.monthExtraDetai > 0 ? "" : "h-1rem"}>{this.props.monthExtraDetail && this.props.monthExtraDetai > 0 ? prettyPrint(this.props.monthExtraDetail) : ''}</label>}>
                        <label className={"invoices-link"} onClick={this.navigateToInvoices}>
                            {this.props.monthExtraDetail ? prettyPrint(this.props.monthExtraDetail) : ''}
                        </label>
                    </RenderIf>}
                    {
                        this.props.cashPositionValues && <div className="text-left mt-1">
                            <label
                                className={"font-weight-bold text-left text-dark " + (this.getCashflowSum() ? "" : "h-115rem")}>{ !isNaN(this.getCashflowSum()) && this.getCashflowSum() < 0 ? '(' + prettyPrint(Math.abs(this.getCashflowSum())) + ')' : prettyPrint(this.getCashflowSum()) }</label>
                        </div>
                        
                    }
                    <div className={this.isClosed() ? "border border-success" : ""}>
                        <div className="d-flex align-items-center kb-month-box">
                            <div className=" border border-white border-top-0 border-bottom-0 input-tooltip-container">
                                { (this.props.categoryType === 'COST' || this.props.categoryType === 'INCOME') &&
                                this.props.category === "Cashflow" && this.props.type === 'Actual' &&
                                (this.props.forecast.monthClosed === true || this.props.forecast.actualForecast !== null) &&
                                    <div className="input-tooltip">
                                        {this.getTooltipText()}
                                    </div>
                                }
                                {this.props.comma2 ?
                                    <React.Fragment>
                                    <CommaSeparatedInput2
                                         className={this.refreshAmountText(this.props.forecast) && this.refreshAmountText(this.props.forecast) < 0 ? " text-danger" : ""}
                                         elementType="FormControl"
                                         type="number"
                                         decimalsAfterDot={2}
                                         max={9999999999}
                                         min={-1000000000}
                                         value={this.props.cashPositionValues ? this.getFinancingEquityValue() : this.refreshAmountText(this.props.forecast) && this.refreshAmountText(this.props.forecast) !== 0 ? this.refreshAmountText(this.props.forecast) : ''}
                                         handleChange={this.update}
                                         disabled={this.props.readOnly || this.isClosed()}
                                     />
                                    </React.Fragment>
                                     :
                                    <React.Fragment>
                                    <CommaSeparatedInput
                                        className={this.refreshAmountText(this.props.forecast) && this.refreshAmountText(this.props.forecast) < 0 ? " text-danger" : ""}
                                        elementType="FormControl"
                                        type="number"
                                        decimalsAfterDot={2}
                                        max={9999999999}
                                        min={-1000000000}
                                        value={this.props.cashPositionValues ? this.getFinancingEquityValue() : this.refreshAmountText(this.props.forecast) && this.refreshAmountText(this.props.forecast) !== 0 ? this.refreshAmountText(this.props.forecast) : ''}
                                        handleChange={this.update}
                                        disabled={this.props.readOnly || this.isClosed()}
                                    /></React.Fragment>
                                }
                            </div>
                        </div>
                        <div className={this.props.readOnly ? "text-left pl-2 pr-2 " : "d-table w-100 card pl-2 pr-2"}>
                            <div className={this.props.readOnly ? "" : "d-table-cell"}>
                                <label
                                    className={"pt-2 font-weight-bold" + (this.props.readOnly ? " text-dark" : " text-grey2")}>
                                    {momentFromMonthAndYear(this.props.forecast.monthAsInteger - 1, this.props.forecast.year).format(dateMonthYearFormat)}
                                </label>
                            </div>
                            <div className="d-table-cell position-relative">
                                {
                                    !this.props.readOnly && !this.isClosed() && (
                                        <React.Fragment>
                                <span className={dropdownClassName} onClick={this.toggleDropdown}>
                                        <i className="fas fa-chevron-down pl-2"></i>
                                    </span>
                                            <ul className="dots-hover">
                                                <li onClick={this.moveRight}>Move right</li>
                                                <li onClick={this.showSplitForm}>Create split value</li>
                                                <li className="mt-2 border-top pt-2 text-muted"
                                                    onClick={this.toggleDropdown}>
                                                    <i className="fas fa-times pr-2"></i> Close
                                                </li>
                                            </ul>
                                        </React.Fragment>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <RenderIf condition={this.state.splitting}>
                    <div className='popup'>
                        <div className='popup_inner variable-width-side-modal'>
                            <div className={"manage-contract-layout mt-5 mb-2"}>
                                <div key={this.props.forecast.id} className={"manage-formula add-formula-form visible"}>
                                    <h5 className="category-details">
                                        Split starting from {this.props.forecast.month + ' ' + this.props.forecast.year}
                                    </h5>
                                    <AdvancedFormulaSplitForm
                                        fixedDate={momentFromMonthAndYear(this.props.forecast.monthAsInteger - 1, this.props.forecast.year)}
                                        toBeForecasted={this.props.toBeForecasted}
                                        forecastType={this.props.forecastType}
                                        contractId={this.props.contractId}
                                        onSave={this.props.onSplitSave}
                                        onCancel={this.toggleSplit}
                                        activeTab={this.props.activeTab}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </RenderIf>
            </React.Fragment>
        );
    }

    getTooltipText() {
        if (this.props.forecast.invoicedAmount && this.props.forecast.invoicedAmount !== 0) {
            return "Invoiced amount";
        } else if (this.props.forecast.endOfMonthBalance === this.props.forecast.actualForecast) {
            return "End of month balance amount";
        } else {
            return "Closed month without invoices"
        }
    }
}

ForecastSplitCell.propTypes = {
    forecast: PropTypes.shape({
        id: PropTypes.string,
        initial: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
        execution: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
        month: PropTypes.string,
        monthAsInteger: PropTypes.number,
        year: PropTypes.number
    }).isRequired,
    contracts: PropTypes.array,
    type: PropTypes.string,
    monthExtraDetail: PropTypes.string,
    toBeForecasted: PropTypes.number,
    forecastType: PropTypes.string,
    contractId: PropTypes.string,
    forecastUpdated: PropTypes.func,
    cashPositionUpdated: PropTypes.func,
    onSplitSave: PropTypes.func,
    onMoveRight: PropTypes.func,
    isCurrentMonth: PropTypes.bool,
    invoicesRequestParams: PropTypes.shape({
        projectId: PropTypes.string,
        categoryName: PropTypes.string,
        contractId: PropTypes.string,
        contractorName: PropTypes.string,
    }),
    readOnly: PropTypes.bool,
    cashPosition: PropTypes.bool,
    categoryType: PropTypes.string,
    openedMonths: PropTypes.array
};

export default withRouter(ForecastSplitCell);
