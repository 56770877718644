import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {prettyPrint, prettyPrintWithPrecision} from "../../../../common/numberOperations";
import {Link} from "react-router-dom";

class BudgetExecutionCategoryView extends Component {

    constructor(props) {
        super(props);
    }

    isRoot(category) {
        return category.subCategoriesEndOfMonthBalance !== null;
    }


    render() {
        return  (
            <div className={this.props.className + " kb-table"}>

                <div className={this.props.className + "-values row"}>

                    <div className={"col-4 kb-col-name font-weight-bold text-break"}>
                        {!this.isRoot(this.props.category) ?
                            <Link
                                to={"/manageExecution/" + this.props.category.categoryId + '/' + this.props.category.projectId + '/' + this.props.category.categoryType}
                            >
                                <span className="kb-businessplan-ibcode">
                                    {this.props.category.ibCode}
                                </span>{this.props.category.name}
                            </Link>
                            :
                            <Fragment>
                                {this.props.category.name}
                            </Fragment>
                        }
                    </div>

                    <div className={this.isRoot(this.props.category) ? "col table-col" : "col table-col font-weight-bold"}
                         data-label="Initial Budget">
                        {this.props.category.initialBudget ? prettyPrint(this.props.category.initialBudget) : 0}
                    </div>

                    <div className={this.isRoot(this.props.category) ? "col table-col" : "col table-col font-weight-bold"}
                         data-label="Execution budget">
                        {this.props.category.executionBudget ? prettyPrint(this.props.category.executionBudget) : 0}
                    </div>

                    {(this.props.category.categoryType === 'COST' || this.props.category.categoryType === 'INCOME') &&

                    <React.Fragment>
                        <div
                            className={this.isRoot(this.props.category) ? "col table-col" : "col table-col font-weight-bold"}
                            data-label="Committed">
                            {prettyPrint(this.props.category.committed)}
                        </div>

                        <div
                            className={this.isRoot(this.props.category) ? "col table-col" : "col table-col font-weight-bold"}
                            data-label="Invoiced">
                            {prettyPrint(this.props.category.invoiced)}
                        </div>

                        <div
                            className={this.isRoot(this.props.category) ? "col table-col" : "col table-col font-weight-bold"}
                            data-label="To be Invoiced">
                            {prettyPrint(this.props.category.toBeInvoiced)}
                        </div>

                        <div
                            className={this.isRoot(this.props.category) ? "col table-col" : "col table-col font-weight-bold"}
                            data-label="Uncommitted">
                            {prettyPrint(this.props.category.uncommitted)}
                        </div>

                        <div
                            className={this.isRoot(this.props.category) ? "col table-col" : "col table-col font-weight-bold"}
                            data-label="Forecast">
                            {prettyPrint(this.props.category.forecast)}
                        </div>

                        <div
                            className={this.isRoot(this.props.category) ? "col table-col" : "col table-col font-weight-bold"}
                            data-label="Total">
                            {prettyPrint(this.props.category.total)}
                        </div>
                        {this.props.category.status && this.props.category.status < 0 ?
                            <div
                                className={this.isRoot(this.props.category) ? "col table-col kb-real-time-budget-overflow" : "col table-col font-weight-bold kb-real-time-budget-overflow"}
                                data-label="Status">
                                {prettyPrint(this.props.category.status)}
                            </div>
                            :
                            <div
                                className={this.isRoot(this.props.category) ? "col table-col" : "col table-col font-weight-bold"}
                                data-label="Status">
                                {prettyPrint(this.props.category.status)}
                            </div>
                        }
                    </React.Fragment>
                    }
                    {(this.props.category.categoryType === 'FINANCING' || this.props.category.categoryType === 'INVESTMENT') &&
                    <React.Fragment>
                        <div
                            className={this.isRoot(this.props.category) ? "col table-col" : "col table-col font-weight-bold"}
                            data-label="Contribution Per Entity">
                            {prettyPrintWithPrecision(this.props.category.contributionPerEntity, 2)}%
                        </div>
                        <div
                            className={this.isRoot(this.props.category) ? "col table-col" : "col table-col font-weight-bold"}
                            data-label="Drawdown">
                            {prettyPrint(this.props.category.drawdown - this.props.category.reimbursement)}
                        </div>
                        <div
                            className={this.isRoot(this.props.category) ? "col table-col" : "col table-col font-weight-bold"}
                            data-label="Remaining Balance">
                            {prettyPrint(this.props.category.remainingBalance)}
                        </div>
                        <div
                            className={this.isRoot(this.props.category) ? "col table-col" : "col table-col font-weight-bold"}
                            data-label="Outstanding Balance">
                            {prettyPrint(this.props.category.outstandingBalance)}
                        </div>
                        <div
                            className={this.isRoot(this.props.category) ? "col table-col" : "col table-col font-weight-bold"}
                            data-label="Loan To Cost">
                            {prettyPrint(this.props.category.loanToCost)}
                        </div>
                    </React.Fragment>
                    }
                </div>
            </div>
        );
    }

}

BudgetExecutionCategoryView.propTypes = {
    category: PropTypes.shape.isRequired,
    className: PropTypes.string,
};

BudgetExecutionCategoryView.defaultProps = {
    className: 'category-view'
};

export default BudgetExecutionCategoryView;
