import React from "react";
import InvoiceManagementComponentContainer from "../invoice/support/project/InvoiceManagementComponentContainer";
import LinkButton from "../../components-shared/LinkButton";
import Breadcrumbs from "../../components-shared/Breadcrumbs/Breadcrumbs";
import RightSideModal from "../../components-shared/RightSideModal/RightSideModal";

export default function ManageProjectInvoicesPage(props) {
    const projectId = props.match.params.projectId;
    const optionalRequestParams = props.location.state;
    
    return (
            <React.Fragment>
            <Breadcrumbs breadcrumbs={[{url: '/projects', title: 'Home'}, {url: `/project/${projectId}`, title: props.projectDetails && props.projectDetails.name}, {url: `/invoices/COST`, title: 'Invoices'}]} />
            <header className="kb-header px-3">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 d-flex align-items-center ">
                        <div className="kb-title_page d-flex align-items-center py-4">
                            <h1 className="h4 font-weight-normal m-0">Manage Invoices</h1>
                        </div>
                        <nav className="kb-nav_ctas ml-auto">
                         <LinkButton className="btn btn-primary" to={"/newInvoice/" + projectId}>
                                Add new invoice
                            </LinkButton>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
        <main className="kb-main kb-main_invoices px-3 py-4 bg-white">
            <div className="container-fluid">
                <div className="row">
                 <div className="col-12 pb-5">
                     <InvoiceManagementComponentContainer
                            projectId={projectId}
                            optionalRequestParams={optionalRequestParams}
                        />
                </div>
            </div>
            </div>
        </main> 
           
            <RightSideModal 
                items={['Dragée brownie chocolate bar oat cake. Icing tiramisu chocolate bar. Biscuit halvah pastry cotton candy croissant chocolate cake gummi bears wafer cake. ', 'Cotton candy lollipop topping marshmallow gummi bears.', 'Candy croissant gingerbread cookie sweet macaroon. Candy canes macaroon candy canes marshmallow sweet candy muffin donut. Pudding chocolate sweet cake toffee. ']}
            />
         </React.Fragment>
    );
}
