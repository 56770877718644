import React, {Component} from "react";
import {Button, FormLabel, Form, FormControl, FormGroup} from "react-bootstrap";
//import Select from 'react-select';
import {registerUser} from "../actions/userActions";
//import {userRoles} from "../support/UserConstants";
import PropTypes from "prop-types";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import {toast} from "react-toastify";
import {withRouter} from "react-router-dom";
import ReactPasswordStrength from 'react-password-strength'
import {getRoles} from "../actions/rolesActions";
import '../styles/dropdown.css';

class RegisterForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            firstName: '',
            lastName: '',
            companyName: '',
            phoneNumber: '',
            additionalInfo: '',
            password: '',
            confirmPassword: '',
            userRoleIds: [''],
            error: '',
            roles: [],
            isSubmited: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.validateForm = this.validateForm.bind(this);

        this.dismissError = this.dismissError.bind(this);

        this.handleRoleDelete = this.handleRoleDelete.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.handleAddRole = this.handleAddRole.bind(this);
        this.isEmpty = this.isEmpty.bind(this);
        this.hasErrors = this.hasErrors.bind(this);
        this.rolesAreValid = this.rolesAreValid.bind(this);
    }


    componentDidMount() {
        getRoles().then(response => {
            this.setState({
                roles: response
            })
        })
    }

    getAssignableRoles(index) {
        if (this.state.roles.length) {
            let allRoles = this.state.roles;
            let assignedRoleIds = this.state.userRoleIds;

            for (let i = 0; i < allRoles.length; i++) {
                allRoles[i].selectedIndex = this.getIndex(assignedRoleIds, allRoles[i].id);
            }

            return allRoles.filter(role => {
                if (role.selectedIndex === index) {
                    return true;
                }
                return !assignedRoleIds.includes(role.id);
            });
        }
        return [];
    }

    getIndex(assignedRoleIds, id) {
        return assignedRoleIds.findIndex(roleId => roleId === id);
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handlePhoneNumberChange(value) {
        this.setState({
            phoneNumber: value
        })
    }

    handlePasswordChange(passwordData) {
        this.setState({
            password: passwordData.password
        })
    }

    handleConfirmPasswordChange(passwordData) {
        this.setState({
            confirmPassword: passwordData.password
        })
    }

    hasErrors(stateField, fieldName, formValidation, inFormError, fieldType) {
        const errorsArray = [{
            errMsg: `The ${fieldName} should have only alphanumeric characters and white spaces`,
            isValid: (value) => {
                return value && /^[a-z 0-9]+$/i.test(value);
            },
            usedFor: ['text']
        },
        {
            errMsg: `The ${fieldName} field should not start or end with a whitespace`,
            isValid: (value) => {
                return value && !value.startsWith(' ') && !value.endsWith(' ');
            },
            usedFor: ['text', 'email', 'password', 'confirmPassword']
        },
        {
            errMsg: `The ${fieldName} field is not valid`,
            isValid: (value) => {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(value).toLowerCase());
            },
            usedFor: ['email']
        },
        {
            errMsg: `Passwords must match!`,
            isValid: (value) => {
                return value === this.state.password;
            },
            usedFor: ['confirmPassword']
        },
        {
            errMsg: `The ${fieldName} field should not be empty or have only whitespaces`,
            isValid: (value) => {
                return value && value.replace(/\s/g, '').length > 0;
            },
            usedFor: ['text', 'email', 'password', 'confirmPassword']
        }];

        
        let errorsFound = false;
        let errorsMessageFound = '';
        if (stateField === 'userRoleIds') {
            if (formValidation) {
                if (!this.rolesAreValid()) {
                    errorsFound = true;
                }
            } else {
                if (!this.rolesAreValid()) {
                    errorsMessageFound = 'User Role is required!';
                }
            }
        } else {
            
            errorsArray.forEach(error => {
                if (error.usedFor.includes(fieldType)){
                    if (formValidation) {
                        if (!error.isValid(this.state[stateField])) {
                            errorsFound = true;
                        }
                    } else {
                        if (!error.isValid(this.state[stateField])) {
                            errorsMessageFound = error.errMsg;
                        }
                    }
                }
            });
        }
        if (inFormError) {
            return errorsMessageFound;
        } else if (formValidation) {
            return errorsFound;
        } else {
            return this.setState({error: errorsMessageFound});
        }

    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            isSubmited: true
        }, () => {
            if (this.validateForm()) {
                const objToSend = {
                    email: this.state.email,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    companyName: this.state.companyName,
                    phoneNumber: this.state.phoneNumber,
                    additionalInfo: this.state.additionalInfo,
                    password: this.state.password,
                    confirmPassword: this.state.confirmPassword,
                    userRoleIds: this.state.userRoleIds,
                    error: '',
                    roles: this.state.roles
                }
                registerUser(objToSend).then((json) => {
                    if (json.code) {
                        return this.setState({error: json.message});
                    } else {
                        toast.info("User registered successfully", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    }
                    this.props.closePopup();
                });
            }
    
            return this.setState({error: ''});

        });
    }

    validateForm() {
        let rolesAreValid = this.rolesAreValid();

        
        const firstName = !this.hasErrors('firstName', 'First Name', true, false, 'text');
        const lastName = !this.hasErrors('lastName', 'Last Name', true, false, 'text');
        const companyName = !this.hasErrors('companyName', 'Company name', true, false, 'text');
        
        const email = !this.hasErrors('email', 'Email', true, false, 'email');

        const password = !this.hasErrors('password', 'Password', true, false, 'password');
        const confirmPassword = !this.hasErrors('confirmPassword', 'Confirm Password', true, false, 'confirmPassword');

        const valuesExist = email && firstName && lastName && companyName && password && confirmPassword;
        return valuesExist  && rolesAreValid;
    }

    rolesAreValid() {
        return !this.state.userRoleIds.some(this.isEmpty);
    }

    isEmpty(value) {
        return value === '';
    }

    dismissError() {
        this.setState({error: ''});
    }

    handleRoleChange(e, i) {
        let userRoleIds = this.state.userRoleIds;
        userRoleIds[i] = e.target.value;
        this.setState({
            userRoleIds: userRoleIds
        });
    }

    handleRoleDelete(i) {
        let userRoleIds = this.state.userRoleIds;
        userRoleIds.splice(i, 1);
        this.setState({
            userRoleIds: userRoleIds
        });
    }

    handleAddRole() {
        let userRoleIds = this.state.userRoleIds;
        userRoleIds.push('');
        this.setState({
            userRoleIds: userRoleIds
        });
    }

    render() {
        const inputClassName = " ";

        return (
            <form onSubmit={this.handleSubmit}>
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-12">
                            <FormGroup className={inputClassName} controlId="email">
                                <FormLabel>Email</FormLabel>
                                <input type="email" style={{opacity: "0", position: 'absolute', left: '-50000px'}} name="email"  defaultValue='' autoComplete="email"/>
                                <FormControl
                                    type="text"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                />
                                <div className="text-danger"><small>{(this.state.isSubmited) && this.hasErrors('email', 'Email', false, true, 'email')}</small></div>
                            </FormGroup>
                        </div>
                        <div className="col-12">
                            <FormGroup className={inputClassName} controlId="firstName">
                                <FormLabel>First Name</FormLabel>
                                <FormControl
                                    type="firstName"
                                    value={this.state.firstName}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                />
                                <div className="text-danger"><small>{(this.state.isSubmited) && this.hasErrors('firstName', 'First Name', false, true, 'text')}</small></div>
                            </FormGroup>
                        </div>
                        <div className="col-12">
                            <FormGroup className={inputClassName} controlId="lastName">
                                <FormLabel>Last Name</FormLabel>
                                <FormControl
                                    type="lastName"
                                    value={this.state.lastName}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                />
                                <div className="text-danger"><small>{(this.state.isSubmited) && this.hasErrors('lastName', 'Last Name', false, true, 'text')}</small></div>
                            </FormGroup>
                        </div>
                        <div className="col-12">
                            <FormGroup className={inputClassName} controlId="companyName">
                                <FormLabel>Company Name</FormLabel>
                                <FormControl
                                    type="companyName"
                                    value={this.state.companyName}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                />
                                <div className="text-danger"><small>{(this.state.isSubmited) && this.hasErrors('companyName', 'Company Name', false, true, 'text')}</small></div>
                            </FormGroup>
                        </div>
                        <div className="col-12">
                            <FormGroup className={inputClassName} controlId="phoneNumber">
                                <FormLabel>Phone Number</FormLabel>
                                <PhoneInput
                                    placeholder="Enter phone number"
                                    value={this.state.phoneNumber}
                                    onChange={this.handlePhoneNumberChange}
                                />
                            </FormGroup>
                        </div>
                        <div className="col-12">
                            <FormGroup className={inputClassName} controlId="additionalInfo">
                                <FormLabel>Additional info</FormLabel>
                                <input type="additionalInfo" style={{opacity: "0", position: 'absolute', left: '-50000px'}}/>
                                <FormControl
                                    type="additionalInfo"
                                    componentclass="textarea"
                                    value={this.state.additionalInfo}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </div>
                        <div className="col-12">
                            <FormLabel>User Role</FormLabel>
                            {this.state.userRoleIds && this.state.userRoleIds.length > 0 &&
                            this.state.userRoleIds.map((userRoleId, i) => (
                                <React.Fragment key={userRoleId + i}>
                                    <div className="dropdown-role__container d-flex w-100">
                                        <div className="dropdown-role__input">
                                            <FormGroup className={inputClassName} controlId={"userRoleId" + i}>

                                                <Form.Control
                                                    as="select"
                                                    value={this.state.userRoleIds && this.state.userRoleIds[i] && this.state.userRoleIds[i] !== '' ? this.state.userRoleIds[i] : ''}
                                                    onChange={(e) => this.handleRoleChange(e, i)}
                                                >
                                                    <option key={""} value={""}>{"Select role.."}</option>
                                                    {this.getAssignableRoles(i).map((item) => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>))}
                                                </Form.Control>
                                                
                                                <div className="text-danger"><small>{(this.state.isSubmited) && this.hasErrors('userRoleIds', "User Role", false, true, 'text')}</small></div>
                                            </FormGroup>
                                        </div>
                                        <div className="dropdown-role__icon">
                                            <button
                                                type="button"
                                                className="border-0 bg-transparent mb-0"
                                                disabled={this.state.userRoleIds.length === 1}
                                                onClick={() => this.handleRoleDelete(i)}>
                                                <i className="fas fa-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))
                            }
                            <div className="row mb-5">
                                <div className="col-md-12">
                                    <button type="button" className="btn btn-primary kb-btn-save_permissions"
                                            onClick={() => this.handleAddRole()}>Add
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div className="col-12">
                            <FormGroup className={inputClassName}>
                                <FormLabel>Password</FormLabel>
                                <input type="password" style={{opacity: "0", position: 'absolute', left: '-50000px'}}/>
                                <ReactPasswordStrength
                                    className={inputClassName}
                                    minLength={8}
                                    minScore={2}
                                    scoreWords={['weak', 'okay', 'good', 'strong', 'very strong']}
                                    changeCallback={(e) => this.handlePasswordChange(e)}
                                    inputProps={{name: "password", autoComplete: "off", className: "form-control"}}
                                    controlId="password"
                                />
                                <div className="text-danger"><small>{(this.state.isSubmited) && this.hasErrors('password', 'Password', false, true, 'password')}</small></div>
                            </FormGroup>
                        </div>
                        <div className="col-12">
                            <FormGroup className={inputClassName}>
                                <FormLabel>Confirm Password</FormLabel>
                                <ReactPasswordStrength
                                    className={inputClassName}
                                    minLength={8}
                                    minScore={2}
                                    scoreWords={['weak', 'okay', 'good', 'strong', 'very strong']}
                                    changeCallback={(e) => this.handleConfirmPasswordChange(e)}
                                    inputProps={{
                                        name: "confirmPassword",
                                        autoComplete: "off",
                                        className: "form-control"
                                    }}
                                    controlId="confirmPassword"
                                />
                                <div className="text-danger"><small>{(this.state.isSubmited) && this.hasErrors('confirmPassword', 'Confirm Password', false, true, 'confirmPassword')}</small></div>
                            </FormGroup>
                        </div>
                        <div className="col-12 mt-3 mb-5">
                            <Button className="mr-3"
                                    variant="primary"
                                    type="submit"
                            >
                                Save
                            </Button>
                            {
                                this.props.closePopup &&
                                <Button
                                    className="btn btn-light ml-3"
                                    variant='light'
                                    onClick={this.props.closePopup}
                                >
                                    Cancel
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

RegisterForm.propTypes = {
    closePopup: PropTypes.func,
};

export default withRouter(RegisterForm);
