import React from "react";

import {NavDescriptor, NavPanel} from "../../components-shared/NavPanel";

import CompanySettingsPage from "./CompanySettingsPage";
import ViewAllTemplatesPage from "../template/pages/ViewAllTemplatesPage";
//import SettingsPageTitle from "./SettingsPageTitle";
import {viewAndManageAppSettings} from "../../main-components/UserPermissionsFunctions";
import PermissionsPage from "../user/UserPermissionsPage/PermissionsPage";

export default function SettingsNavigation(props) {

    const navDescriptors = [
        new NavDescriptor('/settings', viewAndManageAppSettings(), '/company', 'Company settings', CompanySettingsPage, "fas fa-cog"),
        new NavDescriptor('/settings', viewAndManageAppSettings(), '/roles', 'Roles and Permissions', PermissionsPage, "fas fa-gavel"),
        new NavDescriptor('/settings', viewAndManageAppSettings(), '/templates', 'Templates', ViewAllTemplatesPage, "fas fa-file")
    ];

    return (
        <React.Fragment>
           
            <NavPanel navDescriptors={navDescriptors}
                      navBarClassName={"settings-navigation"}
                      contentClassName={"settings-content-wrapper"}
                      {...props}/>
        </React.Fragment>
    );
}
