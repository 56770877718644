import React, {Component, Fragment} from 'react';
import {confirmAlert} from 'react-confirm-alert';
import { withRouter } from 'react-router-dom';
import {getAllPermissions, getAlluserRoles, setAlluserRoles} from "../actions/userPermissionsActions";
import PermissionsTableHeader from './PermissionsTableHeader';
import RouteLeavingGuard from './RouteLeavingGuard';


class UserPermissionsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            permissionGroups: [],
            permissions: [{
                permissionGroup: ''
            }],
            editMode: false,
            initialPermissionGroups: [],
            initialPermissions: [{
                permissionGroup: ''
            }],
            roles: [],
            initialRoles: [],
            statePermissions: {},
            initialStatePermissions: {}
        };

        this.getAllPermissionsForGroup = this.getAllPermissionsForGroup.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
        this.startEdit = this.startEdit.bind(this);
        this.setPermissionValue = this.setPermissionValue.bind(this);
        this.updateRoles = this.updateRoles.bind(this);
        this.getPermissions = this.getPermissions.bind(this);
        this.getRoles = this.getRoles.bind(this);
        this.setSingleStatePermissions = this.setSingleStatePermissions.bind(this);
        this.mounted = true;
    }


    componentDidMount() {
        this.getPermissions();
        this.getRoles();
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        this.mounted = false;
    }


    getRoles() {
        getAlluserRoles().then(roles => {
            if (this.mounted) {
                this.setState({
                    roles: roles,
                    initialRoles: roles
                }, () => this.setState({editMode: false}));
            }
        });
    }

    getPermissions() {
        getAllPermissions().then(permissions => {
            if (this.mounted) {
                
                const statePermissions = {};
                this.props.roles.forEach(rl => {
                    
                    permissions.permissions.forEach(perm => {
                        const boolValue = perm.assignedUserRoles.includes(rl.name);
                        if (statePermissions[rl.name]) {
                            statePermissions[rl.name][perm.permissionName] = boolValue;
                        } else {
                            statePermissions[rl.name] = {
                                [perm.permissionName]: boolValue
                            }
                        }
                    })
                });
                this.setState({
                    permissions: permissions.permissions.slice(),
                    permissionGroups: permissions.permissionGroups.slice(),
                    initialPermissions: permissions.permissions.slice(),
                    initialPermissionGroups: permissions.permissionGroups.slice(),
                    statePermissions: statePermissions,
                    initialStatePermissions: statePermissions
                });
            }
        });
    }

    getAllPermissionsForGroup(group) {
        const permissions = this.state.permissions.slice();
        const wantedPermissions = [];
        permissions.forEach(permission => {
            if (permission.permissionGroup === group) {
                wantedPermissions.push(permission);
            }
        });
        return wantedPermissions;
    }

    confirmCancelObj() {
        return {
            title: 'Confirm to submit',
            message: 'Are you sure you want to cancel?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: this.onClickCancel
                },
                {
                    label: 'No',
                }
            ]
        }
    };

    onClickCancel() {
        this.props.getRoles();
        this.props.getPermissions();
        this.getPermissions();
        this.getRoles();
    }

    cancelEdit() {
        confirmAlert(this.confirmCancelObj());
    }

    startEdit() {
        this.setState({
            editMode: true
        })
    }

    setPermissionValue(permissionId, value) {
        let newPermissions = this.state.permissions.slice();
        let newRoles = this.state.roles.slice();
        if (value !== "ADMIN" && value !== "Admin" && value !== "admin") {
            newRoles = newRoles.map(role => {
                const roleName = role.name.toUpperCase().replace(" ", "_");
                if (roleName === value) {
                    if (role.permissionIds.includes(permissionId)) {
                        role.permissionIds = role.permissionIds.filter(permission => permission !== permissionId);
                    } else {
                        if (role && role.permissionIds instanceof Array) {
                            role.permissionIds.push(permissionId);
                        }
                    }
                }
                return role;
            });
            newPermissions = newPermissions.map(permission => {
                if (permission.id === permissionId) {
                    if (permission.assignedUserRoles.includes(value)) {
                        permission.assignedUserRoles = permission.assignedUserRoles.filter(role => role !== value);
                    } else {
                        permission.assignedUserRoles.push(value);
                    }
                }
                return permission;
            });
            this.setState({
                permissions: newPermissions,
                roles: newRoles
            });
        }
    }

    confirmUpdateObj() {
        return {
            title: 'Confirm to submit',
            message: 'Are you sure you want to save?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const rolesToSend = [];
                        this.state.roles.forEach(rl => {
                            let permissionIds = [];
                            for (const el in this.state.statePermissions[rl.name]) {
                                if (el) {
                                    this.state.permissions.forEach(perm => {
                                        if (perm.permissionName === el && this.state.statePermissions[rl.name][perm.permissionName]) {
                                            permissionIds.push(perm.id);
                                        }
                                    })
                                }
                            }
                            rl.permissionIds = permissionIds;
                            rolesToSend.push(rl);
                        });
                        setAlluserRoles(
                            rolesToSend
                            ).then((res) => {
                            this.props.getRoles();
                            this.props.getPermissions();
                            this.getRoles();
                            this.getPermissions();
                        });
                    }
                },
                {
                    label: 'No',
                }
            ]
        };
    }

    updateRoles() {
        confirmAlert(this.confirmUpdateObj());
    }

    setSingleStatePermissions(roleNm, permissionNm) {
        const newStatePerm = {...this.state.statePermissions};
        if (newStatePerm[roleNm]) {
            newStatePerm[roleNm] = {
                ...newStatePerm[roleNm],
                [permissionNm]: newStatePerm[roleNm] && newStatePerm[roleNm][permissionNm] ? false : true
            }
        }
        this.setState({statePermissions: newStatePerm});
    }


    render() {
        const permissionGroups = this.state.permissionGroups.slice();

        // noinspection CheckTagEmptyBody
        return (
            <div> 
            <RouteLeavingGuard 
                //shouldBlockNavigation={editMode} 
                message="You have unsaved changes, are you sure you want to leave?"
                when={this.state.editMode}
                // Navigate function
                navigate={path => this.props.history.push(path)}
                    // Use as "message" prop of Prompt of React-Router
                shouldBlockNavigation={location => {
                        // This case it blocks the navigation when: 
                        if (this.state.editMode) {
                            return true
                        } 
                        return false
                    }
                }
            />
            <div className="row">
            <div className="col-12 d-flex align-items-center py-3">
                    <div className="kb-title_page d-flex align-items-center">
                        <h1 className="kb-title_page h4 font-weight-normal m-0">Permissions</h1>
                    </div>
                    <nav className="kb-nav_ctas ml-auto">
                        {this.state.editMode ? (
                            <Fragment>
                                <button type="button" className="btn btn-link kb-btn-cancel_permissions ml-3" variant='light'
                                        onClick={this.cancelEdit}>Cancel
                                </button>
                                <button type="button" className="btn btn-primary kb-btn-save_permissions ml-3"
                                        onClick={this.updateRoles}>Save
                                </button>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <button type="button" variant='secondary' className="btn btn-secondary kb-btn-edit_permissions ml-3"
                                        onClick={this.startEdit}>Edit Permissions
                                </button>
                            </Fragment>

                        )}
                    </nav>
                </div>
            </div>
            
            <div className="kb-table_permissions w-100">
            <div className="row w-100">
                
                <div className="col-12 bg-white py-4">
                    <table className=".kb-table-permissions  table table-hover">

                        {this.state.roles && this.state.roles.length > 0 && (
                            <PermissionsTableHeader referencePermissions={this.state.roles}/>)}

                        <tbody>

                        {permissionGroups.map(permissionGroup => {
                            const allPermissionsForGroup = this.getAllPermissionsForGroup(permissionGroup.permissionGroup);
                            return (
                                <Fragment key={permissionGroup.permissionGroup}>
                                    <tr>
                                        <td className="font-weight-bold border-none">{permissionGroup.humanReadableDescription}</td>
                                    </tr>
                                {this.state.roles && this.state.roles.length > 0 && allPermissionsForGroup.map(permission => {
                                    return (
                                        
                                        <tr key={permission.id}>
                                            <td className="custom-col-1">{permission.humanReadableDescription}</td>
                                            {
                                                this.state.roles.map(rl => (
                                                    <td key={rl.name}>
                                                        {
                                                        <label className="fancy" htmlFor={rl.name + permission.permissionName}>
                                                            <input  
                                                                name={rl.name + permission.permissionName} type="checkbox" 
                                                                value={this.state.statePermissions[rl.name] && this.state.statePermissions[rl.name][permission.permissionName] === true ? true : false} 
                                                                checked={this.state.statePermissions[rl.name] && this.state.statePermissions[rl.name][permission.permissionName] === true ? true : false} 
                                                                disabled={!this.state.editMode} 
                                                                onChange={()=>{this.setSingleStatePermissions(rl.name, permission.permissionName)}} 
                                                            />
                                                        </label>
                                                        }
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    )
                                })}
                                </Fragment>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
            </div>
        );
    }
}

export default withRouter(UserPermissionsPage);
