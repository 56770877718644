import React, {Component} from "react";
import {Button, FormControl, FormGroup, InputGroup} from "react-bootstrap";
import PropTypes from "prop-types";
import {insertCategoryTotal} from "../actions/categoriesTotalsActions";

class CategoryTotalEntryForm extends Component {

    constructor(props) { 
        super(props);
        this.state = {
            name: '',
            error: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.dismissError = this.dismissError.bind(this);
    };

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit(event) {
        event.preventDefault();

        if (!this.state.name) {
            return this.setState({error: 'Name is required!'});
        }

        if (this.state.error === '') {
            let categoryTotalObject = {
                projectId: this.props.projectId,
                name: this.state.name,
                categoryType: this.props.categoryType.type
            };

            insertCategoryTotal(categoryTotalObject).then((result) => {
                if (result.code) {
                    return this.setState({error: result.message});
                } else {
                    this.props.onSuccessfulSubmit(result);
                }
            });
            //don't return from here, fall through to reset the form
        }

        this.setState({name: '', error: ''});
    }

    validateForm() {
        return this.state.name.length > 0;
    }

    dismissError() {
        this.setState({error: ''});
    }

    render() {
        const inputClassName = " ";
        return (
            <form className={"add-category-total"} onSubmit={this.handleSubmit}>
                <FormGroup className={inputClassName} controlId="name">
                    <InputGroup >
                        <FormControl
                            placeholder={"Add " + this.props.categoryType.displayValue + " Total"}
                            type="text"
                            value={this.state.name}
                            onChange={this.handleChange}
                            autoComplete="off"
                        />
                            <span className="input-group-btn">
                                <Button
                                    className="btn-secondary rounded-0" variant="secondary"
                                    type="submit"
                                    disabled={!this.validateForm()}
                                >
                                    Add
                                </Button>
                            </span>
                    </InputGroup>
                </FormGroup>
            </form>
        );
    }
}

CategoryTotalEntryForm.propTypes = {
    projectId: PropTypes.string.isRequired,
    onSuccessfulSubmit: PropTypes.func.isRequired,
    categoryType: PropTypes.shape({
        displayValue: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    }).isRequired,
};

// componentType = category or subcategory
// categoryType = COST, INCOME, FINANCING or INVESTMENT

export default CategoryTotalEntryForm;
