import React from "react";
import PropTypes from "prop-types";

export default function UserSearchCard(props) {

    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">{props.cardTitle}</h5>
                <h6 className="card-subtitle mb-2">{props.cardSubtitle}</h6>
                <p className="card-text">{props.cardText}</p>
                <button
                    className="btn btn-secondary"
                    onClick={props.onClickFunction}
                >
                    {props.buttonName}
                </button>
            </div>
        </div>
    )
}

UserSearchCard.propTypes = {
    cardTitle: PropTypes.string.isRequired,
    cardSubtitle: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    cardText: PropTypes.string.isRequired,
    onClickFunction: PropTypes.func.isRequired,
    buttonName: PropTypes.string.isRequired
};
